import { makeStyles } from "@material-ui/core/styles";
import { Theme } from "src/web/themes/theme";

export const useStyles = makeStyles((theme: Theme) => ({
  root: {
    position: "relative",
    width: "100%",
    height: `calc(100vh - ${
      theme.trip_shock_theme.environment.toolbarHeight +
      theme.trip_shock_theme.environment.headerHeight
    }px)`,
    minHeight: "60vh",
    borderRadius: 0,
    "@media (max-width: 850px)": {
      height: `calc(100vh - ${
        theme.trip_shock_theme.environment.toolbarHeight +
        theme.trip_shock_theme.environment.headerHeight
      }px + 30px)`,
    },
  },
  tableRelative: {
    position: "relative",
    "& .MuiTableCell-stickyHeader": {
      zIndex: 0,
      backgroundColor: "#fcfdfe",
      padding: "7px 30px",
      borderBottom: "1px solid #dedfe0",
      "@media (max-width: 850px)": {
        padding: "2px 23px",
      },
    },
  },
  customScroll: {
    position: "absolute",
    bottom: "150px",
  },
  tableLoader: {
    position: "absolute",
    left: "50%",
    transform: "translateX(-50%)",
    height: "300px",
    width: "100%",
    display: "flex",
    alignItems: "center",
  },
  adornments: {
    display: "flex",
    justifyContent: "flex-end",
    minWidth: "10rem",
    color: theme.trip_shock_theme.text.trip_shock_white,
  },
  table_header_container: {
    cursor: "pointer",
    display: "flex",
    alignItems: "center",
    fontWeight: 500,
    fontSize: "14px",
  },
  table_header_icon_container: {
    cursor: "pointer",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },

  icons_container: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "flex-start",
  },
  icon_container: {
    marginLeft: "10px",
    height: "24px",
    width: "24px",
  },
  table_row: {
    cursor: "pointer",
    height: '70px',
  },
  inner_subtext: {
    fontSize: "12px",
    fontWeight: 500,
    color: "#4F4F4F",
  },
  custom_chip: {
    textTransform: "uppercase",
    minWidth: "70px",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    height: "19px",
    borderRadius: "2px",
  },
  chip_text: {
    fontSize: "10px",
    fontWeight: 700,
    padding: "0px",
    margin: "0px",
  },
  table_cell: {
    color: "#4F4F4F",
    fontWeight: 500,
    fontSize: "12px",
    whiteSpace: "nowrap",
  },
}));
