import {
  Box,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
  useMediaQuery,
} from '@material-ui/core';
import React from 'react';
import { useStyles } from './styles';

interface Props {
  invoice_item: InvoiceItem[];
}

const InvoiceTable = ({ invoice_item }: Props) => {
  const classes = useStyles();

  const mdMatches = useMediaQuery('(max-width: 1020px)');

  return (
    <Box>
      <Typography variant="h2">Bill</Typography>
      {mdMatches ? (
        <Box className={classes.mobileTable}>
          {invoice_item.map((invoiceItem) => {
            const {
              cart_items_id,
              customer,
              dates,
              product_name,
              booked_info,
            } = invoiceItem;
            return (
              <Box key={cart_items_id} className={classes.mobileTableRow}>
                <Box className={classes.mobileTableCell}>
                  <Typography className={classes.mobileTableHeader}>
                    Id
                  </Typography>
                  <Typography className={classes.mobileTableBody}>
                    {cart_items_id}
                  </Typography>
                </Box>
                <Box className={classes.mobileTableCell}>
                  <Typography className={classes.mobileTableHeader}>
                    Customer
                  </Typography>
                  <Typography className={classes.mobileTableBody}>
                    {customer}
                  </Typography>
                </Box>
                <Box className={classes.mobileTableCell}>
                  <Typography className={classes.mobileTableHeader}>
                    Date of trip
                  </Typography>
                  <Typography className={classes.mobileTableBody}>
                    {dates}
                  </Typography>
                </Box>
                <Box className={classes.mobileTableCell}>
                  <Typography className={classes.mobileTableHeader}>
                    Activity name
                  </Typography>
                  <Typography className={classes.mobileTableBody}>
                    {product_name}
                  </Typography>
                </Box>
                <Box className={classes.mobileTableCell}>
                  <Typography className={classes.mobileTableHeader}>
                    Tickets purchased
                  </Typography>
                  <Typography className={classes.mobileTableBody}>
                    {booked_info}
                  </Typography>
                </Box>
              </Box>
            );
          })}
        </Box>
      ) : (
        <Table className={classes.table}>
          <TableHead>
            <TableRow>
              <TableCell width={"15%"} >Id</TableCell>
              <TableCell width={"20%"}>Customer</TableCell>
              <TableCell width={"15%"}>Date of trip</TableCell>
              <TableCell width={"25%"}>Activity name</TableCell>
              <TableCell width={"25%"}>Tickets purchased</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {invoice_item.map((invoiceItem) => {
              const {
                cart_items_id,
                customer,
                dates,
                product_name,
                booked_info,
              } = invoiceItem;
              return (
                <TableRow key={cart_items_id}>
                  <TableCell>{cart_items_id}</TableCell>
                  <TableCell>{customer}</TableCell>
                  <TableCell>{dates}</TableCell>
                  <TableCell>{product_name}</TableCell>
                  <TableCell>{booked_info}</TableCell>
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      )}
    </Box>
  );
};

export default InvoiceTable;
