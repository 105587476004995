import React, { useEffect, useState } from 'react';
import {
  Box,
  TextField,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  IconButton,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
} from '@material-ui/core';
import { useDispatch, useSelector } from 'react-redux';
import CloseIcon from 'src/assets/icons/light/close.svg';
import {
  tableState,
  setDetailedFilters,
  setAllDetailedFilters,
  applyDetailedFilters,
  setLoading,
  clearFilterStates,
} from 'src/web/features/table/sliceTables';
import { useStyles } from './styles';
//import { useRegionsAutocomplete } from 'src/web/hooks/autocompletes/useRegionAutocomplete';
import { ArrivalDateFilter } from './DateFilter';
import { usePartnersTripsAutocomplete } from 'src/hooks/autocompletes/usePartnersTripsAutocomplete';
import { auth } from 'src/redux/Authentication/sliceAuthentication';
import moment from 'moment';
import { CustomSelect } from '../../UI/CustomSelect';
import { IDetailedFilters } from 'src/web/features/table/interfaces';

interface ISkip {
  usePartnersTripsAutocomplete: boolean;
  useActivitiesAutocomplete: boolean;
  useRegionsAutocomplete: boolean;
}

export const DetailedFilters = ({ open, setOpen }: any) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { user } = useSelector(auth);

  const { detailedFilters, filter } = useSelector(tableState);
  const [choosedStatuses, setChoosedStatuses] = useState<string[]>([]);
  const [skip, setSkip] = useState<ISkip>({
    usePartnersTripsAutocomplete: true,
    useActivitiesAutocomplete: true,
    useRegionsAutocomplete: true,
  });
  const [currentFilters, setCurrentFilters] = useState<IDetailedFilters>(
    detailedFilters as IDetailedFilters
  );

  const skipHandlerChange = (key: keyof ISkip) => {
    setSkip((prev) => ({ ...prev, [key]: false }));
  };

  const statuses = filter?.find(
    (filter) => filter.field === 'state_name'
  )?.values;

  useEffect(() => {
    if (statuses && statuses?.some((item) => item.isSelected)) {
      const newValues = statuses
        .filter((item) => item.isSelected)
        .map((item) => item.value);
      setChoosedStatuses(newValues);
    }
  }, [statuses]);

  // const { data: regionsData, loading: regionsLoading } = useRegionsAutocomplete(
  //   skip.useRegionsAutocomplete
  // );

  const {
    products: productsData,
    destinations: destinationsData,
    loading,
  } = usePartnersTripsAutocomplete(+user['custom:partner_id'], false, false);

  const onHandlerChange = ({
    key,
    value,
  }: {
    key: string;
    value: string | null;
  }) => {
    setCurrentFilters((prev) => {
      return { ...prev, [key]: { ...prev[key], value: value } };
    });
  };

  useEffect(() => {
    if (detailedFilters) {
      setCurrentFilters(detailedFilters);
    }
  }, [detailedFilters]);

  if (!detailedFilters) return null;

  const handleChangeStatuses = (value: string[]) => {
    setChoosedStatuses(value);
    onHandlerChange({ key: 'state_name', value: JSON.stringify(value) });
  };

  return (
    <Dialog
      fullWidth={true}
      className={classes.dialog}
      open={open}
      onClose={() => {
        setOpen(false);
        setCurrentFilters(detailedFilters);
      }}>
      <DialogTitle className={classes.dialogTitle}>
        <Box className={classes.header}>
          <Box className={classes.title} mr={12} px={1}>
            Select additional filters
          </Box>
          <IconButton
            onClick={() => {
              setOpen(false);
              setCurrentFilters(detailedFilters);
            }}
            className={classes.closeIcon}>
            <CloseIcon />
          </IconButton>
        </Box>
      </DialogTitle>
      <DialogContent className={classes.dialogContent}>
        <Box display="flex" flexWrap="wrap" className={classes.fieldsWrapper}>
          <Box p={1} width={1} mt={1} className={classes.statusSelector}>
            <FormControl variant="outlined" fullWidth>
              <InputLabel className={classes.selectorLabel} shrink>
                Status
              </InputLabel>
              <Select
                className={classes.statusSelect}
                value={choosedStatuses}
                placeholder="Choose status"
                MenuProps={{
                  PaperProps: {
                    className: classes.statusSelectPaper,
                  },
                }}
                multiple
                onChange={(e) =>
                  handleChangeStatuses(e.target.value as string[])
                }>
                {statuses?.map((status: any) => (
                  <MenuItem value={status.value} key={status.id}>
                    {status.label}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Box>
          <Box mt={1} p={1} className={classes.fieldBox}>
            <TextField
              fullWidth
              label={'Confirmation number'}
              placeholder={'#'}
              variant="outlined"
              value={currentFilters?.id.value || ''}
              onChange={(e) =>
                onHandlerChange({
                  key: 'id',
                  value: !e.target.value
                    ? null
                    : Number.isInteger(+e.target.value)
                    ? e.target.value
                    : currentFilters?.id?.value || null,
                })
              }
              className={classes.filterArea}
              InputLabelProps={{
                shrink: true,
              }}
            />
          </Box>
          <Box mt={1} p={1} className={classes.fieldBox}>
            <TextField
              fullWidth
              label={'Voucher number'}
              placeholder={'Number'}
              variant="outlined"
              value={currentFilters?.voucher.value || ''}
              className={classes.filterArea}
              onChange={(e) =>
                onHandlerChange({
                  key: 'voucher',
                  value: e.target.value || null,
                })
              }
              InputLabelProps={{
                shrink: true,
              }}
            />
          </Box>
          <Box p={1} className={classes.fieldBox} mt={1}>
            <ArrivalDateFilter
              isRange
              startDate={currentFilters?.madeFrom.value}
              endDate={currentFilters?.madeTo.value}
              label={'Order date'}
              onChange={({ start, end }: any) => {
                onHandlerChange({
                  key: 'madeFrom',
                  value: start
                    ? moment(start)
                        .startOf('day')
                        .tz('America/Chicago', true)
                        .utc()
                        .format('YYYY-MM-DD HH:mm:ss')
                    : null,
                });
                onHandlerChange({
                  key: 'madeTo',
                  value: end
                    ? moment(end)
                        .endOf('day')
                        .tz('America/Chicago', true)
                        .utc()
                        .format('YYYY-MM-DD HH:mm:ss')
                    : null,
                });
              }}
            />
          </Box>
          <Box p={1} className={classes.fieldBox} mt={1}>
            <ArrivalDateFilter
              isRange
              startDate={currentFilters?.dateFrom.value}
              endDate={currentFilters?.dateTo.value}
              label={'Arrival date'}
              onChange={({ start, end }: any) => {
                onHandlerChange({
                  key: 'dateFrom',
                  value: start
                    ? moment(start)
                        .startOf('day')
                        .tz('America/Chicago', true)
                        .utc()
                        .format('YYYY-MM-DD HH:mm:ss')
                    : null,
                });
                onHandlerChange({
                  key: 'dateTo',
                  value: end
                    ? moment(end)
                        .endOf('day')
                        .tz('America/Chicago', true)
                        .utc()
                        .format('YYYY-MM-DD HH:mm:ss')
                    : null,
                });
              }}
            />
          </Box>
          <Box p={1} className={classes.fieldBox} mt={1}>
            <CustomSelect
              label={'Product'}
              list={productsData}
              loading={loading}
              placeholder={'Choose product'}
              deletable={true}
              onChange={(data: any) => {
                onHandlerChange({
                  key: 'product_id',
                  value: data
                    ? String(
                        productsData.find(
                          (item) => item.name === data.target.textContent
                        )?.id
                      )
                    : null,
                });
              }}
              value={{
                name:
                  productsData.find(
                    (i) => i.id === +(currentFilters?.product_id.value || 0)
                  )?.name || '',
              }}
              onFocus={() => {
                skip.usePartnersTripsAutocomplete &&
                  skipHandlerChange('usePartnersTripsAutocomplete');
              }}
              position="end"
            />
          </Box>
          <Box p={1} className={classes.fieldBox} mt={1}>
            <CustomSelect
              label={'Destination'}
              list={destinationsData}
              loading={loading}
              placeholder={'Choose destination'}
              deletable={true}
              onFocus={() =>
                skip.usePartnersTripsAutocomplete &&
                skipHandlerChange('usePartnersTripsAutocomplete')
              }
              onChange={(data: any) => {
                console.log(data);
                onHandlerChange({
                  key: 'city_id',
                  value: data
                    ? String(
                        destinationsData.find(
                          (item) =>
                            item.name ===
                            data.target.textContent.substr(
                              0,
                              data.target.textContent.length - 2
                            )
                        )?.id
                      )
                    : null,
                });
              }}
              value={{
                name:
                  destinationsData.find(
                    (i) => i.id === +(currentFilters?.city_id.value || 0)
                  )?.name || '',
              }}
              position="end"
            />
          </Box>
        </Box>
      </DialogContent>
      <DialogActions>
        <Box p={1} mr={1}>
          <Button
            variant="outlined"
            className={classes.resetButton}
            onClick={() => {
              dispatch(
                setDetailedFilters({
                  key: 'id',
                  value: null,
                  clear: true,
                })
              );
              setOpen(false);
              dispatch(clearFilterStates());
              dispatch(setLoading({ type: 'change', value: true }));
            }}>
            Reset filters
          </Button>
          <Button
            className={classes.button}
            color="secondary"
            onClick={() => {
              dispatch(setAllDetailedFilters(currentFilters));
              dispatch(applyDetailedFilters());
              setOpen(false);
              dispatch(setLoading({ type: 'change', value: true }));
            }}
            variant="contained">
            Apply filters
          </Button>
        </Box>
      </DialogActions>
    </Dialog>
  );
};
