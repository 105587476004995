import {
  Box,
  MenuItem,
  Popper,
  TextField,
  useMediaQuery,
} from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import { CustomAutocomplete } from 'src/web/components/UI/CustomAutocomplete';
import { useStyles } from '../styles';
import { usePartnersTripsAutocomplete } from 'src/hooks/autocompletes/usePartnersTripsAutocomplete';
import { useSelector } from 'react-redux';
import { auth } from 'src/redux/Authentication/sliceAuthentication';
import { useQuery } from '@apollo/client';
import { GET_TRIP } from 'src/api/calendar';

const CustomPopper = function (props: any) {
  const classes = useStyles();
  return <Popper {...props} className={classes.popper} placement="top" />;
};

type Props = {
  trip?: ITripCalendarData;
  setTrip: React.Dispatch<React.SetStateAction<ITripCalendarData>>;
  type: 'ticket' | 'schedule' | 'option';
  setType: React.Dispatch<
    React.SetStateAction<'ticket' | 'schedule' | 'option'>
  >;
  setLoading: React.Dispatch<React.SetStateAction<boolean>>;
  withoutOptions?: boolean;
  rateId?: number;
  setRateId?: React.Dispatch<React.SetStateAction<number>>;
};

export const TripBlock = ({
  type,
  trip,
  rateId,
  setTrip,
  setType,
  setLoading,
  setRateId,
  withoutOptions,
}: Props) => {
  const classes = useStyles();
  const { user } = useSelector(auth);
  const isMobile = useMediaQuery('(max-width: 960px)');
  const [selectedTrip, setSelectedTrip] = useState<number>(0);
  const partnerId = +user['custom:partner_id'];
  const [skip, setSkip] = useState({
    usePartnersTripsAutocomplete: false,
  });
  const [withRates, setWithRates] = useState(false);

  const { loading } = useQuery(GET_TRIP, {
    variables: {
      prm: { id: selectedTrip, user_id: 2 }, // FIXME: user_id
    },
    skip: !selectedTrip,
    onCompleted: (data) => {
      const trip = structuredClone(data.v1_activities_get);
      trip.schedules = trip.schedules.map((i: any) => ({
        ...i,
        checked: false,
      }));

      setTrip(trip);
      setWithRates(!!trip.rates?.length);
    },
    onError: (error) => {
      console.log(error);
    },
  });

  useEffect(() => {
    setLoading(loading);
  }, [loading]);

  const { products: tripsList, loading: tripsAutocompleteLoading } =
    usePartnersTripsAutocomplete(
      partnerId,
      skip.usePartnersTripsAutocomplete,
      true
    );

  const skipHandlerChange = (field: keyof typeof skip) => {
    setSkip((prev) => ({ ...prev, [field]: true }));
  };

  const types = withRates
    ? ['ticket']
    : withoutOptions
    ? ['ticket', 'schedule']
    : ['ticket', 'option', 'schedule'];

  return (
    <Box
      display="flex"
      alignItems="center"
      justifyContent="space-between"
      flexWrap={isMobile ? 'wrap' : 'nowrap'}
      gridGap={20}>
      <CustomAutocomplete
        label="Trips"
        placeholder="Choose trip"
        className={classes.inputWrapper}
        options={tripsList.map((i) => ({ id: +i.id, title: i.name }))}
        value={tripsList.find((i: any) => +i?.id === selectedTrip) || null}
        onFocus={
          skip.usePartnersTripsAutocomplete
            ? () => skipHandlerChange('usePartnersTripsAutocomplete')
            : undefined
        }
        onChange={(data: { id: number; title: string }) =>
          setSelectedTrip(data.id)
        }
        loading={tripsAutocompleteLoading}
        deletable={true}
        CustomPopper={CustomPopper}
        renderOption={(option: any) => (
          <Box width={1} className={classes.autocompleteItem}>
            <Box>{option.title}</Box>
          </Box>
        )}
      />

      <Box className={classes.inputWrapper} width={1}>
        <TextField
          value={type}
          className={classes.label}
          onChange={(e) => setType(e.target.value as typeof type)}
          select
          variant="outlined"
          label="Selection Type"
          fullWidth>
          {types.map((type) => {
            return (
              <MenuItem value={type} style={{ textTransform: 'capitalize' }}>
                {type[0].toLocaleUpperCase() + type.slice(1, type.length) + 's'}
              </MenuItem>
            );
          })}
        </TextField>
      </Box>
      {trip && withRates && setRateId && (
        <Box className={classes.inputWrapper} width={1}>
          <TextField
            value={rateId}
            className={classes.label}
            onChange={(e) => setRateId(+e.target.value)}
            select
            variant="outlined"
            label="Rate"
            fullWidth>
            {trip.rates.map((rate) => {
              return <MenuItem value={rate.id}>{rate.name}</MenuItem>;
            })}
          </TextField>
        </Box>
      )}
    </Box>
  );
};
