import React, { useState } from 'react';
import { Box, Typography } from '@material-ui/core';

import {
  ActionButton,
  PageContentHeader,
} from 'src/web/components/PageContentHeader';
import { EditNotificationsModal } from './EditNotificationsModal';

import EditIcon from 'src/assets/icons/web/edit.svg';

import { useStyles } from './styles';
import { IPartner } from 'src/web/pages/Settings';

export const NotificationsContent = ({ data }: { data: IPartner }) => {
  const classes = useStyles();
  const [open, setOpen] = useState(false);

  const buttons: ActionButton[] = [
    {
      icon: <EditIcon />,
      name: 'Edit',
      //onClick: () => setOpen(true),
      onClick: () => setOpen(false),
    },
  ];

  return (
    <Box pl="30px" pr="30px">
      <PageContentHeader title="Company emails" buttons={buttons} />

      <EditNotificationsModal open={open} setOpen={setOpen} />

      <Box className={classes.contentWrapper}>
        <Box className={classes.emailWrapper}>
          <Box className={classes.emailContainer}>
            <Typography className={classes.emailLabel}>
              Admin only email
            </Typography>
            <Typography className={classes.emailValue}>
              {data.admin_only_email || '-'}
            </Typography>
          </Box>
        </Box>

        {/* <Box className={classes.emailWrapper}>
          <Box className={classes.emailContainer}>
            <Typography className={classes.emailLabel}>
              Accounting email
            </Typography>
            <Typography className={classes.emailValue}>
              accounting@gmail.com
            </Typography>
          </Box>
        </Box> */}

        <Box className={classes.emailWrapper}>
          <Box className={classes.emailContainer}>
            <Typography className={classes.emailLabel}>
              Email for notifications
            </Typography>
            <Typography className={classes.emailValue}>{data.email}</Typography>
          </Box>
        </Box>

        {/* <Box className={classes.emailWrapper}>
          <Box className={classes.emailContainer}>
            <Typography className={classes.emailLabel}>Main email</Typography>
            <Typography className={classes.emailValue}>
              2by2host@gmail.com
            </Typography>
          </Box>
        </Box> */}
      </Box>
    </Box>
  );
};
