import React from 'react';
import { Add } from '@material-ui/icons';
import { useStyles } from './styles';

export const PageLayout = ({
  header,
  content,
  sidebar,
  actionBtn,
  actionId,
  isCreate,
  maxWidth,
}: IPageWithTable) => {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <div className={classes.topBg}></div>
      <div className={classes.pageWrapper}>
        {sidebar && sidebar}
        <div
          className={classes.contentWrapper}
          style={{ maxWidth: maxWidth || '100%' }}>
          <div className={classes.header}>{header}</div>
          <div className={classes.content}>{content}</div>
        </div>

        {actionBtn && isCreate && (
          <div
            aria-describedby={actionId}
            className={classes.addBtnWrapper}
            onClick={(e) => actionBtn(e)}>
            <div className={classes.addBtn}>
              <Add />
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

interface IPageWithTable {
  header: JSX.Element;
  content: JSX.Element;
  sidebar?: JSX.Element;
  actionBtn?: (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => void;
  actionId?: string;
  isCreate?: boolean;
  maxWidth?: string;
}
