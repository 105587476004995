import 'intl';
import 'intl/locale-data/jsonp/en';

export const dollarAmounts = (value: any) => {
  if (!value) {
    return new Intl.NumberFormat('en-US', {
      style: 'currency',
      currency: 'USD',
    }).format(0);
  }

  const intValue = parseInt(value);
  const result = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
  }).format(intValue);

  return result;
};

export const dollarAmountsFloat = (value: any) => {
  if (!value) {
    return new Intl.NumberFormat('en-US', {
      style: 'currency',
      currency: 'USD',
    }).format(0.0);
  }

  const floatValue = parseFloat(parseFloat(value).toFixed(2));
  const result = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
  }).format(floatValue);

  return result;
};
