import { calendarWeek } from "./calendarWeek";

export const convertWeekForRequest = (
  week: { id: number; checked: boolean }[]
) => {
  return week
    .reduce<number[]>((acc, curr) => {
      if (!curr.checked) return acc;

      return [...acc, curr.id];
    }, [])
    .toString();
};

export const convertWeekToObject = (
  week: { id: number; checked: boolean }[]
) => {
  return week.reduce((acc, curr) => {
    if (!curr.checked) return acc;

    return { ...acc, [curr.id]: curr.id };
  }, {});
};

export const convertDateRangesForQuery = (
  dates: { date_from: string | null; date_to: string | null }[]
) => {
  return dates
    .reduce<string[]>((acc, { date_from, date_to }) => {
      if (!date_from || !date_to) return acc;

      return [...acc, date_from, date_to];
    }, [])
    .toString();
};

type CalendarSearchFields = {
  type: string;
  trip: ITripCalendarData;
  week: typeof calendarWeek;
  dates: {
    date_from: string | null;
    date_to: string | null;
  }[];
  rateId?: number;
};

export const checkIsDisabledToSearch = ({
  trip,
  type,
  dates,
  week,
  rateId,
}: CalendarSearchFields) => {
  const isScheduleChecked =
    type !== "option"
      ? trip?.schedules?.length && trip.schedules.some((item) => item.checked)
      : true;

  const isWeekChecked = week.some((item) => item.checked);
  const isDateChecked = dates.some((item) => item.date_from && item.date_to);

  let isRateChecked = true;

  if (typeof rateId === "number" && trip?.rates?.length) {
    isRateChecked = rateId > 0;
  }

  return (
    !trip?.id ||
    !isScheduleChecked ||
    !isWeekChecked ||
    !isDateChecked ||
    !isRateChecked
  );
};

export const normalizeCalendarDataForRates = (
  calendarTicket: ICalendarTicket
) => {
  return Object.entries(calendarTicket).reduce(
    (acc, [scheduleId, scheduleData]) => {
      return {
        ...acc,
        [scheduleId]: Object.entries(scheduleData).reduce(
          (_acc, [date, calendar]) => {
            const { calendar_id, price, strike_out, commission, wholesale } =
              calendar as any;

            return {
              ..._acc,
              [date]: {
                calendar_id,
                price,
                strike_out,
                commission,
                wholesale,
              },
            };
          },
          {}
        ),
      };
    },
    {}
  );
};
