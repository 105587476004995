import { makeStyles } from "@material-ui/core/styles";
import { Theme } from "src/web/themes/theme";

export const useStyles = makeStyles((theme: Theme) => ({
  contentWrapper: {
    paddingTop: "17px",
    display: "flex",
    flexDirection: "row",

    "@media (max-width: 1020px)": {
      flexDirection: "column",
    },
  },
  mainInfoWrapper: {
    height: "275px",
    border: "1px solid #dbf8f6",
    borderRadius: "15px",
    padding: "40px 0px",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  avatarWrapper: {
    marginBottom: "12px",
    background: "#F9FCFD",
    width: "100px",
    minHeight: "100px",
    borderRadius: "80px",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  profileName: {
    color: "#333333",
    fontSize: "14px",
    fontWeight: 600,
    marginBottom: "15px"
  },
  userGroup: {
    marginBottom: "15px",
    color: "#333333",
    fontSize: "14px",
    fontWeight: 400,
  },
  changePasswordButton: {
    height: "30px",
    border: "1px solid #0DD1C5",
    borderRadius: "50px",
    color: "#0DD1C5",
    fontSize: "14px",
    textTransform: "none",
    padding: "0 16px",
  },
  secondaryInfoWrapper: {
    width: "calc(100% - 20px)",
    marginLeft: "auto",
    padding: "20px",
    height: "275px",
    borderRadius: "15px",
    border: "1px solid #dbf8f6",

    "@media (max-width: 1020px)": {
      width: "100%",
      marginTop: "20px",
    },
  },
  input: {
    width: "100%",
    fontSize: "14px",
    fontWeight: 400,

    "& .MuiFormLabel-root.Mui-disabled": {
      color: "#C4C4C4",
      fontWeight: 500,
      fontSize: "16px",
    },

    "& input.Mui-disabled": {
      color: "#333333",
    },
    "& .MuiInput-underline.Mui-disabled:before": {
      borderBottom: "none",
    },
  },

  detailModalRoot: {
    "& .MuiPaper-root": {
      padding: "50px",
      paddingTop: "0px",
      borderRadius: "15px",
    },

    "& .MuiDialogTitle-root": {
      padding: "15px 0 23px 0",
      borderBottom: "1px solid #FAFAFA",
    },

    "& .MuiDialogContent-root": {
      padding: "37px 0",
    },
    "@media (max-width: 750px)": {
      "& .MuiPaper-root": {
        padding: "27px",
        "& .MuiDialogTitle-root": {
          padding: "15px 0 18px 0",
          border: "none",
          borderBottom: "1px solid #FAFAFA",
        },
      },
    },
  },
  dialogHeader: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "flex-start",
  },
  dialogHeaderTitle: {
    fontSize: "18px",
    "@media (max-width: 850px)": {
      fontSize: "14px",
      paddingTop: "0px",
    },
    fontWeight: 600,
    paddingTop: "34px",
    color: "#202020",
  },
  dialogResponse: {
    fontSize: "12px",
    "@media (max-width: 850px)": {
      fontSize: "10px",
    },
    fontWeight: 400,
    color: "#6C6B6B",
  },
  closeIcon: {
    position: "absolute",
    top: "10px",
    right: "14px",

    "&:hover": {
      background: "none",
      border: "1px solid #FAFAFA",
      padding: "11px",
    },
  },
  saveButton: {
    background: "linear-gradient(90deg, #03D9B1 0%, #1CBBD9 100%)",
    color: "white",
    textTransform: "none",
    borderRadius: "50px",
    boxShadow:
      "0px 13px 28px rgba(29, 203, 192, 0.2), 0px 8px 16px rgba(29, 203, 192, 0.06)",
    fontSize: "16px",
    "@media (max-width: 850px)": {
      fontSize: "14px",
    },
    padding: "6.5px 46px",
    fontWeight: 500,
    "&:hover": {
      background: "linear-gradient(90deg, #06C9A4 0%, #00A2C1 100%)",
      color: "#FFFFFF",
      boxShadow:
        "0px 13px 28px rgba(29, 203, 192, 0.2), 0px 8px 16px rgba(29, 203, 192, 0.06)",
    },
  },
  cancelButton: {
    textTransform: "none",
    color: "#c4c4c4",
    fontSize: "16px",
    "@media (max-width: 850px)": {
      fontSize: "14px",
    },
    [theme.breakpoints.down("sm")]: {
      fontSize: "14px",
      padding: "6.5px 20px",
    },
    padding: "6.5px 33px",
    borderRadius: "50px",
    "&:hover": {
      background: "white",
      color: "#6C6B6B",
    },
  },
  editInput: {
    width: "100%",
    marginBottom: "20px",

    "& label": {
      color: "#333333",
      paddingLeft: "22px"
    },

    "& input": {
      padding: "0px 30px",
      height: "50px",
      color: "#6C6B6B",
      fontSize: "14px",

      "&:focus": {
        color: "#333333",
      },
    },

    "& .MuiInputBase-root": {
      borderRadius: "50px",
      "& .MuiOutlinedInput-notchedOutline": {
        paddingLeft: "25px",
      },
    },

    "& .MuiOutlinedInput-notchedOutline": {
      border: "1px solid #C4C4C4",
    },

    "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline": {
      border: "1px solid #333333",
    },

    "& .MuiFormLabel-root.Mui-focused": {
      color: "#333333",
    },
  },
}));
