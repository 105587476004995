import React, { useState } from 'react';

import {
  Box,
  Card,
  Typography,
  FormControlLabel,
  Checkbox,
  CardContent,
  TextField,
  useMediaQuery,
} from '@material-ui/core';
import clsx from 'clsx';
import { ExpandMore } from '@material-ui/icons';
import { parseNumberInputValue } from 'src/helpers/calendar/parseNumberInput';
import { useStyles } from './styles';
import CheckboxIcon from 'src/assets/icons/web/checkbox.svg';
import CheckboxCheckedIcon from 'src/assets/icons/web/checkboxChecked.svg';

export const MasterTicketFields = ({
  subTitle,
  ticket,
  scheduleId,
  withRates,
  setCalendarTicket,
}: {
  subTitle?: string;
  ticket?: any;
  scheduleId?: number;
  withRates: boolean;
  setCalendarTicket: (value: React.SetStateAction<ICalendarTicket>) => void;
}) => {
  const classes = useStyles();
  const isMobile = useMediaQuery('(max-width: 960px)');
  const [state, setState] = useState({
    disable_promos: 0,
    is_disabled: 0,
    price: 0,
    strike_out: 0,
    commission: 0,
    wholesale: 0,
    min: 0,
    total: 0,
  });

  const onChangeField = (field: keyof typeof state, value: number) => {
    setState((prev) => ({ ...prev, [field]: value }));
  };

  const onApply = (field: keyof typeof state) => {
    if (scheduleId) {
      setCalendarTicket((prev) => ({
        ...prev,
        [scheduleId]: {
          ...Object.fromEntries(
            Object.entries(prev[scheduleId]).map(([key, calendar]) => {
              return [key, { ...calendar, [field]: state[field] }];
            })
          ),
        },
      }));
    } else {
      setCalendarTicket((prev) =>
        Object.keys(prev).reduce((acc, scheduleId) => {
          return {
            ...acc,
            [+scheduleId]: {
              ...Object.fromEntries(
                Object.entries(prev[+scheduleId]).map(([key, calendar]) => {
                  return [key, { ...calendar, [field]: state[field] }];
                })
              ),
            },
          };
        }, {})
      );
    }
  };

  return (
    <Card
      className={clsx(
        classes.fieldsCard,
        classes.masterCard,
        classes.inputWrapper
      )}>
      <CardContent style={{ padding: 0 }}>
        <Box>
          <Box display="flex">
            {subTitle && (
              <Typography style={{ marginRight: '20px', fontWeight: 600 }}>
                {subTitle}
              </Typography>
            )}
            <Typography>{ticket}</Typography>
          </Box>
          {!withRates && (
            <Box display="flex">
              <Box display="flex" alignItems="center" justifyContent="center">
                <FormControlLabel
                  control={
                    <Checkbox
                      color="primary"
                      value={state.is_disabled}
                      onChange={(_, checked) =>
                        onChangeField('is_disabled', Number(checked))
                      }
                      icon={<CheckboxIcon />}
                      checkedIcon={<CheckboxCheckedIcon />}
                    />
                  }
                  label="Stop Sell"
                  labelPlacement="start"
                />
                <ExpandMore
                  fontSize="medium"
                  style={{ marginLeft: '10px', cursor: 'pointer' }}
                  onClick={() => onApply('is_disabled')}
                />
              </Box>
            </Box>
          )}
        </Box>
        <Box width={1} display="flex" flexWrap="wrap">
          <Box
            width={isMobile ? 1 : 1 / 6}
            p={2}
            display="flex"
            alignItems="center"
            justifyContent="center">
            <TextField
              type="number"
              value={parseNumberInputValue(state.price)}
              onChange={(e) => onChangeField('price', Number(e.target.value))}
              variant="outlined"
              size="small"
              InputLabelProps={{ shrink: true }}
              label="Rate"
            />
            <ExpandMore
              fontSize="medium"
              style={{ marginLeft: '5px', cursor: 'pointer' }}
              onClick={() => onApply('price')}
            />
          </Box>
          <Box
            width={isMobile ? 1 : 1 / 6}
            p={2}
            display="flex"
            alignItems="center"
            justifyContent="center">
            <TextField
              type="number"
              value={parseNumberInputValue(state.strike_out)}
              onChange={(e) =>
                onChangeField('strike_out', Number(e.target.value))
              }
              variant="outlined"
              size="small"
              InputLabelProps={{ shrink: true }}
              label="Strikeout"
            />
            <ExpandMore
              fontSize="medium"
              style={{ marginLeft: '5px', cursor: 'pointer' }}
              onClick={() => onApply('strike_out')}
            />
          </Box>

          {!withRates && (
            <>
              <Box
                width={isMobile ? 1 : 1 / 6}
                p={2}
                display="flex"
                alignItems="center"
                justifyContent="center">
                <TextField
                  type="number"
                  value={parseNumberInputValue(state.min)}
                  onChange={(e) => onChangeField('min', Number(e.target.value))}
                  variant="outlined"
                  size="small"
                  InputLabelProps={{ shrink: true }}
                  label="Min"
                />
                <ExpandMore
                  fontSize="medium"
                  style={{ marginLeft: '5px', cursor: 'pointer' }}
                  onClick={() => onApply('min')}
                />
              </Box>
              <Box
                width={isMobile ? 1 : 1 / 6}
                p={2}
                display="flex"
                alignItems="center"
                justifyContent="center">
                <TextField
                  type="number"
                  value={parseNumberInputValue(state.total)}
                  onChange={(e) =>
                    onChangeField('total', Number(e.target.value))
                  }
                  variant="outlined"
                  size="small"
                  InputLabelProps={{ shrink: true }}
                  label="Total"
                />
                <ExpandMore
                  fontSize="medium"
                  style={{ marginLeft: '5px', cursor: 'pointer' }}
                  onClick={() => onApply('total')}
                />
              </Box>
            </>
          )}
        </Box>
      </CardContent>
    </Card>
  );
};
