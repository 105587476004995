import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme: any) => {
  return {
    wrapper: {
      display: 'flex',
      alignItems: 'center',
      height: '40px',
      margin: '0 16px 0 auto',
      padding: '2px',
      border: '1px solid #0DD1C5',
      borderRadius: '100px',
      background: 'rgba(13, 209, 197, 0.1)',
      fontWeight: 500,
      [theme.breakpoints.down('sm')]: {
        display: 'none',
      },
    },
    content: {
      color: '#0DD1C5',
      margin: '0 16px',
    },
    button: {
      display: 'flex',
      alignItems: 'center',
      height: '100%',
      padding: '0 16px',
      borderRadius: '100px',
      color: '#fff',
      background: 'linear-gradient(90deg, #03D9B1 0%, #1CBBD9 100%)',
      textDecoration: 'none',
    },
  };
});
