import React, {
  useState,
  useContext,
  ReactElement,
  createContext,
  useEffect,
} from 'react';
import { useMutation, useQuery } from '@apollo/client';
import { useDispatch, useSelector } from 'react-redux';

import {
  DELETE_NOTIFICATIONS,
  GET_NOTIFICATIONS,
  REQUEST_INFO,
  UPDATE_NOTIFICATIONS,
} from 'src/api/notifications';
import { currentUser } from 'src/redux/Authentication/sliceAuthentication';
import { setNotification } from 'src/redux/Notifications/sliceNotifications';
import { Platform } from 'react-native';
import { AsyncStorage } from '@aws-amplify/core';

type Props = {
  children: ReactElement;
};

type ReadAllNotificationsTask = {
  interval: number;
  requestId: string;
};

type NotificationsFilter = {
  showReaded: boolean;
};

interface INotificationsContext {
  isReadAllProgress: boolean;
  lastNotificationsLoading: boolean;
  lastNotifications: INotification[];
  isExistNewNotifications: boolean;
  lastNotificationsFilter?: NotificationsFilter;
  readAllNotifications: () => Promise<void>;
  readLastNotificationHandle: (id: string) => void;
  unreadLastNotificationHandle: (id: string) => void;
  deleteLastNotificationsHandle: (id: string) => void;
  setIsExistNewNotifications: React.Dispatch<React.SetStateAction<boolean>>;
  setLastNotifications: React.Dispatch<React.SetStateAction<INotification[]>>;
  setLastNotificationsFilter: React.Dispatch<
    React.SetStateAction<NotificationsFilter | undefined>
  >;
  filterLastNotificationsByRead: (
    notifications: INotification[],
    showReaded?: boolean,
  ) => INotification[];
}

const initialData: INotificationsContext = {
  isReadAllProgress: false,
  lastNotificationsLoading: false,
  lastNotifications: [],
  isExistNewNotifications: false,
  lastNotificationsFilter: undefined,
  readAllNotifications: () => {
    throw new Error('Not in context');
  },
  readLastNotificationHandle: () => {
    throw new Error('Not in context');
  },
  unreadLastNotificationHandle: () => {
    throw new Error('Not in context');
  },
  deleteLastNotificationsHandle: () => {
    throw new Error('Not in context');
  },
  setLastNotifications: () => {
    throw new Error('Not in context');
  },
  setIsExistNewNotifications: () => {
    throw new Error('Not in context');
  },
  setLastNotificationsFilter: () => {
    throw new Error('Not in context');
  },
  filterLastNotificationsByRead: () => {
    throw new Error('Not in context');
  },
};

const storage = Platform.OS === 'web' ? localStorage : AsyncStorage;

const NotificationsContext = createContext(initialData);

export const useNotificationsContext = () => {
  return useContext(NotificationsContext);
};

export const NotificationsProvider = ({ children }: Props) => {
  const dispatch = useDispatch();
  const user = useSelector(currentUser);

  const [lastNotificationsFilter, setLastNotificationsFilter] = useState<
    NotificationsFilter | undefined
  >(); // this state use for the notifications popup and for the notifications list on mobile app
  const [isReadAllProgress, setIsReadAllProgress] = useState<boolean>(false);
  const [isExistNewNotifications, setIsExistNewNotifications] =
    useState<boolean>(false);
  const [lastNotifications, setLastNotifications] = useState<INotification[]>(
    []
  );

  const [readNotification] = useMutation(UPDATE_NOTIFICATIONS, {
    onCompleted: () => {
      refetch();
    },
  });

  const [deleteNotification] = useMutation(DELETE_NOTIFICATIONS, {
    onCompleted: () => {
      refetch();
    },
  });

  const [getRequestInfo] = useMutation(REQUEST_INFO, {
    onCompleted: (data) => {
      const completed = data?.v1_mrs_request?.completed;

      if (completed === '100.00%') {
        stopReadAllNotificationsTask();
      }
    },
    onError: (error) => {
      console.log(error);
    },
  });

  const { refetch, loading: lastNotificationsLoading } = useQuery(
    GET_NOTIFICATIONS,
    {
      variables: {
        prm: {
          limit: 5,
          pager: '',
          username: user?.['cognito:username'],
          filter: lastNotificationsFilter,
        },
      },
      notifyOnNetworkStatusChange: true,
      skip: (!user && Platform.OS === 'android') || Platform.OS === 'ios',
      onCompleted: (data) => {
        const notifications = data.v1_partners_getNotifications.notifications;
        setLastNotifications(notifications);
        setIsExistNewNotifications(!!notifications.length);
      },
    }
  );

  useEffect(() => {
    getReadAllNotificationsTask().then((task) => {
      if (task && task.requestId) {
        startReadAllNotificationsTask(task.requestId);
      }
    });
  }, []);

  const filterLastNotificationsByRead = (
    notifications: INotification[],
    showReaded?: boolean
  ) => {
    if (typeof showReaded === 'undefined') {
      return notifications;
    }

    if (showReaded) {
      return notifications.filter((notification) => notification.isReaded);
    } else {
      return notifications.filter((notification) => !notification.isReaded);
    }
  };

  const readLastNotificationHandle = (id: string) => {
    const notificationsClone: INotification[] = JSON.parse(
      JSON.stringify(lastNotifications)
    );
    const needleIndex = notificationsClone.findIndex((item) => item.id === id);

    notificationsClone[needleIndex].isReaded = true;

    setLastNotifications(
      filterLastNotificationsByRead(
        notificationsClone,
        lastNotificationsFilter?.showReaded
      )
    );
    setIsExistNewNotifications(!!notificationsClone.length);

    readNotification({
      variables: {
        prm: {
          id,
          username: user['cognito:username'],
          isReaded: true,
        },
      },
    });
  };

  const unreadLastNotificationHandle = (id: string) => {
    const notificationsClone: INotification[] = JSON.parse(
      JSON.stringify(lastNotifications)
    );
    const needleIndex = notificationsClone.findIndex((item) => item.id === id);

    notificationsClone[needleIndex].isReaded = false;

    setLastNotifications(
      filterLastNotificationsByRead(
        notificationsClone,
        lastNotificationsFilter?.showReaded
      )
    );
    setIsExistNewNotifications(!!notificationsClone.length);

    readNotification({
      variables: {
        prm: {
          id,
          username: user['cognito:username'],
          isReaded: false,
        },
      },
    });
  };

  const deleteLastNotificationsHandle = (id: string) => {
    deleteNotification({
      variables: {
        prm: {
          id,
          username: user['cognito:username'],
        },
      },
    });
  };

  const readAllNotifications = async () => {
    try {
      setIsReadAllProgress(true);
      setIsExistNewNotifications(false);
      setLastNotifications([]);

      const result = await readNotification({
        variables: {
          prm: {
            isAllAsReaded: true,
            username: user['cognito:username'],
            isReaded: true,
          },
        },
      });

      const requestId =
        result?.data?.v1_partners_updateNotifications?.requestId;

      if (requestId) {
        await startReadAllNotificationsTask(requestId);
      }
    } catch (error) {
      if (error instanceof Error) {
        dispatch(
          setNotification({
            message: error.message,
            type: 'error',
          })
        );
      }
    }
  };

  const getReadAllNotificationsTask = async () => {
    const readAllNotificationsTask: ReadAllNotificationsTask | null =
      JSON.parse((await storage.getItem('readNotificationsTask')) || 'null');

    return readAllNotificationsTask;
  };

  const stopReadAllNotificationsTask = async () => {
    setIsReadAllProgress(false);
    const task = await getReadAllNotificationsTask();

    if (task) {
      clearInterval(task.interval);
      await storage.removeItem('readNotificationsTask');
    }
  };

  const startReadAllNotificationsTask = async (requestId: string) => {
    const interval = setInterval(() => {
      getRequestInfo({
        variables: {
          prm: {
            id: requestId,
            service: 'tsPartnersPS',
            stage: process.env.REACT_APP_ENV || 'dev',
          },
        },
      });
    }, 5000);

    await storage.setItem(
      'readNotificationsTask',
      JSON.stringify({
        interval,
        requestId,
      })
    );
  };

  const value = {
    isReadAllProgress,
    lastNotifications,
    isExistNewNotifications,
    lastNotificationsFilter,
    lastNotificationsLoading,
    readAllNotifications,
    setLastNotifications,
    readLastNotificationHandle,
    unreadLastNotificationHandle,
    setIsExistNewNotifications,
    setLastNotificationsFilter,
    deleteLastNotificationsHandle,
    filterLastNotificationsByRead,
  };

  return (
    <NotificationsContext.Provider value={value}>
      {children}
    </NotificationsContext.Provider>
  );
};
