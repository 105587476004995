import { makeStyles } from "@material-ui/core/styles";

import USAIcon from "src/assets/icons/flags/US.png";

export const useStyles = makeStyles(() => ({
  title: {
    fontWeight: 600,
    fontSize: "20px",
    color: "#0DD1C5",
    fontFamily: "Montserrat",
    "@media (max-width: 620px)": {
      fontWeight: 500,
      fontSize: "14px",
    },
  },
  description: {
    fontWeight: 400,
    fontSize: "12px",
    color: "#6C6B6B",
    fontFamily: "Montserrat",
    "@media (max-width: 620px)": {
      fontSize: "10px",
    },
  },
  content: {
    fontWeight: 500,
    fontSize: "12px",
    color: "#6C6B6B",
    fontFamily: "Montserrat",
    paddingBottom: "15px",
    "@media (max-width: 620px)": {
      fontSize: "10px",
    },
  },
  box: {
    padding: "24px 32px",
    border: "1px solid #FAFAFA",
    borderRadius: "15px",
    marginTop: "24px",
    "@media (max-width: 620px)": {
      padding: "20px 14px",
      marginTop: "4px",
    },
  },
  policyBox: {
    padding: "25px 14px 15px 23px",
    border: "1px solid #FAFAFA",
    borderRadius: "15px",
    marginTop: "24px",
    height: "220px",
    "@media (max-width: 620px)": {
      padding: "20px 7px 18px 20px",
      marginTop: "20px",
      height: "180px",
      marginBottom: "9px",
    },
  },
  saveButton: {
    textTransform: "none",
    color: "white",
    fontSize: "16px",
    background: "linear-gradient(90deg, #03D9B1 0%, #1CBBD9 100%)",
    borderRadius: "50px",
    boxShadow:
      "0px 13px 28px rgba(29, 203, 192, 0.2), 0px 8px 16px rgba(29, 203, 192, 0.06)",
    width: "255px",
    padding: "6.5px 50px",
    fontFamily: `"Montserrat", "Helvetica", "Arial", sans-serif`,
    fontWeight: 500,
    "&:hover": {
      background: "linear-gradient(90deg, #06C9A4 0%, #00A2C1 100%)",
      color: "#FFFFFF",
      boxShadow:
        "0px 13px 28px rgba(29, 203, 192, 0.2), 0px 8px 16px rgba(29, 203, 192, 0.06)",
    },
    "&.Mui-disabled": {
      background: "linear-gradient(270deg, #ACB9BA 0%, #D1DDDD 108.89%)",
      color: "#FFFFFF",
    },
    "@media (max-width: 620px)": {
      fontSize: "14px",
    },
  },
  acceptCheckbox: {
    textAlign: "center",
  },
  checkbox: {
    "& span:nth-child(2)": {
      color: "#333333 !important",
      fontFamily: "Montserrat",
      fontSize: "12px",
      fontWeight: 500,
      whiteSpace: "nowrap",
    },
  },
  dekstop: {
    display: "flex",
    justifyContent: "center",
    "@media (max-width: 620px)": {
      display: "none",
    },
  },
  adaptive: {
    display: "none",
    "@media (max-width: 620px)": {
      display: "flex",
      justifyContent: "center",
    },
  },
}));
