import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles(() => ({
  headerTitle: {
    paddingLeft: 14,
    fontSize: 16,
    fontWeight: 500,
  },

  leftColumn: {
    width: '75%',

    '@media (max-width: 1300px)': {
      width: '60%',
    },

    '@media (max-width: 1024px)': {
      display: 'none',
    }
  },
  rightColumn: {
    width: '25%',

    '@media (max-width: 1300px)': {
      width: '40%',
    },

    '@media (max-width: 1024px)': {
      width: '100%',
      marginLeft: '0px',
    }
  }
}));
