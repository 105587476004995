import React, { useEffect, useState } from "react";
import { Box, Button } from "@material-ui/core";
import { useStyles } from "./styles";
import { useSelector, useDispatch } from "react-redux";
import { onboardingData, setData } from "src/redux/Onboarding/sliceOnboarding";
import { FormElement } from "../../OnboardingForm/FormElement";
import { useLazyQuery } from "@apollo/client";
import { GET_FORM } from "src/api/onboardingForms";
import { Spiner } from "../../Loaders";
import { IOnboardingForm } from "../../OnboardingForm/interface";
import { IOnboardingFields } from "src/redux/Onboarding/interfaces";

export const TermsAndConditionsForm = ({
  handleSubmit,
  formId,
}: {
  handleSubmit: () => void;
  formId: string;
}) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const [termsForm, setTermsForm] = useState<
    IOnboardingForm | undefined
  >(undefined);
  const { receivedData, form: partnerData } = useSelector(onboardingData);

  const [getForm, { loading }] = useLazyQuery(GET_FORM, {
    variables: {
      prm: {
        id: formId,
      },
    },
    onCompleted: (data) => {
      const dataScreens = data.v1_general_getForm.screens;
      const newForm = {
        ...data.v1_general_getForm,
        screens: JSON.parse(
          dataScreens.replaceAll("XXX", receivedData?.commission)
        ),
      };
      setTermsForm(newForm);
    },
    onError: (error) => {
      console.error(error);
    },
  });

  useEffect(() => {
    if (formId) {
      getForm();
    }
  }, [formId]);

  const handleAccept = (name: keyof IOnboardingFields) => {
    dispatch(
      setData({
        [name]: !partnerData[name],
      })
    );
  };

  if (loading || !termsForm) {
    return <Spiner fixed />;
  }

  return (
    <div>
      <FormElement
        form={termsForm}
        errorMessage={undefined}
        formInput={termsForm.screens[0]}
        handleChange={handleAccept}
        receivedData={receivedData}
        data={partnerData}
      />

      <>
        <Box mt={2} display="flex" justifyContent="center">
          <Button
            onClick={handleSubmit}
            variant="contained"
            color="primary"
            type="submit"
            size="large"
            fullWidth
            className={classes.saveButton}
          >
            Submit
          </Button>
        </Box>
      </>
    </div>
  );
};
