import { makeStyles } from "@material-ui/core/styles";

export const useStyles = makeStyles(() => ({
  inputWrapper: {
    "& .MuiInputLabel-outlined": {
      color: "#333",
      paddingLeft: "22px",
      fontWeight: 500,
    },
    "& .MuiAutocomplete-inputRoot": {
      padding: "4px 24px !important",
    },
    "& .MuiInputBase-root": {
      height: "50px",
      borderRadius: "50px",
      "& .MuiOutlinedInput-notchedOutline": {
        paddingLeft: "25px",
      },
    },
    "& .MuiInputBase-input": {
      padding: "15px",
      color: "#333333",
      fontSize: "14px",
    },
    "& .Mui-focused .MuiOutlinedInput-notchedOutline": {
      border: "1px #333333 solid",
    },
  },
  label: {
    fontSize: "14px",
    color: "#6C6B6B",
  },
  placeholder: {
    fontSize: "14px",
    color: "#C4C4C4",
    fontWeight: 400,
  },
  listWrapper: {
    padding: "12.5px 19px 17px 30px",
    boxShadow: "0px 3.89px 23.36px rgba(17, 15, 15, 0.15)",
    borderRadius: "15px",
    position: "absolute",
    background: "white",
    zIndex: 10,
    width: "100%",
    maxHeight: "320px",
    overscrollBehavior: "contain",
    "@media (max-width: 750px)": {
      position: "fixed",
      width: "75%",
      top: 'auto !important'
    },
  },
  iconTransform: {
    transform: "rotate(180deg)",
    cursor: "pointer",
  },
  icon: {
    cursor: "pointer",
  },
  listContent: {
    // padding: "13px 13px 13px 0",
    maxHeight: "260px",
  },
  listItem: {
    padding: "16px 38px 16px 28px",
    cursor: "pointer",
    color: "#6C6B6B",
    fontSize: "14px",
    marginRight: "30px",
    display: "flex",
    justifyContent: "space-between",
    "&:hover": {
      color: "#0DD1C5",
      background: "#f3fdfc",
      borderRadius: "15px",
      border: "none",
      "& span": {
        color: "#6C6B6B !important",
      },
    },
    "@media (max-width: 750px)": {
      padding: "10px 10px 10px 0",
    },
  },
  listSubitem: {
    color: "#C4C4C4",
    fontSize: "14px",
    fontWeight: 500,
  },
}));
