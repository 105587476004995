import React, { Dispatch, SetStateAction } from 'react';
import { NavLink } from 'react-router-dom';
import { Box, ListItem } from '@material-ui/core';
import { NavigationLink } from 'src/web/routes/config/navigation';
import { useStyles } from './styles';

type Props = {
  isOpenDrawer: boolean;
  setOpenDrawer: Dispatch<SetStateAction<boolean>>;
  link: NavigationLink;
};

const NavigationItem = ({ isOpenDrawer, setOpenDrawer, link }: Props) => {
  const classes = useStyles();
  const handlerCloseDrawer = () => {
    if (isOpenDrawer && window.innerWidth < 600) {
      setOpenDrawer(false);
    }
  };

  return (
    <NavLink
      exact
      to={link?.path || ''}
      onClick={handlerCloseDrawer}
      key={link.title}
      className={classes.linkWrapper}>
      <ListItem
        className={classes.link}
        style={
          isOpenDrawer ? { padding: '10px 25px' } : { padding: '10px 13px' }
        }
        button>
        <Box className={classes.linkIcon}>{link.icon}</Box>
        {isOpenDrawer && (
          <Box ml={1.5} className={classes.linkTitle}>
            {link.title}
          </Box>
        )}
      </ListItem>
    </NavLink>
  );
};

export default NavigationItem;
