import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useQuery } from '@apollo/client';
import { GET_REVIEWS_LIST } from 'src/api/reviews';
import { setLoading } from 'src/web/features/table/sliceTables';
import { PageCardsLayout } from 'src/web/layouts/PageCardsLayout';
import FilterPopper from 'src/web/components/Reviews/pageHeader/FilterPopper';
import { auth } from 'src/redux/Authentication/sliceAuthentication';
import { ReviewsList } from 'src/web/components/Reviews/ReviewsList';
import { InnerPageHeader } from 'src/web/components/InnerPagesHeader';
import { setNotification } from 'src/redux/Notifications/sliceNotifications';
import { IReviewsPrm, normalizePrm } from './normalizePrm';
import PageHeader from 'src/web/components/Reviews/pageHeader/PageHeader';
import { usePartnersTripsAutocomplete } from 'src/hooks/autocompletes/usePartnersTripsAutocomplete';
import { setRates, reviewsFilter } from 'src/redux/Reviews/sliceReviews';
import { RateData } from 'src/redux/Reviews/interfaces';
import moment from 'moment';

const ReviewsPage = () => {
  const dispatch = useDispatch();
  const { user } = useSelector(auth);
  const rates = useSelector(reviewsFilter);
  const [dates, setDates] = useState({
    date_from: moment().subtract(1, 'year').format('YYYY-MM-DD'),
    date_to: moment().format('YYYY-MM-DD'),
  });
  const [activityId, setActivityId] = useState<number>();

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [open, setOpen] = useState<boolean>(false);
  const [reviews, setReviews] = useState<IReview[]>([]);
  const [isSpiner, setIsSpiner] = useState<boolean>(true);
  const [prm, setPrm] = useState<IReviewsPrm>({
    user_id: +user['custom:partner_id'],
    limit: 15,
    ...dates,
    page: 0,
    show_replied: true,
    rates: '',
  });
  const [totalPages, setTotalPages] = useState(0);

  const { loading, refetch } = useQuery(GET_REVIEWS_LIST, {
    variables: {
      prm: normalizePrm(prm),
    },
    onCompleted: (data) => {
      setIsSpiner(false);
      const { reviews, pagination, totals } = data.v1_general_reviewsList.data;

      if (prm.page > 0) {
        setReviews((prev) => [...prev, ...reviews]);
      } else {
        setReviews(reviews);
        setTotalPages(pagination.total_page);
      }

      if (
        prm.activity_id !== activityId ||
        prm.date_from !== dates.date_from ||
        prm.date_to !== dates.date_to ||
        !rates.all
      ) {
        dispatch(setRates({ [prm.activity_id || 'all']: totals }));
        setActivityId(prm.activity_id);
        setDates({
          date_from: prm.date_from,
          date_to: prm.date_to,
        });
      }

      dispatch(setLoading({ type: 'scroll', value: false }));
    },
    onError: (error) => {
      setIsSpiner(false);
      dispatch(
        setNotification({
          type: 'error',
          message: error.message,
        })
      );
    },
  });

  // useEffect(() => {
  //   if (data && !prm.rates && !prm.date_from && !prm.date_to) {
  //     if (prm.activity_id) {
  //       dispatch(
  //         setProductRates({
  //           data: data.v1_general_reviewsList.data.totals,
  //           product_id: prm.activity_id,
  //         })
  //       );
  //     } else {
  //       dispatch(
  //         setAllRates({ data: data.v1_general_reviewsList.data.totals })
  //       );
  //     }
  //   }
  // }, [data]);

  const { products: productsData, loading: partnerProductsLoading } =
    usePartnersTripsAutocomplete(+user['custom:partner_id'], false, false);

  useEffect(() => {
    if (loading && !reviews.length) {
      setIsSpiner(true);
    }
  }, [loading]);

  const refetchList = () => {
    setIsSpiner(true);

    refetch({
      prm: normalizePrm(prm),
    });
  };

  const onChangeDates = ({
    start,
    end,
  }: {
    start: string | null;
    end: string | null;
  }) => {
    onChangeFilter({
      date_from: start || '',
      date_to: end || '',
    });
  };

  const onChangeFilter = (filter: Partial<IReviewsPrm>) => {
    if (filter.rates === prm.rates) {
      return;
    }

    setIsSpiner(true);
    setPrm((prev) => ({ ...prev, page: 0, ...filter }));
  };

  return (
    <>
      <FilterPopper
        prm={prm}
        open={open}
        anchorEl={anchorEl}
        setOpen={setOpen}
        onChangeFilter={onChangeFilter}
        onChangeDates={onChangeDates}
        partnerProductsLoading={partnerProductsLoading}
        productsData={productsData}
      />
      <PageCardsLayout
        header={
          <InnerPageHeader
            components={
              <PageHeader
                prm={prm}
                setOpen={setOpen}
                onChangeFilter={onChangeFilter}
                partnerProductsLoading={partnerProductsLoading}
                productsData={productsData}
              />
            }
          />
        }
        content={
          <>
            <ReviewsList
              prm={prm}
              setPrm={setPrm}
              totalPages={totalPages}
              reviews={reviews}
              isSpiner={isSpiner}
              refetchList={refetchList}
              rates={rates as RateData}
              onChangeFilter={onChangeFilter}
              product_id={prm.activity_id}
            />
          </>
        }
      />
    </>
  );
};

export default ReviewsPage;
