export const getColorStatus = (text: string | null) => {
  switch (text) {
    case 'confirmed':
      return '#0DD1C5';
    case 'paid':
      return '#4C93FF';
    case 'unconfirmed':
      return '#FFDB5D';
    case 'cancelled':
      return '#EC4652';
    case 'checked_in':
      return '#0DB8AE';
    case 'no_show':
      return '#A700E1';
    case 'completed':
      return '#F079FB';
    case 'on_request':
      return '#79CCFB';
    case 'made':
      return '#84DF76';
    default:
      return '#6C6B6B';
  }
};
