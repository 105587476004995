import { Box, Button, Divider, Typography } from '@material-ui/core';
import React, { useState } from 'react';
import Rating from 'react-rating';
import { useStyles } from '../styles';
import RatingFullIcon from 'src/assets/icons/ratingS.svg';
import RatingEmptyIcon from 'src/assets/icons/ratingSEmpty.svg';
import { EditRounded } from '@material-ui/icons';
// import CloseIcon from 'src/assets/icons/light/close.svg';

export const ReviewCard = ({
  review,
  index,
  reviewsLength,
  handleOpenResponse,
  onDeleteHandle,
  isUserCanResponse,
}: {
  review: IReview;
  index: number;
  reviewsLength: number;
  onDeleteHandle: (replyId: number) => void;
  handleOpenResponse: (review: IReview) => void;
  isUserCanResponse: boolean;
}) => {
  const classes = useStyles();

  const SVGIcon = (icon: any) => <div style={{ margin: '0 2px' }}>{icon}</div>;

  return (
    <div className={classes.reviewsBox} key={review.id}>
      <Box className={classes.reviewBox} width={1}>
        <Box className={classes.reviewInfo}>
          <Box className={classes.reviewInfoLeft}>
            <Box>
              <Typography className={classes.reviewName}>
                {review.customer}
              </Typography>
              {/* <Box className={classes.reviewBottomInfo}>
                <Typography className={classes.reviewContentName}>
                  Arrival date:
                </Typography>
                <Typography className={classes.reviewContentItem}>
                  {review.time_format}
                </Typography>
              </Box> */}
              <Box className={classes.reviewBottomInfo}>
                <Typography className={classes.reviewContentName}>
                  Confirmation number:
                </Typography>
                <Typography className={classes.reviewContentItem}>
                  {review.order_id}
                </Typography>
              </Box>
            </Box>
            <Box>
              <Typography className={classes.reviewTime}>
                {review.time_format}
              </Typography>
            </Box>
          </Box>
        </Box>
        <Box className={classes.reviewTrip}>
          <Box className={classes.reviewTripBox}>
            <Box className={classes.tripName} width={1}>
              {review.product_name}
            </Box>
            <Box>
              <Box display={'flex'} alignItems="center" marginBottom={'5px'}>
                <Typography className={classes.nameOfReview}>
                  {review.title}
                </Typography>
                <Box display="flex" className={classes.adaptive_2}>
                  <Rating
                    emptySymbol={SVGIcon(<RatingEmptyIcon />)}
                    fullSymbol={SVGIcon(<RatingFullIcon />)}
                    readonly
                    initialRating={review.rate}
                    fractions={2}
                  />
                  <Typography className={classes.reviewRaiting}>
                    {review.rate.toFixed(1)}
                  </Typography>
                </Box>
              </Box>
              <Box className={classes.reviewContent}>{review.text}</Box>
              <Box
                display={'flex'}
                justifyContent="space-between"
                alignItems={'end'}>
                <Box className={classes.adaptive_3}>
                  <Rating
                    emptySymbol={SVGIcon(<RatingEmptyIcon />)}
                    fullSymbol={SVGIcon(<RatingFullIcon />)}
                    readonly
                    initialRating={review.rate}
                    fractions={2}
                  />
                  <Typography className={classes.reviewRaiting}>
                    {review.rate.toFixed(1)}
                  </Typography>
                </Box>
                {!review.reply_id && isUserCanResponse && (
                  <Button
                    onClick={() => handleOpenResponse(review)}
                    className={classes.responseButton}>
                    Respond
                  </Button>
                )}
              </Box>
              {!!review.reply_id && (
                <Box>
                  <Divider />

                  <Box mt={2}>
                    <Box
                      display="flex"
                      flexDirection="row"
                      justifyContent="space-between"
                      alignItems="center">
                      <Typography className={classes.reviewReplyTitle}>
                        Management Response
                      </Typography>
                      <Typography className={classes.reviewTime}>
                        {review.reply_time_format}
                      </Typography>
                      {/* <Box
                        onClick={() => onDeleteHandle(review.id)}
                        style={{ cursor: 'pointer' }}>
                        <CloseIcon />
                      </Box> */}
                    </Box>
                    <Box
                      display="flex"
                      mt={1}
                      width="100%"
                      alignItems="flex-start">
                      <Box className={classes.reviewContent}>
                        {review.reply_text}
                      </Box>
                      <Button
                        onClick={() => handleOpenResponse(review)}
                        className={classes.responseButton}
                        style={{
                          padding: '0 8px',
                        }}>
                        Edit response{' '}
                      </Button>
                    </Box>
                  </Box>
                </Box>
              )}
            </Box>
          </Box>
        </Box>
      </Box>
      {reviewsLength - index !== 1 && (
        <Divider
          style={{ backgroundColor: '#dbf8f6' }}
          className={classes.adaptive_2}
        />
      )}
    </div>
  );
};
