export const getNotificationPath = (notification: INotification) => {
  const pathsMap: Record<NotificationType, string> = {
    orders: `/arrivals/${notification.object_id}`,
    order_create: `/arrivals/${notification.object_id}`,
    order_edit: `/arrivals/${notification.object_id}`,
    order_cancel: `/arrivals/${notification.object_id}`,
    unconfirmed_24: `/arrivals/${notification.object_id}`,
    unconfirmed_48: `/arrivals/${notification.object_id}`,
    review_add: `/reviews`,
    invoice_create: `/payments`,
  };

  console.log(notification.object_type);

  const path = pathsMap[notification.object_type];

  if (!path) {
    return '';
  }

  return path;
};

export const getNotificationPathNative = (notification: INotification) => {
  const pathsMap: Record<NotificationType, { route: string; params?: any }> = {
    orders: {
      route: `ArrivalsDetailScreen`,
      params: {
        id: notification.object_id,
      },
    },
    order_create: {
      route: `ArrivalsDetailScreen`,
      params: {
        id: notification.object_id,
      },
    },
    order_edit: {
      route: `ArrivalsDetailScreen`,
      params: {
        id: notification.object_id,
      },
    },
    order_cancel: {
      route: `ArrivalsDetailScreen`,
      params: {
        id: notification.object_id,
      },
    },
    unconfirmed_24: {
      route: `ArrivalsDetailScreen`,
      params: {
        id: notification.object_id,
      },
    },
    unconfirmed_48: {
      route: `ArrivalsDetailScreen`,
      params: {
        id: notification.object_id,
      },
    },
    review_add: {
      route: `Reviews`,
    },
    invoice_create: {
      route: `Payouts`,
    },
  };

  const path = pathsMap[notification.object_type];

  if (!path) {
    return {
      route: 'Notifications',
    };
  }

  return path;
};
