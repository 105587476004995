import React from "react";
import {
  Box,
  Checkbox,
  FormControlLabel,
  useMediaQuery,
} from "@material-ui/core";
import { calendarWeek } from "src/helpers/calendar/calendarWeek";
import { useStyles } from "../styles";
import CheckboxIcon from "src/assets/icons/web/checkbox.svg";
import CheckboxCheckedIcon from "src/assets/icons/web/checkboxChecked.svg";

type Props = {
  week: typeof calendarWeek;
  onChange: (week: typeof calendarWeek) => void;
};

export const WeekBlock = ({ week, onChange }: Props) => {
  const classes = useStyles();
  const isMobile = useMediaQuery("(max-width: 960px)");

  const onDataChange = (index: number) => {
    const newWeekValues = structuredClone(week);
    newWeekValues[index].checked = !newWeekValues[index].checked;
    onChange(newWeekValues);
  };

  return (
    <Box display="flex" flexWrap="wrap" mt={4}>
      {week.map((item, index) => (
        <Box p={1} key={index} className={classes.checkboxItem}>
          <FormControlLabel
            control={
              <Checkbox
                name={item.label}
                icon={<CheckboxIcon />}
                checkedIcon={<CheckboxCheckedIcon />}
                checked={item.checked}
                onChange={() => {
                  onDataChange(index);
                }}
              />
            }
            label={isMobile ? item.mLabel : item.label}
          />
        </Box>
      ))}
    </Box>
  );
};
