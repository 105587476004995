import React, { useState } from 'react';

import { GroupsContent } from 'src/web/components/Settings/Groups';
import { GroupDetailModal } from 'src/web/components/Settings/Groups/GroupDetailModal';

type Props = {
  openDetail: boolean;
  setOpenDetail: (isOpen: boolean) => void;
};

const GroupsPage = ({ openDetail, setOpenDetail }: Props) => {
  return (
    <>
      <GroupDetailModal open={openDetail} setOpen={setOpenDetail} />
      <GroupsContent setOpenDetail={setOpenDetail} />
    </>
  );
};

export default GroupsPage;
