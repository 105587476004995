export interface IReviewsPrm {
  user_id: number;
  date_to: string;
  date_from: string;
  show_replied: boolean;
  limit: number;
  page: number;
  rates: string;
  activity_id?: number;
}

export const normalizePrm = ({
  date_from,
  date_to,
  limit,
  page,
  show_replied,
  user_id,
  rates,
  activity_id,
}: IReviewsPrm) => {
  const prm = {
    page_num: page,
    per_page: limit,
    user_id,
    date_to,
    date_from,
    show_replied: Number(show_replied),
    rates,
    activity_id,
  };

  return prm;
};
