import React from 'react';
import moment from 'moment';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { Box, TableCell, TableRow, Typography } from '@material-ui/core';

import { changeLoading } from 'src/web/features/table/sliceTables';

import { useStyles } from './styles';
import { getColorStatus } from 'src/helpers/getColorStatus';
import { NotFoundData } from '../UI/NotFoundData';
import { dollarAmountsFloat } from 'src/helpers/DollarAmounts';

type Props = {
  payouts: IPayoutInvoice[];
};

export const PayoutsList = ({ payouts }: Props) => {
  const classes = useStyles();
  const history = useHistory();
  const loading = useSelector(changeLoading);

  const formatInvoiceDate = (date: string) => {
    return moment(date, 'MM/DD/YYYY').format('MMMM DD, YYYY');
  };

  const onRowClick = (id: number) => {
    window.open(`/payouts/${id}`, '_blank', 'noopener,noreferrer');
  };

  return (
    <>
      {!loading && !payouts.length ? (
        <TableRow className={classes.table_row}>
          <TableCell className={classes.tableLoaderWrapper} width={100}>
            <Box className={classes.tableLoader}>
              <NotFoundData title={'No data'} />
            </Box>
          </TableCell>
        </TableRow>
      ) : (
        payouts.map((payout) => {
          const statusName = payout.status.replace('_', ' ');

          return (
            <React.Fragment key={payout.id}>
              <TableRow
                hover={true}
                className={classes.table_row}
                onClick={() => onRowClick(payout.id)}>
                <TableCell className={classes.table_cell}>
                  {payout.id}
                </TableCell>

                <TableCell className={classes.table_cell} align="center">
                  {formatInvoiceDate(payout.invoice_date)}
                </TableCell>

                <TableCell className={classes.table_cell} align="center">
                  <Box className={classes.netTotal}>
                    {dollarAmountsFloat(payout.net_total.split('$')[1]) ||
                      payout.net_total}
                  </Box>
                </TableCell>

                <TableCell className={classes.table_cell}>
                  <Box
                    className={classes.custom_chip}
                    bgcolor={getColorStatus(payout.status)}
                    color="white">
                    <div className={classes.chip_text}>
                      {statusName.charAt(0).toUpperCase() +
                        statusName.slice(1) || 'N/A'}
                    </div>
                  </Box>
                </TableCell>
              </TableRow>
            </React.Fragment>
          );
        })
      )}
    </>
  );
};
