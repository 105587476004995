import React from 'react';
import { Box, Typography } from '@material-ui/core';

import { useStyles } from './styles';
import { dollarAmountsFloat } from 'src/helpers/DollarAmounts';

type Props = {
  amount: number;
};

export const DashboardTotalCard = ({ amount }: Props) => {
  const classes = useStyles();

  return (
    <Box className={classes.dashboardTotalCard}>
      <Typography className={classes.dashboardTotalCardTitle}>
        Total Earnings
      </Typography>
      <Typography className={classes.dashboardTotalCardValue}>
        {dollarAmountsFloat(amount)}
      </Typography>
    </Box>
  );
};
