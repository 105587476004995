export const getFilterLenth = (where: any[]): number => {
  const removeFields = [
    'state_name',
    'payments.number',
    'cart_items.state_name',
  ];

  let unicWhere: any[] = [];
  where.forEach((i) => {
    if (!unicWhere.includes(i[0]?.field) && !removeFields.includes(i[0]?.field)) {
      unicWhere.push(i[0]?.field);
    }
  });
  return unicWhere.length;
};
