import { makeStyles } from '@material-ui/core/styles';
import { Theme } from 'src/web/themes/theme';

export const useStyles = makeStyles((theme: Theme) => ({
  root: {
    background: '#f5f5f5',
  },
  content_container: {
    position: 'relative',
    backgroundColor: theme.trip_shock_theme.environment.trip_shock_white,
    minHeight: '100vh',
  },
  headerRoot: {
    padding: '10px 17px',
    top: 0,
    left: 0,
    right: 0,
    position: 'fixed',
    display: 'flex',
    height: '66px',
    zIndex: 1,
    [theme.breakpoints.down('xs')]: {
      padding: '0 16px',
    },
  },
  headerIcons: {
    display: 'flex',
    alignItems: 'center',
    marginLeft: 'auto',
  },
  exitApp: {
    display: 'flex',
    alignItems: 'center',
    paddingLeft: '17px',
    marginLeft: '24px',
    height: '100%',
    borderLeft: '1px solid rgba(224, 224, 224, 1)',
    cursor: 'pointer',
    [theme.breakpoints.down('xs')]: {
      paddingLeft: '16px',
      marginLeft: '16px',
    },
  },
}));
