import { makeStyles, Theme } from "@material-ui/core/styles";

export const useStyles = makeStyles((theme: Theme) => ({
  title: {
    fontSize: '16px',
    color: '#FFFFFF',
    display: 'flex',
    "@media (max-width: 850px)": {
      fontSize: '14px',
    },
  },
  header: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
  },
  showUnreadedCheckbox: {
    
  },
  readAllButton: {
    color: '#EC4652',
    border: '1px solid #EC4652',
    borderRadius: '50px',
    padding: '6px 15px',
    boxShadow: 'none',
    textTransform: 'initial',

    [theme.breakpoints.down("md")]: {
      padding: '4px 10px',
      fontSize: '10px',
    }
  },
  moreFiltersButtonMobile: {
    display: "none",
    marginLeft: "20px",
    [theme.breakpoints.down("md")]: {
      display: "flex",
    },
  },
  headerElementWrapper: {
    display: 'flex',
    height: '25px',
    alignItems: 'center',

  }
}));
