import React, { useState } from "react";

import {
  Box,
  Card,
  Typography,
  FormControlLabel,
  Checkbox,
  CardContent,
  TextField,
  useMediaQuery,
} from "@material-ui/core";
import clsx from "clsx";
import { ExpandMore } from "@material-ui/icons";
import { useStyles } from "./styles";
import { parseNumberInputValue } from "src/helpers/calendar/parseNumberInput";
import CheckboxIcon from "src/assets/icons/web/checkbox.svg";
import CheckboxCheckedIcon from "src/assets/icons/web/checkboxChecked.svg";

export const MasterScheduleContent = ({
  subTitle,
  time,
  scheduleId,
  setCalendarSchedule,
}: {
  subTitle?: string;
  time?: any;
  scheduleId?: number;
  setCalendarSchedule: (value: React.SetStateAction<ICalendarSchedule>) => void;
}) => {
  const classes = useStyles();
  const isMobile = useMediaQuery("(max-width: 960px)");
  const [state, setState] = useState({
    is_disabled: 0,
    min_tickets: 0,
    max_tickets: 0,
  });

  const onChangeField = (field: keyof typeof state, value: number) => {
    setState((prev) => ({ ...prev, [field]: value }));
  };

  const onApply = (field: keyof typeof state) => {
    if (scheduleId) {
      setCalendarSchedule((prev) => ({
        ...prev,
        [scheduleId]: {
          ...Object.fromEntries(
            Object.entries(prev[scheduleId]).map(([key, calendar]) => {
              return [key, { ...calendar, [field]: state[field] }];
            })
          ),
        },
      }));
    } else {
      setCalendarSchedule((prev) =>
        Object.keys(prev).reduce((acc, scheduleId) => {
          return {
            ...acc,
            [+scheduleId]: {
              ...Object.fromEntries(
                Object.entries(prev[+scheduleId]).map(([key, calendar]) => {
                  return [key, { ...calendar, [field]: state[field] }];
                })
              ),
            },
          };
        }, {})
      );
    }
  };

  return (
    <Card
      className={clsx(
        classes.fieldsCard,
        classes.masterCard,
        classes.inputWrapper
      )}
    >
      <CardContent style={{ padding: 0 }}>
        <Box>
          <div style={{ display: "flex" }}>
            {subTitle && (
              <Typography style={{ marginRight: "20px" }}>
                {subTitle}
              </Typography>
            )}
            {time && <Typography>{time}</Typography>}
          </div>
          <Box display="flex" alignItems="center" justifyContent="flex-end">
            <FormControlLabel
              value="disable"
              control={
                <Checkbox
                  checked={Boolean(state.is_disabled)}
                  onChange={(_, checked) =>
                    onChangeField("is_disabled", Number(checked))
                  }
                  color="primary"
                  icon={<CheckboxIcon />}
                  checkedIcon={<CheckboxCheckedIcon />}
                />
              }
              label="Disable"
              labelPlacement="start"
            />
            <ExpandMore
              fontSize="large"
              style={{ marginLeft: "10px", cursor: "pointer" }}
              onClick={() => onApply("is_disabled")}
            />
          </Box>
        </Box>
        <Box width={1} display="flex" flexWrap="wrap">
          <Box
            width={isMobile ? 1 : 1 / 2}
            p={2}
            display="flex"
            alignItems="center"
            justifyContent="center"
          >
            <TextField
              type="number"
              variant="outlined"
              label="Min tickets"
              value={parseNumberInputValue(state.min_tickets)}
              InputLabelProps={{
                shrink: true,
              }}
              onChange={(e) =>
                onChangeField("min_tickets", Number(e.target.value))
              }
            />
            <ExpandMore
              fontSize="large"
              style={{ marginLeft: "10px", cursor: "pointer" }}
              onClick={() => onApply("min_tickets")}
            />
          </Box>
          <Box
            width={isMobile ? 1 : 1 / 2}
            p={2}
            display="flex"
            alignItems="center"
            justifyContent="center"
          >
            <TextField
              type="number"
              variant="outlined"
              label="Max tickets"
              value={parseNumberInputValue(state.max_tickets)}
              InputLabelProps={{
                shrink: true,
              }}
              onChange={(e) =>
                onChangeField("max_tickets", Number(e.target.value))
              }
            />
            <ExpandMore
              fontSize="large"
              style={{ marginLeft: "10px", cursor: "pointer" }}
              onClick={() => onApply("max_tickets")}
            />
          </Box>
        </Box>
      </CardContent>
    </Card>
  );
};
