import { IPrm } from 'src/web/features/table/interfaces';

export interface IPayoutsPrm {
  page_num: number;
  per_page: number;
  partner_id: number[];
  user_id: number;
}

export const normalizePrm = (
  prm: IPrm | null,
  partner_id: number
): IPayoutsPrm | null => {
  if (!prm) return null;

  const normalisePrm: IPayoutsPrm = {
    page_num: (parseInt(prm.offset || '0') + prm.limit) / prm.limit,
    per_page: prm.limit,
    partner_id: [partner_id],
    user_id: partner_id,
  };

  return normalisePrm;
};
