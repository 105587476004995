import React, { useEffect, useState } from "react";
import { useQuery, useMutation } from "@apollo/client";
import { GET_TEMP_PARTNER, UPDATE_PARTNER } from "src/api/tempPartners";
import OnboardingComponent from "src/web/components/Onboarding/OnboardingComponent";
import { useSelector, useDispatch } from "react-redux";
import { auth } from "src/redux/Authentication/sliceAuthentication";
import { setNotification } from "src/redux/Notifications/sliceNotifications";
import { Spiner } from "src/web/components/Loaders";
import {
  setData,
  onboardingData,
  setReceivedData,
  updateData,
} from "src/redux/Onboarding/sliceOnboarding";

const Onboarding = () => {
  const dispatch = useDispatch();
  const { receivedData, form: partnerData } = useSelector(onboardingData);
  const { user } = useSelector(auth);
  const dataForUpdate = useSelector(updateData);

  const [activeStep, setActiveStep] = useState(2);
  const [completed, setCompleted] = useState<{ [k: number]: boolean }>({});
  const [isBackClicked, setIsBackClicked] = useState(false);
  const [isSpiner, setIsSpiner] = useState(false);

  const { loading } = useQuery(GET_TEMP_PARTNER, {
    variables: {
      prm: {
        id: user["cognito:username"],
      },
    },
    onCompleted: (data) => {
      dispatch(setData(data.v1_partners_getTempPartner));
      dispatch(setData({ name: user.preferred_username }));
      dispatch(setReceivedData(data.v1_partners_getTempPartner));
    },
    onError: (error) => {
      dispatch(
        setNotification({
          type: "error",
          message: error.message,
          duaration: 10000,
        })
      );
    },
  });

  const [updatePartner] = useMutation(UPDATE_PARTNER, {
    onCompleted: () => {
      setActiveStep((prevActiveStep) => prevActiveStep + 1);
      setIsSpiner(false);
    },
    onError: (error) => {
      setIsSpiner(false);
      dispatch(
        setNotification({
          type: "error",
          message: error.message,
          duaration: 10000,
        })
      );
    },
  });

  const handleSubmit = (data: any) => {
    const updatedData = {
      id: user["cognito:username"],
      ...data,
    };
    setIsSpiner(true);
    updatePartner({
      variables: {
        prm: {
          ...updatedData,
        },
      },
    });
  };

  const handleStep = (step: number) => () => {
    if (step < activeStep) {
      setIsBackClicked(true);
    }
    setActiveStep(step);
  };

  const onCompleteStep = (type: boolean) => {
    const newCompleted = completed;
    newCompleted[activeStep] = type;
    setCompleted(newCompleted);
  };

  const handleComplete = () => {
    onCompleteStep(true);
    if (activeStep === 0) {
      if (!receivedData?.termsAndConditions) {
        handleSubmit({ termsAndConditions: true });
      } else {
        onCompleteSuccess(1);
      }
    }
    if (activeStep === 1) {
      if (!receivedData?.w9) {
        handleSubmit({ ...dataForUpdate });
      } else {
        onCompleteSuccess(2);
      }
    }
    if (activeStep === 2) {
      if (!receivedData?.pli_exp) {
        handleSubmit({ pli_exp: partnerData.pli_exp });
      } else {
        onCompleteSuccess(3);
      }
    }
  };

  const onCompleteSuccess = (step: number) => {
    onCompleteStep(true);
    setActiveStep(step);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
    onCompleteStep(false);
    setIsBackClicked(true);
  };

  useEffect(() => {
    if (!isBackClicked) {
      if (activeStep === 0 && receivedData?.termsAndConditions) {
        onCompleteSuccess(1);
      }
      if (activeStep === 1 && receivedData?.w9) {
        onCompleteSuccess(2);
      }
      if (activeStep === 2 && receivedData?.pli_exp) {
        onCompleteSuccess(3);
      }
    }
  }, [activeStep, receivedData, isBackClicked]);

  useEffect(() => {
    if (activeStep === 0) {
      const keyDownHandler = (event: any) => {
        if (event.key === "Enter") {
          handleComplete();
        }
      };

      document.addEventListener("keydown", keyDownHandler);

      return () => {
        document.removeEventListener("keydown", keyDownHandler);
      };
    }
  }, [activeStep, partnerData]);

  return (
    <>
      {(isSpiner || loading) && (
        <div style={{ background: "white", width: "100%", height: "100%" }}>
          <Spiner fixed />
        </div>
      )}
      <OnboardingComponent
        activeStep={activeStep}
        handleStep={handleStep}
        completed={completed}
        handleComplete={handleComplete}
        handleBack={handleBack}
        setIsSpiner={setIsSpiner}
      />
    </>
  );
};

export default Onboarding;
