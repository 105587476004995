import { makeStyles } from "@material-ui/core/styles";

export const useStyles = makeStyles(() => ({
  title: {
    fontSize: "18px",
    color: "#333333",
    textAlign: "center",
    fontWeight: 600,
    fontFamily: `"Montserrat", "Helvetica", "Arial", sans-serif`,
    marginBottom: "18px",
  },
  subTitle: {
    fontSize: "12px",
    color: "#C4C4C4",
    fontWeight: 400,
    fontFamily: `"Montserrat", "Helvetica", "Arial", sans-serif`,
    marginTop: "17px",
    textAlign: "center",
  },
  icon_box: {
    width: "64px",
    height: "64px",
    zIndex: 5,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    position: "absolute",
    top: "-35px",
    left: "20px",
    borderRadius: ".5rem",
  },
  icon_box_icon: {
    color: "#fff",
    height: "36px",
    width: "36px",
  },
  grey_bg: {
    background: "#444",
    color: "#fff",
    "&:hover": {
      background: "#616161",
    },
  },
  buttonsContainer: {
    display: "flex",
    justifyContent: "center",
    marginTop: "70px",
    "@media (max-width: 620px)": {
      marginTop: "10px",
      marginX: "0px 8px",
    },
  },
  contentWrapper: {
    height: "285px",
    display: "flex",
    alignItems: "center",
    marginTop: "30px",
    
    // justifyContent: "center",
    flexDirection: "column",
    "& > div": { 
      width: "100%",
    },
    "@media (max-width: 620px)": {
      padding: "0 12px",
      height: "285px",
    },
  },
  groupTitle: {
    color: "#333333",
    fontSize: "20px",
    textAlign: "center",
    fontWeight: 500,
    paddingBottom: "24px",
    "@media (max-width: 620px)": {
      fontSize: "16px",
      paddingBottom: "12px",
    },
  },
  saveButton: {
    textTransform: "none",
    color: "white",
    fontSize: "16px",
    background: "linear-gradient(90deg, #03D9B1 0%, #1CBBD9 100%)",
    borderRadius: "50px",
    boxShadow:
      "0px 13px 28px rgba(29, 203, 192, 0.2), 0px 8px 16px rgba(29, 203, 192, 0.06)",
    width: "140px",
    padding: "6.5px 50px",
    fontFamily: `"Montserrat", "Helvetica", "Arial", sans-serif`,
    fontWeight: 500,
    "&:hover": {
      background: "linear-gradient(90deg, #06C9A4 0%, #00A2C1 100%)",
      color: "#FFFFFF",
      boxShadow:
        "0px 13px 28px rgba(29, 203, 192, 0.2), 0px 8px 16px rgba(29, 203, 192, 0.06)",
    },
    "@media (max-width: 620px)": {
      fontSize: "14px",
      width: "120px",
      padding: "7.5px 43px",
    },
  },
  cancelButton: {
    textTransform: "none",
    marginRight: "27px",
    color: "#333333",
    fontFamily: `"Montserrat", "Helvetica", "Arial", sans-serif`,
    fontSize: "16px",
    padding: "6.5px 33px",
    borderRadius: "50px",
    fontWeight: 500,
    "&:hover": {
      background: "white",
      color: "#6C6B6B",
    },
    "@media (max-width: 620px)": {
      fontSize: "14px",
      padding: "7.5px 43px",
      marginRight: "10px",
    },
  },
}));
