import { IPrm, ISqlOrder, sqlWhereRule } from "src/web/features/table/interfaces";

export interface INotificationsPrm {
  username: string;
  limit: number;
  pager: string;
  filter?: {
    showReaded: boolean;
  };
  order?: ISqlOrder;
}

export const normalizePrm = (
  prm: IPrm | null,
  username: string
): INotificationsPrm | null => {
  if (!prm) return null;

  const showReadedValue = prm.where?.[0]?.[0]?.value;
  let filter;

  if (showReadedValue === 'new') {
    filter = {
      showReaded: false,
    }
  }

  if (showReadedValue === 'read') {
    filter = {
      showReaded: true,
    }
  }

  const normalisePrm: INotificationsPrm = {
    username: username,
    pager: !prm.offset || prm.offset === "0" ? "" : prm.offset,
    limit: prm.limit || 15,
    filter,
    order: prm.order,
  };

  return normalisePrm;
};
