import { makeStyles } from "@material-ui/core/styles";

export const useStyles = makeStyles(() => ({
  visuallyHidden: {},
  sortingItem: {
    textTransform: "none",
    color: "#333333",
    fontSize: "14px",
    lineHeight: "20px",
    "@media (max-width: 850px)": {
      fontSize: "12px",
    },
  },
  tableSort: {
    "&.active": {
      "& svg": {
        opacity: '1 !important',
      },
    },
    "&.asc": {
      "& svg": {
        transform: "rotate(180deg)",
      },
    },
    "&.desc": {
      "& svg": {
        transform: "rotate(0deg)",
      },
    },
  },
}));
