import React, { SyntheticEvent } from 'react';
import { useDispatch, useSelector } from 'react-redux';
// import { useTranslation } from 'react-i18next';
import {
  List,
  ListSubheader,
  ListItem,
  ListItemText,
  Button,
} from '@material-ui/core/';
import Animation from 'src/web/features/animation';
import {
  filter,
  setFilter,
  setLoading,
  clearFilterStates,
} from 'src/web/features/table/sliceTables';
import { useStyles } from './styles';

type Props = {
  forbiddenReselect?: boolean;
}

export const TableFilter = ({ forbiddenReselect }: Props) => {
  const classes = useStyles();
  const currentFilter = useSelector(filter);
  const dispatch = useDispatch();

  const visibleFilters = currentFilter.filter((filter: any) => !filter.isHidden);

  const onClickHandler = (e: SyntheticEvent) => {
    const field = e.currentTarget.getAttribute('data-key');
    const filterIdx = Number(e.currentTarget.getAttribute('data-field-idx'));
    const valueIdx = Number(
      (e.target as HTMLElement)
        .closest('[data-value]')
        ?.getAttribute('data-value')
    );

    if ((!valueIdx && valueIdx !== 0) || !field) return;

    if (forbiddenReselect && currentFilter[filterIdx].values[valueIdx].isSelected) {
      return;
    }

    dispatch(setLoading({ type: 'change', value: true }));
    dispatch(setFilter({ field, valueIdx }));
  };

  const handleClearFilter = () => {
    dispatch(setLoading({ type: 'change', value: true }));
    dispatch(clearFilterStates());
  };

  return (
    <Animation animation="transition.slideUpIn" delay={400}>
      <div>
        {visibleFilters.map((filter: any, idx: number) => (
          <List onClick={onClickHandler} key={idx} data-key={filter.field} data-field-idx={idx}>
            <ListSubheader className={classes.listTitle} disableSticky>
              {filter.label}
              {filter.field === 'state_id' &&
                filter.values.find((f: any) => f.isSelected === true) && (
                  <Button
                    className={classes.clearBtn}
                    onClick={() => handleClearFilter()}
                    size="small">
                    Clear
                  </Button>
                )}
            </ListSubheader>

            {filter.values.map((value: any, idx: number) => {
              return (
                <ListItem
                  key={value.label}
                  data-value={idx}
                  className={classes.listItem}
                  button
                  selected={value.isSelected}>
                  <ListItemText primary={value.label} disableTypography />
                </ListItem>
              );
            })}
          </List>
        ))}
      </div>
    </Animation>
  );
};
