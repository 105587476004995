import { makeStyles } from "@material-ui/core/styles";
import errorIcon from "src/assets/icons/helperError.png";
import USAIcon from "src/assets/icons/flags/US.png";

export const useStyles = makeStyles(() => ({
  root: {
    opacity: "0.6",
    fontFamily: `"Montserrat", "Helvetica", "Arial", sans-serif`,
    "&$checked": {
      opacity: 1,
    },
  },
  subTitle: {
    fontSize: '12px',
    color: '#C4C4C4',
    fontWeight: 400,
    fontFamily: `"Montserrat", "Helvetica", "Arial", sans-serif`,
    marginTop: '12px',
    textAlign: "center"
  },
  checked: {},
  label: {
    color: "rgba(0, 0, 0, 0.54)",
    "& span": {
      fontSize: "0.875rem !important",
    },
  },
  customInput: {
    "& label": {
      color: "#333",
      paddingLeft: "22px",
      fontWeight: 500,
      fontFamily: `"Montserrat", "Helvetica", "Arial", sans-serif`,
      fontSize: "15px",
      "&.Mui-focused": {
        color: "#333",
      },
    },
    "& div": {
      height: "50px",
      borderRadius: "50px",
      paddingLeft: 0,
    },
    "& input": {
      padding: "15px 30px",
      color: "#333333",
      fontSize: "14px",
      fontFamily: `"Montserrat", "Helvetica", "Arial", sans-serif`,
    },
    "& fieldset": {
      border: "1px #C4C4C4 solid",
      paddingLeft: "25px",
    },
    "& .Mui-focused fieldset": {
      border: "1px #202020 solid !important",
    },

    "& p.Mui-error": {
      color: "#EC4652",
      background: "#FEF6F6",
      border: "1px solid #DDDDDD",
      boxShadow: "0px 3.89312px 23.3587px rgba(189, 189, 189, 0.23)",
      borderRadius: "15px",
      padding: "12px 29px",
      fontWeight: 600,
      fontSize: "12px",
      fontFamily: `"Montserrat", "Helvetica", "Arial", sans-serif`,
      margin: "13px 0 0 0",
      display: "flex",
      alignItems: "center",
      position: "relative",
      "&:before": {
        content: '""',
        backgroundImage: `url(${errorIcon})`,
        display: "inline-flex",
        marginRight: "8px",
        width: "18px",
        height: "18px",
      },
    },
  },
  customLinkWrapper: {
    display: "flex",
    justifyContent: "center",
    marginTop: "5px",
    fontFamily: `"Montserrat", "Helvetica", "Arial", sans-serif`,
    fontSize: "12px",
  },
  customLink: {
    color: "#0DD1C5",
  },
  authLink: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    marginTop: "25px",
    "& a": {
      color: "rgba(0, 0, 0, 0.54)",
    },
  },
  authButton: {
    textTransform: "none",
    color: "white",
    fontSize: "16px",
    background: "linear-gradient(90deg, #03D9B1 0%, #1CBBD9 100%)",
    borderRadius: "50px",
    height: "50px",
    fontFamily: `"Montserrat", "Helvetica", "Arial", sans-serif`,
    boxShadow:
      "0px 13px 28px rgba(29, 203, 192, 0.2), 0px 8px 16px rgba(29, 203, 192, 0.06)",
    padding: "6.5px 60px",
    "&:hover": {
      background: "linear-gradient(90deg, #06C9A4 0%, #00A2C1 100%)",
      color: "#FFFFFF",
      boxShadow:
        "0px 13px 28px rgba(29, 203, 192, 0.2), 0px 8px 16px rgba(29, 203, 192, 0.06)",
    },
  },
  cancelButton: {
    textTransform: "none",
    color: "#c4c4c4",
    fontSize: "16px",
    padding: "6.5px 33px",
    borderRadius: "50px",
    fontFamily: `"Montserrat", "Helvetica", "Arial", sans-serif`,
    "&:hover": {
      color: "#6C6B6B",
    },
  },
  backButton: {
    textTransform: "none",
    color: "#0DD1C5",
    padding: "6.5px 33px",
    fontWeight: 500,
    fontFamily: "Montserrat",
    "&:hover": {
      color: "#06C9A4",
      background: "none",
    },
  },
  authSocial: {
    position: "relative",
    margin: "30px 0 -10px",
    "&::before": {
      position: "absolute",
      display: "block",
      content: "''",
      width: "340px",
      height: "1px",
      background: "#cacaca",
      top: "8px",
      left: "50%",
      transform: "translateX(-50%)",
      zIndex: 1,
    },
  },
  authSocialTitle: {
    position: "relative",
    width: "120px",
    margin: "0 auto 20px",
    zIndex: 2,
    textAlign: "center",
    textTransform: "uppercase",
    color: "rgba(0, 0, 0, 0.54)",
    background: "#fff",
    padding: "0 12px",
  },
  authSocialList: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-around",
    padding: "0 50px",
  },
  authSocialItem: {
    opacity: "0.7",
    cursor: "pointer",
    transition: "all ease 0.3s",
    "&:hover": {
      opacity: "1",
      transition: "all ease 0.3s",
    },
  },
  blackBtn: {
    backgroundColor: "#333",
    color: "#f5f5f5",
    transition: "all ease 0.4s",
    "&:hover": {
      backgroundColor: "#222",
      color: "#fff",
      transition: "all ease 0.4s",
    },
  },
  phoneInput: {
    position: "relative",
    marginTop: "74px",
    "@media (max-width: 620px)": {
      marginBottom: "70px",
      marginTop: "60px",
    },
    "& .form-control": {
      borderRadius: 50,
      padding: "4px 4px 5px",
      height: "50px",
      color: "#6C6B6B",
      fontSize: "14px",
      "@media (max-width: 620px)": {
        fontSize: "12px",
        height: "45px",
      },
      paddingLeft: "90px",
      fontFamily: "Montserrat",
    },
    "& .special-label": {
      fontSize: "12px",
      fontWeight: 500,
      "@media (max-width: 620px)": {
        fontSize: "10px",
      },
      color: "#333333",
      fontFamily: "Montserrat",
      left: "24px !important",
    },
    "& .selected-flag": {
      paddingLeft: "30px",
      marginTop: "3px",
    },
    "& .react-tel-input .form-control:focus": {
      border: "1px solid #333333",
      boxShadow: "none",
    },
    "& .react-tel-input .us": {
      backgroundImage: `url(${USAIcon})`,
      backgroundPosition: "inherit",
      // marginTop: "-10px",
      width: "28px",
    },
    "& .react-tel-input .selected-flag .arrow": {
      borderTop: "4px solid #C4C4C4",
      borderBottom: "none",
      marginLeft: "12px",
    },
    "& .country-name, & .dial-code": {
      fontFamily: "Montserrat",
      fontSize: "14px",
    },
  },
  saveButton: {
    textTransform: "none",
    color: "white",
    fontSize: "16px",
    background: "linear-gradient(90deg, #03D9B1 0%, #1CBBD9 100%)",
    borderRadius: "50px",
    boxShadow:
      "0px 13px 28px rgba(29, 203, 192, 0.2), 0px 8px 16px rgba(29, 203, 192, 0.06)",
    width: "140px",
    padding: "6.5px 50px",
    fontFamily: `"Montserrat", "Helvetica", "Arial", sans-serif`,
    fontWeight: 500,
    "&:hover": {
      background: "linear-gradient(90deg, #06C9A4 0%, #00A2C1 100%)",
      color: "#FFFFFF",
      boxShadow:
        "0px 13px 28px rgba(29, 203, 192, 0.2), 0px 8px 16px rgba(29, 203, 192, 0.06)",
    },
    "@media (max-width: 620px)": {
      fontSize: "14px",
      width: "120px",
      padding: "7.5px 43px",
    },
  },
}));
