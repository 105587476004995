import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from 'src/redux/store';
import { initialState, initialArrivalData } from './initialData';
import { IArrivalDetail, TPartial } from './interfaces';

export const arrivalSlice = createSlice({
  name: 'arrivals',
  initialState,
  reducers: {
    setArrivalData: (state, action: PayloadAction<TPartial<IArrivalDetail | null>>) => {
      const data = action.payload;
      state.arrivalData = data || initialArrivalData;
    },
    setInitialData: (state) => {
      state.arrivalData = { ...initialState.arrivalData };
      state.showError = false;
      state.loading = true;
      state.receivedData = 0;
    },
    setValue: (state, action: PayloadAction<Partial<IArrivalDetail>>) => {
      const data = action.payload;

      state.arrivalData = {
        ...state.arrivalData,
        ...data,
      };
    },
    setReceivedData: (state, action: PayloadAction<IArrivalDetail>) => {
      const data = action.payload;
      state.receivedData = data;
      state.loading = false;
    },
    setLoading: (state, action: PayloadAction<boolean>) => {
      state.loading = action.payload;
    },
    setShowError: (state) => {
      state.showError = true;
    },
  },
});

export const {
  setValue,
  setArrivalData,
  setInitialData,
  setLoading,
  setReceivedData,
  setShowError,
} = arrivalSlice.actions;

export const arrivalData = (state: RootState) => state.arrival;
export const arrival = (state: RootState) => state.arrival.arrivalData;
export const receivedData = (state: RootState) => state.arrival.receivedData;
export const isCreate = (state: RootState) => Boolean(!state.arrival.arrivalData.id);
export const showError = (state: RootState) => state.arrival.showError;
export const arrivalLoading = (state: RootState) => state.arrival.loading;

export default arrivalSlice.reducer;
