import { jwtDecode } from 'jwt-decode';
import sign from 'jwt-encode';
import { TUserInfo } from '../domain/Chat.domain';
import config from 'src/config';

const initChatWidget = (
  attributes: TUserInfo & { InitPage: string },
  JWTtoken?: string | null,
  callback?: any
) => {
  (function (w: any, d: any, x: any, id: any) {
    const s: HTMLScriptElement = d.createElement('script');
    s.src = config.CHAT_WIDGET_SRC;
    s.async = !!1;
    s.id = id;
    d.getElementsByTagName('head')[0].appendChild(s);
    w[x] =
      w[x] ||
      function () {
        (w[x].ac = w[x].ac || []).push(arguments);
      };
  })(window, document, 'amazon_connect', config.CHAT_WIDGET_ID);

  //@ts-ignore
  amazon_connect('styles', {
    openChat: {
      color: 'white',
      backgroundColor: 'linear-gradient(90deg, #03d9b1 0%, #1cbbd9 100%)',
    },
    closeChat: {
      color: 'white',
      backgroundColor: 'linear-gradient(90deg, #03d9b1 0%, #1cbbd9 100%)',
    },
  });

  //@ts-ignore
  amazon_connect('snippetId', config.CHAT_WIDGET_SNIPPET_ID);

  //@ts-ignore
  amazon_connect('customerDisplayName', function (callback) {
    const displayName = `${attributes.FirstName} ${attributes.LastName}`.trim();
    callback(displayName);
  });

  //@ts-ignore
  amazon_connect('authenticate', function (callback) {
    const payload = {
      sub: config.CHAT_WIDGET_ID,
      iat: Date.now(),
      exp: Date.now() + 600000,
      attributes: {
        ...attributes,
        InitPage: `${window.location.origin}${window.location.pathname}`,
      },
    };

    if (JWTtoken) {
      const { exp } = jwtDecode(JWTtoken);
      if (exp && Date.now() < exp) {
        callback(JWTtoken);
      } else {
        const token = sign(payload, config.CHAT_WIDGET_KEY, {
          algorithm: 'HS256',
        });
        window.localStorage.setItem('clientChatToken', token);
        callback(token);
      }
    } else {
      const token = sign(payload, config.CHAT_WIDGET_KEY, {
        algorithm: 'HS256',
      });
      window.localStorage.setItem('clientChatToken', token);
      callback(token);
    }

    setTimeout(() => {
      const frame = document.querySelector(
        '#amazon-connect-chat-widget iframe'
      );
      if (frame) {
        const cssLink = document.createElement('link');
        cssLink.href = '/amazonChat.css';
        cssLink.rel = 'stylesheet';
        cssLink.type = 'text/css';
        //@ts-ignore
        // eslint-disable-next-line no-restricted-globals
        frames['amazon-connect-chat-widget'].document.head.appendChild(cssLink);
      }
    }, 1500);
  });

  if (callback) {
    callback();
  }
};

export default initChatWidget;
