import React, { ReactElement } from 'react';
import PerfectScrollbar from 'react-perfect-scrollbar';
import { useStyles } from './styles';

type Props = {
  header: ReactElement;
  content: ReactElement;
};

export const DashboardLayout = ({ header, content }: Props) => {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <div className={classes.topBg}></div>
      <div className={classes.pageWrapper}>
        <div className={classes.contentWrapper}>
          <PerfectScrollbar style={{ maxHeight: '100%' }}>
            <div className={classes.header}>{header}</div>
            <div className={classes.content}>{content}</div>
          </PerfectScrollbar>
        </div>
      </div>
    </div>
  );
};
