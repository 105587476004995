import moment from "moment";

export const normalizeTime = (h: number, m: number): string => {
  const time = `${h}:${m}`;
  return moment(time, "HH:mm").format("hh:mma");
};

export const dateRanges = (startDate: string | null, endDate: string | null) => {
  if (!startDate || !endDate) return [];

  let date = [];
  while (moment(startDate) <= moment(endDate)) {
    date.push(startDate);
    startDate = moment(startDate).add(1, "days").format("YYYY-MM-DD");
  }
  return date;
};
