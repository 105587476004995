import React, { useEffect, useState } from 'react';
import { Box, Typography } from '@material-ui/core';
import { useDispatch, useSelector } from 'react-redux';
import { useInView } from 'react-intersection-observer';
import { ReviewCard } from './content/ReviewCard';
import { Loader, Spiner } from '../Loaders';
import { scrollLoading, setLoading } from 'src/web/features/table/sliceTables';
import { IReviewsPrm } from 'src/web/pages/Reviews/normalizePrm';
import { useStyles } from './styles';
import { ResponseDialog } from './content/ResponseDialog';
import { useMutation } from '@apollo/client';
import { DELETE_REVIEW } from 'src/api/reviews';
import { setNotification } from 'src/redux/Notifications/sliceNotifications';
import { auth } from 'src/redux/Authentication/sliceAuthentication';
import { Header } from './Header';
import { RateData } from 'src/redux/Reviews/interfaces';
import { isUserHasPermissions } from 'src/helpers/permissions';
import { NotFoundData } from '../UI/NotFoundData';

export const ReviewsList = ({
  isSpiner,
  reviews,
  totalPages,
  prm,
  setPrm,
  refetchList,
  rates,
  onChangeFilter,
  product_id,
}: {
  isSpiner: boolean;
  reviews: IReview[];
  totalPages: number;
  prm: IReviewsPrm;
  setPrm: React.Dispatch<React.SetStateAction<IReviewsPrm>>;
  refetchList: () => void;
  rates: RateData;
  onChangeFilter: (filter: Partial<IReviewsPrm>) => void;
  product_id?: number;
}) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const [ref, inView] = useInView();
  const { user } = useSelector(auth);

  const [choosenReview, setChoosenReview] = useState<IReview>();

  const currentScrollLoading = useSelector(scrollLoading);
  const isUserCanResponse = isUserHasPermissions(user, ['review_reply']);

  const [deleteReply] = useMutation(DELETE_REVIEW, {
    onCompleted: () => {
      dispatch(
        setNotification({
          message: 'Reply was deleted',
          type: 'success',
        })
      );

      refetchList();
    },
    onError: (error) => {
      dispatch(
        setNotification({
          message: error.message,
          type: 'error',
        })
      );
    },
  });

  useEffect(() => {
    if (inView && !currentScrollLoading && totalPages > prm.page + 1) {
      dispatch(setLoading({ type: 'scroll', value: true }));
      setPrm((prevPrm) => ({ ...prevPrm, page: prevPrm.page + 1 }));
    }
  }, [dispatch, inView, currentScrollLoading, prm.page]);

  const InfinityScrollHelper = () => {
    if (isSpiner) {
      return <></>;
    }

    if (currentScrollLoading) {
      return <Loader />;
    }

    return <div ref={ref} className={classes.hiddenScrollActivator}></div>;
  };

  const handleOpenResponse = (review: IReview) => {
    setChoosenReview(review);
  };

  const onDeleteHandle = (reviewId: number) => {
    deleteReply({
      variables: {
        prm: {
          action: 'delete_reply',
          review_id: reviewId,
          user_id: +user['custom:partner_id'],
        },
      },
    });
  };

  return (
    <>
      <ResponseDialog
        setReview={setChoosenReview}
        review={choosenReview}
        refetchList={refetchList}
      />

      <Header
        rates={rates}
        onChangeFilter={onChangeFilter}
        product_id={product_id}
      />
      <Box className={classes.mainBox}>
        {isSpiner && <Spiner fixed />}
        {!reviews.length && !isSpiner && (
          <div className={classes.noDataBox}>
            <NotFoundData title={'No reviews were found'} />
          </div>
        )}
        {reviews.map((review, index) => (
          <div key={index}>
            <ReviewCard
              review={review}
              reviewsLength={reviews.length}
              index={index}
              handleOpenResponse={handleOpenResponse}
              onDeleteHandle={onDeleteHandle}
              isUserCanResponse={isUserCanResponse}
            />
          </div>
        ))}
        <InfinityScrollHelper />
      </Box>
    </>
  );
};
