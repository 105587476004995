import { useEffect, useState } from "react";
import { useLazyQuery } from "@apollo/client";
import { GET_ALL_LOCATIONS } from "src/api/locations";
import { useError } from "./useError";

export const useLocations = () => {
  const [locations, setLocations] = useState<any>([]);
  const [getLocations, { data, loading, error }] =
    useLazyQuery(GET_ALL_LOCATIONS);

  useEffect(() => {
    if (!loading && data) {
      setLocations(data.v1_partners_places);
    }
  }, [data, loading]);

  useEffect(() => {
    getLocations();
  }, [getLocations]);

  useError(error?.message);

  return locations;
};
