import React from 'react';
import { parsePhoneNumber } from 'libphonenumber-js';

import CAIcon from 'src/assets/icons/flags/CA.svg';
import USAIcon from 'src/assets/icons/flags/USA.svg';
import DOIcon from 'src/assets/icons/flags/DO.svg';

export const GetPhoneFlagIcon = (phone: string) => {

  if (!phone) {
    return null;
  }

  const phoneNumber = parsePhoneNumber(phone);

  if (phoneNumber) {
    if (phoneNumber.country === 'CA') {
      return React.createElement(CAIcon);
    }
    if (phoneNumber.country === 'DO') {
      return React.createElement(DOIcon);
    }
    if (phoneNumber.country === 'US') {
      return React.createElement(USAIcon);
    }
  }

  return null;
};
