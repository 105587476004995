export const formArray = {
  tickets: [
    {
      name: "",
      is_default: "0",
      price: "0",
      barcodes_remaining: "0",
      barcodes_total: "0",
      duration: "0",
      is_child: "0",
      is_disabled_ts: "0",
      min: "0",
      strike_out: "0",
      total_ts: "0",
      wholesale: "0",
      view_order: "0",
      age_from: "0",
      age_to: "0",
      use_age_range: "0",
      use_barcodes: "0",
    },
    {
      name: "",
      is_default: "0",
      price: "0",
      barcodes_remaining: "0",
      barcodes_total: "0",
      duration: "0",
      is_child: "0",
      is_disabled_ts: "0",
      min: "0",
      strike_out: "0",
      total_ts: "0",
      wholesale: "0",
      view_order: "0",
      age_from: "0",
      age_to: "0",
      use_age_range: "0",
      use_barcodes: "0",
    },
  ],
  additional_options: [
    {
      name: "",
      type: "",
    },
  ],
};
