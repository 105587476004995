import { Box, Typography } from '@material-ui/core';
import React, { ReactElement } from 'react';
import { dollarAmountsFloat } from 'src/helpers/DollarAmounts';
import { useStyles } from './styles';

type Props = {
  icon: () => ReactElement;
  title: string;
  amount: number;
};

export const DashboardCard = ({ icon: Icon, title, amount }: Props) => {
  const classes = useStyles();

  return (
    <Box className={classes.dashboardCard}>
      <Box>
        <Icon />
      </Box>
      <Box display="flex" flexDirection="column" marginLeft="15px">
        <Typography className={classes.dashboardCardTitle}>
          {title}
        </Typography>
        <Typography className={classes.dashboardCardAmount}>
          {dollarAmountsFloat(amount)}
        </Typography>
      </Box>
    </Box>
  );
};
