import React, { useState, createRef, useEffect } from "react";
import { useLazyQuery, useMutation } from "@apollo/client";
import { Box, Button, Typography } from "@material-ui/core";
import { useStyles } from "./styles";
import { onboardingData, setData } from "src/redux/Onboarding/sliceOnboarding";
import { useDispatch, useSelector } from "react-redux";
import { FILES_UPLOAD } from "src/api/files";
import { auth } from "src/redux/Authentication/sliceAuthentication";
import { setNotification } from "src/web/features/notifications/sliceNotifications";
import {
  IInterviewBlock,
  IOnboardingForm,
} from "../../OnboardingForm/interface";
import { FormElement } from "../../OnboardingForm/FormElement";
import { validateField } from "../../OnboardingForm/validate";
import { GET_FORM } from "src/api/onboardingForms";
import { Spiner } from "../../Loaders";

const toBase64 = (file: any) =>
  new Promise<boolean | string | ArrayBuffer | null>((resolve, reject) => {
    if (!(file instanceof Blob)) {
      return reject(false);
    }
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = (error) => reject(error);
  });

export const Insurance = ({
  handleSubmit,
  handleBack,
  setIsSpiner,
  formId,
}: {
  handleSubmit: () => void;
  handleBack: () => void;
  setIsSpiner: React.Dispatch<React.SetStateAction<boolean>>;
  formId: string;
}) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const [step, setStep] = useState(0);
  const [files, setFiles] = useState<any[]>([]);
  const [isNextWasClicked, setIsNextClicked] = useState(false);
  const [errorMessage, setErrorMessage] = useState<
    Partial<Record<keyof IOnboardingForm, string>>
  >({});
  const [insuranceForm, setInsuranceForm] = useState<
    IOnboardingForm | undefined
  >(undefined);

  const { receivedData, form: data } = useSelector(onboardingData);
  const { user } = useSelector(auth);

  const [getForm, { loading }] = useLazyQuery(GET_FORM, {
    variables: {
      prm: {
        id: formId,
      },
    },
    onCompleted: (data) => {
      const dataScreens = data.v1_general_getForm.screens;
      const newForm = {
        ...data.v1_general_getForm,
        screens: JSON.parse(dataScreens),
      };
      setInsuranceForm(newForm);
    },
    onError: (error) => {
      console.error(error);
    },
  });

  const [uploadFile] = useMutation(FILES_UPLOAD, {
    onCompleted: () => {
      setIsSpiner(false);
    },
    onError: (error) => {
      setIsSpiner(false);
      setStep(step - 1);
      dispatch(
        setNotification({
          type: "error",
          message: error.message,
          duaration: 10000,
        })
      );
    },
  });

  const incrementStep = () => {
    setIsNextClicked(true);
    if (step === 0 && !data.pli_exp) {
      handleUploadFile();
    }
    if (step === 1) {
      handleSubmit();
      return;
    }

    setStep(step + 1);
  };

  const decrementStep = () => {
    setStep(step - 1);

    if (step === 0) {
      handleBack();
    } else {
      setStep(step - 1);
    }
  };

  useEffect(() => {
    setIsNextClicked(false);
  }, [step]);

  useEffect(() => {
    if (formId) {
      getForm();
    }
  }, [formId]);

  useEffect(() => {
    const keyDownHandler = (event: any) => {
      if (event.key === "Enter") {
        incrementStep();
      }
    };

    document.addEventListener("keydown", keyDownHandler);

    return () => {
      document.removeEventListener("keydown", keyDownHandler);
    };
  }, [step, data, insuranceForm]);

  const dropzoneRef = createRef() as any;
  const openDialog = () => {
    if (dropzoneRef.current) {
      dropzoneRef.current.open();
    }
  };

  const handleUploadFile = async () => {
    setIsSpiner(true);
    const file = files[0];
    let base64 = await toBase64(file);
    if (!base64) return;
    base64 = base64.toString();
    uploadFile({
      variables: {
        prm: {
          partner_id: String(user["cognito:username"]),
          type: "pli",
          name: file.name,
          file: base64.slice(base64.indexOf(",") + 1),
          candidate: true,
        },
      },
    });
  };

  if (loading || !insuranceForm) {
    return <Spiner fixed />;
  }

  const currentScreens = insuranceForm.screens;

  const currentContent = currentScreens[step].content as IInterviewBlock;

  const renderSteps = () => {
    let steps = [];
    for (let i = 0; i <= step; i++) {
      steps.push(
        <div
          key={i}
          className={classes.step}
          style={{ background: "#0DD1C5", width: "90px" }}
        ></div>
      );
    }

    for (let k = 1; k <= 1 - step; k++) {
      steps.push(
        <div key={k} className={classes.step} style={{ width: "90px" }}></div>
      );
    }

    return steps;
  };

  const isNextDisabled = step === 1 ? !data.pli_exp : !files.length;

  const handleChange = (field: string, value: string) => {
    if (isNextWasClicked) {
      validateField(setErrorMessage, currentContent, field, value);
    }
    dispatch(setData({ [field]: value }));
  };

  return (
    <div>
      <Box className={classes.stepsWrapper}>
        {step + 1}/{currentScreens.length}
        <Box className={classes.stepsBox}>{renderSteps()}</Box>
      </Box>

      <Box className={classes.contentWrapper}>
        <Typography className={classes.groupTitle}>
          {currentScreens[step].title}
        </Typography>
        <div>
          <div key={`${step}`}>
            <FormElement
              formInput={currentScreens[step]}
              data={data}
              receivedData={receivedData}
              form={insuranceForm}
              errorMessage={errorMessage}
              openDialog={openDialog}
              setFiles={setFiles}
              handleChange={handleChange}
            />
          </div>
        </div>
      </Box>

      <Box className={classes.buttonsContainer}>
        <Button
          onClick={decrementStep}
          variant="text"
          color="primary"
          type="submit"
          className={classes.cancelButton}
          size="large"
        >
          Back
        </Button>

        <Button
          onClick={incrementStep}
          disabled={isNextDisabled && !receivedData?.pli_exp}
          variant="contained"
          color="primary"
          type="submit"
          size="large"
          className={classes.saveButton}
          style={{ width: "140px" }}
        >
          Next
        </Button>
      </Box>
    </div>
  );
};
