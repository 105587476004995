import React, { useEffect, useState } from 'react';
import { useMutation, useQuery } from '@apollo/client';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import {
  GET_GENERAL_INVOICE_CSV,
  GET_GENERAL_INVOICE_VIEW,
} from 'src/api/payouts';
import { auth } from 'src/redux/Authentication/sliceAuthentication';
import { Spiner } from 'src/web/components/Loaders';
import PayoutDetailItem from 'src/web/components/Payouts/PayoutsDetail/PayoutDetailItem';

type RouteParams = {
  id: string;
};

const PayoutDetailPage = () => {
  const { user } = useSelector(auth);
  const { id } = useParams<RouteParams>();
  const [invoice, setInvoice] = useState<IPayoutInvoice>();
  const [csvInvoiceData, setCsvInvoiceData] = useState<string>('');

  const { loading } = useQuery(GET_GENERAL_INVOICE_VIEW, {
    variables: {
      prm: {
        id,
        user_id: +user['custom:partner_id'],
      },
    },
    onCompleted: ({ v1_general_invoicesView }) => {
      setInvoice({
        ...v1_general_invoicesView.data,
        data: JSON.parse(v1_general_invoicesView.data.data || 'undefined'),
      });
    },
    onError: (error) => {
      console.error(error);
    },
  });

  const [getInvoiceCSV, { loading: csvLoading }] = useMutation(
    GET_GENERAL_INVOICE_CSV,
    {
      variables: {
        prm: {
          id,
          user_id: +user['custom:partner_id'],
        },
      },
      onCompleted: (data) => {
        setCsvInvoiceData(data.v1_general_invoicesDownload.replace(/["]/g, ''));
      },
      onError: (error) => {
        console.error(error);
      },
    }
  );

  useEffect(() => {
    getInvoiceCSV();
  }, []);

  if (loading || csvLoading) {
    return <Spiner fixed />;
  }

  if (!invoice) {
    return <></>;
  }

  return <PayoutDetailItem invoice={invoice} csvInvoiceData={csvInvoiceData} />;
};

export default PayoutDetailPage;
