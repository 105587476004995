import { makeStyles, createTheme } from "@material-ui/core/styles";
import { deepOrange } from "@material-ui/core/colors/";
import background from "src/images/login_bg.png";

export const useStyles = makeStyles((theme) => ({
  root: {
    background: `url(${background})`,
    backgroundPosition: "center",
    backgroundRepeat: "no-repeat",
    backgroundSize: "cover",
    minHeight: "100vh",
    width: "100%",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    flexDirection: "column",
    padding: "24px",
  },
  card: {
    overflow: "visible",
    position: "relative",
    boxShadow: "0px 3.89px 23.36px rgba(17, 15, 15, 0.15)",
    borderRadius: "15px",
    maxWidth: "380px",
    width: "100%",
  },
  logoWrapper: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    flexDirection: "column",
    paddingBottom: "20px",
  },
  subtitle: {
    fontWeight: 400,
    fontFamily: `"Montserrat", "Helvetica", "Arial", sans-serif`,
    fontSize: "18px",
    color: "#6C6B6B",
    marginTop: "5px"
  },
  cardContent: {
    padding: "30px",
  },
}));

export const theme = createTheme({
  palette: {
    primary: {
      main: "#5ACC6A",
      light: "#5EE071",
      dark: "#50BF60",
      contrastText: "#fff",
    },
    secondary: deepOrange,
  },
});
