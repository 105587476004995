import React, { useState } from 'react';
import {
  Box,
  Button,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControlLabel,
  IconButton,
  MenuItem,
  Select,
  TextField,
  Typography,
} from '@material-ui/core';

import PhoneInput from 'src/web/components/UI/PhoneInput';

import CheckboxIcon from 'src/assets/icons/web/checkbox.svg';
import CheckboxCheckedIcon from 'src/assets/icons/web/checkboxChecked.svg';
import CloseIcon from 'src/assets/icons/light/close.svg';

import { useStyles } from './styles';
import clsx from 'clsx';

type Props = {
  open: boolean;
  setOpen: (isOpen: boolean) => void;
};

export const GroupDetailModal = ({ open, setOpen }: Props) => {
  const classes = useStyles();

  return (
    <Dialog
      fullWidth
      maxWidth="sm"
      open={open}
      className={classes.detailModalRoot}
      onClose={() => setOpen(false)}>
      <DialogTitle>
        <Box className={classes.dialogHeader}>
          <Box className={classes.dialogHeaderTitle}>Change group</Box>
          <IconButton
            onClick={() => setOpen(false)}
            className={classes.closeIcon}>
            <CloseIcon />
          </IconButton>
        </Box>
      </DialogTitle>
      <DialogContent>
        <Box
          width={1}
          display="flex"
          flexWrap="wrap"
          justifyContent="space-between">
          <Box className={classes.groupRightsTitle}>Group rights</Box>

          <Box width={1}>
            <Box className={classes.permissionRow}>
              <Typography className={classes.permissionEntityTitle}>
                Arrivals
              </Typography>
              <Box className={classes.permissionWrapper} mr="37px">
                <FormControlLabel
                  control={
                    <Checkbox
                      icon={<CheckboxIcon />}
                      checkedIcon={<CheckboxCheckedIcon />}
                    />
                  }
                  label="Can read"
                />
              </Box>
              <Box className={classes.permissionWrapper}>
                <FormControlLabel
                  control={
                    <Checkbox
                      icon={<CheckboxIcon />}
                      checkedIcon={<CheckboxCheckedIcon />}
                    />
                  }
                  label="Can update"
                />
              </Box>
            </Box>

            <Box className={classes.permissionRow}>
              <Typography className={classes.permissionEntityTitle}>
                Calendar
              </Typography>
              <Box className={classes.permissionWrapper} mr="37px">
                <FormControlLabel
                  control={
                    <Checkbox
                      icon={<CheckboxIcon />}
                      checkedIcon={<CheckboxCheckedIcon />}
                    />
                  }
                  label="Can read"
                />
              </Box>
              <Box className={classes.permissionWrapper}>
                <FormControlLabel
                  control={
                    <Checkbox
                      icon={<CheckboxIcon />}
                      checkedIcon={<CheckboxCheckedIcon />}
                    />
                  }
                  label="Can update"
                />
              </Box>
            </Box>

            <Box className={classes.permissionRow}>
              <Typography className={classes.permissionEntityTitle}>
                Users
              </Typography>
              <Box className={classes.permissionWrapper} mr="37px">
                <FormControlLabel
                  control={
                    <Checkbox
                      icon={<CheckboxIcon />}
                      checkedIcon={<CheckboxCheckedIcon />}
                    />
                  }
                  label="Can read"
                />
              </Box>
              <Box className={classes.permissionWrapper}>
                <FormControlLabel
                  control={
                    <Checkbox
                      icon={<CheckboxIcon />}
                      checkedIcon={<CheckboxCheckedIcon />}
                    />
                  }
                  label="Can update"
                />
              </Box>
            </Box>
          </Box>
        </Box>
      </DialogContent>
      <DialogActions>
        <Box>
          <Button
            onClick={() => setOpen(false)}
            className={classes.cancelButton}
            variant="text">
            Cancel
          </Button>
          <Button
            onClick={() => setOpen(false)}
            className={classes.saveButton}
            variant="contained">
            Save
          </Button>
        </Box>
      </DialogActions>
    </Dialog>
  );
};
