export const calendarWeek = [
  {
    id: 1,
    label: "Monday",
    mLabel: "Mon",
    checked: true
  },
  {
    id: 2,
    label: "Tuesday",
    mLabel: "Tue",
    checked: true
  },
  {
    id: 3,
    label: "Wednesday",
    mLabel: "Wed",
    checked: true
  },
  {
    id: 4,
    label: "Thursday",
    mLabel: "Thu",
    checked: true
  },
  {
    id: 5,
    label: "Friday",
    mLabel: "Fri",
    checked: true
  },
  {
    id: 6,
    label: "Saturday",
    mLabel: "Sat",
    checked: true
  },
  {
    id: 7,
    label: "Sunday",
    mLabel: "Sun",
    checked: true
  },
]