import React, { useState } from 'react';
import { useForm } from 'react-hook-form';
import valid from './validation';
import {
  TextField,
  Box,
  Button,
  Typography,
  InputAdornment,
  IconButton,
} from '@material-ui/core/';
import PasswordControl from 'src/web/components/Form/PasswordControl';
import { useStyles } from './styles';
import VisibilityPassword from 'src/assets/icons/web/visibilityPassword.svg';
import VisibilityOffPassword from 'src/assets/icons/web/visibilityOffPassword.svg';

export const ConfirmPasswordForm = ({ onSubmit }: any) => {
  const classes = useStyles();
  const { register, handleSubmit, errors, watch, setValue } = useForm();
  const [showPassword, setShowPassword] = useState(false);
  const [confirmCode, setConfirmCode] = useState(null);


  const changeCodeConfirm = (e: any) => {
    e.preventDefault();
    setValue('password', '', {
      shouldDirty: false,
    });
    setConfirmCode(watch('code'));
  };

  const onHandleSubmit = async (data: any, e: any) => {
    e.preventDefault();
    if (confirmCode) {
      data.code = confirmCode;
    }

    onSubmit(data);
  };

  console.log(confirmCode);

  return (
    <>
      <form onSubmit={handleSubmit(onHandleSubmit)} className="w-full">
        <Box hidden={!!confirmCode}>
          <Box mb={3} mt={2}>
            <Typography className={classes.subTitle} align="center">
              A confirmation code has been sent to your email.
              <br />
              Please, check one!
            </Typography>
          </Box>
          <Box p={2}>
            <TextField
              className={classes.customInput}
              name="code"
              fullWidth
              inputRef={register()}
              label="Confirmation code"
              variant="outlined"
              InputProps={{
                startAdornment: <></>,
              }}
            />
          </Box>
          <Box style={{ padding: '0px 16px 16px 16px' }}>
            <Button
              onClick={changeCodeConfirm}
              fullWidth={true}
              className={classes.authButton}
              variant="contained"
              color="primary"
              type="submit"
              size="large">
              Ok
            </Button>
          </Box>
        </Box>
        <Box hidden={!confirmCode}>
          <Box mb={3} mt={2}>
            <Typography className={classes.subTitle}>
              Please, enter new password
            </Typography>
          </Box>
          <Box p={2}>
            <TextField
              name="password"
              type={showPassword ? 'text' : 'password'}
              className={classes.customInput}
              fullWidth
              inputRef={register({ ...valid.password })}
              label="Password"
              placeholder="your password"
              InputProps={{
                startAdornment: <></>,
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      style={{ color: '#E0E2E4' }}
                      aria-label="toggle password visibility"
                      onClick={() => setShowPassword((prev: boolean) => !prev)}>
                      {showPassword ? (
                        <VisibilityPassword />
                      ) : (
                        <VisibilityOffPassword />
                      )}
                    </IconButton>
                  </InputAdornment>
                ),
              }}
              variant="outlined"
              error={errors.password ? true : false}
              helperText={errors?.password?.message}
            />
          </Box>
          <Box style={{ padding: '0 16px 16px 16px' }}>
            <Button
              className={classes.authButton}
              fullWidth={true}
              variant="contained"
              color="primary"
              type="submit"
              size="large">
              Submit
            </Button>
          </Box>
        </Box>
      </form>
    </>
  );
};
