import React, { useState } from "react";
import { useForm } from "react-hook-form";
import valid from "./validation";
import {
  TextField,
  Box,
  Button,
  InputAdornment,
  IconButton,
} from "@material-ui/core/";
import { useSelector } from "react-redux";
import { temporaryUser } from "src/redux/Authentication/sliceAuthentication";
import PhoneInput from "src/web/components/UI/PhoneInput";
import { isValidPhoneNumber } from "libphonenumber-js";
import { useStyles } from "./styles";
import { InputError } from "src/web/components/UI/InputError";
import VisibilityPassword from "src/assets/icons/web/visibilityPassword.svg";
import VisibilityOffPassword from "src/assets/icons/web/visibilityOffPassword.svg";

export const CompleteRegistrationForm = ({ onSubmit }: any) => {
  const classes = useStyles();
  const { register, handleSubmit, errors, setError, clearErrors } = useForm();
  const { userAttributes } = useSelector(temporaryUser);
  const [phone, setPhone] = useState("");
  const [showPassword, setShowPassword] = useState(false);

  const onHandleSubmit = (data: any) => {
    const updatedData = { ...data };

    if (!userAttributes.phone_number) {
      if (!isValidPhoneNumber(phone)) {
        setError("phone", { type: "required" });
        return;
      }
      updatedData.phone_number = phone;
    }

    onSubmit({ ...updatedData });
  };

  return (
    <>
      <form onSubmit={handleSubmit(onHandleSubmit)}>
        {!userAttributes.email && (
          <Box mb={3}>
            <TextField
              name="email"
              fullWidth
              className={classes.customInput}
              inputRef={register({ ...valid.email })}
              label="Email"
              placeholder="your email"
              required
              InputLabelProps={{
                shrink: true,
              }}
              variant="outlined"
              error={errors.email ? true : false}
            />
            {!!errors.email && <InputError message={errors?.email?.message} />}
          </Box>
        )}

        {!userAttributes.given_name && (
          <Box mb={3}>
            <TextField
              name="first_name"
              fullWidth
              className={classes.customInput}
              inputRef={register({ ...valid.first_name })}
              label="First name"
              placeholder="your first name"
              required
              InputLabelProps={{
                shrink: true,
              }}
              variant="outlined"
              error={errors.first_name ? true : false}
            />
            {!!errors.first_name && (
              <InputError message={errors?.first_name?.message} />
            )}
          </Box>
        )}

        {!userAttributes.family_name && (
          <Box mb={3}>
            <TextField
              name="last_name"
              fullWidth
              className={classes.customInput}
              inputRef={register({ ...valid.last_name })}
              label="Last name"
              placeholder="your last name"
              required
              InputLabelProps={{
                shrink: true,
              }}
              variant="outlined"
              error={errors.last_name ? true : false}
            />
            {!!errors.last_name && (
              <InputError message={errors?.last_name?.message} />
            )}
          </Box>
        )}

        {!userAttributes.phone_number && (
          <Box className={classes.phoneInput} mb={3}>
            <PhoneInput
              placeholder={"your phone number"}
              value={phone}
              onChange={(phone) => {
                setPhone(phone);
                if (errors?.phone && isValidPhoneNumber(phone)) {
                  clearErrors("phone");
                }
              }}
              label="Phone *"
              error={errors?.phone ? true : false}
              helperText={"invalid phone number"}
              preferredCountries={["us", "ca"]}
            />
          </Box>
        )}

        <Box mb={3}>
          <TextField
            name="password"
            type={showPassword ? "text" : "password"}
            fullWidth
            inputRef={register({ ...valid.password })}
            label="Password"
            placeholder="your password"
            required
            className={classes.customInput}
            InputLabelProps={{
              shrink: true,
            }}
            variant="outlined"
            error={errors.password ? true : false}
            InputProps={{
              startAdornment: <></>,
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton
                    style={{ color: "#E0E2E4" }}
                    aria-label="toggle password visibility"
                    onClick={() => setShowPassword((prev: boolean) => !prev)}
                  >
                    {showPassword ? (
                      <VisibilityPassword />
                    ) : (
                      <VisibilityOffPassword />
                    )}
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />
          {!!errors.password && (
            <InputError message={errors?.password?.message} />
          )}
        </Box>

        <Button
          fullWidth={true}
          variant="contained"
          color="primary"
          type="submit"
          style={{ width: "100%", margin: "10px 0", padding: "11px 50px" }}
          className={classes.saveButton}
          size="large"
        >
          Update information
        </Button>
      </form>
    </>
  );
};
