import { Box } from "@material-ui/core";
import React from "react";

import { DetailedSettingsFilter } from "./DetailedSettingsFilter";
import { OpenCloseFilter } from "./OpenCloseFilter";

export const CalendarFilters = () => {
  return (
    <Box>
      <DetailedSettingsFilter />
      <OpenCloseFilter />
    </Box>
  );
};
