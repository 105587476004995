import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import PerfectScrollbar from 'react-perfect-scrollbar';
import {
  Table,
  TableContainer,
  TableHead,
  TableBody,
  Paper,
} from '@material-ui/core';
import { useInView } from 'react-intersection-observer';
import { Spiner, Loader } from 'src/web/components/Loaders';
import {
  scrollLoading,
  changeLoading,
  setOffsetLoadData,
  setLoading,
  offset,
  page,
} from 'src/web/features/table/sliceTables';
import { useStyles } from './styles';

export const TableContent = ({
  sorting,
  content,
  isSkip,
}: ICustomerContentProps) => {
  const classes = useStyles();
  const [ref, inView] = useInView();
  const dispatch = useDispatch();
  const currentScrollLoading = useSelector(scrollLoading);
  const currentChangeLoading = useSelector(changeLoading);
  const currentPage = useSelector(page);
  const currentOffset = useSelector(offset);

  React.useEffect(() => {
    if (
      inView &&
      !currentScrollLoading &&
      !currentChangeLoading &&
      currentOffset
    ) {
      dispatch(setLoading({ type: 'scroll', value: true }));
      dispatch(setOffsetLoadData({ page: currentPage }));
    }
  }, [
    dispatch,
    inView,
    currentScrollLoading,
    currentChangeLoading,
    currentOffset,
    currentPage,
  ]);

  return (
    <TableContainer className={classes.root} component={Paper}>
      {currentChangeLoading && !isSkip ? (
        <>
          <Spiner />
          <Table stickyHeader className={classes.tableRelative}>
            <TableHead>{sorting}</TableHead>
            <TableBody></TableBody>
          </Table>
        </>
      ) : (
        <PerfectScrollbar>
          <Table stickyHeader className={classes.tableRelative}>
            <TableHead>{sorting}</TableHead>
            <TableBody>
              {content}
              {!currentScrollLoading && (
                <tr className={classes.customScroll} ref={ref}></tr>
              )}
            </TableBody>
          </Table>
          {currentScrollLoading && <Loader />}
        </PerfectScrollbar>
      )}
    </TableContainer>
  );
};

interface ICustomerContentProps {
  sorting: JSX.Element;
  content: JSX.Element;
  isSkip?: boolean;
}
