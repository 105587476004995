import { useDispatch } from 'react-redux';
import { useQuery } from '@apollo/client';
import { setNotification } from 'src/redux/Notifications/sliceNotifications';
import { GET_PARTNERS_TRIPS_AUTOCOMPLETE } from 'src/api/partners';

export interface ITrip {
  id: number;
  name: string;
  city_id: number;
  state_code: string;
  city_name: string;
}

interface IPartnerProductsAutocomplete {
  v1_general_trips: ITrip[];
}

type Product = {
  id: number;
  name: string;
  city_id: number;
};

type Destination = {
  id: number;
  name: string;
  state_code: string;
};

export const usePartnersTripsAutocomplete = (
  partner_id: number,
  skip: boolean = true,
  all: boolean = false
) => {
  const dispatch = useDispatch();

  const { data, loading } = useQuery<IPartnerProductsAutocomplete>(
    GET_PARTNERS_TRIPS_AUTOCOMPLETE,
    {
      onError: (error) => {
        dispatch(
          setNotification({
            type: 'error',
            message: error.message,
            duaration: 10000,
          })
        );
      },
      fetchPolicy: 'cache-first',
      pollInterval: 1800000,
      variables: {
        prm: {
          partner_id,
          all,
        },
      },
      skip,
    }
  );

  const { products, destinations } = data?.v1_general_trips.reduce<{
    products: Product[];
    destinations: Destination[];
  }>(
    (acc, curr) => {
      const products = [
        ...acc.products,
        {
          id: curr.id,
          name: curr.name,
          city_id: curr.city_id,
        },
      ];

      const isDestinationAlreadyExist = acc.destinations.some(
        (destination) => destination.id === curr.city_id
      );

      const destinations = !isDestinationAlreadyExist
        ? [
            ...acc.destinations,
            {
              id: curr.city_id,
              name: curr.city_name,
              state_code: curr.state_code,
            },
          ]
        : [...acc.destinations];

      return {
        products,
        destinations,
      };
    },
    {
      products: [],
      destinations: [],
    }
  ) || {
    products: [],
    destinations: [],
  };

  return {
    products,
    destinations,
    loading,
  };
};
