import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles(() => ({
  icon_box: {
    width: '64px',
    height: '64px',
    zIndex: 5,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    position: 'absolute',
    top: '-35px',
    left: '20px',
    borderRadius: '.5rem',
  },
  icon_box_icon: {
    color: '#fff',
    height: '36px',
    width: '36px',
  },
}));
