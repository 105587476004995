import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "src/redux/store";
import { IRegistration, IRegistrationForm } from "./interfaces";

export const initialState: IRegistration = {
  form: {
    first_name: "",
    last_name: "",
    email: "",
    phone: "",
    role: "",
    title: "",
    isInsured: "",
    yearsWork: "0",
    website: "",
    city: "",
    state: "",
    reservationSystem: "",
    hearAboutTripShock: "",
  },
  receivedData: null
};

export const sliceRegistration = createSlice({
  name: "registration",
  initialState,
  reducers: {
    setData: (state, action: PayloadAction<Partial<IRegistrationForm>>) => {
      const data = action.payload;
      state.form = {
        ...state.form,
        ...data,
      };
    },
    setInitialData: (state) => {
      state = initialState
    },
    setReceivedData: (state, action: PayloadAction<IRegistrationForm>) => {
      state.receivedData = action.payload;
    },
  },
});

export const { setData, setInitialData } = sliceRegistration.actions;

export const partnerData = (state: RootState) => state.registration;
export const partnerDataForm = (state: RootState) => state.registration.form;

export default sliceRegistration.reducer;
