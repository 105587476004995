import React, { lazy } from 'react';
import { Platform } from 'react-native';

import Login from 'src/web/pages/Authentication/Login';
import ForgotPassword from 'src/web/pages/Authentication/ForgotPassword';
import ConfirmPassword from 'src/web/pages/Authentication/ConfirmPassword';
import NotFoundPage from 'src/web/pages/SystemPages/NotFoundPage';
import InternalServerPage from 'src/web/pages/SystemPages/InternalServerPage';
import UnauthorizedPage from 'src/web/pages/SystemPages/UnauthorizedPage';
import NotAccessPage from 'src/web/pages/SystemPages/NotAccessPage';
import Arrivals from 'src/web/pages/Arrivals/Arrivals';
import ArrivalDetailedPage from 'src/web/pages/Arrivals/ArrivalDetail';
import Dashboard from 'src/web/pages/Dashboard';
import ReviewsPage from 'src/web/pages/Reviews';
import PayoutsPage from 'src/web/pages/Payouts';
import PayoutsDetailPage from 'src/web/pages/Payouts/PayoutDetail';
import CalendarManagement from 'src/web/pages/CalendarManagement';
import NotificationsPage from 'src/web/pages/Notifications/Notifications';
import { SettingsPage } from 'src/web/pages/Settings';
import Registration from 'src/web/pages/Authentication/Registration';
import OnboardingPage from 'src/web/pages/Onboarding';
import FieldsArray from 'src/web/pages/FieldsArray/FieldsArray'

// const LazyPage = (path: string) => {
//   console.log(Platform);
//   const Component = lazy(() => import(`../../pages/${path}`));
//   return Component;
// };

export const LOGIN_PAGE = Login;
export const FORGOT_PASSWORD_PAGE = ForgotPassword;
export const REGISTRATION_PAGE = Registration;
export const CONFIRM_PASSWORD_PAGE = ConfirmPassword;
export const NOT_FOUND_PAGE = NotFoundPage;
export const INTERNAL_SERVER_PAGE = InternalServerPage;
export const UNAUTHORIZED_PAGE = UnauthorizedPage;
export const NOT_ACCESS_PAGE = NotAccessPage;

export const ARRIVALS_PAGE = Arrivals;
export const ARRIVALS_DETAIL_PAGE = ArrivalDetailedPage;
export const DASHBOARD_PAGE = Dashboard;
export const REVIEWS_PAGE = ReviewsPage;
export const PAYOUTS_PAGE = PayoutsPage;
export const PAYOUTS_DETAIL_PAGE = PayoutsDetailPage
export const CALENDAR_MANAGEMENT_PAGE = CalendarManagement;

export const NOTIFICATIONS_PAGE = NotificationsPage;
export const SETTINGS_PAGE = SettingsPage;

export const ONBOARDING_PAGE = OnboardingPage;
export const FIELDS_GROUP_PAGE = FieldsArray;

// export const PROFILE_PAGE = ProfilePage;
// export const COMPANY_PAGE = CompanyPage;
// export const USERS_PAGE = UsersPage;
// export const GROUPS_PAGE = GroupsPage;
