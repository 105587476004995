import { makeStyles } from "@material-ui/core/styles";

export const useStyles = makeStyles(() => ({
  dashboardCard: {
    display: "flex",
    flexDirection: "row",
    backgroundColor: "#fff",
    boxShadow: "0px 3.89312px 23.3587px #6060612E",
    padding: "16px",
    borderRadius: "15px",
    alignItems: "center",
    marginBottom: "10px",
    marginRight: "10px",
    width: "calc(50% - 16px)",
    height: "70px",

    "@media (max-width: 1024px)": {
      marginRight: "10px",
    },
  },
  dashboardCardTitle: {
    color: "#C4C4C4",
    fontSize: "12px",
    fontWeight: 500,
  },
  dashboardCardAmount: {
    color: "#333333",
    fontSize: "16px",
    fontWeight: 600,
  },

  dashboardStatistics: {
    position: "relative",
    backgroundColor: "#FFFFFF",
    height: 250,
    boxShadow: "0px 3.89312px 23.3587px #6060612E",
    borderRadius: "15px",
    padding: "15px 0 10px 0",
  },
  dashboardStatisticsHeader: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    paddingLeft: "26px",
    paddingRight: "14px",
  },
  dashboardStatisticsHeaderTitle: {
    fontSize: "14px",
    fontWeight: 500,
    color: "#333333",
  },
  dashboardStatisticsHeaderButton: {
    width: "22px",
    height: "22px",
    cursor: "pointer",
    borderRadius: "15px",
    boxShadow: "0px 3.89312px 23.3587px #6060612E;",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  dashboardStatisticsChart: {
    width: "100%",
    height: "100%",

    "& .recharts-tooltip-cursor": {
      strokeDasharray: "5,5",
    },
  },
  dashboardStatisticsDropdown: {
    position: "absolute",
    width: "380px",
    height: "275px",
    backgroundColor: "#FFFFFF",
    boxShadow: "0px 3.89312px 23.3587px #BDBDBD3B",
    borderRadius: "15px",
    zIndex: 5,
    right: -11,
    top: 50,
  },
  dashboardStatisticsDropdownListWrapper: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    padding: "5px 30px 24px 24px",
  },
  dashboardStatisticsDropdownList: {
    display: "flex",
    flexDirection: "column",
  },
  dashboardStatisticsDropdownListItem: {
    display: "flex",
    alignItems: "center",
    padding: "12px 0",
    borderBottom: "0.6px solid #dbf8f6",
    "&:last-child": {
      borderBottom: "none",
    },
  },
  dashboardStatisticsDropdownTripIcon: {
    marginRight: "15px",
    cursor: "pointer",
  },
  dashboardStatisticsDropdownTripColor: {
    width: "4px",
    height: "4px",
    borderRadius: "50%",
    marginRight: "6px",
  },
  dashboardStatisticsDropdownTripName: {
    fontSize: "11px",
    color: "#6C6B6B",
    fontWeight: 400,

    ".active &": {
      color: "#333333",
    },
  },
  dashboardStatisticsDropdownButton: {
    backgroundColor: "#ecfbfa",
    width: "100%",
    height: 20,
    fontWeight: 500,
    textTransform: "inherit",
    fontSize: 11,
    color: "#0DD1C5",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    borderRadius: "15px",
  },
  dashboardStatisticsTooltip: {
    background: "#FFFFFF",
    boxShadow: "0px 3.89312px 23.3587px #BDBDBD3B",
    width: "350px",
    borderRadius: "15px",
    paddingTop: "10px",
  },
  dashboardStatisticsTooltipLabel: {
    height: "18px",
    padding: "0px 13px",
    borderRadius: "15px",
    fontSize: "12px",
    color: "#333333",
    fontWeight: 500,
    width: "fit-content",
    dispay: "flex",
    alignItems: "center",
    marginBottom: "2px",
  },
  dashboardStatisticsTooltipTableContainer: {
    padding: "0 8px",
  },
  dashboardStatisticsTooltipTableHead: {
    "& .MuiTableCell-root": {
      color: "#0DD1C5",
      fontSize: "11px",
      fontWeight: 400,
      padding: "5px 0",
      borderBottom: "none",
      backgroundColor: "#F9FCFD",

      "&:first-child": {
        paddingLeft: "11px",
        borderRadius: "8px 0 0 8px",
      },

      "&:last-child": {
        borderRadius: "0 8px 8px 0",
      },
    },
  },
  dashboardStatisticsTooltipTableBody: {
    "& .MuiTableCell-root": {
      color: "#6C6B6B",
      fontSize: "11px",
      fontWeight: 400,
      height: "45px",
      padding: 0,
      borderBottom: "0.6px solid #dbf8f6",

      "&:first-child": {
        paddingLeft: "11px",
      },
    },
    "& .MuiTableRow-root": {
      "&:last-of-type": {
        "& .MuiTableCell-root": {
          borderBottom: "none",
        },
      },
    },
  },
  dashboardStatisticsTooltipTableTripColor: {
    minWidth: "4px",
    minHeight: "4px",
    maxWidth: "4px",
    maxHeight: "4px",
    borderRadius: "50%",
    marginRight: "9px",
  },

  dashboardTotalCard: {
    marginTop: "10px",
    marginRight: "10px",
    borderRadius: "15px",
    backgroundColor: "#0DD1C5",
    height: "80px",
    boxShadow: "0px 3.89312px 23.3587px #6060612E",
    padding: "17px 30px",
    width: "calc(100% - 24px)",

    "@media (max-width: 1024px)": {
      textAlign: "center",
    },
  },
  dashboardTotalCardTitle: {
    color: "#FFFFFF",
    fontWeight: 500,
    fontSize: "12px",
  },
  dashboardTotalCardValue: {
    color: "#FFFFFF",
    fontWeight: 600,
    fontSize: "20px",
  },

  dashboardTable: {
    height: 420,
    marginTop: "20px",
    backgroundColor: "#FFFFFF",
    boxShadow: "0px 3.89312px 23.3587px #6060612E",
    borderRadius: "15px",
  },
  dashboardTableHeadRow: {
    "& .MuiTableCell-head": {
      borderBottom: "1px solid rgb(39, 174, 96, 0.15)",
      fontSize: "12px",
      fontWeight: 500,
      color: "#333333",
      backgroundColor: "#F9FCFD",
      height: "40px",
      padding: "0 18px",
    },
  },
  dashboardTableBodyRow: {
    padding: "0px 24px",

    "&:hover .MuiTableCell-body": {
      backgroundColor: "#F9FCFD",

      "&:first-child": {
        borderRadius: "15px 0 0 15px",
      },
      "&:last-child": {
        borderRadius: "0 15px 15px 0",
      },
    },

    "& .MuiTableCell-body": {
      height: 67,
      borderBottom: "1px solid rgb(39, 174, 96, 0.15)",
      fontSize: "12px",
      fontWeight: 500,
      color: "#6C6B6B",
    },
  },
  dashboardTableTripColor: {
    width: "8px",
    height: "8px",
    borderRadius: "50%",
    marginRight: "8px",
  },

  dashboardPieChart: {
    width: "calc(100% - 24px)",
    height: 420,
    marginRight: "10px",
    marginTop: "20px",
    backgroundColor: "#FFFFFF",
    boxShadow: "0px 3.89312px 23.3587px #6060612E",
    borderRadius: "15px",
    padding: "20px 23px 23px 20px",
  },
  dashboardPieChartHeader: {
    padding: "0 5px",
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
  },
  dashboardPieChartTitle: {
    fontSize: "14px",
    color: "#333333",
    fontWeight: 600,
  },
  dashboardPieChartWrapper: {
    height: "230px",
  },
  dashboardPieChartCell: {
    cursor: "pointer",
    zIndex: 5,
  },
  dashboardPieChartTripsListItem: {
    display: "flex",
    backgroundColor: "#FFFFFF",
    borderRadius: "8px",
    padding: "8px 17px",
    cursor: "pointer",

    "&.active": {
      backgroundColor: "#F9FBFD",
    },
    "&.disabled": {
      cursor: "default",
    },
  },
  dashboardPieChartTripsListItemColor: {
    width: "4px",
    height: "4px",
    borderRadius: "50%",
    marginRight: "5px",
    marginTop: "4px",
    alignSelf: "center",
  },
  dashboardPieChartTripsListItemTitle: {
    color: "#6C6B6B",
    fontSize: "11px",
    fontWeight: 400,
    width: "90%",

    ".active &": {
      color: "#333333",
      fontWeight: 500,
    },
  },
  dashboardPieChartTripsListItemOrders: {
    color: "#C4C4C4",
    fontSize: "11px",
    fontWeight: 400,
    marginLeft: "auto",
  },

  dashboardPieChartSelectMobile: {
    borderRadius: "15px",
    border: "1px solid #C4C4C4",
    padding: "0 11px 0 14px",
    display: "none",
    alignItems: "center",

    "@media (max-width: 1024px)": {
      display: "flex",
    },
  },
  dashboardPieChartSelectMobileText: {
    fontSize: "12px",
    color: "#6C6B6B",
    marginRight: "10px",
  },
  dashboardPieChartMobileDialog: {
    "& .MuiPaper-root": {
      borderRadius: "15px",
    },

    "& .MuiDialog-paper": {
      margin: "17px",
    },

    "& .MuiDialogContent-root": {
      width: "340px",
      padding: "40px 30px",
    },
  },
  dashboardPieChartMobileDialogSelector: {
    marginTop: "30px",
  },
  dashboardPieChartMobileDialogSelectorItem: {
    color: "#6C6B6B",
    fontSize: "12px",
    fontWeight: 500,
    padding: "20px 23px",

    "&.active": {
      borderRadius: "15px",
      backgroundColor: "#f3fdfc",
      color: "#0DD1C5",
    },
  },
  dashboardPieChartMobileDialogSelectorClose: {
    width: "36px",
    height: "36px",
    borderRadius: "50%",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    position: "absolute",
    boxShadow: "0px 3.89312px 23.3587px #BDBDBD3B",
    border: "1px solid #FAFAFA",
    backgroundColor: "#ffffff",
    top: "10px",
    right: "10px",
    padding: "5px 0 0 5px",
  },

  dashboardPieChartSelect: {
    borderRadius: "15px",
    border: "none",
    fontSize: "12px",
    color: "#6C6B6B",

    "& fieldset": {
      border: "1px solid #C4C4C4",
    },

    "&:hover": {
      color: "#0DD1C5",

      "& fieldset": {
        border: "1px solid #0DD1C5 !important",
      },
    },

    "& .MuiSelect-select:focus": {
      background: "none",
    },

    "& .MuiOutlinedInput-notchedOutline": {
      border: "1px solid #C4C4C4 !important",
    },

    "& .MuiSelect-root": {
      height: "24px",
      paddingTop: 0,
      paddingBottom: 0,
      display: "flex",
      alignItems: "center",
    },

    "@media (max-width: 1024px)": {
      display: "none",
    },
  },
  dashboardPieChartSelectPaper: {
    boxShadow: "0px 3.89px 23.36px #110F0F26",
    backgroundColor: "#FFFFFF",
    borderRadius: "15px",
    padding: "0px 10px",
    marginTop: "35px",

    "& .MuiMenuItem-root": {
      color: "#6C6B6B",
      fontSize: "11px",
      fontWeight: 400,
      borderRadius: "15px",
    },

    "& .MuiListItem-button:hover": {
      color: "#0DD1C5",
      backgroundColor: "#ecfbfa",
      borderRadius: "10px",
    },

    "& .Mui-selected": {
      color: "#0DD1C5",
      fontSize: "12px",
      backgroundColor: "transparent",

      "&:hover": {
        color: "#0DD1C5",
        backgroundColor: "transparent",
      },
    },
  },
}));
