import React from "react";
import { DateFilter } from "../../UI/DatePickers/DateFilter/DateFilter";
import { DateRangeFilter } from "../../UI/DatePickers/DateFilter/DateRangeFilter";

export const ArrivalDateFilter: React.FC<any> = ({
  date,
  label,
  onChange,
  isRange,
  startDate,
  endDate,
  format
}) => {
  return isRange ? (
    <DateRangeFilter
      label={label}
      startDate={startDate}
      endDate={endDate}
      placeholder={"Choose date"}
      onChange={onChange}
    />
  ) : (
    <DateFilter
      date={date}
      placeholder={"Choose date"}
      label={label}
      onChange={onChange}
      format={format}
    />
  );
};
