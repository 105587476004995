import React from "react";
import DatePicker from "react-datepicker";
import { FormControl, InputLabel } from "@material-ui/core";
import moment from "moment";
import { IDatePickerProps } from "../DatePickerFilter/interfaces";
import { useStyles } from "./styles";
import "react-datepicker/dist/react-datepicker.css";

export const DateRangeFilter: React.FC<IDatePickerProps> = ({
  startDate,
  endDate,
  onChange,
  placeholder,
  label,
  props,
}) => {
  const classes = useStyles();
  const dateFormat = "YYYY-MM-DD HH:mm:ss";

  return (
    <FormControl
      variant="outlined"
      fullWidth
      className={classes.datePickerWrapper}
    >
      <InputLabel shrink className={classes.datePickerLabel}>
        {label}
      </InputLabel>
      <DatePicker
        selected={
          startDate ? new Date(moment(startDate).format(dateFormat)) : null
        }
        onChange={(dates) => {
          if (dates && !(dates instanceof Date)) {
            const start = dates[0] ? moment(dates[0]).format(dateFormat) : null;
            const end = dates[1] ? moment(dates[1]).format(dateFormat) : null;
            return onChange({ start, end });
          }
        }}
        startDate={
          startDate ? new Date(moment(startDate).format(dateFormat)) : null
        }
        endDate={endDate ? new Date(moment(endDate).format(dateFormat)) : null}
        placeholderText={placeholder}
        dateFormat={"EEE, MMM d"}
        selectsRange
        isClearable
        className={classes.datePicker}
        {...props}
      />
    </FormControl>
  );
};
