import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { TextField, Box } from "@material-ui/core";
import { Menu } from "@material-ui/icons";
import { useStyles } from "./styles";
import {
  setSearch,
  setLoading,
  tableState,
} from "src/web/features/table/sliceTables";
import SearchIcon from "src/assets/icons/light/search.svg";
import ClearIcon from "src/assets/icons/light/clearSearch.svg";
import { ITableSearchProps } from "../interfaces";

let typingTimer: any;

export const TableSearch = ({ setOpenSidebar }: ITableSearchProps) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const currentState = useSelector(tableState);
  const currentSearchValue =
    currentState.history[currentState.page]?.search?.value;
  const [initialLoad, setInitialLoad] = useState<boolean>(true);
  const [value, setValue] = useState<string>(currentSearchValue || "");
  const [isActive, setIsActive] = useState<boolean>(false);
  const [isOpenSearch, setOpenSearch] = useState<boolean>(false);

  const searchByFields = (value: string) => {
    dispatch(setSearch(value));
    dispatch(setLoading({ type: "change", value: true }));
  };

  useEffect(() => {
    if (initialLoad && currentSearchValue !== value) {
      setValue(currentSearchValue || "");
      setInitialLoad(false);
    }
  }, [currentSearchValue, value, initialLoad]);

  const onChangeHandler = (value: string) => {
    if (initialLoad) {
      setInitialLoad(false);
    }
    setValue(value);
    if (!value) {
      dispatch(setSearch(""));
    }
    if (value.length < 3 && !(value.length === 0 && isActive)) {
      return;
    }
    setIsActive(true);
    clearTimeout(typingTimer);
    typingTimer = setTimeout(() => searchByFields(value), 1000);
  };

  useEffect(() => {
    if (currentState.detailedFiltersApplied) {
      setValue("");
    }
  }, [currentState.detailedFiltersApplied]);

  const onBlurSearch = () => {
    if (!value) {
      setOpenSearch(false);
      setValue("");
    }
  };

  return (
    <Box>
      <Box className={classes.searchBox}>
        <TextField
          disabled={
            currentState.detailedFiltersApplied &&
            !!currentState.detailedFilters
          }
          variant="outlined"
          className={classes.search_field}
          onChange={(e) => onChangeHandler(e.target.value)}
          value={value}
          placeholder="Search..."
          InputProps={{
            startAdornment: (
              <>
                <Menu
                  className={classes.menu_icon}
                  onClick={() => setOpenSidebar(true)}
                />
                <SearchIcon />
              </>
            ),
            endAdornment: value ? (
              <div
                className={classes.clearIcon}
                onClick={() => onChangeHandler("")}
              >
                <ClearIcon />
              </div>
            ) : null,
          }}
        />
      </Box>
      <Box className={classes.searchBoxMobile}>
        {!isOpenSearch && (
          <div onClick={() => setOpenSearch(true)}>
            <SearchIcon />
          </div>
        )}
        {isOpenSearch && (
          <TextField
            disabled={
              currentState.detailedFiltersApplied &&
              !!currentState.detailedFilters
            }
            variant="outlined"
            className={classes.search_field}
            onChange={(e) => onChangeHandler(e.target.value)}
            value={value}
            placeholder="Search..."
            autoFocus
            onBlur={() => onBlurSearch()}
            InputProps={{
              startAdornment: (
                <>
                  <div onClick={() => setOpenSearch(false)}>
                    <SearchIcon />
                  </div>
                </>
              ),
              endAdornment: value ? (
                <div
                  className={classes.clearIcon}
                  onClick={() => onChangeHandler("")}
                >
                  <ClearIcon />
                </div>
              ) : null,
            }}
          />
        )}
      </Box>
    </Box>
  );
};
