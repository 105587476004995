import clsx from 'clsx';
import React, { useState } from 'react';
import { Box, Divider, Typography, useMediaQuery } from '@material-ui/core';
import { NavLink, useHistory } from 'react-router-dom';

import DropdownActiveArrowIcon from 'src/assets/icons/web/dropdownActiveArrow.svg';
import DropdownInactiveArrowIcon from 'src/assets/icons/web/dropdownInactiveArrow.svg';
import LaunchIcon from '@material-ui/icons/Launch';
import {
  IInnerPageHeaderProps,
  InnerPageHeader,
} from 'src/web/components/InnerPagesHeader';
import { MobileMenuModal } from './MobileMenuModal';

import { useStyles } from './styles';

export const PageWithMenuLayout = ({
  headerProps,
  content,
  menu,
}: IPageWithMenuLayout) => {
  const classes = useStyles();
  const { location } = useHistory();
  const mdMatches = useMediaQuery('(max-width: 1020px)');

  const [isOpenMobileMenu, setIsOpenMobileMenu] = useState(false);
  const [disclosedDropdowns, setDisclosedDropdowns] = useState<string[]>([]);

  const toggleMobileMenu = () => {
    setIsOpenMobileMenu((prev) => !prev);
  };

  const toggleMenuDropdown = (dropdownTitle: string) => {
    if (disclosedDropdowns.includes(dropdownTitle)) {
      return setDisclosedDropdowns((prev) =>
        prev.filter((item) => item !== dropdownTitle)
      );
    }

    setDisclosedDropdowns((prev) => [...prev, dropdownTitle]);
  };

  const MenuItem = ({ ...item }: MenuItem) => {
    if (item.childrens?.length) {
      const isDropdownOpen = disclosedDropdowns.includes(item.title);

      return (
        <Box
          className={clsx({
            dropdownActive: location.pathname === item.link,
          })}>
          <Box
            className={clsx(
              classes.menuItemWrapper,
              classes.menuItemDropdownWrapper
            )}
            onClick={() => toggleMenuDropdown(item.title)}>
            {item.icon}
            <Typography className={classes.menuItemTitle}>
              {item.title}
            </Typography>
            <Box className={classes.dropdownArrow}>
              {isDropdownOpen ? (
                <DropdownActiveArrowIcon />
              ) : (
                <DropdownInactiveArrowIcon />
              )}
            </Box>
          </Box>
          {isDropdownOpen && (
            <Box ml="10px">
              {item.childrens.map((children) => (
                <NavLink
                  key={children.title}
                  to={children.link || '#'}
                  isActive={(match, location) =>
                    `${location.pathname}${location.search}` === children.link
                  }
                  activeClassName="active"
                  style={{ textDecoration: 'none' }}>
                  <Box className={classes.menuItemWrapper}>
                    {children.icon}
                    <Typography className={classes.menuItemTitle}>
                      {children.title}
                    </Typography>
                  </Box>
                </NavLink>
              ))}
            </Box>
          )}
        </Box>
      );
    }

    return (
      <NavLink
        to={item.link}
        activeClassName="active"
        style={{ textDecoration: 'none' }}>
        <Box className={classes.menuItemWrapper}>
          {item.icon}
          <Typography className={classes.menuItemTitle}>
            {item.title}
          </Typography>
        </Box>
      </NavLink>
    );
  };

  const Menu = () => (
    <div
      className={clsx(classes.menu, {
        mobileMenu: mdMatches,
      })}>
      {menu.map((item) => (
        <MenuItem key={item.link} {...item} />
      ))}
      <Box my={2} pr={3}>
        <Divider variant={'fullWidth'} />
      </Box>
      <a
        href="https://form.typeform.com/to/n0cipenc"
        target="_blank"
        rel="nofollow"
        className={classes.menuItemExternalLink}>
        <Box>Account Update</Box>
        <LaunchIcon
          style={{
            fontSize: '16px',
          }}
        />
      </a>
      <a
        href="https://login.us.bill.com/neo/login"
        target="_blank"
        rel="nofollow"
        className={classes.menuItemExternalLink}>
        <Box>Billing Update</Box>
        <LaunchIcon
          style={{
            fontSize: '16px',
          }}
        />
      </a>
    </div>
  );

  return (
    <div className={classes.root}>
      {mdMatches && (
        <MobileMenuModal
          open={isOpenMobileMenu}
          setOpen={setIsOpenMobileMenu}
          menu={() => <Menu />}
        />
      )}

      <div className={classes.topBg}></div>
      <div className={classes.pageWrapper}>
        <div className={classes.contentWrapper}>
          <div className={classes.header}>
            <InnerPageHeader
              {...headerProps}
              isExistMobileMenu={true}
              toggleMobileMenu={toggleMobileMenu}
            />
          </div>
          <div className={classes.content}>
            {!mdMatches && <Menu />}
            <div className={classes.contentRoot}>{content}</div>
          </div>
        </div>
      </div>
    </div>
  );
};

interface IPageWithMenuLayout {
  headerProps: IInnerPageHeaderProps;
  content: JSX.Element;
  menu: MenuItem[];
}

type MenuItem = {
  link: string;
  title: string;
  icon: JSX.Element;
  childrens?: MenuItem[];
};
