import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Box, Typography, Divider } from "@material-ui/core";
import {
  signIn,
  confirmSignUp,
  completePassword,
  IAuth,
  auth,
} from "src/redux/Authentication/sliceAuthentication";
import {
  CompleteRegistrationForm,
  LoginForm,
} from "src/web/features/authentication";
import { useStyles } from "./styles";

const Login: React.FC<{}> = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { temporaryUser } = useSelector(auth);

  const onSubmit = (data: IAuth, e: any) => {
    e.preventDefault();
    dispatch(signIn(data));
  };

  const onChangePassword = (data: any, phone: string) => {
    dispatch(completePassword(temporaryUser, data));
  };

  useEffect(() => {
    const params = new URLSearchParams(window.location.search);
    const code = params.get("code");
    const username = params.get("username");
    if (code && username) {
      dispatch(confirmSignUp({ username, code }));
    }
  }, [dispatch]);

  return !temporaryUser ? (
    <>
      <LoginForm onSubmit={onSubmit} />
    </>
  ) : (
    <>
      <Box>
        <Typography variant="h5" className={classes.title}>
          Complete registration
        </Typography>
      </Box>
      <Box mb={4}>
        <Divider style={{ backgroundColor: "#fafafa"}} />
      </Box>
      <CompleteRegistrationForm onSubmit={onChangePassword} />
    </>
  );
};

export default Login;
