import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme: any) => ({
  backdrop: {
    position: 'absolute',
    top: 0,
    right: 0,
    bottom: 0,
    left: 0,
    zIndex: 101,
    background: 'rgba(0, 0, 0, 0.5)',
  },
  sidebar: {
    top: 0,
    position: 'relative',
    zIndex: 102,
    width: '200px',
    overflow: 'hidden',
    height: 'calc(100vh - 66px)',
    maxHeight: '100%',
    transition: theme.transitions.create(['transform'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
    [theme.breakpoints.down('md')]: {
      position: 'absolute',
      transform: 'translateX(-100%)',
    },
    '& .MuiDrawer-paper': {
      position: 'relative',
      border: 'none',
      backgroundColor: theme.palette.background.default,
    },
  },
  sidebarOpen: {
    [theme.breakpoints.down('md')]: {
      transform: 'translateX(0px)',
    },
  },
  sidebarHeader: {
    display: 'flex',
    flexDirection: 'column',
    padding: '37px 0 0 20px',
    height: `calc(${theme.trip_shock_theme.environment.topBgHeight}px)`,
    color: theme.palette.primary.contrastText,
    backgroundColor: '#2E3035',
  },
  sidebarIcon: {
    marginBottom: '1.5rem',
    display: 'flex',
    alignItems: 'center',
    color: '#fff',
    '& > span': {
      fontSize: '16px',
      marginLeft: '4px',
    },
    '& > svg': {
      fontSize: '30px',
      fill: '#fff',
      width: '20px',
      height: '20px',
    },
  },
  sidebarContent: {
    overflow: 'hidden',
    height: `calc(100vh - ${
      theme.trip_shock_theme.environment.topBgHeight +
      theme.trip_shock_theme.environment.toolbarHeight
    }px)`,
    color: theme.palette.text.primary,
    [theme.breakpoints.down('md')]: {
      height: `calc(100vh - ${theme.trip_shock_theme.environment.toolbarHeight}px)`,
      boxShadow: '0 1px 5px rgba(0, 0, 0, 0.2) inset',
    },
  },
  innerSidebarContent: {
    padding: '12px 0',
  },
}));
