import React, { useState } from 'react';
import {
  Box,
  Button,
  IconButton,
  Paper,
  Popper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  useMediaQuery,
} from '@material-ui/core';
import PerfectScrollbar from 'react-perfect-scrollbar';

import EditIcon from 'src/assets/icons/web/editGray.svg';
import DeleteIcon from 'src/assets/icons/web/delete.svg';
import DotsBurgerVMenuIcon from 'src/assets/icons/light/dotsBurgerVMenu.svg';

import { useStyles } from './styles';
import { DeleteGroupAlertModal } from './DeleteGroupAlertModal';

type Props = {
  setOpenDetail: (isOpen: boolean) => void;
};

export const GroupsContent = ({ setOpenDetail }: Props) => {
  const classes = useStyles();
  const [openDeleteAlert, setOpenDeleteAlert] = useState(false);
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);
  const [openDropdownActions, setOpenDropdownActions] = useState(false);

  const mdMatches = useMediaQuery('(max-width: 1020px)');

  const handleClickActions = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
    setOpenDropdownActions(!openDropdownActions);
  };

  return (
    <>
      <DeleteGroupAlertModal
        open={openDeleteAlert}
        setOpen={setOpenDeleteAlert}
      />

      <TableContainer>
        <PerfectScrollbar>
          <Table stickyHeader className={classes.table}>
            <TableHead>
              <TableRow>
                <TableCell style={{ width: 250 }}>Status</TableCell>
                <TableCell>Members</TableCell>
                <TableCell></TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              <TableRow>
                <TableCell>
                  <Box className={classes.statusWrapper}>Administrator</Box>
                </TableCell>
                <TableCell>6 people</TableCell>
                <TableCell
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'end',
                  }}>
                  <Box className={classes.actions}>
                    {mdMatches ? (
                      <>
                        <IconButton onClick={handleClickActions}>
                          <DotsBurgerVMenuIcon />
                        </IconButton>
                        <Popper
                          open={openDropdownActions}
                          anchorEl={anchorEl}
                          placement="bottom-start">
                          <Paper className={classes.actionsPaper}>
                            <Button
                              className={classes.actionButton}
                              onClick={() => setOpenDetail(true)}>
                              <EditIcon />
                              Edit
                            </Button>
                            <Button
                              onClick={() => setOpenDeleteAlert(true)}
                              className={classes.actionButton}
                              style={{ color: '#EC4652' }}>
                              <DeleteIcon />
                              Delete
                            </Button>
                          </Paper>
                        </Popper>
                      </>
                    ) : (
                      <>
                        <Box mr="30px">
                          <IconButton onClick={() => setOpenDetail(true)}>
                            <EditIcon />
                          </IconButton>
                        </Box>
                        <Box>
                          <IconButton onClick={() => setOpenDeleteAlert(true)}>
                            <DeleteIcon />
                          </IconButton>
                        </Box>
                      </>
                    )}
                  </Box>
                </TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </PerfectScrollbar>
      </TableContainer>
    </>
  );
};
