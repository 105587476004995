import React from "react";
import { Box, Button, Typography } from "@material-ui/core";
import CompleteOnboardingIcon from "src/assets/icons/web/completeRegistration.svg";
import CompleteOnboardingAdaptiveIcon from "src/assets/icons/web/completeRegistrationAdaptive.svg";
import { useStyles } from "./styles";

const CompleteOnboarding: React.FC<{}> = () => {
  const classes = useStyles();

  return (
    <div className={classes.completeWrapper}>
      <div className={classes.dekstop}>
        <CompleteOnboardingIcon />
      </div>
      <div className={classes.adaptive}>
        <CompleteOnboardingAdaptiveIcon />
      </div>
      <Box mt={4}>
        <Typography align="center" variant="h5" className={classes.title}>
          Thank you!
        </Typography>
        <Typography
          style={{ fontSize: "14px", marginTop: "10px" }}
          align="center"
          variant="h5"
          className={classes.description}
        >
          The form was submitted successfully.
        </Typography>
      </Box>
      {/* <Box mt={4}>
        <Button
          onClick={() => console.log("complete")}
          variant="contained"
          color="primary"
          type="submit"
          size="large"
          className={classes.saveButton}
          style={{ width: "160px" }}
        >
          Complete
        </Button>
      </Box> */}
    </div>
  );
};

export default CompleteOnboarding;
