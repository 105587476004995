import { Box, TextField, InputAdornment } from "@material-ui/core";
import ArrowUp from "src/assets/icons/web/inputArrowUp.svg";
import ArrowDown from "src/assets/icons/web/inputArrowDown.svg";
import React from "react";
import { useStyles } from "../styles";
import { IRegistrationForm } from "src/redux/Registration/interfaces";
import { InputError } from "../../UI/InputError";
import { ITextfield } from "../interface";

type Props = {
  formInput: ITextfield;
  errorMessage: string;
  handleChange: (field: string, value: string) => void;
  value: any;
};

export const FormNumberInput = ({
  formInput,
  errorMessage,
  handleChange,
  value,
}: Props) => {
  const classes = useStyles();

  const handleChangeInputField = (type: string, name: string, e: any) => {
    e.preventDefault();
    if (type === "up") {
      handleChange(
        name,
        String(+value + 1)
      );
    }
    if (type === "down") {
      handleChange(
        name,
        String(+value - 1)
      );
    }
  };

  return (
    <div className={classes.field}>
      <TextField
        name={formInput.name}
        fullWidth
        type="number"
        label={formInput.label}
        variant="outlined"
        value={value}
        error={!!errorMessage}
        placeholder={formInput.placeholder}
        autoFocus
        className={classes.customInput}
        InputLabelProps={{
          shrink: true,
        }}
        InputProps={{
          endAdornment: (
            <InputAdornment
              className={classes.inputNumberAdornment}
              position="end"
            >
              <Box
                onClick={(e) => handleChangeInputField("up", formInput.name, e)}
                style={{ cursor: "pointer" }}
              >
                <ArrowUp />
              </Box>
              <Box
                onClick={(e) => {
                  if (value === 0) {
                    return;
                  }
                  handleChangeInputField("down", formInput.name, e);
                }}
                style={{ cursor: "pointer" }}
              >
                <ArrowDown />
              </Box>
            </InputAdornment>
          ),
        }}
        onChange={(e) => handleChange(formInput.name, e.target.value)}
      />
      {!!errorMessage && <InputError message={errorMessage} />}
      {/* <Typography>{formInput}</Typography> */}
    </div>
  );
};
