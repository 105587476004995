import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { setNotification } from 'src/web/features/notifications/sliceNotifications';

export const useError = (message?: string) => {
  const dispatch = useDispatch();

  useEffect(() => {
    if (message) {
      dispatch(
        setNotification({
          type: 'error',
          message,
          duaration: 5000,
        })
      );
    }
  }, [message, dispatch]);

  return null;
};
