import React, { useState } from "react";
import { useMutation } from "@apollo/client";
import { UPDATE_PARTNER_POLICY } from "src/api/tempPartners";
import { setNotification } from "src/redux/Notifications/sliceNotifications";
import { useDispatch } from "react-redux";
import { setInitialData } from "src/redux/Registration/sliceRegistration";
import CompleteRegistration from "./CompleteRegistraion";
import { PrivacyPolicyForm } from "./PrivacyPolicyForm";

const UpdateForm = ({ id }: { id: string }) => {
  const [privacyPolicy, setPrivacyPolicy] = useState(false);
  const [isSpiner, setIsSpiner] = useState(false);
  const [isComplete, setIsComplete] = useState(false);
  const dispatch = useDispatch();

  const [updatePartner] = useMutation(UPDATE_PARTNER_POLICY, {
    onCompleted: () => {
      dispatch(setInitialData());
      setIsSpiner(false);
      setIsComplete(true);
    },
    onError: (error) => {
      dispatch(
        setNotification({
          type: "error",
          message: error.message,
          duaration: 10000,
        })
      );
    },
  });

  const handleSubmit = () => {
    setIsSpiner(true);
    updatePartner({
      variables: {
        prm: {
          id: id,
          accept: privacyPolicy,
        },
      },
    });
  };

  return isComplete ? (
    <CompleteRegistration />
  ) : (
    <PrivacyPolicyForm
      handleSubmit={handleSubmit}
      isSpiner={isSpiner}
      privacyPolicy={privacyPolicy}
      setPrivacyPolicy={setPrivacyPolicy}
    />
  );
};

export default UpdateForm;
