import { parsePhoneNumber } from "libphonenumber-js";
import {
  IOnboardingForm,
  IInterviewBlock,
} from "src/web/components/OnboardingForm/interface";

export const validateField = (
  setErrorMessage: React.Dispatch<
    React.SetStateAction<Partial<Record<keyof IOnboardingForm, string>>>
  >,
  currentFields: IInterviewBlock,
  name: string,
  value: string | number | boolean
) => {
  
  console.log(currentFields.fields, name, value)

  const validationResult = currentFields.fields
    .filter((item) => item.name === name)
    .map((field) => {
      const validateRules = field.validation;
      if (validateRules?.required && !value) {
        setErrorMessage((prev) => ({
          ...prev,
          [name]: validateRules.messages.default,
        }));
        return false;
      }

      if (!validateRules?.required && !value) {
        setErrorMessage((prev) => ({
          ...prev,
          [name]: "",
        }));
        return true;
      }

      if (typeof value === "string") {
        if (validateRules?.regex) {
          if (!value) {
            setErrorMessage((prev) => ({
              ...prev,
              [name]: "",
            }));
            return true;
          }
          const newRegex = new RegExp(validateRules.regex);
          const isValid = newRegex.test(value);

          if (!isValid) {
            setErrorMessage((prev) => ({
              ...prev,
              [name]: validateRules.messages.regex,
            }));
          } else {
            setErrorMessage((prev) => ({
              ...prev,
              [name]: "",
            }));
          }
          return isValid;
        }

        if (validateRules?.type === "email") {
          if (!value) {
            setErrorMessage((prev) => ({
              ...prev,
              [name]: "",
            }));
            return true;
          }

          const regex =
            /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

          const isValid = regex.test(value);

          if (!isValid) {
            setErrorMessage((prev) => ({
              ...prev,
              [name]: validateRules.messages.regex,
            }));
            return false;
          } else {
            setErrorMessage((prev) => ({
              ...prev,
              [name]: "",
            }));
            return true;
          }
        }

        if (validateRules?.type === "phone") {
          if (!value) {
            setErrorMessage((prev) => ({
              ...prev,
              [name]: "",
            }));
            return true;
          }

          const validate = (v: string | undefined) => {
            const phoneNumber = parsePhoneNumber(v || "", "US");
            return phoneNumber?.isValid() || false;
          };

          const isValid = validate(value);

          if (!isValid) {
            setErrorMessage((prev) => ({
              ...prev,
              [name]: validateRules.messages.regex,
            }));
            return false;
          } else {
            setErrorMessage((prev) => ({
              ...prev,
              [name]: "",
            }));
            return true;
          }
        }

        if (name === "fein_snn" && validateRules.type === "custom") {
          const feinReg = /^[1-9]\d?-\d{7}$/;
          const ssnReg = /^\d{3}-\d{2}-\d{4}$/;

          if (!value) {
            setErrorMessage((prev) => ({
              ...prev,
              [name]: "",
            }));
            return true;
          }

          if (feinReg.test(value) || ssnReg.test(value)) {
            setErrorMessage((prev) => ({
              ...prev,
              [name]: "",
            }));
            return true;
          }
          setErrorMessage((prev) => ({
            ...prev,
            [name]: validateRules.messages.regex,
          }));
          return false;
        }

        return true;
      }

      return true;
    });

  if (validationResult.some((i) => !i)) {
    return false;
  }

  setErrorMessage((prev) => ({
    ...prev,
    [name]: "",
  }));
  return true;
};
