import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles(() => ({
  chatIconWrapper: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    paddingTop: '3px',
    height: '32px',
    width: '32px',
    marginRight: '8px',
  },
}));
