import { useEffect, RefObject } from 'react';

type Event = MouseEvent | TouchEvent;

const useOnClickOutside = (
  ref: RefObject<HTMLElement>,
  handler: (event: Event) => void,
  refExcluded?: RefObject<HTMLElement>
) => {
  useEffect(() => {
    const listener = (event: Event) => {
      const containerElement = ref?.current;
      const targetElement = (event?.target as Node) || null;
      const excludedRef = refExcluded?.current;

      if (excludedRef && excludedRef.contains(targetElement)) {
        return;
      }

      if (
        !containerElement ||
        !targetElement ||
        containerElement.contains(targetElement)
      ) {
        return;
      }

      handler(event);
    };

    document.addEventListener('mousedown', listener);
    document.addEventListener('touchstart', listener);

    return () => {
      document.removeEventListener('mousedown', listener);
      document.removeEventListener('touchstart', listener);
    };
  }, [ref, handler]);
};

export default useOnClickOutside;
