import { makeStyles } from '@material-ui/core/styles';
import { Theme } from 'src/web/themes/theme';

export const useStyles = makeStyles((theme: Theme) => ({
  table_row: {
    cursor: 'pointer',
    height: '70px',
  },
  table_cell: {
    color: '#6C6B6B',
    fontWeight: 500,
    fontSize: '12px',
    whiteSpace: 'nowrap',
    padding: '16px 30px',
    [theme.breakpoints.down("sm")]: {
      padding: '18px',
      fontSize: "10px",
    },
  },
  tableLoader: {
    position: 'absolute',
    height: 'calc(100vh - 270px)',
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  tableLoaderWrapper: {
    borderBottom: 'none',
    width: 0,
    height: 0,
    padding: 0,
  },
  table_cell_detailed_header: {
    background: '#f5f5f5',
    padding: '8px 16px',
    fontSize: '12px',
    barrivalTop: '1px solid #cdcdcd',
  },

  table_cell_detailed_body: {
    background: '#fafafa',
    padding: '8px 16px',
    fontSize: '12px',
    barrivalBottom: '1px solid #cdcdcd',
  },
  netTotal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    height: '20px',
    backgroundColor: '#e7faf9',
    borderRadius: '50px',
    padding: '5px 15px',
    fontSize: '12px',
    color: '#0DD1C5',
    width: 'fit-content',
    margin: 'auto',
  },
  custom_chip: {
    textTransform: 'uppercase',
    minWidth: '110px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    height: '20px',
    borderRadius: '50px',
    boxShadow: '0px 13px 28px rgba(29, 203, 192, 0.2), 0px 8px 16px rgba(29, 203, 192, 0.06)',
    whiteSpace: 'nowrap',
    width: '110px',
  },
  chip_text: {
    fontSize: '12px',
    textTransform: 'none',
    fontWeight: 500,
    padding: '0px',
    margin: '0px',
  },
}));
