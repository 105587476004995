import { Paper, Box, Typography } from "@material-ui/core";
import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { ApolloQueryResult } from "@apollo/client";
import { Spiner } from "src/web/components/Loaders/";
import {
  arrivalData,
  isCreate as newArrival,
} from "src/redux/Arrivals/sliceArrival";
import { TripDetailCard } from "./content/TripDetailCard";
import { HeaderCard } from "./content/HeaderCard";
import { EditModal } from "./content/EditModal";
import { GET_PARTNERS_SCHEDULE } from "src/api/partners";
import { useQuery } from "@apollo/client";
import { useStyles } from "./styles";
import { IArrivalSchedule } from "src/redux/Arrivals/interfaces";

type Props = {
  setIsSpiner: React.Dispatch<React.SetStateAction<boolean>>;
};

export const ArrivalDetailsContent = ({ setIsSpiner }: Props) => {
  const classes = useStyles();
  const {
    receivedData,
    loading,
    arrivalData: arrival,
  } = useSelector(arrivalData);
  const isCreate = useSelector(newArrival);
  const [open, setOpen] = useState(false);
  const [schedules, setSchedules] = useState<IArrivalSchedule[]>([]);

  const { loading: schedulesLoading } = useQuery(GET_PARTNERS_SCHEDULE, {
    variables: {
      prm: {
        trip_id: +arrival.data?.product?.id! || 0,
      },
    },
    skip: !arrival.data || !arrival.data?.product?.id,
    onCompleted(data) {
      setSchedules(data.v1_partners_schedule || []);
    },
  });

  if (loading || schedulesLoading) {
    return <Spiner />;
  }

  if (!receivedData && !isCreate && !arrival) {
    return (
      <Paper elevation={2}>
        <Box>
          <Typography variant="button">No data</Typography>
        </Box>
      </Paper>
    );
  }

  const handleOpenModal = () => {
    setOpen(true);
  };

  return (
    <>
      <EditModal
        setIsSpiner={setIsSpiner}
        open={open}
        setOpen={setOpen}
        schedules={schedules}
      />
      <Box className={classes.header} display="flex" flexDirection="row">
        <HeaderCard handleOpenModal={handleOpenModal} />
      </Box>
      <TripDetailCard handleOpenModal={handleOpenModal} />
    </>
  );
};
