import React, { Dispatch, SetStateAction, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Badge, Box, Divider, Typography } from '@material-ui/core';
import SidebarOpenIcon from 'src/assets/sideBarIcons/sideBarOpen.svg';
import LogoutIcon from 'src/assets/icons/logOut.svg';
import NotificationsIcon from 'src/assets/icons/notifications.svg';
import ChatIcon from 'src/assets/icons/chat.svg';
import DropDownIcon from 'src/assets/icons/web/general/dropdown.svg';
import { auth, signOut } from 'src/redux/Authentication/sliceAuthentication';
import { useStyles } from './styles';
import NotificationPopup from '../Notifications/NotificationsPopup';
import Popup from './Popup';
import { checkIsHasAccessToRoute } from 'src/helpers/limitationRoutes';
import { useNotificationsContext } from 'src/contexts/NotificationsContext';
import AvatarIcon from 'src/assets/icons/web/avatar.svg';
import { AffiliateBlock } from '../AffiliateBlock/AffiliateBlock';
import { ChatComponent } from '../Chat/ChatComponent';

interface IMainHeaderProps {
  open: boolean;
  setOpen: Dispatch<SetStateAction<boolean>>;
}

export const MainHeader = ({ open, setOpen }: IMainHeaderProps) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { user } = useSelector(auth);
  const [openNotifications, setOpenNotifications] = useState<boolean>(false);
  const [openPopup, setOpenPopup] = useState<boolean>(false);
  const [anchorEl, setAnchorEl] = useState<any>(null);

  const { isExistNewNotifications } = useNotificationsContext();

  const onSignOut = () => dispatch(signOut());
  const openNotificationsPage = (
    event: React.MouseEvent<HTMLDivElement, MouseEvent>
  ) => {
    setOpenNotifications(true);
    setAnchorEl(event?.currentTarget);
  };

  const openPopupHandler = (
    event: React.MouseEvent<HTMLDivElement, MouseEvent>
  ) => {
    setOpenPopup(true);
    setAnchorEl(event?.currentTarget);
  };

  const isUserCanSetting =
    checkIsHasAccessToRoute(user, 'Settings', 'routes') &&
    checkIsHasAccessToRoute(user, 'Profile', 'routes');

  return (
    <>
      <NotificationPopup
        open={openNotifications}
        setOpen={setOpenNotifications}
        anchorEl={anchorEl}
        setAnchorEl={setAnchorEl}
      />
      <Popup
        open={openPopup}
        setOpen={setOpenPopup}
        anchorEl={anchorEl}
        setAnchorEl={setAnchorEl}
      />
      <Box className={classes.root}>
        <Box className={classes.left_container}>
          {!open && (
            <>
              <Box
                className={classes.menu_container}
                onClick={() => setOpen((prev) => !prev)}>
                <SidebarOpenIcon />
              </Box>
              <Divider
                orientation="vertical"
                style={{
                  margin: '5px 25px 5px 5px',
                  opacity: 0.8,
                  height: '75%',
                }}
              />
            </>
          )}
          <Box
            display="flex"
            alignItems="center"
            style={{
              cursor: isUserCanSetting ? 'pointer' : 'default',
            }}
            onClick={(e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
              if (!isUserCanSetting) return;
              openPopupHandler(e);
            }}>
            <Box className={classes.avatar}>
              <AvatarIcon />
            </Box>
            <Typography className={classes.title}>
              {user?.given_name + ' ' + user?.family_name}
              <span className={classes.sub_title}>
                {user?.preferred_username || user?.['cognito:username']}
              </span>
            </Typography>
            {isUserCanSetting && (
              <Box className={classes.dropdown}>
                <DropDownIcon />
              </Box>
            )}
          </Box>
        </Box>
        <AffiliateBlock />
        <Box className={classes.headerIcons} ml={6}>
          <ChatComponent />
          <Box
            onClick={(e: React.MouseEvent<HTMLDivElement, MouseEvent>) =>
              openNotificationsPage(e)
            }
            style={{ cursor: 'pointer' }}>
            <Badge
              // variant="dot"
              invisible={!isExistNewNotifications}
              className={classes.notificationsBadge}
              overlap="rectangular">
              <NotificationsIcon color="action" />
            </Badge>
          </Box>
          <Box className={classes.exitApp}>
            <Box onClick={onSignOut}>
              <LogoutIcon />
            </Box>
          </Box>
        </Box>
      </Box>
    </>
  );
};
