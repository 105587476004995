import { gql } from '@apollo/client';

export const GET_GENERAL_INVOICES_LIST = gql`
  query v1_general_invoicesList($prm: prmInvoicesList) {
    v1_general_invoicesList(prm: $prm) {
      data {
        invoices {
          data
          date_after
          date_before
          first_name
          gross_total
          id
          invoice_date
          last_name
          name
          net_total
          new
          partner_id
          partner_name
          region_name
          removed
          status
          title
        }
        pagination {
          current_page
          from
          to
          total
          total_page
        }
      }
    }
  }
`;

export const GET_GENERAL_INVOICE_VIEW = gql`
  query v1_general_invoicesView($prm: prmInvoicesView) {
    v1_general_invoicesView(prm: $prm) {
      data {
        id
        partner_id
        new
        removed
        name
        invoice_date
        gross_total
        date_after
        date_before
        net_total
        status
        last_name
        first_name
        title
        region_name
        partner_name
        data
      }
    }
  }
`;

export const GET_GENERAL_INVOICE_CSV = gql`
  mutation v1_general_invoicesDownload($prm: invoicesDownloadPrm!) {
    v1_general_invoicesDownload(prm: $prm)
  }
`;