import React from "react";
import { useStyles } from "./styles";
import {
  Box,
  Dialog,
  DialogTitle,
  DialogContent,
  IconButton,
} from "@material-ui/core";
import CloseIcon from "src/assets/icons/light/close.svg";
import moment from "moment";

interface IProps {
  item: INotification;
  setItem: React.Dispatch<React.SetStateAction<INotification | null>>;
  open: boolean;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
}

const ShowNotificationModal = ({ open, setOpen, item, setItem }: IProps) => {
  const classes = useStyles();

  const handleClose = () => {
    setOpen(false);
    setItem(null);
  };

  if (!item) {
    return <></>;
  }

  return (
    <Dialog
      open={open}
      fullWidth={true}
      onClose={() => handleClose()}
      className={classes.dialog}
    >
      <DialogTitle>
        <Box className={classes.header}>
          <Box>
            <Box className={classes.dialogHeaderTitle}>{item.title}</Box>
            <Box className={classes.popoverInfo}>
              <Box marginRight={"15px"}>
                {moment(+item.createdAt).format("MM/DD/YYYY")}
              </Box>
              <Box>{moment(+item.createdAt).format("hh:mm:ss")}</Box>
            </Box>
          </Box>
          <IconButton
            onClick={() => setOpen(false)}
            className={classes.closeIcon}
          >
            <CloseIcon />
          </IconButton>
        </Box>
      </DialogTitle>
      <DialogContent>
        <Box className={classes.dialogResponse}>{item.message}</Box>
      </DialogContent>
    </Dialog>
  );
};

export default ShowNotificationModal;
