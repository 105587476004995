import React from "react";
import DatePicker from "react-datepicker";
import { Box, InputLabel } from "@material-ui/core";
import moment from "moment";
import DateCalendar from "src/assets/icons/DateCalendar.svg";
import { IDatePickersProps } from "./interfaces";
import { useStyles } from "./styles";

export const DatePickers: React.FC<IDatePickersProps> = ({
  startDate,
  endDate,
  onChange,
  placeholder,
  props,
  label,
}) => {
  const classes = useStyles();

  const dateFormat = "YYYY-MM-DD HH:mm:ss";

  return (
    <Box>
      {label && <InputLabel className={classes.label}>{label}</InputLabel>}
      <Box className={classes.DatePickersWrapper}>
        <DateCalendar />
        <DatePicker
          selected={
            startDate ? new Date(moment(startDate).toISOString()) : null
          }
          onChange={(dates) => {
            if (dates && !(dates instanceof Date)) {
              const start = dates[0]
                ? moment(dates[0]).format(dateFormat)
                : null;
              const end = dates[1]
                ? moment(dates[1]).format(dateFormat)
                : null;
              return onChange({ start, end });
            }
          }}
          startDate={
            startDate ? new Date(moment(startDate).toISOString()) : null
          }
          endDate={endDate ? new Date(moment(endDate).toISOString()) : null}
          placeholderText={placeholder}
          dateFormat={"EEE, MMM d"}
          selectsRange
          isClearable
          {...props}
        />
      </Box>
    </Box>
  );
};
