import React from 'react';
import { Box, Typography } from '@material-ui/core';
import { HelpOutline } from '@material-ui/icons/';
import SystemNavigation from 'src/web/components/SystemNavigation/index';
import { useStyles } from './styles';

export default function NotAccessPage() {
  const classes = useStyles();

  return (
    <>
      <Box bgcolor="warning.light" className={classes.icon_box}>
        <HelpOutline className={classes.icon_box_icon} />
      </Box>
      <Box>
        <Typography variant="h3">403</Typography>
      </Box>
      <Box>
        <Typography variant="subtitle1">
          We’re sorry. You don't have enough permissions to access.
        </Typography>
      </Box>
      <SystemNavigation onlyLogout />
    </>
  );
}
