import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { routerActions } from "connected-react-router";
import Auth, { CognitoHostedUIIdentityProvider } from "@aws-amplify/auth";
import { nanoid } from "nanoid";
import { AppThunk, RootState } from "src/redux/store";
import { setNotification } from "src/redux/Notifications/sliceNotifications";

export interface IForgotPassword {
  email: string;
}

export interface IConfirmPassword {
  username: string;
  code: string;
  password: string;
}

export interface IChangePassword {
  oldPassword: string;
  newPassword: string;
}

export interface IAuthConfirm {
  username: string;
  code: string;
}

export interface IAuth {
  email: string;
  password: string;
}

export interface IRegister {
  email: string;
  password: string;
  first_name: string;
  last_name: string;
  phone_number: string;
}

export interface AuthState {
  user: any;
  temporaryUser: any;
  loadingUser: boolean;
  loadingPermissions: boolean;
  successAuth: boolean;
  resetPasswordEmail: null | string;
  loadingAuth: boolean;
}

const initialState: AuthState = {
  user: null,
  temporaryUser: null,
  loadingUser: true,
  loadingPermissions: true,
  successAuth: false,
  resetPasswordEmail: null,
  loadingAuth: false,
};

export const authenticationSlice = createSlice({
  name: "authentication",
  initialState,
  reducers: {
    setCurrentUser: (state, action: PayloadAction<any>) => {
      state.user = action.payload;
      state.loadingUser = false;

      if (action.payload && action.payload.id) {
        state.loadingPermissions = true;
      } else {
        state.loadingPermissions = false;
      }
    },
    setTemporaryUser: (state, action: PayloadAction<any>) => {
      state.temporaryUser = action.payload;
    },
    setSuccessAuth: (state, action: PayloadAction<boolean>) => {
      state.successAuth = action.payload;
    },
    setResetPasswordEmail: (state, action: PayloadAction<string>) => {
      state.resetPasswordEmail = action.payload;
    },
    setLoadingAuth: (state, action: PayloadAction<boolean>) => {
      state.loadingAuth = action.payload;
    },
    setUserPermissions: (state, action: PayloadAction<any>) => {
      state.user = {
        ...state.user,
        permissions: action.payload,
      };
      state.loadingPermissions = false;
    },
  },
});

export const {
  setSuccessAuth,
  setCurrentUser,
  setResetPasswordEmail,
  setTemporaryUser,
  setLoadingAuth,
  setUserPermissions,
} = authenticationSlice.actions;

export const getCurrentUser = (): AppThunk => async (dispatch) => {
  try {
    const user = await Auth.currentAuthenticatedUser();
    if (user) {
      const currentUserInfo = await Auth.currentUserInfo();
      const userId = currentUserInfo?.attributes["custom:id"];

      const { payload } = user.signInUserSession.idToken;
      dispatch(
        setCurrentUser({
          ...payload,
          id: userId,
        })
      );
    } else {
      dispatch(setCurrentUser(null));
    }
  } catch (error) {
    dispatch(setCurrentUser(null));
  }
};

export const getCurrentSession = (): AppThunk => async (dispatch) => {
  try {
    const token = await Auth.currentSession();
    return token;
  } catch (error) {
    //console.log(error);
  }
};

export const signUp =
  ({
    email,
    password,
    first_name,
    last_name,
    phone_number,
  }: IRegister): AppThunk =>
  async (dispatch) => {
    try {
      await Auth.signUp({
        username: nanoid(),
        password,
        attributes: {
          email,
          given_name: first_name,
          family_name: last_name,
          phone_number,
        },
      });
      dispatch(setSuccessAuth(true));
    } catch (error) {
      const message = (error as Error).message.includes(
        "PreSignUp failed with error"
      )
        ? (error as Error).message.split("PreSignUp failed with error")[1]
        : (error as Error).message;
      dispatch(setNotification({ type: "error", message }));
    }
  };

export const confirmSignUp =
  ({ username, code }: IAuthConfirm): AppThunk =>
  async () => {
    try {
      await Auth.confirmSignUp(username, code);
    } catch (error) {
      //console.log(error);
    }
  };

export const signIn =
  ({ email, password }: IAuth): AppThunk =>
  async (dispatch) => {
    try {
      dispatch(setLoadingAuth(true));
      const user = await Auth.signIn(email, password);

      if (user.challengeName === "NEW_PASSWORD_REQUIRED") {
        dispatch(setLoadingAuth(false));

        return dispatch(
          setTemporaryUser({
            email,
            password,
            userAttributes: user.challengeParam.userAttributes,
          })
        );
      }
      if (!user.signInUserSession) {
        dispatch(setLoadingAuth(false));
        return;
      }

      const { payload } = user.signInUserSession.idToken;

      dispatch(
        setCurrentUser({
          ...payload,
          id: payload["custom:id"],
        })
      );
      dispatch(
        setNotification({
          type: "info",
          position: "external",
          message: "Welcome to the platform",
        })
      );

      dispatch(setLoadingAuth(false));
    } catch (error) {
      //console.log(error);
      dispatch(setLoadingAuth(false));
      dispatch(
        setNotification({ type: "error", message: (error as Error).message })
      );
    }
  };

export const signInWithGoogle = (): AppThunk => async (dispatch) => {
  try {
    Auth.federatedSignIn({ provider: CognitoHostedUIIdentityProvider.Google });
  } catch (error) {
    //console.log(error);
  }
};

export const signInWithFacebook = (): AppThunk => async (dispatch) => {
  try {
    await Auth.federatedSignIn({
      provider: CognitoHostedUIIdentityProvider.Facebook,
    });
  } catch (error) {
    //console.log(error);
  }
};

export const signOut = (): AppThunk => async (dispatch) => {
  try {
    await Auth.signOut();

    dispatch(setCurrentUser(null));
  } catch (error) {
    console.log(error);
  }
};

export const signOutEverywere = (): AppThunk => async (dispatch) => {
  try {
    await Auth.signOut({ global: true });
  } catch (error) {
    console.log(error);
  }
};

export const changePassword =
  ({ oldPassword, newPassword }: IChangePassword): AppThunk =>
  async (dispatch) => {
    const user = await Auth.currentAuthenticatedUser();
    try {
      await Auth.changePassword(user, oldPassword, newPassword);
      dispatch(
        setNotification({
          type: "success",
          message: "Your password has been successfully changed!",
        })
      );
    } catch (error) {
      console.log(error);
      dispatch(
        setNotification({
          type: "error",
          message: (error as Error).message,
        })
      );
    }
  };

export const forgotPassword =
  ({ email }: IForgotPassword): AppThunk =>
  async (dispatch) => {
    try {
      await Auth.forgotPassword(email);
      dispatch(setResetPasswordEmail(email));
    } catch (error) {
      console.log(error);
    }
  };

export const forgotPasswordSubmit =
  ({ username, code, password }: IConfirmPassword): AppThunk =>
  async (dispatch) => {
    try {
      await Auth.forgotPasswordSubmit(username, code, password);
      dispatch(
        setNotification({
          type: "success",
          message: "Your password has been successfully changed!",
        })
      );
      dispatch(routerActions.push("/login"));
    } catch (error) {
      dispatch(
        setNotification({
          type: "error",
          message: (error as Error).message,
        })
      );
    }
  };

export const completePassword =
  (temporaryUser: any, data: any): AppThunk =>
  async (dispatch) => {
    try {
      const user = await Auth.signIn(
        temporaryUser.email,
        temporaryUser.password
      );

      if (user.challengeName === "NEW_PASSWORD_REQUIRED") {
        const dataUser = await Auth.completeNewPassword(user, data.password, {
          email: data.email || user.challengeParam.userAttributes.email,
          given_name:
            data.first_name || user.challengeParam.userAttributes.given_name,
          family_name:
            data.last_name || user.challengeParam.userAttributes.family_name,
          phone_number:
            data.phone_number ||
            user.challengeParam.userAttributes.phone_number,
        });
        if (!dataUser.signInUserSession) return;
        const { payload } = dataUser.signInUserSession.idToken;
        dispatch(
          setCurrentUser({
            ...payload,
          })
        );
        dispatch(setTemporaryUser(null));
        dispatch(
          setNotification({
            type: "info",
            position: "external",
            message: "Welcome in platform",
          })
        );
      }
    } catch (error) {
      console.log(error);
      setNotification({
        type: "error",
        message: (error as Error).message,
      });
    }
  };

export const currentUser = (state: RootState) => state.auth.user;
export const auth = (state: RootState) => state.auth;
export const selectLoadingUser = (state: RootState) => state.auth.loadingUser;
export const successAuth = (state: RootState) => state.auth.successAuth;
export const resetPasswordEmail = (state: RootState) =>
  state.auth.resetPasswordEmail;
export const temporaryUser = (state: RootState) => state.auth.temporaryUser;

export default authenticationSlice.reducer;
