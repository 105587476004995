import {
  LOGIN_PATH,
  NOT_FOUND_PATH,
  FORGOT_PASSWORD_PATH,
  CONFIRM_PASSWORD_PATH,
  UNAUTHORIZED_PATH,
  INTERNAL_SERVER_PATH,
  ARRIVALS_PATH,
  DASHBOARD_PATH,
  CALENDAR_MANAGEMENT_PATH,
  REVIEWS_PATH,
  PAYOUTS_PATH,
  ARRIVALS_DETAIL_PATH,
  SETTINGS_PATH,
  // PROFILE_PATH,
  // COMPANY_PATH,
  NOTIFICATIONS_PATH,
  // USERS_PATH,
  // GROUPS_PATH,
  PAYOUTS_DETAIL_PATH,
  REGISTRATION_PATH,
  ONBOARDING_PATH,
  NOT_ACCESS_PATH,
  FIELDS_GROUP_PATH,
} from './paths';
import {
  LOGIN_PAGE,
  NOT_FOUND_PAGE,
  FORGOT_PASSWORD_PAGE,
  CONFIRM_PASSWORD_PAGE,
  UNAUTHORIZED_PAGE,
  INTERNAL_SERVER_PAGE,
  ARRIVALS_PAGE,
  DASHBOARD_PAGE,
  CALENDAR_MANAGEMENT_PAGE,
  REVIEWS_PAGE,
  PAYOUTS_PAGE,
  ARRIVALS_DETAIL_PAGE,
  NOTIFICATIONS_PAGE,
  SETTINGS_PAGE,
  PAYOUTS_DETAIL_PAGE,
  REGISTRATION_PAGE,
  ONBOARDING_PAGE,
  NOT_ACCESS_PAGE,
  FIELDS_GROUP_PAGE,
} from './pages';

type Route = {
  path: string;
  component: () => JSX.Element;
  name?: string;
};

export const AuthRoutes = [
  {
    path: LOGIN_PATH,
    component: LOGIN_PAGE,
  },
  {
    path: FORGOT_PASSWORD_PATH,
    component: FORGOT_PASSWORD_PAGE,
  },
  {
    path: CONFIRM_PASSWORD_PATH,
    component: CONFIRM_PASSWORD_PAGE,
  },
];

export const authPaths = AuthRoutes.map((i) => i.path);

export const RegistrationRoutes = [
  {
    path: REGISTRATION_PATH,
    component: REGISTRATION_PAGE,
  },
];

export const registrationPaths = RegistrationRoutes.map((i) => i.path);

export const OnboardingRoutes = [
  {
    path: ONBOARDING_PATH,
    component: ONBOARDING_PAGE,
    name: 'Onboarding',
  },
  {
    path: FIELDS_GROUP_PATH,
    component: FIELDS_GROUP_PAGE,
    name: 'Fields group',
  },
];

export const onboardingPaths = OnboardingRoutes.map((i) => i.path);

export const PrivateRoutes: Route[] = [
  {
    path: DASHBOARD_PATH,
    component: DASHBOARD_PAGE,
    name: 'Dashboard',
  },
  {
    path: ARRIVALS_PATH,
    component: ARRIVALS_PAGE,
    name: 'Arrivals',
  },
  {
    path: ARRIVALS_DETAIL_PATH,
    component: ARRIVALS_DETAIL_PAGE,
    name: 'ArrivalsDetailScreen',
  },
  {
    path: CALENDAR_MANAGEMENT_PATH,
    component: CALENDAR_MANAGEMENT_PAGE,
    name: 'Calendar',
  },
  {
    path: REVIEWS_PATH,
    component: REVIEWS_PAGE,
    name: 'Reviews',
  },
  {
    path: PAYOUTS_PATH,
    component: PAYOUTS_PAGE,
    name: 'Payouts',
  },
  {
    path: SETTINGS_PATH,
    component: SETTINGS_PAGE,
    name: 'Settings',
  },
  // {
  //   path: PROFILE_PATH,
  //   component: SETTINGS_PAGE,
  //   groups: ['Admins', 'Managers', 'Reservations'],
  // },
  // {
  //   path: COMPANY_PATH,
  //   component: SETTINGS_PAGE,
  //   groups: ['Admins', 'Managers', 'Reservations'],
  // },
  {
    path: NOTIFICATIONS_PATH,
    component: NOTIFICATIONS_PAGE,
    name: 'Notifications',
  },
  // {
  //   path: USERS_PATH,
  //   component: SETTINGS_PAGE,
  //   groups: ['Admins', 'Managers', 'Reservations'],
  // },
  // {
  //   path: GROUPS_PATH,
  //   component: SETTINGS_PAGE,
  //   groups: ['Admins', 'Managers', 'Reservations'],
  // },
];

export const privatePaths = PrivateRoutes.map((i) => i.path);

export const SystemRoutes = [
  {
    path: UNAUTHORIZED_PATH,
    component: UNAUTHORIZED_PAGE,
  },
  {
    path: INTERNAL_SERVER_PATH,
    component: INTERNAL_SERVER_PAGE,
  },
  {
    path: NOT_ACCESS_PATH,
    component: NOT_ACCESS_PAGE,
  }
];

export const systemPaths = SystemRoutes.map((i) => i.path);

export const NotFoundRoutes = {
  path: NOT_FOUND_PATH,
  component: NOT_FOUND_PAGE,
};

export const WithoutLayoutRoutes: Route[] = [
  {
    path: PAYOUTS_DETAIL_PATH,
    component: PAYOUTS_DETAIL_PAGE,
    name: 'PayoutsDetail',
  },
];

export const withoutLayoutPaths = WithoutLayoutRoutes.map((i) => i.path);
