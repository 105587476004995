import React from 'react';
import { ThemeProvider } from '@material-ui/core/styles';
import { createTheme } from '@material-ui/core';
import { StylesProvider } from '@material-ui/styles/';
import { GenerateClassName } from '../../helpers/GenerateClassName';
import { Theme as muiTheme } from '@material-ui/core';

interface Text {
  trip_shock_black: string;
  trip_shock_white: string;
  trip_shock_gray: string;
}

interface Environment {
  trip_shock_white: string;
  trip_shock_black: string;
  trip_shock_charcoal: string;
  trip_shock_navy: string;
  trip_shock_gray: string;
  trip_shock_silver: string;
  trip_shock_off_white: string;
  trip_shock_blue_grey: string;
  trip_shock_green_2: string;
  trip_shock_light_grey: string;
  trip_shock_dark: string;
  headerHeight: number;
  toolbarHeight: number;
  topBgHeight: number;
}

interface AccentColors {
  primary: string;
  secondary: string;
}

interface Statuses {
  trip_shock_dark_green: string;
  trip_shock_green: string;
  trip_shock_yellow: string;
  trip_shock_red: string;
  trip_shock_black: string;
  status_light_green: string;
  status_off_green: string;
  status_green: string;
  status_yellow: string;
  status_orange: string;
  status_pink: string;
  status_dark_green: string;
  status_dark_orange: string;
  status_purp: string;
  status_steel_blue: string;
  status_brown: string;
}

export interface Theme extends muiTheme {
  trip_shock_theme: {
    text: Text;
    environment: Environment;
    accent_colors: AccentColors;
    statuses: Statuses;
  };
}

// creating empty mui theme so that we gain access to all the fancy MUI theme stuffs
const theme = createTheme({
  typography: {
    fontFamily: `"Montserrat", "Helvetica", "Arial", sans-serif`,
  },
  breakpoints: {
    values: {
      xs: 0,
      sm: 600,
      md: 1020,
      lg: 1280,
      xl: 1920,
    },
  },
});

const tripshockTheme = {
  trip_shock_theme: {
    text: {
      trip_shock_black: '#202020',
      trip_shock_white: '#ffffff',
      trip_shock_gray: '#4F4F4F',
    },
    environment: {
      trip_shock_white: '#ffffff',
      trip_shock_black: '#202020',
      trip_shock_charcoal: '#333333',
      trip_shock_navy: '#2E3A45',
      trip_shock_gray: '#6C6B6B',
      trip_shock_silver: '#CDCDCD',
      trip_shock_off_white: '#F7F7F7',
      trip_shock_green: '#009A53',
      trip_shock_green_2: '#0DD1C5',
      trip_shock_blue_grey: '#263238',
      trip_shock_light_grey: '#C4C4C4',
      trip_shock_dark: '#2E3035',
      headerHeight: 95,
      toolbarHeight: 70,
      topBgHeight: 153,
    },
    accent_colors: {
      primary: '#50B061',
      secondary: '#5C8FDC',
    },
    statuses: {
      trip_shock_dark_green: '#50B061',
      trip_shock_green: '#40900F',
      trip_shock_yellow: '#E9C052',
      trip_shock_red: '#EC4652',
      trip_shock_black: '#202020',
      status_light_green: '#BACF66',
      status_off_green: '#73C081',
      status_green: '#D5F984',
      status_yellow: '#FBFB52',
      status_orange: '#F5BC40',
      status_pink: '#EE7F7B',
      status_dark_green: '#71EE91',
      status_dark_orange: '#EE7F52',
      status_purp: '#E08CE9',
      status_steel_blue: '#B6D7E3',
      status_brown: '#D5ACAB',
    },
  },
};

interface IMuiTheme {
  staticClasses?: boolean;
}

const MuiTheme: React.FC<IMuiTheme> = ({ children, staticClasses = false }) => {
  if (staticClasses) {
    return (
      <StylesProvider generateClassName={GenerateClassName}>
        <ThemeProvider theme={{ ...theme, ...tripshockTheme }}>
          {children}
        </ThemeProvider>
      </StylesProvider>
    );
  } else {
    return (
      <ThemeProvider theme={{ ...theme, ...tripshockTheme }}>
        {children}
      </ThemeProvider>
    );
  }
};

export default MuiTheme;
