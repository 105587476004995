import {
  Box,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
  useMediaQuery,
} from '@material-ui/core';
import React from 'react';
import { useStyles } from './styles';

interface Props {
  deduct_item: DeductItem[];
  deductions_total: string;
}

const DeductTable = ({deduct_item, deductions_total}: Props) => {
  const classes = useStyles();

  const mdMatches = useMediaQuery('(max-width: 1020px)');
  return (
    <>
      <Box mt="60px">
        <Typography variant="h2">Deductions</Typography>
        {mdMatches ? (
          <Box className={classes.mobileTable}>
            {deduct_item?.map((deductItem) => {
              const {
                cart_items_id,
                customer,
                dates,
                product_name,
                booked_info,
                deduct_state,
              } = deductItem;
              return (
                <Box className={classes.mobileTableRow}>
                  <Box className={classes.mobileTableCell}>
                    <Typography className={classes.mobileTableHeader}>
                      Id
                    </Typography>
                    <Typography className={classes.mobileTableBody}>
                      {cart_items_id}
                    </Typography>
                  </Box>
                  <Box className={classes.mobileTableCell}>
                    <Typography className={classes.mobileTableHeader}>
                      Customer
                    </Typography>
                    <Typography className={classes.mobileTableBody}>
                      {customer}
                    </Typography>
                  </Box>
                  <Box className={classes.mobileTableCell}>
                    <Typography className={classes.mobileTableHeader}>
                      Date of trip
                    </Typography>
                    <Typography className={classes.mobileTableBody}>
                      {dates}
                    </Typography>
                  </Box>
                  <Box className={classes.mobileTableCell}>
                    <Typography className={classes.mobileTableHeader}>
                      Activity name
                    </Typography>
                    <Typography className={classes.mobileTableBody}>
                      {product_name}
                    </Typography>
                  </Box>
                  <Box className={classes.mobileTableCell}>
                    <Typography className={classes.mobileTableHeader}>
                      Tickets purchased
                    </Typography>
                    <Typography className={classes.mobileTableBody}>
                      {booked_info}
                    </Typography>
                  </Box>
                  <Box className={classes.mobileTableCell}>
                    <Typography className={classes.mobileTableHeader}>
                      Deduct Status
                    </Typography>
                    <Typography className={classes.mobileTableBody}>
                      {deduct_state}
                    </Typography>
                  </Box>
                </Box>
              );
            })}
          </Box>
        ) : (
          <Table className={classes.table}>
            <TableHead>
              <TableRow>
                <TableCell>Id</TableCell>
                <TableCell>Customer</TableCell>
                <TableCell>Date of trip</TableCell>
                <TableCell>Activity name</TableCell>
                <TableCell>Tickets purchased</TableCell>
                <TableCell>Deduct Status</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {deduct_item &&
                deduct_item?.map((deductItem) => {
                  const {
                    cart_items_id,
                    customer,
                    dates,
                    product_name,
                    booked_info,
                    deduct_state,
                  } = deductItem;
                  return (
                    <TableRow>
                      <TableCell>{cart_items_id}</TableCell>
                      <TableCell>{customer}</TableCell>
                      <TableCell>{dates}</TableCell>
                      <TableCell>{product_name}</TableCell>
                      <TableCell>{booked_info}</TableCell>
                      <TableCell>{deduct_state}</TableCell>
                    </TableRow>
                  );
                })}
            </TableBody>
          </Table>
        )}
      </Box>

      <Box
        mt="20px"
        display="flex"
        flexDirection="row"
        justifyContent="flex-end">
        <Typography variant="h4">
          Total Deductions: {deductions_total || '$0.00'}
        </Typography>
      </Box>
    </>
  );
};

export default DeductTable;
