import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Badge, Box, Button } from '@material-ui/core';
import {
  prm as initialPrm,
  setLoading,
  setDetailedFilters,
  applyDetailedFilters,
  tableState,
} from 'src/web/features/table/sliceTables';
import { getFilterLenth } from 'src/helpers/getFiltersLength';
import { useStyles } from './styles';
import MoreFiltersIcon from 'src/assets/icons/light/moreFilters.svg';
import moment from 'moment';
import DatePickers from 'src/web/components/UI/DatePickers/DatePickers';
import FiltersMoblieIcon from 'src/assets/icons/moreFiltersMobile.svg';

const Header = ({
  setOpen,
}: {
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
}) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const prm = useSelector(initialPrm);

  const { detailedFilters } = useSelector(tableState);
  const appliedFilters = detailedFilters || {};

  const onHandlerChange = ({
    key,
    value,
  }: {
    key: string;
    value: string | null;
  }) => {
    dispatch(setDetailedFilters({ key, value }));
  };

  return (
    <Box
      display={'flex'}
      alignItems="flex-end"
      justifyContent={'space-around'}
      marginLeft={3}>
      <Box width={'250px'} className={classes.dateFilter}>
        <DatePickers
          label={'Arrivals date'}
          startDate={appliedFilters?.dateFrom?.value}
          endDate={appliedFilters?.dateTo?.value}
          onChange={({ start, end }) => {
            if ((start && end) || (!start && !end)) {
              dispatch(setLoading({ type: 'change', value: true }));
            }
            onHandlerChange({
              key: 'dateFrom',
              value: start
                ? moment(start).startOf('day').format('YYYY-MM-DD HH:mm:ss')
                : null,
            });
            onHandlerChange({
              key: 'dateTo',
              value: end
                ? moment(end).endOf('day').format('YYYY-MM-DD HH:mm:ss')
                : null,
            });
            if (typeof start === typeof end) {
              dispatch(applyDetailedFilters());
            }
          }}
          placeholder={'Choose dates'}
        />
      </Box>
      <Box mt={1.2}>
        <div
          className={classes.moreFiltersButton}
          onClick={() => setOpen(true)}>
          <Badge
            badgeContent={
              prm?.where[0]?.length ? getFilterLenth(prm?.where) : 0
            }
            color="secondary"
            anchorOrigin={{
              vertical: 'top',
              horizontal: 'left',
            }}
            overlap="rectangular">
            <MoreFiltersIcon style={{ marginRight: '4px' }} />
          </Badge>
          More filters
        </div>
      </Box>
      <Box className={classes.moreFiltersButtonMobile} mt={1.2}>
        <Button
          style={{
            padding: '0',
            minWidth: '28px',
            marginRight: '4px',
            height: '30px',
          }}
          variant="text"
          onClick={() => setOpen(true)}>
          <FiltersMoblieIcon />
        </Button>
      </Box>
    </Box>
  );
};

export default Header;
