import { ISorting } from 'src/web/features/table/interfaces';

export const sorting: ISorting[] = [
  {
    id: 'id',
    label: 'Id',
    sortable: false,
    active: false,
    order: undefined,
    width: '150px',
    align: 'left',
  },
  {
    id: 'invoice_date',
    label: 'Invoice date',
    sortable: false,
    active: false,
    order: undefined,
    width: '160px',
    align: 'center',
  },
  {
    id: 'net_total',
    label: 'Net total',
    sortable: false,
    active: false,
    order: undefined,
    width: '280px',
    align: 'center',
  },
  {
    id: 'status',
    label: 'Status',
    sortable: false,
    active: false,
    order: undefined,
    width: '150px',
    align: 'left',
  },
];
