import { makeStyles } from '@material-ui/core/styles';
import { Theme } from 'src/web/themes/theme';

export const useStyles = makeStyles((theme: Theme) => ({
  table_row: {
    cursor: 'pointer',
    height: '70px',
  },
  table_cell: {
    color: '#4F4F4F',
    fontWeight: 500,
    fontSize: '12px',
    whiteSpace: 'nowrap',
    padding: '16px',
    [theme.breakpoints.down('sm')]: {
      padding: '16px',
      fontSize: '10px',
    },
  },
  orderCell: {
    width: '138px',
    whiteSpace: 'normal',
  },
  cityCell: {
    width: '120px',
    whiteSpace: 'normal',
  },
  tripCell: {
    width: '200px',
    whiteSpace: 'normal',
    overflow: 'hidden',
    display: '-webkit-box',
    '-webkit-box-orient': 'vertical',
    '-webkit-line-clamp': 2,
  },
  adaptiveTripCell: {
    whiteSpace: 'pre-wrap',
  },
  madeCell: {
    width: '110px',
    whiteSpace: 'normal',
  },
  customerCell: {
    minWidth: '150px',
    whiteSpace: 'normal',
  },
  adaptiveCustomerCell: {
    whiteSpace: 'pre-wrap',
  },
  tableLoader: {
    position: 'absolute',
    height: 'calc(100vh - 270px)',
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  tableLoaderWrapper: {
    borderBottom: 'none',
    width: 0,
    height: 0,
    padding: 0,
  },
  table_cell_arrival_type: {
    color: '#4F4F4F',
    fontWeight: 500,
    fontSize: '12px',
    whiteSpace: 'nowrap',
    display: 'flex',
  },
  custom_chip: {
    textTransform: 'uppercase',
    minWidth: '110px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    height: '20px',
    borderRadius: '50px',
    boxShadow:
      '0px 13px 28px rgba(29, 203, 192, 0.2), 0px 8px 16px rgba(29, 203, 192, 0.06)',
    whiteSpace: 'nowrap',
    width: '110px',
    [theme.breakpoints.down('sm')]: {
      minWidth: '80px',
      width: '80px',
    },
  },
  chip_text: {
    fontSize: '12px',
    textTransform: 'none',
    fontWeight: 500,
    padding: '0px',
    margin: '0px',
    [theme.breakpoints.down('sm')]: {
      fontSize: '10px',
    },
  },
  icons_container: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
    width: '90px',
    whiteSpace: 'normal',
  },
  icon_container: {
    marginRight: '5px',
    height: '24px',
    width: '24px',
  },
  name_subtext: {
    fontSize: '12px',
    fontWeight: 500,
    color: '#4F4F4F',
    [theme.breakpoints.down('sm')]: {
      fontSize: '10px',
    },
  },
  phone_subtext: {
    fontSize: '12px',
    fontWeight: 500,
    color: '#0DD1C5',
    backgroundColor: '#e7faf9',
    borderRadius: '50px',
    width: '108px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    [theme.breakpoints.down('sm')]: {
      fontSize: '10px',
      width: '84px',
    },
  },
  arrival_type_container: {
    display: 'flex',
    alignItems: 'center',
    width: '90px',
    whiteSpace: 'normal',
  },
  icon_arrival_type: {
    marginRight: '7px',
    height: '24px',
  },
  flex: {
    display: 'flex',
    justifyContent: 'center',
  },
  table_cell_detailed_header: {
    background: '#f5f5f5',
    padding: '8px 16px',
    fontSize: '12px',
    barrivalTop: '1px solid #cdcdcd',
  },

  table_cell_detailed_body: {
    background: '#fafafa',
    padding: '8px 16px',
    fontSize: '12px',
    barrivalBottom: '1px solid #cdcdcd',
  },
}));
