import {
  Box,
  RadioGroup,
  FormControl,
  TextField,
  FormControlLabel,
  Radio,
  FormLabel,
  RadioProps,
  makeStyles,
} from "@material-ui/core";
import React from "react";
import { useStyles } from "../styles";
import { IRegistrationForm } from "src/redux/Registration/interfaces";
import clsx from "clsx";
import { InputError } from "../../UI/InputError";
import { IGroupFields } from "../interface";

type Props = {
  formInput: IGroupFields;
  errorMessage: string;
  handleChange: (field: string, value: string) => void;
  value: any;
  other?: boolean;
  setOther?: React.Dispatch<React.SetStateAction<boolean>>;
  disabled?: boolean;
};

const CustomRadioStyles = makeStyles({
  root: {
    "&:hover": {
      backgroundColor: "transparent",
    },
  },
  icon: {
    borderRadius: "50%",
    width: 20,
    height: 20,
    border: "1px solid #C4C4C4",
  },
  checkedIcon: {
    border: "2px solid #0DD1C5",
    "&:before": {
      display: "block",
      width: 16,
      height: 16,
      backgroundImage: "radial-gradient(#0DD1C5,#0DD1C5 45%,transparent 50%)",
      content: '""',
    },
  },
  label: {
    color: "red",
  },
});

function StyledRadio(props: RadioProps) {
  const classes = CustomRadioStyles();

  return (
    <Radio
      className={classes.root}
      disableRipple
      color="default"
      checkedIcon={<span className={clsx(classes.icon, classes.checkedIcon)} />}
      icon={<span className={classes.icon} />}
      {...props}
    />
  );
}

export const RadioGroupElement = ({
  formInput,
  errorMessage,
  other,
  setOther,
  handleChange,
  disabled,
  value,
}: Props) => {
  const classes = useStyles();

  const isShowOther =
    other ||
    (!formInput.values.find(
      (item) =>
        item.label ===
          (value as any) ||
        item.value === (value as any)
    ) &&
      !!value);

  return (
    <div className={classes.field}>
      <FormControl fullWidth component="fieldset" error={!!errorMessage}>
        <FormLabel className={classes.radioTitle} component="legend">
          {formInput.label}
        </FormLabel>
        <div
          className={clsx(classes.radioGroupContainer, {
            flex: formInput?.values && formInput?.values?.length < 3,
          })}
        >
          <RadioGroup
            aria-label={formInput.name}
            name={formInput.name}
            className={clsx(classes.radioGroup, {
              flex: formInput?.values && formInput?.values?.length < 3,
            })}
            onChange={(e) => {
              if (e.target.id === "custom") {
                setOther && setOther(true);
                handleChange(formInput.name, "");
              } else {
                setOther && setOther(false);
                handleChange(formInput.name, e.target.value);
              }
            }}
            value={
              isShowOther
                ? formInput.values.find((item) => item.useCustom)?.label
                : (value as string)
            }
          >
            {formInput?.values?.map((item, index) => (
              <FormControlLabel
                key={index}
                className={classes.radioItem}
                style={
                  formInput?.values &&
                  (formInput?.values?.length < 3
                    ? {
                        justifyContent: "center",
                        gridTemplateRows: "repeat(2, 1fr)",
                      }
                    : {
                        justifyContent: "start",
                        gridTemplateRows: `repeat(${Math.ceil(
                          formInput?.values?.length / 2
                        )}, 1fr)`,
                      })
                }
                value={item.label || item.value}
                control={
                  <StyledRadio id={item.useCustom ? "custom" : "default"} />
                }
                label={item.label}
                disabled={disabled}
              />
            ))}
          </RadioGroup>
          {!!errorMessage &&
            !value &&
            !other && (
              <Box style={{ bottom: "-55px" }}>
                <InputError message={errorMessage} />
              </Box>
            )}
          {isShowOther && (
            <div style={{ position: "relative" }}>
              <TextField
                name={formInput.name}
                fullWidth
                autoFocus
                style={{ marginTop: "22px" }}
                label={"Write your answer"}
                className={classes.customInput}
                variant="outlined"
                value={value}
                InputLabelProps={{
                  shrink: true,
                }}
                onChange={(e) => handleChange(formInput.name, e.target.value)}
                error={!!errorMessage}
              />
              {!!errorMessage && <InputError message={"Please fill this in"} />}
            </div>
          )}
        </div>
      </FormControl>
    </div>
  );
};
