import { CssBaseline } from '@material-ui/core';
import { Notifications } from 'src/web/features/notifications';
import React, { useEffect, useState } from 'react';
import { Spiner } from './components/Loaders';
import Routes from './routes';
import MuiTheme from './themes/theme';
import { useDispatch, useSelector } from 'react-redux';
import {
  currentUser,
  getCurrentUser,
  setUserPermissions,
} from 'src/redux/Authentication/sliceAuthentication';
import { useLazyQuery } from '@apollo/client';
import { GET_EMPLOYEE_PERMISSIONS } from 'src/api/employees';
import { setNotification } from 'src/redux/Notifications/sliceNotifications';

type Props = {
  isLoadingUser: boolean;
};

const WebTemplate = ({ isLoadingUser }: Props) => {
  const dispatch = useDispatch();
  const user = useSelector(currentUser);
  const [isLoadingPermissions, setIsLoadingPermissions] = useState(true);

  const [getEmployeePermissions] = useLazyQuery(GET_EMPLOYEE_PERMISSIONS, {
    onCompleted: (data) => {
      const permissions: string[] = JSON.parse(
        data.v1_partners_getEmployee.permissions || '[]'
      );
      dispatch(setUserPermissions(permissions));
      setIsLoadingPermissions(false);
    },
    onError: (error) => {
      setNotification({ type: 'error', message: error.message });
      setIsLoadingPermissions(false);
    },
  });

  useEffect(() => {
    if (!isLoadingUser && !user) {
      setIsLoadingPermissions(false);
    }
  }, [isLoadingUser]);

  useEffect(() => {
    dispatch(getCurrentUser());
  }, [dispatch]);

  useEffect(() => {
    if (
      user &&
      !user.permissions &&
      (!user['cognito:groups'] || !user['cognito:groups']?.lenght)
    ) {
      setIsLoadingPermissions(true);
      getEmployeePermissions({
        variables: {
          prm: {
            id: user['cognito:username'],
          },
        },
      });
    } else if (user) {
      setIsLoadingPermissions(false);
    }
  }, [getEmployeePermissions, user]);

  return (
    <MuiTheme>
      <CssBaseline />
      <Notifications />
      {isLoadingUser || isLoadingPermissions ? <Spiner fixed /> : <Routes />}
    </MuiTheme>
  );
};

export default WebTemplate;
