import React, { useEffect, useState } from 'react';
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  TextField,
  useMediaQuery,
} from '@material-ui/core';

import PhoneInput from 'src/web/components/UI/PhoneInput';

import CloseIcon from 'src/assets/icons/light/close.svg';
import { UserPermissionsModal } from './UserPermissionsModal';

import { useStyles } from './styles';
import { ApolloQueryResult, useMutation } from '@apollo/client';
import { CREATE_EMPLOYEE, UPDATE_EMPLOYEE } from 'src/api/employees';
import { useDispatch, useSelector } from 'react-redux';
import { currentUser } from 'src/redux/Authentication/sliceAuthentication';
import { validateEmail, validatePhone } from 'src/helpers/BasicValidation';
import { setNotification } from 'src/redux/Notifications/sliceNotifications';

type Props = {
  open: boolean;
  setOpen: (isOpen: boolean) => void;
  setIsSpinner: React.Dispatch<React.SetStateAction<boolean>>;
  employee?: IEmployee;
  setChoosenUser: React.Dispatch<React.SetStateAction<string | null>>;
  refetch: (
    variables?:
      | Partial<{
          prm: {
            partner_id: string | number;
          };
        }>
      | undefined
  ) => Promise<ApolloQueryResult<any>>;
};

export type TempEmployee = Omit<IEmployee, 'id'>;

const initValidatedFields = {
  first_name: {
    isValid: true,
    errorMessage: '',
  },
  email: {
    isValid: true,
    errorMessage: '',
  },
  last_name: {
    isValid: true,
    errorMessage: '',
  },
  phone: {
    isValid: true,
    errorMessage: '',
  },
};

export const UserDetailModal = ({
  open,
  setIsSpinner,
  refetch,
  setOpen,
  employee,
  setChoosenUser,
}: Props) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const user = useSelector(currentUser);
  const mdMatches = useMediaQuery('(max-width: 1020px)');

  const [validatedFields, setValidatedFields] = useState(initValidatedFields);
  const [showError, setShowError] = useState(false);

  const initialEmployeeState: TempEmployee = {
    email: '',
    first_name: '',
    last_name: '',
    permissions: '[]',
    phone: '',
    partner_id: user['custom:partner_id'],
  };

  const [tempEmployee, setTempEmployee] = useState<IEmployee | TempEmployee>(
    initialEmployeeState
  );

  useEffect(() => {
    if (employee) {
      setTempEmployee(employee);
    } else {
      setTempEmployee(initialEmployeeState);
    }
  }, [employee]);

  const [isPermissionsOpen, setIsPermissionsOpen] = useState(false);

  const [createEmployee] = useMutation(CREATE_EMPLOYEE, {
    onCompleted: (data) => {
      refetch();
      onCloseHandler();
    },
    onError: (error) => {
      console.log(error);
      dispatch(
        setNotification({
          message: error.message,
          type: 'error',
        })
      );
      onCloseHandler();
    },
  });

  const [updateEmployee] = useMutation(UPDATE_EMPLOYEE, {
    onCompleted: (data) => {
      refetch();
      onCloseHandler();
    },
    onError: (error) => {
      console.log(error);
      dispatch(
        setNotification({
          message: error.message,
          type: 'error',
        })
      );
      onCloseHandler();
    },
  });

  const onCloseHandler = () => {
    setChoosenUser(null);
    setValidatedFields(initValidatedFields);
    setShowError(false);
    setOpen(false);
  };

  const onEmployeeChange = (
    field: keyof TempEmployee,
    value: string | number
  ) => {
    if (showError) {
      const isValid = validate();

      setShowError(!isValid);
    }

    setTempEmployee((prev) => ({
      ...prev,
      [field]: value,
    }));
  };

  const validate = () => {
    let fieldsIsValid = true;

    Object.keys(validatedFields).forEach((field) => {
      if (!tempEmployee[field as keyof typeof initValidatedFields]) {
        setValidatedFields((prev) => ({
          ...prev,
          [field]: {
            isValid: false,
            errorMessage: 'Field is required',
          },
        }));

        fieldsIsValid = false;
      }
    });

    if (!validateEmail(tempEmployee.email)) {
      setValidatedFields((prev) => ({
        ...prev,
        email: {
          isValid: false,
          errorMessage: 'Field is not correct',
        },
      }));

      fieldsIsValid = false;
    }

    if (!validatePhone(tempEmployee.phone)) {
      setValidatedFields((prev) => ({
        ...prev,
        phone: {
          isValid: false,
          errorMessage: 'Field is not correct',
        },
      }));

      fieldsIsValid = false;
    }

    return fieldsIsValid;
  };

  const onSubmit = () => {
    const isValid = validate();

    if (!isValid) {
      setShowError(!isValid);
      return;
    }

    setIsSpinner(true);

    if (employee) {
      updateEmployee({
        variables: {
          prm: {
            ...tempEmployee,
            phone: tempEmployee.phone.includes('+')
              ? tempEmployee.phone.substring(1)
              : tempEmployee.phone,
            partner_id: undefined,
          },
        },
      });
    } else {
      createEmployee({
        variables: {
          prm: {
            ...tempEmployee,
            phone: tempEmployee.phone.includes('+')
              ? tempEmployee.phone.substring(1)
              : tempEmployee.phone,
            partner_id: user['custom:partner_id'],
          },
        },
      });
    }

    onCloseHandler();
  };

  const employeePermissions: string[] = JSON.parse(
    tempEmployee?.permissions || '[]'
  );

  return (
    <>
      <UserPermissionsModal
        employee={employee}
        open={isPermissionsOpen}
        setOpen={setIsPermissionsOpen}
        onEmployeeChange={onEmployeeChange}
        employeePermissions={employeePermissions}
      />
      <Dialog
        fullWidth
        maxWidth="sm"
        open={open}
        className={classes.detailModalRoot}
        onClose={onCloseHandler}>
        <DialogTitle>
          <Box className={classes.dialogHeader}>
            <Box className={classes.dialogHeaderTitle}>
              {employee ? 'Edit person' : 'Add a new person'}
            </Box>
            <IconButton onClick={onCloseHandler} className={classes.closeIcon}>
              <CloseIcon />
            </IconButton>
          </Box>
        </DialogTitle>
        <DialogContent>
          <Box
            width={1}
            display="flex"
            flexDirection={mdMatches ? 'column' : 'row'}
            flexWrap="wrap"
            justifyContent="space-between">
            <Box width={mdMatches ? '100%' : '48%'}>
              <TextField
                className={classes.editInput}
                label="First name"
                variant="outlined"
                placeholder="Your first name"
                InputLabelProps={{
                  shrink: true,
                }}
                value={tempEmployee.first_name}
                onChange={(e) => onEmployeeChange('first_name', e.target.value)}
                error={!validatedFields.first_name.isValid && showError}
                helperText={
                  showError &&
                  !validatedFields.first_name.isValid &&
                  validatedFields.first_name.errorMessage
                }
              />
            </Box>
            <Box width={mdMatches ? '100%' : '48%'}>
              <TextField
                className={classes.editInput}
                label="Last name"
                value={tempEmployee.last_name}
                onChange={(e) => onEmployeeChange('last_name', e.target.value)}
                placeholder="Your last name"
                variant="outlined"
                InputLabelProps={{
                  shrink: true,
                }}
                error={!validatedFields.last_name.isValid && showError}
                helperText={
                  showError &&
                  !validatedFields.last_name.isValid &&
                  validatedFields.last_name.errorMessage
                }
              />
            </Box>
            <Box width={1}>
              <TextField
                className={classes.editInput}
                label="Email"
                value={tempEmployee.email}
                onChange={(e) => onEmployeeChange('email', e.target.value)}
                placeholder="Your email"
                variant="outlined"
                InputLabelProps={{
                  shrink: true,
                }}
                error={!validatedFields.email.isValid && showError}
                helperText={
                  showError &&
                  !validatedFields.email.isValid &&
                  validatedFields.email.errorMessage
                }
              />
            </Box>
            <Box width={1} className={classes.phoneWrapper}>
              <PhoneInput
                value={tempEmployee.phone}
                onChange={(value) => onEmployeeChange('phone', value)}
                placeholder={'your phone number'}
                preferredCountries={['us', 'ca']}
                error={!validatedFields.phone.isValid && showError}
                helperText={
                  showError &&
                  !validatedFields.phone.isValid &&
                  validatedFields.phone.errorMessage
                }
              />
            </Box>
            <Box width={1} display="flex" justifyContent="center">
              <Button
                onClick={() => setIsPermissionsOpen(true)}
                className={classes.saveButton}
                variant="contained">
                Set up permissions
              </Button>
            </Box>
          </Box>
        </DialogContent>
        <DialogActions>
          <Box>
            <Button
              onClick={onCloseHandler}
              className={classes.cancelButton}
              variant="text">
              Cancel
            </Button>
            <Button
              disabled
              onClick={onSubmit}
              className={classes.saveButton}
              variant="contained">
              Save
            </Button>
          </Box>
        </DialogActions>
      </Dialog>
    </>
  );
};
