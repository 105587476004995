import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from 'src/redux/store';
import { initialState } from './initialData';
import { RateData } from './interfaces';

export const reviewsSlice = createSlice({
  name: 'reviews',
  initialState,
  reducers: {
    setRates: (state, action: PayloadAction<RateData>) => {
      state.reviewsFilter = {
        ...state.reviewsFilter,
        ...action.payload,
      };
    },
  },
});

export const { setRates } = reviewsSlice.actions;

export const reviewsData = (state: RootState) => state.reviews;
export const reviewsFilter = (state: RootState) => state.reviews.reviewsFilter;

export default reviewsSlice.reducer;
