import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  IconButton,
} from '@material-ui/core';
import React, { useState } from 'react';
import { useMutation, useQuery } from '@apollo/client';
import { GET_CALENDAR, UPDATE_CALENDAR } from 'src/api/calendar';
import { useDispatch, useSelector } from 'react-redux';
import { auth } from 'src/redux/Authentication/sliceAuthentication';
import {
  convertDateRangesForQuery,
  convertWeekForRequest,
} from 'src/helpers/calendar/calendarRequests';
import { dateRanges } from 'src/helpers/calendar/normalizeTime';

import { useStyles } from './styles';
import { Close } from '@material-ui/icons';
import { MasterOptionFields } from '../CalendarFields/MasterOptionFields';
import { OptionFields } from '../CalendarFields/OptionFields';
import { setNotification } from 'src/redux/Notifications/sliceNotifications';
import { Spiner } from '../../Loaders';
import { BatchEditFields } from '../CalendarFields/BatchEditFields';

type Props = {
  open: boolean;
  onClose: () => void;
  trip: ITripCalendarData;
  type: 'ticket' | 'schedule' | 'option';
  dates: {
    date_from: string | null;
    date_to: string | null;
  }[];
  week: {
    id: number;
    label: string;
    mLabel: string;
    checked: boolean;
  }[];
  optionId: number;
  isBatchEdit: boolean;
};

export const OptionModal = ({
  open,
  onClose,
  trip,
  type,
  dates,
  week,
  optionId,
  isBatchEdit,
}: Props) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { user } = useSelector(auth);
  const [calendarOption, setCalendarOption] = useState<ICalendarOption>({});
  const [batchData, setBatchData] = useState<IBatchData>({} as IBatchData);

  useQuery(GET_CALENDAR, {
    variables: {
      prm: {
        user_id: 2,
        trip_id: trip.id,
        rate_id: 0,
        partner_id: +user['custom:partner_id'] || 0,
        date_ranges: convertDateRangesForQuery(dates),
        days: convertWeekForRequest(week),
        type: type,
        schedules_id: trip.schedules
          ?.filter((schedule) => schedule.checked)
          .map((schedule) => schedule.id)
          .toString(),
        option_id: optionId,
      },
    },
    skip: !open,
    onCompleted: (data) => {
      const calendarData = JSON.parse(
        data.v1_activities_getCalendar.data || '{}'
      );

      setCalendarOption(calendarData);
    },
  });

  const checkedDays = week
    ?.filter((item) => item.checked)
    .map((item) => item.id)
    .join(',');

  const option: ITripAdditionalOptionReceived = trip.additional_options?.find(
    (i) => +i.id === +optionId
  ) as ITripAdditionalOptionReceived;

  const [updateCalendar, { loading: updateCalendarLoading }] = useMutation(
    UPDATE_CALENDAR,
    {
      onCompleted: (data) => {
        if (JSON.parse(data.v1_activities_updateCalendar)?.success) {
          setCalendarOption({});
          onClose();
          dispatch(
            setNotification({
              message: 'The calendar has been updated successfully',
              type: 'success',
            })
          );
        }
      },
      onError: (error) => {
        onClose();
        dispatch(
          setNotification({
            message: error.message,
            type: 'error',
          })
        );
      },
    }
  );

  const onSaveData = () => {
    const prm: Record<string, string | number | number[]> = {
      user_id: +user['custom:partner_id'] || 0,
      trip_id: +trip.id,
      rate_id: 0,
      partner_id: +user['custom:partner_id'] || 0,
      type: type,
      option_id: optionId,
    };

    if (isBatchEdit) {
      prm.batch_data = JSON.stringify(batchData);
      prm.date_from = dates[0].date_from || '';
      prm.date_to = dates[0].date_to || '';
      prm.schedules_ids = trip.schedules
        .filter((schedule) => schedule.checked)
        .map((schedule) => schedule.id);
    } else {
      prm.calendar_days = JSON.stringify([calendarOption]);
    }

    updateCalendar({
      variables: {
        prm,
      },
    });
  };

  return (
    <Dialog open={open} onClose={onClose} className={classes.dialog}>
      {updateCalendarLoading && <Spiner />}
      <DialogTitle>
        <Box>
          <Box
            display="flex"
            flexDirection="row-reverse"
            justifyContent="space-between"
            alignItems="flex-start">
            <IconButton
              onClick={onClose}
              style={{
                padding: '13px',
                background: 'white',
                boxShadow: '0px 3.89312px 23.3587px rgba(189, 189, 189, 0.23)',
              }}>
              <Close />
            </IconButton>
          </Box>
          <Divider style={{ backgroundColor: '#FAFAFA' }} />
        </Box>
      </DialogTitle>
      <DialogContent>
        {isBatchEdit ? (
          <BatchEditFields
            batchData={batchData}
            setBatchData={setBatchData}
            trip={trip}
            type="option"
            dates={dates}
          />
        ) : (
          <Box>
            <MasterOptionFields
              title="Bulk Update (All Schedules)"
              option={option}
              setCalendarOption={setCalendarOption}
            />

            {dates.map((choosenPeriod) => {
              const _dates = dateRanges(
                choosenPeriod.date_from,
                choosenPeriod.date_to
              );

              return _dates.map((date) => {
                if (checkedDays.includes(new Date(date).getDay().toString())) {
                  return (
                    <OptionFields
                      date={date}
                      option={option}
                      calendarOption={calendarOption}
                      setCalendarOption={setCalendarOption}
                    />
                  );
                }
                return <></>;
              });
            })}
          </Box>
        )}
      </DialogContent>
      <DialogActions>
        <Box display="flex" justifyContent="flex-end">
          <Button className={classes.button} onClick={onSaveData} disabled>
            Save
          </Button>
        </Box>
      </DialogActions>
    </Dialog>
  );
};
