import {
  TextField,
  Box,
  FormControlLabel,
  Checkbox,
  Typography,
} from "@material-ui/core";
import React from "react";
import PhoneInput from "src/web/components/UI/PhoneInput";
import PhoneIcon from "src/assets/icons/web/phoneInput.svg";
import { useStyles } from "./styles";
import { RadioGroupElement } from "./elements/RadioGroup";
import { GoogleTitleElement } from "./elements/GoogleTitle";
import { InputError } from "src/web/components/UI/InputError";
import {
  IOnboardingForm,
  IDependenceField,
  IDate,
  IGroupFields,
  ITextfield,
  ITextArea,
  ISelect,
  IFileFields,
  IFieldGroupArray,
} from "./interface";
import { DateFilter } from "src/web/components/UI/DatePickers/DateFilter/DateFilter";
import moment from "moment";
import { StyledDropzone } from "./elements/Dropzone";
import { CustomSelect } from "../UI/CustomSelect";
import CheckboxIcon from "src/assets/icons/web/policyCheckbox.svg";
import CheckboxCheckedIcon from "src/assets/icons/web/policyCheckboxChecked.svg";
import { FormNumberInput } from "./elements/FormNumber";
import { DateRangeFilter } from "../UI/DatePickers/DateFilter/DateRangeFilter";

type Props = {
  item:
    | IFieldGroupArray
    | IGroupFields
    | ITextfield
    | ITextArea
    | ISelect
    | IFileFields
    | IDate;
  errorMessage: Partial<Record<keyof any, string>> | undefined;
  data: any;
  receivedData: any;
  handleChange: any;
  files?: any;
  setFiles?: any;
  locations?: any;
  openDialog?: any;
  other?: boolean;
  setOther?: React.Dispatch<React.SetStateAction<boolean>>;
  handleAccept?: any;
  handleChangeGoogle?: (updateData: any) => void;
  formInput?: any;
  index?: number;
  parentName?: string;
  isArray?: boolean;
};

export const Inputs = ({
  item,
  errorMessage,
  locations,
  openDialog,
  data,
  receivedData,
  handleChange,
  files,
  setFiles,
  other,
  setOther,
  handleAccept,
  handleChangeGoogle,
  formInput,
  index,
  parentName,
  isArray,
}: Props) => {
  const classes = useStyles();

  const removeItem = () => {
    setFiles((prev: any) => []);
  };

  const isActiveByDependence = (dependence: IDependenceField) => {
    const currentData =
      typeof index === "number" && parentName ? data[parentName][index] : data;
    const value = currentData[dependence.dependensOn];

    return value === dependence.value;
  };

  const onChangeDates = (start: string, end: string, item: IDate) => {
    onChangeField(item.dates?.start as string, start);
    onChangeField(item.dates?.end as string, end);
  };

  let itemValue = "";
  if (isArray && parentName && typeof index === "number" && item.name) {
    itemValue = data[parentName][index][item.name];
  } else {
    itemValue = data[item.name as keyof IOnboardingForm] || "";
  }

  const onChangeField = (
    name: string,
    value: string | number | boolean | null
  ) => {
    if (typeof index === "number" && isArray) {
      handleChange(name, value, parentName, index);
    } else {
      handleChange(name, value);
    }
  };

  if (!item.dependence || isActiveByDependence(item.dependence)) {
    if (item.type === "fieldArray") {
      return (
        <Box pt="70px">
          <Typography align="center" variant="h5" className={classes.title}>
            {item.label}
          </Typography>

          <div style={{ display: "flex", justifyContent: "center" }}>
            {data[formInput.name].map((element: any, idx: number) => {
              return (
                <div className={classes.groupFields}>
                  {item.fields.map((field) => {
                    return (
                      <Inputs
                        item={field}
                        errorMessage={errorMessage}
                        locations={locations}
                        openDialog={openDialog}
                        data={data}
                        receivedData={receivedData}
                        handleChange={handleChange}
                        files={files}
                        setFiles={setFiles}
                        other={other}
                        setOther={setOther}
                        handleAccept={handleAccept}
                        handleChangeGoogle={handleChangeGoogle}
                        index={idx}
                        parentName={formInput.name}
                        isArray={true}
                      />
                    );
                  })}
                </div>
              );
            })}
          </div>
        </Box>
      );
    }
    if (item.type === "textfield") {
      if (item.textType === "google") {
        return (
          <GoogleTitleElement
            formInput={item}
            errorMessage={
              (errorMessage &&
                errorMessage[item.name as keyof IOnboardingForm]) ||
              ""
            }
            key={item.name}
            disabled={!!receivedData?.w9}
            locations={locations}
            data={data}
            handleChange={handleChangeGoogle}
          />
        );
      }

      if (item.textType === "phone") {
        return (
          <div className={classes.phoneInput}>
            <PhoneInput
              placeholder={item.placeholder}
              isForm={true}
              key={item.name}
              value={itemValue}
              onChange={(phone) => {
                onChangeField(item.name, phone);
              }}
              preferredCountries={["us", "ca"]}
              label={item.label}
              error={
                errorMessage &&
                !!errorMessage[item.name as keyof IOnboardingForm]
              }
              helperText={
                errorMessage &&
                !!errorMessage[item.name as keyof IOnboardingForm] &&
                errorMessage[item.name as keyof IOnboardingForm]
              }
              autoFocus={true}
              disabled={!!receivedData?.w9}
            />
            <div className={classes.iconPhone}>
              <PhoneIcon />
            </div>
          </div>
        );
      }

      if (item.textType === "number") {
        return (
          <FormNumberInput
            key={item.name}
            formInput={item}
            handleChange={onChangeField}
            errorMessage={
              (errorMessage &&
                errorMessage[item.name as keyof IOnboardingForm]) ||
              ""
            }
            value={itemValue}
          />
        );
      }

      return (
        <div key={item.name}>
          <div className={classes.field}>
            <TextField
              name={item.name}
              fullWidth
              label={item.label}
              className={classes.customInput}
              placeholder={item.placeholder}
              variant="outlined"
              value={itemValue}
              InputLabelProps={{
                shrink: true,
              }}
              onChange={(e) => {
                onChangeField(item.name, e.target.value);
              }}
              error={
                errorMessage &&
                !!errorMessage[item.name as keyof IOnboardingForm]
              }
              disabled={!!receivedData?.w9}
              autoFocus
            />
            {errorMessage &&
              !!errorMessage[item.name as keyof IOnboardingForm] && (
                <InputError
                  message={
                    errorMessage[item.name as keyof IOnboardingForm] as string
                  }
                />
              )}
          </div>
        </div>
      );
    }

    if (item.type === "radio") {
      return (
        <RadioGroupElement
          formInput={item}
          key={item.name}
          value={itemValue}
          errorMessage={
            (errorMessage &&
              errorMessage[item.name as keyof IOnboardingForm]) ||
            ""
          }
          handleChange={onChangeField}
          disabled={
            !!receivedData && !!receivedData[item.name as keyof IOnboardingForm]
          }
          other={other}
          setOther={setOther}
        />
      );
    }

    if (item.type === "date") {
      if (item.isRange) {
        return (
          <Box mt="20px" key={item.name}>
            <DateRangeFilter
              label={item.label}
              startDate={data[item.dates?.start as string]}
              endDate={data[item.dates?.end as string]}
              placeholder={item.placeholder}
              onChange={(dates) => onChangeDates(dates[0], dates[1], item)}
            />
          </Box>
        );
      }
      return (
        <Box mt="20px" key={item.name}>
          <DateFilter
            date={itemValue}
            placeholder={item.placeholder}
            label={item.label}
            onChange={(date) =>
              onChangeField(
                item.name,
                date ? moment(date).format("YYYY-MM-DD") : null
              )
            }
            format={item.format}
            disabled={
              !!receivedData &&
              !!receivedData[item.name as keyof IOnboardingForm]
            }
          />
        </Box>
      );
    }

    if (item.type === "files") {
      return (
        <Box className={classes.dropzoneWrapper} key={item.name}>
          <StyledDropzone
            openDialog={openDialog}
            files={files}
            removeItem={removeItem}
            setFiles={setFiles}
            isDisabled={
              !!receivedData &&
              !!receivedData[item.name as keyof IOnboardingForm]
            }
          />
        </Box>
      );
    }

    if (item.type === "select") {
      const list = item.values.map((item) => {
        return {
          name: item.title,
          id: item.value,
        };
      });

      return (
        <div
          key={item.name}
          style={{ display: "flex", justifyContent: "center" }}
        >
          <Box
            width={typeof index === "number" ? 1 : "65%"}
            className={classes.selectWrapper}
            mt={3}
          >
            <CustomSelect
              label={item.label}
              list={list}
              name={item.name}
              loading={false}
              placeholder={item.label}
              deletable={true}
              onChange={(e: any) => {
                onChangeField(
                  item.name,
                  e
                    ? (list.find((item) => item.name === e.target.textContent)
                        ?.id as string)
                    : null
                );
              }}
              value={list.find((value) => itemValue === value.id)}
              position="end"
            />
            {errorMessage &&
              !!errorMessage[item.name as keyof IOnboardingForm] && (
                <InputError
                  message={
                    errorMessage[item.name as keyof IOnboardingForm] as string
                  }
                />
              )}
          </Box>
        </div>
      );
    }

    if (item.type === "textarea") {
      return (
        <Box mt="20px" key={item.name}>
          <TextField
            name={item.name}
            fullWidth
            label={item.label}
            className={classes.customInput}
            placeholder={item.placeholder}
            variant="outlined"
            value={itemValue || ""}
            InputLabelProps={{
              shrink: true,
            }}
            onChange={(e) => onChangeField(item.name, e.target.value)}
            error={
              errorMessage && !!errorMessage[item.name as keyof IOnboardingForm]
            }
            multiline
            minRows={3}
            autoFocus
            maxRows={6}
          />
          {errorMessage &&
            !!errorMessage[item.name as keyof IOnboardingForm] && (
              <InputError
                message={
                  errorMessage[item.name as keyof IOnboardingForm] as string
                }
              />
            )}
        </Box>
      );
    }

    if (item.type === "checkbox") {
      return (
        <FormControlLabel
          key={item.name}
          value={Boolean(+itemValue)}
          className={classes.checkbox}
          control={
            <Checkbox
              color="default"
              icon={<CheckboxIcon />}
              checkedIcon={<CheckboxCheckedIcon />}
              checked={Boolean(+itemValue)}
              disabled={!!receivedData && !!receivedData[item.name]}
            />
          }
          label={item.label}
          labelPlacement="end"
          onChange={(_, checked) =>
            onChangeField(item.name, checked ? "1" : "0")
          }
        />
      );
    }
  }

  return <></>;
};
