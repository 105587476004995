import { makeStyles, createTheme } from "@material-ui/core/styles";
import { deepOrange } from "@material-ui/core/colors/";

export const useStyles = makeStyles((theme) => ({
  root: {
    background: `#F9FCFD`,
    backgroundPosition: "center",
    backgroundRepeat: "no-repeat",
    backgroundSize: "cover",
    minHeight: "100vh",
    width: "100%",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    flexDirection: "column",
    padding: "24px",
  },
  card: {
    overflow: "visible",
    position: "relative",
    boxShadow: "0px 3.89312px 23.3587px rgba(189, 189, 189, 0.23)",
    borderRadius: "15px",
    maxWidth: "740px",
    height: "580px",
    width: "100%",
    display: "flex",
    "@media (max-width: 620px)": {
      height: "390px",
    },
  },
  logoWrapper: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    flexDirection: "column",
    paddingBottom: "20px",
    "@media (max-width: 620px)": {
      display: "none"
    },
  },
  logoWrapperAdaptive: {
    display: "none",
    "@media (max-width: 620px)": {
      display: "flex",
      justifyContent: "center",
      paddingBottom: "23px",
    },
  },
  subtitle: {
    fontWeight: 400,
    fontFamily: `"Montserrat", "Helvetica", "Arial", sans-serif`,
    fontSize: "18px",
    color: "#6C6B6B",
    marginTop: "5px",
    "@media (max-width: 620px)": {
      display: "none"
    },
  },
  cardContent: {
    padding: "47px 117px 56px 118px !important",
    width: "100%",

    "@media (max-width: 620px)": {
      padding: "30px 18px 35px 18px !important",
    },
  },
}));

export const theme = createTheme({
  palette: {
    primary: {
      main: "#5ACC6A",
      light: "#5EE071",
      dark: "#50BF60",
      contrastText: "#fff",
    },
    secondary: deepOrange,
  },
});
