import React from "react";

import { CompanyContent } from "src/web/components/Settings/Company";
import { IPartner } from "..";

const CompanyPage = ({
  data,
  setData,
}: {
  data: IPartner;
  setData: React.Dispatch<React.SetStateAction<IPartner | null>>;
}) => {
  return <CompanyContent data={data} setData={setData} />;
};

export default CompanyPage;
