import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  forgotPassword,
  resetPasswordEmail,
  IForgotPassword,
} from "src/redux/Authentication/sliceAuthentication";
import { ForgotPasswordForm } from "src/web/features/authentication";
import { Box, Divider, Typography } from "@material-ui/core";
import { LockOpen } from "@material-ui/icons";
import Logo from "src/images/logo.svg";
import { useStyles } from "./styles";
import { useHistory } from "react-router-dom";

const ForgotPassword: React.FC<{}> = () => {
  const classes = useStyles();
  const history = useHistory();
  const currentResetPasswordEmail = useSelector(resetPasswordEmail);
  const dispatch = useDispatch();

  useEffect(() => {
    if (currentResetPasswordEmail) {
      localStorage.setItem('resetPasswordEmail', currentResetPasswordEmail);
      history.push('/confirm-password');
    }
  }, [currentResetPasswordEmail, history]);

  const onSubmit = (data: IForgotPassword, e: any) => {
    e.preventDefault();
    dispatch(forgotPassword(data));
  };

  return (
    <>
      <Box>
        <Typography className={classes.title}>Restore password</Typography>
      </Box>
      <Box>
        <Divider style={{ backgroundColor: "#fafafa" }} />
      </Box>
      <Box mb={3}>
        <Typography className={classes.subTitle}>
          Enter the email address you used to register and we'll send you
          instructions.
        </Typography>
      </Box>

      <ForgotPasswordForm onSubmit={onSubmit} />
    </>
  );
};

export default ForgotPassword;
