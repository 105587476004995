import { makeStyles } from "@material-ui/core/styles";
import { Theme } from "src/web/themes/theme";

export const useStyles = makeStyles((theme: Theme) => ({
  mainBox: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    flexDirection: "column",
  },
}));
