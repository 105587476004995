import { gql } from "@apollo/client";

export const GET_ALL_FORMS = gql`
  query {
    v1_general_listForms {
      items {
        createdAt
        id
        title
        updatedAt
      }
    }
  }
`;

export const GET_FORM = gql`
  query v1_general_getForm($prm: getFormPrm!) {
    v1_general_getForm(prm: $prm) {
      createdAt
      createdUser
      id
      screens
      title
      updatedAt
      updatedUser
    }
  }
`;

export const SEND_FORM = gql`
  mutation v1_general_sendForm($prm: sendFormPrm!) {
    v1_general_sendForm(prm: $prm)
  }
`;
