import React from "react";
import DatePicker from "react-datepicker";
import { FormControl, InputLabel } from "@material-ui/core";
import moment from "moment";
import { IDateFilterProps } from "./interfaces";
import { useStyles } from "./styles";
import "react-datepicker/dist/react-datepicker.css";

export const DateFilter: React.FC<IDateFilterProps> = ({
  date,
  onChange,
  placeholder,
  label,
  format="EEE, MMM d",
  disabled
}) => {
  const classes = useStyles();

  return (
    <FormControl variant="outlined" fullWidth className={classes.datePickerWrapper}>
      <InputLabel className={classes.datePickerLabel} shrink>
        {label}
      </InputLabel>
      <DatePicker
        selected={
          date
            ? new Date(moment(date).toISOString())
            : null
        }
        placeholderText={placeholder}
        onChange={onChange}
        dateFormat={format}
        disabledKeyboardNavigation
        className={classes.datePicker}
        isClearable={!disabled}
        disabled={disabled}
      />
    </FormControl>
  );
};
