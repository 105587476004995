import clsx from 'clsx';
import React from 'react';
import {
  Box,
  Card,
  Typography,
  FormControlLabel,
  Checkbox,
  CardContent,
  TextField,
  useMediaQuery,
} from '@material-ui/core';
import { parseNumberInputValue } from 'src/helpers/calendar/parseNumberInput';
import { useStyles } from './styles';
import CheckboxIcon from 'src/assets/icons/web/checkbox.svg';
import CheckboxCheckedIcon from 'src/assets/icons/web/checkboxChecked.svg';

export const TicketFields = ({
  date,
  subTitle,
  ticket,
  scheduleId,
  withRates,
  calendarTicket,
  setCalendarTicket,
}: {
  date: string;
  subTitle?: string;
  ticket?: any;
  calendarTicket: ICalendarTicket;
  scheduleId: number;
  withRates: boolean;
  setCalendarTicket: (value: React.SetStateAction<ICalendarTicket>) => void;
}) => {
  const classes = useStyles();
  const isMobile = useMediaQuery('(max-width: 960px)');
  const calendarData = calendarTicket?.[scheduleId]?.[date] || {};

  const onChangeField = (field: keyof typeof calendarData, value: number) => {
    setCalendarTicket((prev) => ({
      ...prev,
      [scheduleId]: {
        ...prev[scheduleId],
        [date]: {
          ...prev[scheduleId][date],
          [field]: value,
        },
      },
    }));
  };

  return (
    <Card className={clsx(classes.fieldsCard, classes.inputWrapper)}>
      <CardContent style={{ padding: 0 }}>
        <Box>
          <div style={{ display: 'flex' }}>
            <Typography>{date}</Typography>
            {subTitle && (
              <Typography style={{ marginRight: '20px' }}>
                {subTitle}
              </Typography>
            )}
            <Typography>{ticket}</Typography>
          </div>
          {!withRates && (
            <div>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={Boolean(+calendarData.is_disabled)}
                    onChange={(_, checked) =>
                      onChangeField('is_disabled', Number(checked))
                    }
                    icon={<CheckboxIcon />}
                    checkedIcon={<CheckboxCheckedIcon />}
                    color="primary"
                  />
                }
                label="Stop Sell"
                labelPlacement="start"
              />
            </div>
          )}
        </Box>
        <Box width={1} display="flex" flexWrap="wrap">
          <Box width={isMobile ? 1 : 1 / 6} p={3}>
            <TextField
              type="number"
              value={parseNumberInputValue(calendarData.price)}
              onChange={(e) => onChangeField('price', Number(e.target.value))}
              variant="outlined"
              size="small"
              InputLabelProps={{ shrink: true }}
              label="Rate"
            />
          </Box>
          <Box width={isMobile ? 1 : 1 / 6} p={3}>
            <TextField
              type="number"
              value={parseNumberInputValue(calendarData.strike_out)}
              onChange={(e) =>
                onChangeField('strike_out', Number(e.target.value))
              }
              variant="outlined"
              size="small"
              InputLabelProps={{ shrink: true }}
              label="Strikeout"
            />
          </Box>

          {!withRates && (
            <>
              <Box width={isMobile ? 1 : 1 / 6} p={3}>
                <TextField
                  type="number"
                  value={parseNumberInputValue(calendarData.min)}
                  onChange={(e) => onChangeField('min', Number(e.target.value))}
                  variant="outlined"
                  size="small"
                  InputLabelProps={{ shrink: true }}
                  label="Min"
                />
              </Box>
              <Box width={isMobile ? 1 : 1 / 6} p={3}>
                <TextField
                  type="number"
                  value={parseNumberInputValue(calendarData.total)}
                  onChange={(e) =>
                    onChangeField('total', Number(e.target.value))
                  }
                  variant="outlined"
                  size="small"
                  InputLabelProps={{ shrink: true }}
                  label="Total"
                />
              </Box>
            </>
          )}
        </Box>
      </CardContent>
    </Card>
  );
};
