import React from "react";
import { Box, Typography } from "@material-ui/core";
import CompleteRegistrationIcon from "src/assets/icons/web/completeRegistration.svg";
import CompleteRegistrationAdaptiveIcon from "src/assets/icons/web/completeRegistrationAdaptive.svg";
import { useStyles } from "./styles";

const CompleteRegistration: React.FC<{}> = () => {
  const classes = useStyles();

  return (
    <div style={{ textAlign: "center", marginTop: "5px" }}>
      <div className={classes.dekstop}>
        <CompleteRegistrationIcon />
      </div>
      <div className={classes.adaptive}>
        <CompleteRegistrationAdaptiveIcon />
      </div>
      <Box mt={3.5}>
        <Typography align="center" variant="h5" className={classes.description}>
          Thank you! We will be getting back to you as soon as possible. <br />
          Be on the lookout for an email from us.
        </Typography>
      </Box>
    </div>
  );
};

export default CompleteRegistration;
