import React, { useContext } from "react";
import clsx from "clsx";

import {
  Box,
  Card,
  Typography,
  FormControlLabel,
  Checkbox,
  CardContent,
  TextField,
  Table,
  TableCell,
  TableHead,
  TableRow,
  TableBody,
  useMediaQuery,
  TableContainer,
} from "@material-ui/core";
import { useStyles } from "./styles";
import { parseNumberInputValue } from "src/helpers/calendar/parseNumberInput";
import CheckboxIcon from "src/assets/icons/web/checkbox.svg";
import CheckboxCheckedIcon from "src/assets/icons/web/checkboxChecked.svg";

interface Column {
  field: string;
  headerName: string;
  width?: string;
  align?: "right" | "left" | "center";
  format?: (value: number) => string;
}

const columns: Column[] = [
  { field: "value", headerName: "Value Name", align: "right", width: "30%" },
  {
    field: "price",
    headerName: "Price",
    align: "center",
    width: "20%",
  },
  {
    field: "disable",
    headerName: "Disable",
    align: "center",
    width: "25%",
  },
  {
    field: "tax",
    headerName: "Apply Tax",
    align: "center",
    width: "25%",
  },
];

export const OptionFields = ({
  date,
  option,
  calendarOption,
  setCalendarOption,
}: {
  date: string;
  option?: ITripAdditionalOptionReceived;
  calendarOption: ICalendarOption;
  setCalendarOption: (value: React.SetStateAction<ICalendarOption>) => void;
}) => {
  const classes = useStyles();
  const isMobile = useMediaQuery("(max-width: 960px)");

  const optionState = calendarOption[date];

  const onChangeOptionField = (
    field: keyof Omit<ICalendarOption, "values">,
    value: string | number
  ) => {
    setCalendarOption((prev) => ({
      ...prev,
      [date]: { ...prev[date], [field]: value },
    }));
  };

  const onChangeValueField = (
    valueIndex: number,
    field: keyof ICalendarOptionValue,
    value: number
  ) => {
    const newValues = structuredClone(option?.values);

    if (!newValues || !newValues.length) {
      return;
    }

    (newValues[valueIndex][field] as any) = value;

    // @ts-ignore
    setCalendarOption((prev) => ({
      ...prev,
      [date]: {
        ...prev[date],
        values: newValues,
      },
    }));
  };

  return (
    <Card className={clsx(classes.fieldsCard, classes.inputWrapper)}>
      <CardContent style={{ padding: 0 }}>
        <Box>
          <div style={{ display: "flex" }}>
            <Typography>{date}</Typography>
            <Typography>{option?.name}</Typography>
          </div>
          <Box display="flex" alignItems="center" justifyContent="flex-end">
            <FormControlLabel
              value="disable"
              control={
                <Checkbox
                  checked={Boolean(optionState?.option_is_disabled)}
                  icon={<CheckboxIcon />}
                  checkedIcon={<CheckboxCheckedIcon />}
                />
              }
              label="Disable Option"
              labelPlacement="start"
              onChange={(_, checked) =>
                onChangeOptionField("option_is_disabled", Number(checked))
              }
            />
          </Box>
        </Box>
        <TableContainer>
        <Table>
          <TableHead>
            <TableRow>
              {columns.map((column) => (
                <TableCell
                  align={column.align}
                  key={column.field}
                  style={{ width: column.width, fontSize: "16px" }}
                >
                  {column.headerName}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {optionState?.values?.map((row, index) => {
              return (
                <TableRow
                  hover
                  role="checkbox"
                  tabIndex={-1}
                  key={row.id + index}
                >
                  <TableCell style={{ padding: "6px 16px" }} align="right">
                    {option?.values[index].name}
                  </TableCell>
                  <TableCell style={{ padding: "6px 16px" }} align="center">
                    <Box p={3}>
                      <TextField
                        type="number"
                        size="small"
                        variant="outlined"
                        value={parseNumberInputValue(row.price)}
                        onChange={(e) =>
                          onChangeValueField(
                            index,
                            "price",
                            Number(e.target.value)
                          )
                        }
                        style={{ padding: "0 20px", minWidth: "150px" }}
                      />
                    </Box>
                  </TableCell>
                  <TableCell style={{ padding: "6px 16px" }} align="center">
                    <Box>
                      <Checkbox
                        color="primary"
                        checked={Boolean(Number(row.is_disabled))}
                        onChange={(_, checked) =>
                          onChangeValueField(
                            index,
                            "is_disabled",
                            Number(checked)
                          )
                        }
                        icon={<CheckboxIcon />}
                        checkedIcon={<CheckboxCheckedIcon />}
                      />
                    </Box>
                  </TableCell>
                  <TableCell style={{ padding: "6px 16px" }} align="center">
                    <Box>
                      <Checkbox
                        color="primary"
                        checked={Boolean(Number(row.apply_tax))}
                        onChange={(_, checked) =>
                          onChangeValueField(
                            index,
                            "apply_tax",
                            Number(checked)
                          )
                        }
                        icon={<CheckboxIcon />}
                        checkedIcon={<CheckboxCheckedIcon />}
                      />
                    </Box>
                  </TableCell>
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
        </TableContainer>
      </CardContent>
    </Card>
  );
};
