import { makeStyles } from '@material-ui/core/styles';
import { Theme } from 'src/web/themes/theme';

export const useStyles = makeStyles((theme: Theme) => ({
  root: {
    position: 'relative',
    backgroundColor: theme.palette.background.default,
  },
  topBg: {
    position: 'absolute',
    left: 0,
    right: 0,
    top: 0,
    backgroundColor: '#2E3035',
    pointerEvents: 'none',
    height: theme.trip_shock_theme.environment.topBgHeight,
  },
  pageWrapper: {
    width: '100%',
    display: 'flex',
    height: `calc(100vh - ${theme.trip_shock_theme.environment.toolbarHeight}px)`,
  },
  contentWrapper: {
    maxWidth: '100%',
    position: 'relative',
    display: 'flex',
    flex: '1',
    flexDirection: 'column',
    padding: '0 20px',
    [theme.breakpoints.down('sm')]: {
      padding: '0 1rem',
    },
  },
  header: {
    position: 'relative',
    height: theme.trip_shock_theme.environment.headerHeight,
    zIndex: 101,
    display: 'flex',
    alignItems: 'center',
    color: theme.palette.primary.contrastText,
  },
  content: {
    display: 'flex',
    flexDirection: 'row',
    width: '100%',
    height: `calc(100vh - ${theme.trip_shock_theme.environment.toolbarHeight}px)`,
  },
  contentRoot: {
    flex: '1 0 auto',
    backgroundColor: '#fff',
    width: 'calc(100% - 170px)',
    boxShadow: '0px 3.89312px 23.3587px rgba(96, 96, 97, 0.18)',
    borderRadius: '15px',
    overflow: 'hidden',
    marginLeft: 'auto',
    height: 'calc(100vh + 50px)',
    '@media (max-width: 750px)': {
      width: '100%',
    },
  },
  menu: {
    width: '170px',
    marginTop: '100px',
  },
  menuItemTitle: {
    fontSize: '12px',
    fontWeight: 500,
    color: '#6C6B6B',
    marginLeft: '5px',
  },
  menuItemExternalLink: {
    fontSize: '12px',
    color: '#6C6B6B',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    padding: '0 24px 0 0',
    marginBottom: '8px',
  },
  menuItemWrapper: {
    display: 'flex',
    width: '140px',
    cursor: 'pointer',
    flexDirection: 'row',
    alignItems: 'center',
    padding: '10px',
    borderRadius: '15px',

    '& svg': {
      fill: '#6C6B6B',
      width: '16px',
      height: '16px',
    },

    '.mobileMenu &': {
      width: '100%',
    },

    '.active &': {
      backgroundColor: '#ecfbfa',
      '& svg': {
        fill: '#0DD1C5',
      },
    },
  },
  menuItemDropdownWrapper: {
    '.dropdownActive &': {
      //backgroundColor: '#ecfbfa',
    },
  },
  dropdownArrow: {
    display: 'flex',
    alignItems: 'center',
    marginLeft: 'auto',
  },

  mobileMenuWrapper: {
    '& .MuiPaper-root': {
      padding: '50px',
      paddingTop: '0px',
      borderRadius: '15px',
    },

    '& .MuiDialogTitle-root': {
      padding: '0 0 10px 0',
      border: '1px solid #FAFAFA',
    },

    '& .MuiDialogContent-root': {
      padding: '33px 0 60px 0',

      '& .mobileMenu': {
        marginTop: 0,
        width: '100%',
      },
    },
  },
  mobileMenuWrapperHeader: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'flex-start',
  },
  mobileMenuTitle: {
    fontSize: '14px',
    fontWeight: 500,
    paddingTop: '34px',
    color: '#202020',
  },
  closeIcon: {
    position: 'absolute',
    top: '10px',
    right: '14px',

    '&:hover': {
      background: 'none',
      border: '1px solid #FAFAFA',
      padding: '11px',
    },
  },
}));
