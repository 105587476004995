import {
  IFilter,
  ISorting,
  IDetailedFilters,
} from 'src/web/features/table/interfaces';

export const filters: IFilter[] = [
  {
    label: 'Status',
    field: 'state_name',
    operator: 'in',
    multiselect: true,
    valueType: 'JSON',
    values: [
      {
        value: 'confirmed',
        label: 'Confirmed',
        isSelected: false,
      },
      {
        value: 'unconfirmed',
        label: 'Unconfirmed',
        isSelected: false,
      },
      {
        value: 'checked_in',
        label: 'Checked in',
        isSelected: false,
      },
      {
        value: 'completed',
        label: 'Completed',
        isSelected: false,
      },
      {
        value: 'paid',
        label: 'Paid',
        isSelected: false,
      },
      {
        value: 'no_show',
        label: 'No show',
        isSelected: false,
      },
      {
        value: 'cancelled',
        label: 'Cancelled',
        isSelected: false,
      },
    ],
  },
];

export const sorting: ISorting[] = [
  {
    id: 'id',
    label: 'Confirmation',
    sortable: true,
    active: false,
    order: undefined,
    width: '150px',
    align: 'left',
  },
  {
    id: 'date',
    label: 'Arrival Date',
    sortable: true,
    active: false,
    order: undefined,
    width: '140px',
    align: 'left',
  },
  {
    id: 'trip_name',
    label: 'Trip name',
    sortable: false,
    active: false,
    order: undefined,
    width: '240px',
    align: 'left',
  },
  {
    id: 'customer',
    label: 'Customer',
    sortable: false,
    active: false,
    order: undefined,
    width: '150px',
    align: 'left',
  },
  {
    id: 'gross',
    label: 'Net',
    sortable: false,
    active: false,
    order: undefined,
    width: '100px',
    align: 'left',
  },
  {
    id: 'state_name',
    label: 'Status',
    sortable: false,
    active: false,
    order: undefined,
    width: '100px',
    align: 'left',
  },
];

export const detailedFilters: IDetailedFilters = {
  id: {
    field: 'id',
    operator: 'eq',
    value: null,
    valueType: 'Number',
  },
  voucher: {
    field: 'cart_item_id',
    operator: 'eq',
    value: null,
    valueType: 'Number',
  },
  dateFrom: {
    field: 'date',
    operator: 'ge',
    value: null,
  },
  dateTo: {
    field: 'date',
    operator: 'le',
    value: null,
  },
  madeFrom: {
    field: 'made',
    operator: 'ge',
    value: null,
  },
  madeTo: {
    field: 'made',
    operator: 'le',
    value: null,
  },
  product_id: {
    field: 'product_id',
    operator: 'eq',
    value: null,
    valueType: 'Number',
  },
  city_id: {
    field: 'city_id',
    operator: 'eq',
    value: null,
    valueType: 'Number',
  },
  region_id: {
    field: 'region_id',
    operator: 'eq',
    value: null,
    valueType: 'Number',
  },
  state_name: {
    field: 'state_name',
    operator: 'in',
    value: null,
    valueType: 'JSON',
  },
};
