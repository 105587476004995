import { makeStyles } from '@material-ui/core/styles';
import { Theme } from 'src/web/themes/theme';

export const useStyles = makeStyles((theme: Theme) => ({
  root: {
    color: theme.trip_shock_theme.environment.trip_shock_white,
  },
  linkHeader: {
    margin: '4px 0 16px 0',
    fontSize: '14px',
    fontWeight: 500,
    padding: '0 4px',
    overflow: 'hidden',
    whiteSpace: 'nowrap',
  },
  linkWrapper: {
    display: 'block',
    textDecoration: 'none',
    borderRadius: '10px',
    color: theme.trip_shock_theme.environment.trip_shock_light_grey,
    '& svg': {
      fill: theme.trip_shock_theme.environment.trip_shock_light_grey,
      width: '18px',
      height: '18px',
    },
    '&:hover': {
      color: theme.trip_shock_theme.environment.trip_shock_green_2,
      '& svg': {
        fill: theme.trip_shock_theme.environment.trip_shock_green_2,
      },
    },
    '&.active': {
      backgroundColor: theme.trip_shock_theme.environment.trip_shock_dark,
      color: theme.trip_shock_theme.environment.trip_shock_green_2,
      '& svg': {
        fill: theme.trip_shock_theme.environment.trip_shock_green_2,
      },
    },
  },
  link: {
    height: '48px',
    display: 'flex',
    padding: '8px 25px',
    borderRadius: '10px',
  },
  externalLink: {
    height: '36px',
    display: 'flex',
    borderRadius: '10px',
  },
  linkTitle: {
    width: '100%',
    overflow: 'hidden',
    fontSize: '14px',
    fontWeight: 400,
    whiteSpace: 'nowrap',
    marginLeft: '10px',
  },
  linkIcon: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  sidebarDevider: {
    width: '35px',
    height: '20px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  divider: {
    backgroundColor: theme.trip_shock_theme.environment.trip_shock_gray,
    margin: '4px 0 24px 0',
    opacity: '0.2',
  },
  wrButton: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    margin: '0 auto',
    color: theme.trip_shock_theme.environment.trip_shock_light_grey,
    border: `1px solid ${theme.trip_shock_theme.environment.trip_shock_light_grey}`,
    borderRadius: '8px',
    width: '180px',
    height: '40px',
    textDecoration: 'none',
  },
}));
