import moment from 'moment-timezone';

export const localizedTime = (
  date: string | null | Date | number | undefined,
  format: string = 'MM/DD/YYYY hh:mma'
) => {
  if (!date) {
    return '-';
  }

  return moment.utc(date).tz('America/Chicago').format(format);
};
