import React from 'react';
import { Box, Tooltip, Typography } from '@material-ui/core';

import { useStyles } from './styles';

export type ActionButton = {
  name: string;
  onClick: () => any;
  icon: JSX.Element;
};

type Props = {
  title: string;
  buttons?: ActionButton[];
};

export const PageContentHeader = ({ title, buttons }: Props) => {
  const classes = useStyles();

  return (
    <Box className={classes.root}>
      <Typography className={classes.title}>{title}</Typography>
      <Box className={classes.actions}>
        {buttons?.map((button) => (
          <Tooltip
            key={button.name}
            title={button.name}
            onClick={button.onClick}>
            <Box className={classes.action}>{button.icon}</Box>
          </Tooltip>
        ))}
      </Box>
    </Box>
  );
};
