import React, { FC, ReactElement, Suspense } from 'react';
import { Spiner } from 'src/web/components/Loaders';
import { useStyles } from './styles';
import { Header } from './Header';

export const OnboardingLayout: FC<{ children: ReactElement }> = ({ children }) => {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <Suspense fallback={<Spiner fixed />}>
        <Header />
        <div className={classes.content_container}>{children}</div>
      </Suspense>
    </div>
  );
};
