import React, { useState } from 'react';
import {
  Box,
  Button,
  InputAdornment,
  TextField,
  Typography,
  useMediaQuery,
} from '@material-ui/core';
import { PageContentHeader } from 'src/web/components/PageContentHeader';
import { useSelector } from 'react-redux';
import BigAvatarIcon from 'src/assets/icons/web/bigAvatar.svg';
import { useStyles } from './styles';
import { GetPhoneFlagIcon } from 'src/web/helpers/GetPhoneFlagIcon';
import { ChangePasswordModal } from './ChangePasswordModal';
import { auth } from 'src/redux/Authentication/sliceAuthentication';

export const ProfileContent = () => {
  const classes = useStyles();
  const { user } = useSelector(auth);
  const [openChangePassword, setOpenChangePassword] = useState(false);
  const mdMatches = useMediaQuery('(max-width: 1020px)');

  const correctPhoneNumber = '+' + user.phone_number.replace(/\+/g, '');

  return (
    <Box pl="30px" pr="30px">
      <PageContentHeader title="User info" />
      <ChangePasswordModal
        open={openChangePassword}
        setOpen={setOpenChangePassword}
      />

      <Box className={classes.contentWrapper}>
        <Box width={mdMatches ? 1 : 2 / 6}>
          <Box className={classes.mainInfoWrapper}>
            <Box className={classes.avatarWrapper}>
              <BigAvatarIcon />
            </Box>
            <Typography className={classes.profileName}>
              {user.given_name} {user.family_name}
            </Typography>
            <Button
              className={classes.changePasswordButton}
              onClick={() => setOpenChangePassword(true)}>
              Change password
            </Button>
          </Box>
        </Box>
        <Box width={mdMatches ? 1 : 4 / 6}>
          <Box className={classes.secondaryInfoWrapper}>
            <Box width={1} mb="12px">
              <TextField
                className={classes.input}
                disabled
                label="Userame"
                value={user.preferred_username || user['cognito:username']}
              />
            </Box>
            <Box width={1} mb="12px">
              <TextField
                className={classes.input}
                disabled
                label="Email"
                value={user.email}
              />
            </Box>
            <Box width={1} mb="12px">
              <TextField
                className={classes.input}
                disabled
                label="Phone number"
                value={correctPhoneNumber}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      {GetPhoneFlagIcon(correctPhoneNumber)}
                    </InputAdornment>
                  ),
                }}
              />
            </Box>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};
