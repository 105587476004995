import React from "react";
import { Box, Checkbox, FormControlLabel, Typography } from "@material-ui/core";
import { useStyles } from "../styles";
import CheckboxIcon from "src/assets/icons/web/policyCheckbox.svg";
import CheckboxCheckedIcon from "src/assets/icons/web/policyCheckboxChecked.svg";
import PerfectScrollbar from "react-perfect-scrollbar";
import { IAcceptanceBlock } from "../interface";

export const AcceptForm = ({ receivedData, data, form, handleAccept }: any) => {
  const classes = useStyles();
  const content = form.screens[0].content as IAcceptanceBlock;
  const name = content.field.name;

  return (
    <>
      <Box mb={2}>
        <Typography align="center" variant="h5" className={classes.title}>
          {form.screens[0].title}
        </Typography>
      </Box>
      <Box mb={2} className={classes.textBox}>
        <PerfectScrollbar>
          <Box pr={3}>
            <Typography
              align="center"
              variant="subtitle1"
              style={{whiteSpace: "pre-wrap"}}
              className={classes.content}
            >
              {content.markdown}
            </Typography>
          </Box>
        </PerfectScrollbar>
      </Box>
      <Box>
        <Typography
          align="center"
          variant="subtitle1"
          style={{ whiteSpace: "pre-wrap", fontSize: "10px" }}
          className={classes.description}
        >
          {form.screens[0].description}
        </Typography>
      </Box>
      <Box width={1} px={1} mt={1} mb={1} className={classes.acceptCheckbox}>
        {content.field.values.map((item, index) => {
          return (
            <FormControlLabel
              key={index}
              value={!!data[name]}
              className={classes.checkbox}
              control={
                <Checkbox
                  color="default"
                  icon={<CheckboxIcon />}
                  checkedIcon={<CheckboxCheckedIcon />}
                  checked={!!data[name]}
                  disabled={!!receivedData && !!receivedData[name]}
                />
              }
              label={item.label}
              labelPlacement="end"
              onChange={() => handleAccept(name)}
            />
          );
        })}
      </Box>
    </>
  );
};
