import { isOwner, isUserHasPermissions } from './permissions';

type Limitation = Record<Group, string[]>;

type PermissionCategory = 'routes' | 'tabs' | 'settings_tabs';

const alwaysAvailabeRoutes = ['Home'];
const alwaysAvailabeTabs = ['More'];
const alwaysAvailabeSettingsTabs: string[] = [];

export const limitationRoutes: Limitation = {
  newPartners: [
    'ArrivalsDetailScreen',
    'RecentBookings',
    'Payouts',
    'Reviews',
    'ChangePassword',
    'PayoutsDetail',
    'Calendar Management',
    'Payments',
    'Arrivals',
    'Dashboard',
    'Profile',
    'Settings',
  ],
  owners: ['Onboarding'],
  employees: ['Onboarding'],
};

export const limitationTabs: Limitation = {
  newPartners: ['Dashboard', 'Arrivals', 'Calendar'],
  owners: ['Onboarding'],
  employees: ['Onboarding'],
};

export const limitationSettingsTabs: Limitation = {
  newPartners: ['Notifications', 'Users', 'Groups'],
  owners: [],
  employees: [],
};

const isHasRoutePermissions = (
  user: any,
  limitation: Limitation,
  name: string
) => {
  const groups: Group[] = user?.['cognito:groups'] || ['employees'];

  if (!groups.length) {
    return true;
  }

  return groups.some((group) => !limitation[group].includes(name));
};

export const checkIsHasAccessToRoute = (
  user: any | null,
  name: string,
  type: PermissionCategory
) => {
  if (isOwner(user) && name !== 'Onboarding') {
    return true;
  }

  let isHasAccessByGroup = false;
  let isHasAccessByPermissions = true;

  if (type === 'routes') {
    if (alwaysAvailabeRoutes.includes(name)) {
      return true;
    }

    isHasAccessByGroup = isHasRoutePermissions(user, limitationRoutes, name);

    if (!user?.['cognito:groups']) {
      isHasAccessByPermissions = isUserHasPermissions(
        user,
        [name],
        true
      );
    }

    return isHasAccessByGroup && isHasAccessByPermissions;
  }

  if (type === 'tabs') {
    if (alwaysAvailabeTabs.includes(name)) {
      return true;
    }

    isHasAccessByGroup = isHasRoutePermissions(user, limitationTabs, name);

    if (!user?.['cognito:groups']) {
      isHasAccessByPermissions = isUserHasPermissions(
        user,
        [name],
        true
      );
    }

    return isHasAccessByGroup && isHasAccessByPermissions;
  }

  if (type === 'settings_tabs') {
    if (alwaysAvailabeSettingsTabs.includes(name)) {
      return true;
    }

    isHasAccessByGroup = isHasRoutePermissions(
      user,
      limitationSettingsTabs,
      name
    );

    if (!user?.['cognito:groups']) {
      isHasAccessByPermissions = isUserHasPermissions(
        user,
        [name],
        true
      );
    }

    return isHasAccessByGroup && isHasAccessByPermissions;
  }

  return false;
};
