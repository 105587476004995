import { IOnboardingForm, IOnboardingScreen } from "./interface";

const ageArray = Array.from(Array(100).keys()).map((item) => {
  return {
    title: String(item),
    value: String(item),
  };
});

export const screens: IOnboardingScreen[] = [
  {
    order: 0,
    title: "Tickets",
    name: "tickets",
    content: {
      fields: [
        {
          type: "fieldArray",
          label: "Tickets",
          name: "tickets",
          fields: [
            {
              label: "Default",
              name: "is_default",
              type: "checkbox",
              values: [{ label: "", value: "1" }],
              validation: {
                messages: {
                  default: "Please fill this in",
                },
                required: true,
                type: "empty",
              },
            },
            {
              label: "Name",
              name: "name",
              type: "textfield",
              placeholder: "Choose ticket",
              validation: {
                messages: {
                  default: "Please fill this in",
                },
                required: true,
                type: "empty",
              },
            },
            {
              label: "Price",
              name: "price",
              textType: "number",
              type: "textfield",
              placeholder: "price",
              validation: {
                messages: {
                  default: "Please fill this in",
                },
                required: true,
                type: "empty",
              },
            },
            {
              label: "Strikeout",
              name: "strike_out",
              textType: "number",
              type: "textfield",
              placeholder: "strikeout",
              validation: {
                messages: {
                  default: "Please fill this in",
                },
                required: false,
                type: "empty",
              },
            },
            {
              label: "Wholesale",
              name: "wholesale",
              textType: "number",
              type: "textfield",
              placeholder: "wholesale",
              validation: {
                messages: {
                  default: "Please fill this in",
                },
                required: false,
                type: "empty",
              },
            },
            {
              label: "Total",
              name: "total_ts",
              textType: "number",
              type: "textfield",
              placeholder: "total",
              validation: {
                messages: {
                  default: "Please fill this in",
                },
                required: false,
                type: "empty",
              },
            },
            {
              label: "Min Tickets",
              name: "min",
              textType: "number",
              type: "textfield",
              placeholder: "min tickets",
              validation: {
                messages: {
                  default: "Please fill this in",
                },
                required: true,
                type: "empty",
              },
            },
            {
              label: "Duration",
              name: "duration",
              textType: "number",
              type: "textfield",
              placeholder: "duration",
              validation: {
                messages: {
                  default: "Please fill this in",
                },
                required: false,
                type: "empty",
              },
            },
            {
              label: "Child",
              name: "is_child",
              type: "select",
              values: [
                { title: "True", value: "1", useCustom: false },
                { title: "False", value: "0", useCustom: false },
              ],
              validation: {
                messages: {
                  default: "Please fill this in",
                },
                required: false,
                type: "empty",
              },
            },
            {
              label: "Disabled",
              name: "is_disabled_ts",
              type: "select",
              values: [
                { title: "True", value: "1", useCustom: false },
                { title: "False", value: "0", useCustom: false },
              ],
              validation: {
                messages: {
                  default: "Please fill this in",
                },
                required: false,
                type: "empty",
              },
            },
            {
              label: "Description",
              name: "description",
              type: "textarea",
              placeholder: "description",
              validation: {
                messages: {
                  default: "Please fill this in",
                },
                required: false,
                type: "empty",
              },
            },
            {
              label: "Use age range",
              name: "use_age_range",
              type: "checkbox",
              values: [
                {
                  label: "",
                  value: "1",
                },
              ],
              validation: {
                messages: {
                  default: "Please fill this in",
                },
                required: false,
                type: "empty",
              },
            },
            {
              label: "Minimum Age",
              name: "age_from",
              type: "select",
              values: ageArray,
              validation: {
                messages: {
                  default: "Please fill this in",
                },
                required: false,
                type: "empty",
              },
              dependence: {
                dependensOn: "use_age_range",
                value: "1",
              },
            },
            {
              label: "Maximum Age",
              name: "age_to",
              type: "select",
              values: ageArray,
              validation: {
                messages: {
                  default: "Please fill this in",
                },
                required: false,
                type: "empty",
              },
              dependence: {
                dependensOn: "use_age_range",
                value: "1",
              },
            },
            {
              label: "Use barcodes",
              name: "use_barcodes",
              type: "checkbox",
              values: [
                {
                  label: "",
                  value: "1",
                },
              ],
              validation: {
                messages: {
                  default: "Please fill this in",
                },
                required: false,
                type: "empty",
              },
            },
            {
              label: "Total",
              name: "barcodes_total",
              textType: "number",
              type: "textfield",
              placeholder: "barcodes_total",
              validation: {
                messages: {
                  default: "Please fill this in",
                },
                required: false,
                type: "empty",
              },
              dependence: {
                dependensOn: "use_barcodes",
                value: "1",
              },
            },
            {
              label: "Remaining",
              name: "barcodes_remaining",
              textType: "number",
              type: "textfield",
              placeholder: "barcodes_remaining",
              validation: {
                messages: {
                  default: "Please fill this in",
                },
                required: false,
                type: "empty",
              },
              dependence: {
                dependensOn: "use_barcodes",
                value: "1",
              },
            },
          ],
        },
      ],
    },
  },
  {
    order: 1,
    title: "Additional options",
    name: "additional_options",
    content: {
      fields: [
        {
          type: "fieldArray",
          label: "Additional options",
          name: "additional_options",
          fields: [
            {
              label: "Name",
              name: "name",
              placeholder: "name",
              type: "textfield",
              validation: {
                messages: {
                  default: "Please fill this in",
                },
                required: true,
                type: "empty",
              },
            },
            {
              label: "Type",
              name: "type",
              values: [
                {
                  title: "Select",
                  value: "select",
                },
                {
                  title: "Text",
                  value: "text",
                },
              ],
              type: "select",
              validation: {
                messages: {
                  default: "Please fill this in",
                },
                required: true,
                type: "empty",
              },
            },
          ],
        },
      ],
    },
  },
];

export const testForm: IOnboardingForm = {
  title: "test form",
  type: "multistep",
  screens: screens,
};
