export const LOGIN_PATH = '/login';
export const FORGOT_PASSWORD_PATH = '/forgot-password';
export const REGISTRATION_PATH = '/registration';
export const CONFIRM_PASSWORD_PATH = '/confirm-password';
export const UNAUTHORIZED_PATH = '/401';
export const INTERNAL_SERVER_PATH = '/500';
export const NOT_ACCESS_PATH = '/403';
export const NOT_FOUND_PATH = '*';

export const ARRIVALS_PATH = '/arrivals';
export const ARRIVALS_DETAIL_PATH = '/arrivals/:id';
export const DASHBOARD_PATH = '/';
export const REVIEWS_PATH = '/reviews';
export const PAYOUTS_PATH = '/payments';
export const PAYOUTS_DETAIL_PATH = '/payouts/:id';
export const CALENDAR_MANAGEMENT_PATH = '/calendar_management';

export const NOTIFICATIONS_PATH = '/notifications';
export const SETTINGS_PATH = '/settings/:page';

export const ONBOARDING_PATH = '/onboarding';
export const FIELDS_GROUP_PATH = '/group_fields';

// export const PROFILE_PATH = '/settings/profile';
// export const COMPANY_PATH = '/settings/company';
// export const USERS_PATH = '/settings/users';
// export const GROUPS_PATH = '/settings/groups';
