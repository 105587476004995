import config from './config';

let awsConfig = {
  Auth: {
    region: 'us-east-1',
    userPoolId: config.userPoolId,
    userPoolWebClientId: config.userPoolWebClientId,
  },
};

export default awsConfig;
