import { makeStyles } from "@material-ui/core/styles";
import { Theme } from "src/web/themes/theme";

export const useStyles = makeStyles((theme: Theme) => ({
  root: {
    position: "relative",
    backgroundColor: theme.palette.background.default,
  },
  topBg: {
    position: "absolute",
    left: 0,
    right: 0,
    top: 0,
    height: theme.trip_shock_theme.environment.topBgHeight,
    backgroundColor: "#2E3035",
    pointerEvents: "none",
  },
  pageWrapper: {
    width: "100%",
    display: "flex",
    height: `calc(100vh - ${theme.trip_shock_theme.environment.toolbarHeight}px)`,
  },
  contentWrapper: {
    position: "relative",
    display: "flex",
    flex: "1",
    flexDirection: "column",
    padding: "0 20px",
    "@media (max-width: 1280px)": {
      maxWidth: "100% !important",
    },
    [theme.breakpoints.down("sm")]: {
      padding: "0 1rem",
      maxWidth: "100% !important",
    },
  },
  header: {
    position: "relative",
    height: theme.trip_shock_theme.environment.headerHeight,
    zIndex: 101,
    display: "flex",
    alignItems: "center",
    color: theme.palette.primary.contrastText,
    "@media (max-width: 850px)": {
      height: "68px",
    },
  },
  headerSidebarToggleButton: {
    color: theme.palette.primary.contrastText,
  },
  content: {
    flex: "1 0 auto",
    backgroundColor: "#fff",
    width: "100%",
    boxShadow: "0px 3.89312px 23.3587px rgba(96, 96, 97, 0.18)",
    borderRadius: "15px",
    overflow: "hidden",
  },
  addBtn: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    backgroundColor: "#50B061",
    color: "#fff",
    width: "56px",
    height: "56px",
    zIndex: 101,
    bottom: "24px",
    right: "24px",
    borderRadius: "50%",
    cursor: "pointer",
    boxShadow: "3px 3px 15px 0 rgba(0, 0, 0, 0.2)",
  },
  addBtnWrapper: {
    width: "70px",
    height: "70px",
    position: "absolute",
    bottom: "24px",
    right: "24px",
    display: "flex",
    justifyContent: "flex-end",
    alignItems: "flex-end",
  },
}));
