import React from 'react';
import { useStyles } from './styles';

const SystemButton = ({ onHandlerClick, children }: any) => {
  const classes = useStyles();
  return (
    <div className={classes.circle_button} onClick={onHandlerClick}>
      {children}
    </div>
  );
};

export default SystemButton;
