export const dataParse = (data: any, id: any, card_item_id: any) => {
  const arrivalData = data.cart_items[card_item_id];
  const tickets = (arrivalData.tickets = Object.keys(
    arrivalData.tickets || {}
  ).map((id) => {
    const ticket = { ...arrivalData.tickets[id] };
    ticket.guests = Object.keys(ticket.guests || {}).map(
      (index) => ticket.guests[index]
    );

    return ticket;
  }));

  const additional_options = (arrivalData.additional_options = Object.keys(
    arrivalData.additional_options || {}
  )
    .filter((id) => id !== "1")
    .map((id) => arrivalData.additional_options[id]));

  return {
    id: data.id,
    cart_id: id,
    cart_item_id: card_item_id,
    calendar_id: arrivalData.calendar_id,
    product_id: arrivalData.trip_id,
    total: data.gross_total,
    tax: data.comission,
    state_name: data.state,
    data: {
      additional_options: additional_options,
      comments: {
        guest: arrivalData.comment_guest,
        partner: arrivalData.comment_partner,
      },
      inventory: arrivalData.inventory,
      product: {
        booking_fee: arrivalData.booking_fee,
        id: arrivalData.trip_id,
        name: arrivalData.trip_name,
        need_tickets_guests: arrivalData.trip_need_tickets_guests,
        product_type: "trip",
        tax: arrivalData.trip_tax,
      },
      schedule: arrivalData.schedule,
      tickets: tickets,
    },
    made: data.made,
    date: arrivalData.arrival_date,
    first_name: data.customer_first_name,
    last_name: data.customer_last_name,
    phone: data.customer_phone,
  };
};
