import { useQuery } from '@apollo/client';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { GET_EMPLOYEES_LIST } from 'src/api/employees';
import { currentUser } from 'src/redux/Authentication/sliceAuthentication';

import { UsersContent } from 'src/web/components/Settings/Users';
import { UserDetailModal } from 'src/web/components/Settings/Users/UserDetailModal';

type Props = {
  openDetail: boolean;
  setOpenDetail: (isOpen: boolean) => void;
};

const UsersPage = ({ openDetail, setOpenDetail }: Props) => {
  const user = useSelector(currentUser);
  const [choosenUser, setChoosenUser] = useState<string | null>(null);
  const [employees, setEmployees] = useState<IEmployee[]>([]);
  const [isSpinner, setIsSpinner] = useState<boolean>(false);
  const [isScrollLoading, setIsScrollLoading] = useState<boolean>(false);
  const [pager, setPager] = useState<string | null>(null);

  const { refetch, loading, fetchMore } = useQuery(GET_EMPLOYEES_LIST, {
    variables: {
      prm: {
        partner_id: user['custom:partner_id'] as string | number,
      },
    },
    onCompleted: (data) => {
      setEmployees(data.v1_partners_listEmployees.items);
      setPager(data.v1_partners_listEmployees.pager);
    },
    onError: (error) => {
      console.log(error);

      setIsScrollLoading(false);
    },
    notifyOnNetworkStatusChange: true,
  });

  useEffect(() => {
    if (!isScrollLoading) {
      setIsSpinner(loading);
    }
  }, [loading]);

  const loadMore = (pager: string | null) => {
    setIsScrollLoading(true);

    if (!pager) {
      setIsScrollLoading(false);
      return;
    }

    fetchMore({
      variables: {
        prm: {
          partner_id: user['custom:partner_id'] as string | number,
          pager,
        },
      },
    }).then((res) => {
      setEmployees((prev) => [
        ...prev,
        ...res.data?.v1_partners_listEmployees.items,
      ]);
      setPager(res.data?.v1_partners_listEmployees.pager as string | null);
      setIsScrollLoading(false);
    });
  };

  return (
    <>
      <UserDetailModal
        setIsSpinner={setIsSpinner}
        refetch={refetch as any}
        open={openDetail}
        setOpen={setOpenDetail}
        employee={employees.find((employee) => employee.id === choosenUser)}
        setChoosenUser={setChoosenUser}
      />
      <UsersContent
        pager={pager || null}
        isScrollLoading={isScrollLoading}
        loadMore={loadMore}
        isSpinner={isSpinner}
        setIsSpinner={setIsSpinner}
        refetch={refetch as any}
        employees={employees}
        setOpenDetail={setOpenDetail}
        choosenUser={choosenUser}
        setChoosenUser={setChoosenUser}
      />
    </>
  );
};

export default UsersPage;
