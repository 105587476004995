import { IOnboardingFields } from "src/redux/Onboarding/interfaces";

export const isDisabled = (step: number, currentFields: any[], data: any) => {
  const isNextDisabled =
    step !== 1 &&
    step !== 5 &&
    step !== 2 &&
    currentFields.some((item) => !data[item.name as keyof IOnboardingFields]);

  const isNextDisabledEmails = () => {
    if (step === 1) {
      const emailField = currentFields.find((item) => item.name === "email")
        ?.name as string;
      if (!data[emailField as keyof IOnboardingFields]) {
        return true;
      } else {
        return false;
      }
    }
  };

  const isNextDisabledBarcodes = () => {
    if (step === 5) {
      const barcodesField = currentFields.find(
        (item) => item.name === "use_barcodes"
      )?.name as string;
      const barcodesTypeField = currentFields.find(
        (item) => item.name === "barcode_type"
      )?.name as string;
      if (!data[barcodesField as keyof IOnboardingFields]) {
        return true;
      }
      if (data[barcodesField as keyof IOnboardingFields] === "No") {
        return false;
      }
      if (
        data[barcodesField as keyof IOnboardingFields] === "Yes" &&
        !data[barcodesTypeField as keyof IOnboardingFields]
      ) {
        return true;
      } else {
        return false;
      }
    }
  };

  const isNextDisabledPhones = () => {
    if (step === 2) {
      const phoneField = currentFields.find((item) => item.name === "phone")
        ?.name as string;
      if (!data[phoneField as keyof IOnboardingFields]) {
        return true;
      } else {
        return false;
      }
    }
  };

  return (
    isNextDisabled ||
    isNextDisabledEmails() ||
    isNextDisabledBarcodes() ||
    isNextDisabledPhones()
  );
};
