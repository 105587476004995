import React, { useEffect, useState } from 'react';
import {
  Box,
  Button,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControlLabel,
  IconButton,
} from '@material-ui/core';
import PerfectScrollbar from 'react-perfect-scrollbar';
import CloseIcon from 'src/assets/icons/light/close.svg';
import CheckboxIcon from 'src/assets/icons/web/checkbox.svg';
import CheckboxCheckedIcon from 'src/assets/icons/web/checkboxChecked.svg';

import { permissions } from 'src/helpers/permissions';

import { TempEmployee } from './UserDetailModal';
import { useStyles } from './styles';
import { useSelector } from 'react-redux';
import { currentUser } from 'src/redux/Authentication/sliceAuthentication';

type Props = {
  open: boolean;
  employee?: IEmployee;
  setOpen: (isOpen: boolean) => void;
  employeePermissions: string[];
  onEmployeeChange: (field: keyof TempEmployee, value: string | number) => void;
};

export const UserPermissionsModal = ({
  open,
  setOpen,
  employee,
  employeePermissions,
  onEmployeeChange,
}: Props) => {
  const classes = useStyles();
  const user = useSelector(currentUser);
  const [employeePermissionsState, setEmployeePermissions] =
    useState(employeePermissions);

  useEffect(() => {
    setEmployeePermissions(employeePermissions);
  }, [employeePermissions]);

  const permissionsForSetUp = permissions
    .filter((permission) => {
      if (
        employee &&
        user['cognito:username'] === employee.id &&
        permission.name === 'users_manage'
      ) {
        return false;
      }

      return true;
    })
    .map((permission) => {
      const checked = employeePermissionsState.includes(permission.name);

      return {
        ...permission,
        checked,
      };
    });

  const onChangePermission = (name: string, checked: boolean) => {
    setEmployeePermissions((prev) => {
      if (checked) {
        return [...prev, name];
      } else {
        return prev.filter((item) => item !== name);
      }
    });
  };

  const onSubmit = () => {
    onEmployeeChange('permissions', JSON.stringify(employeePermissionsState));
    setOpen(false);
  };

  return (
    <Dialog
      fullWidth
      maxWidth="sm"
      open={open}
      className={classes.detailModalRoot}
      onClose={() => setOpen(false)}
    >
      <DialogTitle>
        <Box className={classes.dialogHeader}>
          <Box className={classes.dialogHeaderTitle}>Set up permissions</Box>
          <IconButton
            onClick={() => setOpen(false)}
            className={classes.closeIcon}
          >
            <CloseIcon />
          </IconButton>
        </Box>
      </DialogTitle>
      <PerfectScrollbar>
        <DialogContent>
          <Box
            width={1}
            paddingLeft="10px"
            display="flex"
            flexDirection="column"
            flexWrap="wrap"
            justifyContent="space-between"
          >
            {permissionsForSetUp.map((permission) => (
              <FormControlLabel
                control={
                  <Checkbox
                    checked={permission.checked}
                    onChange={(_, checked) =>
                      onChangePermission(permission.name, checked)
                    }
                    icon={<CheckboxIcon />}
                    checkedIcon={<CheckboxCheckedIcon />}
                  />
                }
                label={permission.label}
              />
            ))}
          </Box>
        </DialogContent>
      </PerfectScrollbar>
      <DialogActions>
        <Box>
          <Button
            onClick={() => setOpen(false)}
            className={classes.cancelButton}
            variant="text"
          >
            Cancel
          </Button>
          <Button
            onClick={onSubmit}
            className={classes.saveButton}
            variant="contained"
          >
            Save
          </Button>
        </Box>
      </DialogActions>
    </Dialog>
  );
};
