import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles(() => ({
  root: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    padding: '30px 0 20px 0',
    borderBottom: '1px solid #dbf8f6',
  },
  title: {
    color: '#333333',
    fontSize: '16px',
    fontWeight: 600,
  },
  actions: {
    display: 'flex',
    flexDirection: 'row',
  },
  action: {
    cursor: 'pointer',
  },
}));
