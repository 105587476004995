import React from 'react';
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  Typography,
} from '@material-ui/core';

import CloseIcon from 'src/assets/icons/light/close.svg';

import { useStyles } from './styles';
import { ApolloQueryResult, useMutation } from '@apollo/client';
import { DELETE_EMPLOYEE } from 'src/api/employees';
import { useDispatch } from 'react-redux';
import { setNotification } from 'src/redux/Notifications/sliceNotifications';

type Props = {
  open: boolean;
  setIsSpinner: React.Dispatch<React.SetStateAction<boolean>>;
  refetch: (
    variables?:
      | Partial<{
          prm: {
            partner_id: string | number;
          };
        }>
      | undefined
  ) => Promise<ApolloQueryResult<any>>;
  choosenUser: string | null;
  setOpen: (isOpen: boolean) => void;
  setChoosenUser: React.Dispatch<React.SetStateAction<string | null>>;
};

export const DeleteUserAlertModal = ({
  open,
  refetch,
  setOpen,
  setIsSpinner,
  choosenUser,
  setChoosenUser,
}: Props) => {
  const classes = useStyles();
  const dispatch = useDispatch();

  const [deleteEmployee] = useMutation(DELETE_EMPLOYEE, {
    onCompleted: (data) => {
      refetch();
    },
    onError: (error) => {
      dispatch(
        setNotification({
          message: error.message,
          type: 'error',
        })
      );

      setIsSpinner(false);
    },
  });

  const onSubmit = () => {
    setIsSpinner(true);

    deleteEmployee({
      variables: {
        prm: {
          id: choosenUser,
        },
      },
    });

    onCloseHandler();
  };

  const onCloseHandler = () => {
    setChoosenUser(null);
    setOpen(false);
  };

  return (
    <Dialog
      fullWidth
      maxWidth="xs"
      open={open}
      className={classes.detailModalRoot}
      onClose={onCloseHandler}>
      <DialogTitle style={{ paddingBottom: 0, marginTop: 38 }}>
        <Box display="flex" justifyContent="flex-end" marginRight="-30px">
          <IconButton onClick={onCloseHandler} className={classes.closeIcon}>
            <CloseIcon />
          </IconButton>
        </Box>
      </DialogTitle>
      <DialogContent style={{ paddingTop: 0, paddingBottom: 25 }}>
        <Box>
          <Typography className={classes.deleteAlertText}>
            Are you sure you really want to delete this profile?
          </Typography>
        </Box>
      </DialogContent>
      <DialogActions style={{ marginBottom: '-20px' }}>
        <Box display="flex" justifyContent="center" width={1}>
          <Button
            style={{ marginRight: 40 }}
            onClick={onCloseHandler}
            className={classes.saveButton}
            variant="text">
            No
          </Button>
          <Button
            style={{
              background: 'transparent',
              boxShadow: 'none',
              color: '#6C6B6B',
            }}
            onClick={onSubmit}
            className={classes.cancelButton}
            variant="contained">
            Yes
          </Button>
        </Box>
      </DialogActions>
    </Dialog>
  );
};
