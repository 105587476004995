import { IPrm, sqlWhereRule } from "src/web/features/table/interfaces";

export interface IArrivalsPrm {
  filters?: any;
  order?: {
    asc: boolean;
    field: string;
  };
  offset?: string;
  search?: string;
  partner_id: number;
}

export const normalizePrm = (
  prm: IPrm | null,
  partner_id: number
): IArrivalsPrm | null => {
  if (!prm) return null;

  const normalisePrm: IArrivalsPrm = {
    partner_id: partner_id,
    offset: !prm.offset || prm.offset === "0" ? "" : prm.offset,
    search: "",
  };
  if (prm.order) {
    normalisePrm.order = prm.order;
  }

  const newFormatFilters: sqlWhereRule[] = [];

  prm.where.forEach((where) => {
    const searchValue = where.find((item) => item.field === "search");
    normalisePrm.search = searchValue?.value.replace(/%/g, "") || "";
    const whereWithoutSearch = where.filter((item) => item.field !== "search");

    if (whereWithoutSearch.length) {
      newFormatFilters.push(...whereWithoutSearch);
    }
  });

  if (newFormatFilters.length) {
    normalisePrm.filters = [newFormatFilters];
  }

  return normalisePrm;
};
