import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { PageLayout } from "src/web/layouts/PageLayout";
import { setNotification } from "src/redux/Notifications/sliceNotifications";
import { setLoading, prm, filter } from "src/web/features/table/sliceTables";
import { TableHeader, TableContent, TableFilter, TableSorting } from "./index";
import { PageSidebar } from "src/web/components/PageSidebar";
import { ITableSearchProps } from "./interfaces";

export const PageTable = ({
  content,
  icon,
  title,
  sidebarHeader,
  error,
  createLink,
  createAction,
  createActionId,
  headerElement,
  isSkip,
  customSearchElement,
  withoutSidebar,
  headerWidth,
  forbiddenReselect,
}: IPageTableProps) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const currentPrm = useSelector(prm);
  const currentFilter = useSelector(filter);

  useEffect(() => {
    if (error) {
      dispatch(
        setNotification({
          type: "error",
          message: error.message,
          duaration: 5000,
        })
      );
      dispatch(setLoading({ type: "scroll", value: false }));
      dispatch(setLoading({ type: "change", value: false }));
    }
  }, [error, dispatch]);

  const [isOpenSidebar, setIsOpenSidebar] = React.useState<boolean>(false);

  const actionButton = (
    event: React.MouseEvent<HTMLDivElement, MouseEvent>
  ) => {
    if (createLink) {
      history.push(createLink);
    }
    if (createAction) {
      createAction(event);
    }
  };

  const isCreate = createLink || createAction ? true : false;

  return (
    <PageLayout
      header={
        <TableHeader
          where_scheme={currentPrm ? currentPrm.where_scheme : undefined}
          setOpenSidebar={setIsOpenSidebar}
          headerElement={headerElement}
          headerWidth={headerWidth}
          customSearchElement={customSearchElement}
          headerTitle={
            icon &&
            title && (
              <>
                {icon}
                <span>{title}</span>
              </>
            )
          }
        />
      }
      content={
        <TableContent
          sorting={<TableSorting />}
          content={content}
          isSkip={isSkip}
        />
      }
      sidebar={
        currentFilter.length ? (
          <PageSidebar
            title={title || ""}
            withoutSidebar={withoutSidebar}
            icon={icon || <></>}
            header={sidebarHeader}
            content={<TableFilter forbiddenReselect={forbiddenReselect} />}
            isOpenSidebar={isOpenSidebar}
            setIsOpenSidebar={setIsOpenSidebar}
          />
        ) : (
          <></>
        )
      }
      actionBtn={createLink || createAction ? actionButton : undefined}
      actionId={createActionId}
      isCreate={isCreate}
      maxWidth={
        withoutSidebar || !currentFilter.length ? "100%" : "calc(100% - 200px)"
      }
    />
  );
};

interface IPageTableProps {
  icon?: JSX.Element;
  title?: string;
  content: JSX.Element;
  sidebarHeader?: JSX.Element;
  error: any;
  createLink?: string;
  createAction?: (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => void;
  createActionId?: string;
  headerElement?: JSX.Element | null;
  isSkip?: boolean;
  customSearchElement?: React.FC<ITableSearchProps>;
  withoutSidebar?: boolean;
  headerWidth?: string;
  forbiddenReselect?: boolean;
}
