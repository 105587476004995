import React, { Suspense, useEffect, useState } from 'react';
import { Switch, Route } from 'react-router-dom';
import { PrivateRoute, AuthRoute } from './routes';
import {
  PrivateRoutes,
  SystemRoutes,
  NotFoundRoutes,
  privatePaths,
  systemPaths,
  AuthRoutes,
  authPaths,
  withoutLayoutPaths,
  WithoutLayoutRoutes,
  registrationPaths,
  RegistrationRoutes,
  onboardingPaths,
  OnboardingRoutes,
} from './config';
import { MainLayout } from 'src/web/layouts/MainLayout';
import { OnboardingLayout } from 'src/web/layouts/OnboardingLayout';
import { RegistrationLayout } from 'src/web/layouts/RegistrationLayout';
import { SystemLayout } from 'src/web/layouts/SystemLayout';
import { Spiner } from 'src/web/components/Loaders';
import { checkIsHasAccessToRoute } from 'src/helpers/limitationRoutes';
import { useSelector } from 'react-redux';
import { currentUser } from 'src/redux/Authentication/sliceAuthentication';

const Routes = () => {
  const user = useSelector(currentUser);

  const firstAvailablePrivateRoute = PrivateRoutes?.find((route) =>
    checkIsHasAccessToRoute(user, route.name || '', 'routes')
  )?.path;

  console.log(firstAvailablePrivateRoute);

  return (
    <Switch>
      <Route path={privatePaths} exact>
        <MainLayout>
          <Suspense fallback={<Spiner />}>
            {PrivateRoutes.map((route) => (
              <PrivateRoute
                path={route.path}
                component={route.component}
                name={route.name}
                exact
                key={route.path}
                firstAvailablePrivateRoute={firstAvailablePrivateRoute}
              />
            ))}
          </Suspense>
        </MainLayout>
      </Route>
      <Route path={onboardingPaths} exact>
        <OnboardingLayout>
          <Suspense fallback={<Spiner />}>
            {OnboardingRoutes.map((route) => (
              <PrivateRoute
                path={route.path}
                component={route.component}
                name={route.name}
                exact
                key={route.path}
              />
            ))}
          </Suspense>
        </OnboardingLayout>
      </Route>
      <Route path={withoutLayoutPaths} exact>
        <Suspense fallback={<Spiner />}>
          {WithoutLayoutRoutes.map((route) => (
            <PrivateRoute
              path={route.path}
              component={route.component}
              name={route.name}
              exact
              key={route.path}
            />
          ))}
        </Suspense>
      </Route>
      <Route path={systemPaths} exact>
        <SystemLayout>
          <Suspense fallback={<Spiner />}>
            {SystemRoutes.map((route) => (
              <Route
                path={route.path}
                component={route.component}
                exact
                key={route.path}
              />
            ))}
          </Suspense>
        </SystemLayout>
      </Route>
      <Route path={authPaths} exact>
        <SystemLayout>
          <Suspense fallback={<Spiner fixed />}>
            {AuthRoutes.map((route) => (
              <AuthRoute
                path={route.path}
                component={route.component}
                exact
                key={route.path}
              />
            ))}
          </Suspense>
        </SystemLayout>
      </Route>
      <Route path={registrationPaths} exact>
        <RegistrationLayout>
          <Suspense fallback={<Spiner fixed />}>
            {RegistrationRoutes.map((route) => (
              <AuthRoute
                path={route.path}
                component={route.component}
                exact
                key={route.path}
              />
            ))}
          </Suspense>
        </RegistrationLayout>
      </Route>
      <SystemLayout>
        <Suspense fallback={<Spiner />}>
          <Route
            path={NotFoundRoutes.path}
            component={NotFoundRoutes.component}
          />
        </Suspense>
      </SystemLayout>
    </Switch>
  );
};

export default Routes;
