type Permission = {
  name: string;
  label: string;
  routes?: string[];
};

export const isOwner = (user: any) =>
  user?.['cognito:groups']?.includes('owners');

export const permissions: Permission[] = [
  {
    name: 'view_statistics',
    label: 'View Statistics',
    routes: ['Dashboard'],
  },
  {
    name: 'order_list_view',
    label: 'View orders (send order notification)',
    routes: ['Arrivals', 'RecentBookings'],
  },
  {
    name: 'order_edit',
    label: 'Edit orders',
  },
  {
    name: 'reviews_view',
    label: 'View reviews (send review notification)',
    routes: ['Reviews'],
  },
  {
    name: 'review_reply',
    label: 'Reply review',
  },
  {
    name: 'payouts_list_view',
    label: 'View payments (send invoices notification)',
    routes: ['Payouts', 'Payments'],
  },
  {
    name: 'users_list_view',
    label: 'View users',
    routes: ['Users'],
  },
  {
    name: 'users_manage',
    label: 'Manage users',
  },
  {
    name: 'company_view',
    label: 'View company information',
    routes: ['Company'],
  },
  {
    name: 'company_edit',
    label: 'Edit company information',
  },
  {
    name: 'listing_view',
    label: 'View listing & equipments',
    routes: ['Activities'],
  },
  {
    name: 'listing_edit',
    label: 'Edit listings & equipments',
  },
  {
    name: 'calendar_manage',
    label: 'Manage calendar',
    routes: ['Calendar Management', 'Calendar'],
  },
];

export const isUserHasPermissions = (
  user: any,
  permissionsNames?: string[],
  isRoute?: boolean
) => {
  if (isOwner(user)) {
    return true;
  }

  // console.log(user, permissionsNames, isRoute);

  const userPermissions: string[] = user?.permissions || [];

  if (!userPermissions || !permissionsNames || !userPermissions.length) {
    return false;
  }

  if (isRoute) {
    const routeName: string = permissionsNames[0];
    const needlePermission = permissions.find((permission) =>
      permission.routes?.includes(routeName)
    );

    if (!needlePermission) {
      return true;
    }

    return userPermissions.includes(needlePermission.name);
  }

  return permissionsNames.some((name) => userPermissions.includes(name));
};
