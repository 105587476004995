import { makeStyles } from "@material-ui/core/styles";
import { Theme } from "src/web/themes/theme";

export const useStyles = makeStyles((theme: Theme) => ({
  dialog: {
    "& .MuiDialog-paperScrollPaper": {
      maxHeight: "100%",
    },
    "& .MuiPaper-elevation24": {
      padding: "14px 13px 27px 50px",
      maxWidth: "1200px",
      boxShadow: "0px 10.89px 43.36px rgba(17, 15, 15, 0.53)",
      borderRadius: "15px",
      overflow: "unset",
      "& .MuiTypography-root": {
        width: "100%",
      },
      "& .MuiDialogContent-root": {
        paddingLeft: 0,
      },
    },
    "@media (max-width: 750px)": {
      "& .MuiPaper-elevation24": {
        padding: "10px 10px 27px 25px",
      },
    },
    "@media (max-width: 600px)": {
      "& .MuiDialog-paper": {
        margin: "17px",
        width: "100%",
      },
    },
  },
  fieldsWrapper: {
    display: "flex",
    flexWrap: "wrap",
    "@media (max-width: 750px)": {
      "& .MuiPaper-elevation24": {
        flexWrap: "nowrap",
        flexDirection: "column",
      },
    },
  },
  fieldBox: {
    width: "50%",
    "@media (max-width: 750px)": {
      width: "100%",
    },
  },
  wrapper: {
    width: "35%",
    [theme.breakpoints.down("sm")]: {
      width: "10%",
    },
  },
  dialogTitle: {
    display: "flex",
    padding: 0,
  },
  header: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "flex-start",
  },
  closeIcon: {
    "&:hover": {
      background: "none",
      border: "1px solid #FAFAFA",
      padding: "11px",
    },
  },
  button: {
    background: "linear-gradient(90deg, #03D9B1 0%, #1CBBD9 100%)",
    color: "white",
    textTransform: "none",
    borderRadius: "50px",
    boxShadow:
      "0px 13px 28px rgba(29, 203, 192, 0.2), 0px 8px 16px rgba(29, 203, 192, 0.06)",
    fontSize: "16px",
    padding: "6.5px 31px",
    fontWeight: 500,
    "&:hover": {
      background: "linear-gradient(90deg, #06C9A4 0%, #00A2C1 100%)",
      color: "#FFFFFF",
      boxShadow:
        "0px 13px 28px rgba(29, 203, 192, 0.2), 0px 8px 16px rgba(29, 203, 192, 0.06)",
    },
    "@media (max-width: 600px)": {
      width: "100%",
    },
  },
}));
