import React from "react";
import { Add } from "@material-ui/icons";
import PerfectScrollbar from "react-perfect-scrollbar";
import { useStyles } from "./styles";

export const PageCardsLayout = ({
  header,
  content,
  sidebar,
  actionBtn,
  actionId,
  isCreate,
}: IPageWithTable) => {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <div className={classes.topBg}></div>
      <div className={classes.pageWrapper}>
        {sidebar && sidebar}
        <div className={classes.contentWrapper}>
          <PerfectScrollbar style={{ maxHeight: "100%" }}>
            <div className={classes.header}>{header}</div>
            <div className={classes.content}>{content}</div>
          </PerfectScrollbar>
        </div>

        {actionBtn && isCreate && (
          <div
            aria-describedby={actionId}
            className={classes.addBtnWrapper}
            onClick={(e) => actionBtn(e)}
          >
            <div className={classes.addBtn}>
              <Add />
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

interface IPageWithTable {
  header: JSX.Element;
  content: JSX.Element;
  sidebar?: JSX.Element;
  actionBtn?: (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => void;
  actionId?: string;
  isCreate?: boolean;
}
