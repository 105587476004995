import React, { useState } from 'react';
import {
  Box,
  Popover,
  IconButton,
  FormControlLabel,
  Checkbox,
  Popper,
} from '@material-ui/core';

import CloseIcon from 'src/assets/icons/light/close.svg';
import CheckboxIcon from 'src/assets/icons/web/checkbox.svg';
import CheckboxCheckedIcon from 'src/assets/icons/web/checkboxChecked.svg';

import { useStyles } from './styles';
import { ReviewsDateFilter } from './DateFilter';
import { IReviewsPrm } from 'src/web/pages/Reviews/normalizePrm';
import { CustomAutocomplete } from '../../UI/CustomAutocomplete';

interface Props {
  open: boolean;
  anchorEl: null | HTMLElement;
  prm: IReviewsPrm;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
  onChangeFilter: (filter: Partial<IReviewsPrm>) => void;
  onChangeDates: ({
    start,
    end,
  }: {
    start: string | null;
    end: string | null;
  }) => void;
  partnerProductsLoading: boolean;
  productsData: {
    id: number;
    name: string;
    city_id: number;
  }[];
};

interface ISkip {
  usePartnersTripsAutocomplete: boolean;
}

const FilterPopper = ({
  open,
  anchorEl,
  prm,
  setOpen,
  onChangeFilter,
  onChangeDates,
  productsData,
  partnerProductsLoading
}: Props) => {
  const classes = useStyles();

  const [skip, setSkip] = useState<ISkip>({
    usePartnersTripsAutocomplete: true,
  });

  const skipHandlerChange = (key: keyof ISkip) => {
    setSkip((prev) => ({ ...prev, [key]: false }));
  };

  const CustomPopper = function (props: any) {
    const classes = useStyles();
    return <Popper {...props} className={classes.popper} placement="top" />;
  };

  return (
    <Popover
      open={open}
      onClose={() => setOpen(false)}
      anchorOrigin={{
        vertical: 'center',
        horizontal: 'center',
      }}
      transformOrigin={{
        vertical: 'center',
        horizontal: 'center',
      }}
      className={classes.popover}>
      <Box className={classes.filterPopper}>
        <Box className={classes.filterTitle}>
          <Box className={classes.dialogHeaderTitle}>Filter</Box>
          <IconButton
            onClick={() => setOpen(false)}
            className={classes.closeIcon}>
            <CloseIcon />
          </IconButton>
        </Box>
        <Box className={classes.filterPopperBody}>
        <Box p={1} mt={1}>
          <CustomAutocomplete
            label={"Product"}
            placeholder={"Choose product"}
            options={productsData}
            className={classes.filterArea}
            value={
              productsData.find((i: any) => i?.id === +(prm.activity_id || 0)) || null
            }
            onFocus={
              skip.usePartnersTripsAutocomplete
                ? () => skipHandlerChange("usePartnersTripsAutocomplete")
                : undefined
            }
            onChange={(data: any) =>
              onChangeFilter({
                activity_id: data ? data.id : 0,
              })
            }
            loading={partnerProductsLoading}
            deletable={true}
            CustomPopper={CustomPopper}
            renderOption={(option: any) => {
              return (
                <Box width={1} className={classes.autocompleteItem}>
                  <Box>{option.name}</Box>
                </Box>
              );
            }}
          />
        </Box>
          <FormControlLabel
            control={
              <Checkbox
                checked={prm.show_replied}
                onChange={() =>
                  onChangeFilter({ show_replied: !prm.show_replied })
                }
                icon={<CheckboxIcon />}
                checkedIcon={<CheckboxCheckedIcon />}
              />
            }
            label="Show with reply"
          />
          <ReviewsDateFilter
            label="Dates"
            startDate={prm.date_from}
            endDate={prm.date_to}
            onChange={onChangeDates}
            width="100%"
          />
        </Box>
      </Box>
    </Popover>
  );
};

export default FilterPopper;
