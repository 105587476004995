import React, { useState } from "react";
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  InputAdornment,
  TextField,
} from "@material-ui/core";
import { changePassword } from "src/redux/Authentication/sliceAuthentication";
import { useDispatch } from "react-redux";
import VisibilityPassword from "src/assets/icons/web/visibilityPassword.svg";
import VisibilityOffPassword from "src/assets/icons/web/visibilityOffPassword.svg";
import CloseIcon from "src/assets/icons/light/close.svg";

import { useStyles } from "./styles";

type Props = {
  open: boolean;
  setOpen: (isOpen: boolean) => void;
};

export const ChangePasswordModal = ({ open, setOpen }: Props) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const [showOldPassword, setShowOldPassword] = useState(false);
  const [showNewPassword, setShowNewPassword] = useState(false);
  const [oldPassword, setOldPassword] = useState("");
  const [newPassword, setNewPassword] = useState("");

  const handleChangePassword = () => {
    dispatch(
      changePassword({
        oldPassword: oldPassword,
        newPassword: newPassword,
      })
    );
    setOldPassword("");
    setNewPassword("");
    setOpen(false);
  };

  return (
    <Dialog
      fullWidth
      maxWidth="sm"
      open={open}
      className={classes.detailModalRoot}
      onClose={() => setOpen(false)}
    >
      <DialogTitle>
        <Box className={classes.dialogHeader}>
          <Box className={classes.dialogHeaderTitle}>Change password</Box>
          <IconButton
            onClick={() => setOpen(false)}
            className={classes.closeIcon}
          >
            <CloseIcon />
          </IconButton>
        </Box>
      </DialogTitle>
      <DialogContent>
        <Box
          width={1}
          display="flex"
          flexWrap="wrap"
          justifyContent="space-between"
        >
          <Box width={1}>
            <TextField
              className={classes.editInput}
              label="Old Password"
              type={showOldPassword ? "text" : "password"}
              placeholder="Your old Password"
              variant="outlined"
              InputLabelProps={{
                shrink: true,
              }}
              value={oldPassword}
              onChange={(e) => setOldPassword(e.target.value)}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={() => setShowOldPassword(!showOldPassword)}
                    >
                      {showOldPassword ? (
                        <VisibilityPassword />
                      ) : (
                        <VisibilityOffPassword />
                      )}
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />
          </Box>
          <Box width={1}>
            <TextField
              className={classes.editInput}
              label="New Password"
              type={showNewPassword ? "text" : "password"}
              placeholder="Your new Password"
              variant="outlined"
              InputLabelProps={{
                shrink: true,
              }}
              value={newPassword}
              onChange={(e) => setNewPassword(e.target.value)}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={() => setShowNewPassword(!showNewPassword)}
                    >
                      {showNewPassword ? (
                        <VisibilityPassword />
                      ) : (
                        <VisibilityOffPassword />
                      )}
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />
          </Box>
        </Box>
      </DialogContent>
      <DialogActions>
        <Box>
          <Button
            onClick={() => setOpen(false)}
            className={classes.cancelButton}
            variant="text"
          >
            Cancel
          </Button>
          <Button
            onClick={() => handleChangePassword()}
            className={classes.saveButton}
            variant="contained"
          >
            Save
          </Button>
        </Box>
      </DialogActions>
    </Dialog>
  );
};
