import { makeStyles } from "@material-ui/core/styles";
import { Theme } from "src/web/themes/theme";

export const useStyles = makeStyles((theme: Theme) => ({
  fieldsCard: {
    padding: "20px 30px",
    borderRadius: "15px",
    marginBottom: "5px",
  },
  masterCard: {
    background: "#e7faf9",
    marginBottom: "20px",
  },
  inputWrapper: {
    "& .MuiInputLabel-outlined": {
      color: "#333",
      paddingLeft: "22px",
      fontWeight: 500,
    },
    "& .MuiAutocomplete-inputRoot": {
      padding: "4px 24px !important",
    },
    "& .MuiInputBase-root": {
      height: "50px",
      borderRadius: "50px",
      "& .MuiOutlinedInput-notchedOutline": {
        paddingLeft: "25px",
      },
    },
    "& .MuiInputBase-input": {
      padding: "15px",
      color: "#333333",
      fontSize: "14px",
    },
    "& .Mui-focused .MuiOutlinedInput-notchedOutline": {
      border: "1px #333333 solid",
    },
  },
}));
