import { makeStyles } from "@material-ui/core/styles";

import USAIcon from "src/assets/icons/flags/US.png";

export const useStyles = makeStyles(() => ({
  title: {
    fontWeight: 600,
    fontSize: "20px",
    color: "#0DD1C5",
    fontFamily: "Montserrat",
    "@media (max-width: 620px)": {
      fontWeight: 500,
      fontSize: "14px",
    },
  },
  description: {
    fontWeight: 400,
    fontSize: "12px",
    color: "#6C6B6B",
    fontFamily: "Montserrat",
    "@media (max-width: 620px)": {
      fontSize: "10px",
    },
  },
  content: {
    fontWeight: 500,
    fontSize: "12px",
    color: "#6C6B6B",
    fontFamily: "Montserrat",
    paddingBottom: "15px",
    "@media (max-width: 620px)": {
      fontSize: "10px",
    },
  },
  box: {
    padding: "24px 32px",
    border: "1px solid #FAFAFA",
    borderRadius: "15px",
    marginTop: "24px",
    "@media (max-width: 620px)": {
      padding: "20px 14px",
      marginTop: "4px",
    },
  },
  textBox: {
    padding: "25px 14px 15px 23px",
    borderRadius: "15px",
    marginTop: "24px",
    background: "#F9FCFD",
    height: "220px",
      "@media (max-width: 620px)": {
      padding: "20px 7px 18px 20px",
      marginTop: "20px",
      height: "180px",
      marginBottom: "9px",
    },
  },
  customInput: {
    "& label": {
      color: "#333",
      paddingLeft: "22px",
      fontWeight: 500,
      fontFamily: `"Montserrat", "Helvetica", "Arial", sans-serif`,
      fontSize: "15px",
      "&.Mui-focused": {
        color: "#333",
      },
      "@media (max-width: 620px)": {
        fontSize: "13px",
      },
    },
    "& div": {
      height: "50px",
      borderRadius: "50px",
      paddingLeft: 0,
      "@media (max-width: 620px)": {
        height: "45px",
      },
    },
    "& input": {
      padding: "15px 30px",
      color: "#333333",
      fontSize: "14px",
      fontFamily: `"Montserrat", "Helvetica", "Arial", sans-serif`,
      "@media (max-width: 620px)": {
        fontSize: "12px",
        padding: "13.5px 30px",
      },
    },
    "& input[type=number]::-webkit-inner-spin-button, input[type=number]::-webkit-outer-spin-button":
      {
        WebkitAppearance: "none",
      },
    "& fieldset": {
      border: "1px #C4C4C4 solid",
      paddingLeft: "25px",
      "& legend": {
        "@media (max-width: 620px)": {
          maxWidth: "210px",
        },
      },
      "& span": {
        fontSize: "12px",
        "@media (max-width: 620px)": {
          fontSize: "10px",
        },
      },
    },
    "& .Mui-focused fieldset": {
      border: "1px #202020 solid !important",
    },
    "& .MuiInputBase-multiline": {
      height: "auto",
      paddingLeft: "14px",
      borderRadius: "20px"
    }
  },
  groupFields: {
    border: "1px #C4C4C4 solid",
    borderRadius: "15px",
    padding: "20px",
    margin: "20px",
    display: "flex",
    flexDirection: "column",
  },
  iconPhone: {
    position: "absolute",
    bottom: "8px",
    right: "20px",
  },
  phoneInput: {
    position: "relative",
    paddingTop: "20px",
    "@media (max-width: 620px)": {
      marginBottom: "24px",
      paddingTop: "13px",
    },
    "& .form-control": {
      borderRadius: 50,
      padding: "4px 4px 5px",
      height: "50px",
      color: "#6C6B6B",
      fontSize: "14px",
      "@media (max-width: 620px)": {
        fontSize: "12px",
        height: "45px",
      },
      paddingLeft: "90px",
      fontFamily: "Montserrat",
    },
    "& .special-label": {
      fontSize: "12px",
      fontWeight: 500,
      "@media (max-width: 620px)": {
        fontSize: "10px",
      },
      color: "#333333",
      fontFamily: "Montserrat",
      left: "24px !important",
    },
    "& .selected-flag": {
      paddingLeft: "30px",
      marginTop: "3px",
    },
    "& .react-tel-input .form-control:focus": {
      border: "1px solid #333333",
      boxShadow: "none",
    },
    "& .react-tel-input .us": {
      backgroundImage: `url(${USAIcon})`,
      backgroundPosition: "inherit",
      // marginTop: "-10px",
      width: "28px",
    },
    "& .react-tel-input .selected-flag .arrow": {
      borderTop: "4px solid #C4C4C4",
      borderBottom: "none",
      marginLeft: "12px",
    },
    "& .country-name, & .dial-code": {
      fontFamily: "Montserrat",
      fontSize: "14px",
    },
  },
  radioTitle: {
    color: "#6C6B6B !important",
    fontFamily: "Montserrat",
    fontSize: "12px",
    fontWeight: 500,
    marginBottom: "40px",
    textAlign: "center",
    "@media (max-width: 620px)": {
      marginBottom: "25px",
      padding: "0 25px",
    },
  },
  radioGroup: {
    display: "grid",
    gridTemplateColumns: "repeat(2, 1fr)",
    gridColumnGap: "30px",
    gridRowGap: "0px",
    "@media (max-width: 620px)": {
      display: "flex",
      flexWrap: "nowrap",
      flexDirection: "column",
      "&.flex": {
        flexDirection: "row",
        justifyContent: "center",
      },
    },
  },
  radioItem: {
    marginLeft: 0,
    "& span:nth-child(2)": {
      color: "#6C6B6B !important",
      fontFamily: "Montserrat",
      fontSize: "12px",
      fontWeight: 400,
      whiteSpace: "nowrap",
    },
  },
  inputNumberAdornment: {
    display: "flex",
    flexDirection: "column",
    marginBottom: "7px",
    marginRight: "15px",
  },
  saveButton: {
    textTransform: "none",
    color: "white",
    fontSize: "16px",
    background: "linear-gradient(90deg, #03D9B1 0%, #1CBBD9 100%)",
    borderRadius: "50px",
    boxShadow:
      "0px 13px 28px rgba(29, 203, 192, 0.2), 0px 8px 16px rgba(29, 203, 192, 0.06)",
    width: "310px",
    padding: "6.5px 50px",
    fontFamily: `"Montserrat", "Helvetica", "Arial", sans-serif`,
    fontWeight: 500,
    "&:hover": {
      background: "linear-gradient(90deg, #06C9A4 0%, #00A2C1 100%)",
      color: "#FFFFFF",
      boxShadow:
        "0px 13px 28px rgba(29, 203, 192, 0.2), 0px 8px 16px rgba(29, 203, 192, 0.06)",
    },
    "&.Mui-disabled": {
      background: "linear-gradient(270deg, #ACB9BA 0%, #D1DDDD 108.89%)",
      color: "#FFFFFF",
    },
    "@media (max-width: 620px)": {
      fontSize: "14px",
    },
  },
  acceptCheckbox: {
    textAlign: "center",
  },
  checkbox: {
    "& span:nth-child(2)": {
      color: "#333333 !important",
      fontFamily: "Montserrat",
      fontSize: "12px",
      fontWeight: 500,
      whiteSpace: "nowrap",
    },
  },
  field: {
    position: "relative",
    paddingTop: "20px",
    "@media (max-width: 620px)": {
      marginBottom: "24px",
      paddingTop: "13px",
    },
  },
  radioGroupContainer: {
    "@media (max-width: 620px)": {
      height: "40px",
    },
  },
  selectWrapper: {
    marginTop: "24px",
    "@media (max-width: 620px)": {
      marginTop: "4px",
      width: "85%",
    },
  },
  dekstop: {
    display: "flex",
    justifyContent: "center",
    "@media (max-width: 620px)": {
      display: "none",
    },
  },
  adaptive: {
    display: "none",
    "@media (max-width: 620px)": {
      display: "flex",
      justifyContent: "center",
    },
  },
  addEmailButton: {
    background: "#F9FCFD",
    borderRadius: "50px",
    color: "#C4C4C4",
    marginTop: "20px",
    fontWeight: 400,
    fontSize: "14px",
    padding: "9px 25px",
    textTransform: "none",
    "@media (max-width: 620px)": {
      fontSize: "12px",
      marginTop: "0px",
    },
  },
  googlePlacesContainer: {
    position: "relative",
    marginTop: "20px",
    "@media (max-width: 620px)": {
      marginBottom: "24px",
      marginTop: "13px",
    },
    '& [class$="-container"], [class$="-control"]': {
      height: "100%",
    },
    '& [class$="-menu"]': {
      zIndex: 2,
    },
  },
  geosuggestContainerError: {
    "&:focus": {
      border: "1px solid #EC4652",
    },
  },
  geosuggestContainer: {
    height: "50px",
    borderRadius: "50px",
    paddingLeft: 0,
    "@media (max-width: 620px)": {
      height: "45px",
    },
    border: "1px #C4C4C4 solid",
    position: "relative",
    display: "block",
    "&$geosuggestContainerError": {
      borderColor: "#f44336",
    },
    "&.Mui-error": {
      border: "1px #C4C4C4 solid",
    },
    "& .MuiInputLabel-outlined": {
      transform: "translate(9px, -6px) scale(0.75)",
      color: "#333",
      marginLeft: "15px",
      padding: "0 10px 0 5px",
      fontWeight: 500,
      fontFamily: `"Montserrat", "Helvetica", "Arial", sans-serif`,
      fontSize: "15px",
      "&.Mui-focused": {
        color: "#333",
      },
      "@media (max-width: 620px)": {
        fontSize: "13px",
      },
      backgroundColor: "#fff",
    },
    "& .geosuggest__input": {
      width: "100%",
      border: 0,
      margin: 0,
      display: "block",
      minWidth: 0,
      background: "none",
      boxSizing: "border-box",
      animationName: "mui-auto-fill-cancel",
      letterSpacing: "inherit",
      animationDuration: "10ms",
      padding: "15px 30px",
      color: "#333333",
      fontSize: "14px",
      fontFamily: `"Montserrat", "Helvetica", "Arial", sans-serif`,
      "@media (max-width: 620px)": {
        fontSize: "12px",
        padding: "13.5px 30px",
      },
      "&::placeholder": {
        color: "#C4C4C4",
      },
    },
    "& .geosuggest__input:focus": {
      borderColor: "#333",
      boxShadow: "0 0 0 transparent",
      outline: "none",
    },
    "& .geosuggest": {
      width: "100%",
      height: "50px",
    },
    "& .geosuggest__suggests": {
      position: "absolute",
      top: "100%",
      left: 0,
      right: 0,
      maxHeight: "25em",
      padding: 0,
      marginTop: "2px",
      background: "#fff",
      borderTopWidth: 0,
      overflowX: "hidden",
      overflowY: "auto",
      listStyle: "none",
      zIndex: 11,
      transition: "max-height 0.2s, border 0.2s",
      borderRadius: "4px",
      boxShadow: "1px 1px 3px 1px rgba(0, 0, 0, 0.3)",
    },
    "& .geosuggest__suggests--hidden": {
      maxHeight: 0,
      overflow: "hidden",
      borderWidth: 0,
      boxShadow: "none",
    },

    /*A geosuggest item*/

    "& .geosuggest__item": {
      fontSize: "16px",
      padding: "0.5em 0.65em",
      cursor: "pointer",
      "@media (max-width: 620px)": {
        fontSize: "14px",
      },
    },
    "& .geosuggest__item:hover, .geosuggest__item:focus": {
      background: "#f5f5f5",
    },
    "& .geosuggest__item--active": {
      background: "#267dc0",
      color: "#fff",
    },
    "& .geosuggest__item--active:hover, .geosuggest__item--active:focus": {
      background: "#ccc",
    },
    "& .geosuggest__item__matched-text": {
      fontWeight: "bold",
    },
  },

  dropzoneContainer: {
    textAlign: "center",
    "@media (max-width: 620px)": {
      padding: "28px 61px !important",
    },
  },
  dropzoneTitle: {
    color: "#6C6B6B",
    fontSize: "14px",
    fontWeight: 500,
    "@media (max-width: 620px)": {
      fontSize: "12px",
    },
  },
  dropzoneSubTitle: {
    color: "#6C6B6B",
    fontSize: "12px",
    fontWeight: 400,
    "@media (max-width: 620px)": {
      fontSize: "10px",
    },
  },
  acceptedFiles: {
    background: "#F9FCFD",
    borderRadius: "10px",
    padding: "11px 20px",
    marginTop: "20px",
    color: "#6C6B6B",
    fontSize: "12px",
    fontWeight: 400,
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    "@media (max-width: 620px)": {
      fontSize: "10px",
      padding: "10px 18px",
      marginTop: "10px",
    },
  },
  dropzoneWrapper: {
    display: "flex",
    justifyContent: "center",
    marginTop: "20px",
    "@media (max-width: 620px)": {
      marginTop: "5px",
    },
  },
  dropzoneDragAccept: {
    background: "#F9FCFD",
    borderRadius: "15px",
    alignItems: "center",
    justifyContent: "center",
    display: "flex",
    width: "340px",
    height: "190px",
    "@media (max-width: 620px)": {
      width: "280px",
      height: "185px",
    },
    "& p": {
      color: "#C4C4C4",
      fontSize: "16px",
      fontWeight: 500,
      "@media (max-width: 620px)": {
        fontSize: "12px",
      },
    },
  },
}));
