import { makeStyles } from "@material-ui/core/styles";
import { Theme } from "src/web/themes/theme";

export const useStyles = makeStyles((theme: Theme) => ({
  contentWrapper: {
    paddingTop: "17px",
  },
  input: {
    width: "100%",
    fontSize: "14px",
    fontWeight: 400,

    "& .MuiFormLabel-root.Mui-disabled": {
      color: "#C4C4C4",
      fontWeight: 500,
      fontSize: "16px",
    },

    "& input.Mui-disabled": {
      color: "#333333",
    },
    "& .MuiInput-underline.Mui-disabled:before": {
      borderBottom: "none",
    },
  },
  about: {
    padding: "20px",
    backgroundColor: "#F9FCFD",
    borderRadius: "15px",

    "@media (max-width: 1020px)": {
      padding: "13px",
    },
  },
  aboutLabel: {
    color: "#C4C4C4",
    fontWeight: 500,
    fontSize: "12px",
  },
  aboutValue: {
    color: "#333333",
    fontWeight: 400,
    fontSize: "14px",
    lineHeight: "20px",
    whiteSpace: "pre-wrap",
  },
  editModalRoot: {
    "& .MuiPaper-root": {
      padding: "50px",
      paddingTop: "0px",
      borderRadius: "15px",
    },

    "& .MuiDialogTitle-root": {
      padding: "15px 0 23px 0",
      borderBottom: "1px solid #FAFAFA",
    },

    "& .MuiDialogContent-root": {
      padding: "37px 0",
    },
    "@media (max-width: 750px)": {
      "& .MuiPaper-root": {
        padding: "27px",
        "& .MuiDialogTitle-root": {
          padding: "15px 0 18px 0",
          border: "none",
          borderBottom: "1px solid #FAFAFA",
        },
      },
    },
  },
  dialogHeader: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "flex-start",
  },
  dialogHeaderTitle: {
    fontSize: "18px",
    "@media (max-width: 850px)": {
      fontSize: "14px",
      paddingTop: "0px",
    },
    fontWeight: 600,
    paddingTop: "34px",
    color: "#202020",
  },
  dialogResponse: {
    fontSize: "12px",
    "@media (max-width: 850px)": {
      fontSize: "10px",
    },
    fontWeight: 400,
    color: "#6C6B6B",
  },
  closeIcon: {
    position: "absolute",
    top: "10px",
    right: "14px",

    "&:hover": {
      background: "none",
      border: "1px solid #FAFAFA",
      padding: "11px",
    },
  },
  textareaLenght: {
    position: "absolute",
    top: "12px",
    right: "18px",
  },
  textarea: {
    width: "100%",
    fontSize: "12px",

    "& textarea": {
      "&::-webkit-scrollbar-track": {
        borderRadius: "6px",
        backgroundColor: "transparent",
      },
      "&::-webkit-scrollbar": {
        width: "6px",
      },
      "&::-webkit-scrollbar-thumb": {
        backgroundColor: "#aaa",
        borderRadius: "6px",
      },
    },
    "@media (max-width: 850px)": {
      fontSize: "10px",
    },
    [theme.breakpoints.down("sm")]: {
      fontSize: "10px",
    },
    color: "#6C6B6B",

    "& .MuiOutlinedInput-multiline": {
      padding: "0",
    },

    "& .MuiInputBase-root": {
      borderRadius: "15px !important",
      "& .MuiOutlinedInput-notchedOutline": {
        paddingLeft: "25px",
      },
    },

    "& .MuiInputBase-input": {
      padding: "18px",
      paddingRight: "43px",
      margin: "10px 10px 10px 0",
      [theme.breakpoints.down("sm")]: {
        fontSize: "12px",
      },
    },

    "& .MuiOutlinedInput-root": {
      borderRadius: "50px",
    },

    "& .MuiSelect-select:focus": {
      background: "white",
    },
    "& fieldset": {
      border: "1px solid #C4C4C4 !important",
      paddingLeft: "8px",
    },

    "&:hover": {
      color: "#0DD1C5",

      "& fieldset": {
        border: "1px solid #0DD1C5 !important",
      },
    },

    "& .Mui-focused": {
      color: "#6C6B6B !importnant",
    },

    "& .MuiSelect-root": {
      height: "50px",
      paddingTop: 0,
      paddingBottom: 0,
      display: "flex",
      alignItems: "center",
      padding: "0 30px",
      "@media (max-width: 850px)": {
        height: "45px",
      },
    },
  },
  textareaBox: {
    position: "relative",
  },
  saveButton: {
    background: "linear-gradient(90deg, #03D9B1 0%, #1CBBD9 100%)",
    color: "white",
    textTransform: "none",
    borderRadius: "50px",
    boxShadow:
      "0px 13px 28px rgba(29, 203, 192, 0.2), 0px 8px 16px rgba(29, 203, 192, 0.06)",
    fontSize: "16px",
    "@media (max-width: 850px)": {
      fontSize: "14px",
    },
    padding: "6.5px 46px",
    fontWeight: 500,
    "&:hover": {
      background: "linear-gradient(90deg, #06C9A4 0%, #00A2C1 100%)",
      color: "#FFFFFF",
      boxShadow:
        "0px 13px 28px rgba(29, 203, 192, 0.2), 0px 8px 16px rgba(29, 203, 192, 0.06)",
    },
  },
  cancelButton: {
    textTransform: "none",
    color: "#c4c4c4",
    fontSize: "16px",
    "@media (max-width: 850px)": {
      fontSize: "14px",
    },
    [theme.breakpoints.down("sm")]: {
      fontSize: "14px",
      padding: "6.5px 20px",
    },
    padding: "6.5px 33px",
    borderRadius: "50px",
    "&:hover": {
      background: "white",
      color: "#6C6B6B",
    },
  },
}));
