import { IFilter, ISorting } from 'src/web/features/table/interfaces';

export const filters: IFilter[] = [
  {
    label: 'Choose',
    field: 'showReaded',
    operator: 'eq',
    multiselect: false,
    valueType: 'JSON',
    values: [
      {
        value: 'all',
        label: 'All',
        isSelected: true,
      },
      {
        value: 'new',
        label: 'New',
        isSelected: false,
      },
      {
        value: 'read',
        label: 'Read',
        isSelected: false,
      },
    ],
  },
];

export const sorting: ISorting[] = [
  {
    id: 'title',
    label: '',
    sortable: false,
    active: false,
    order: undefined,
    width: '350px',
    align: 'left',
  },
  {
    id: 'createdAt',
    label: 'Date',
    sortable: true,
    active: false,
    order: undefined,
    width: '200px',
    align: 'left',
  },
  {
    id: 'time',
    label: 'Time',
    sortable: false,
    active: false,
    order: undefined,
    width: '200px',
    align: 'left',
  },
  {
    id: 'id',
    label: 'Voucher number',
    sortable: false,
    active: false,
    order: undefined,
    width: '270px',
    align: 'left',
  },
  // {
  //   id: 'action',
  //   label: '',
  //   sortable: false,
  //   active: false,
  //   order: undefined,
  //   width: '110px',
  //   align: 'left',
  // },
];
