import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  calendar,
  setTime,
  setLoading,
  tableState,
} from "src/web/features/table/sliceTables";
import DatePickers from "src/web/components/UI/DatePickers/DatePickers";
import moment from "moment";

const startDatePlaceholder = moment().day(-30).format("YYYY MMM DD");
const endDatePlaceholder = moment().format("YYYY MMM DD");

export const TableCalendar = (
) => {
  const dispatch = useDispatch();
  const currentCalendar = useSelector(calendar);
  const currentState = useSelector(tableState);

  useEffect(() => {
    if (currentState.detailedFiltersApplied) {
      dispatch(
        setTime({
          start: null,
          end: null,
        })
      );
    }
  }, [currentState.detailedFiltersApplied]);

  return (
    <DatePickers
      startDate={currentCalendar?.time.startTime}
      endDate={currentCalendar?.time.endTime}
      onChange={({ start, end }) => {
        dispatch(
          setTime({
            start: start,
            end: end,
          })
        );
        if ((start && end) || (!start && !end)) {
          dispatch(setLoading({ type: "change", value: true }));
        }
      }}
      // className={classes.datePickerWrapper}
      placeholder={startDatePlaceholder + " - " + endDatePlaceholder}
      props={{
        maxDate: new Date(),
      }}
    />
  );
};
