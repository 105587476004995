import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles(() => ({
  circle_button: {
    cursor: 'pointer',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    borderRadius: '50%',
    border: '3px solid #000',
    height: '60px',
    width: '60px',
  },
}));
