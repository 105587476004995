import {
  IFilter,
  IInitialStateCalendar,
  IInitialStateSearch,
  ISorting,
  ISqlOrder,
  sqlWhereOperatorEnum,
  sqlWhereRule,
} from './interfaces';

/**
 * Get SQL order
 */
export const getOrderRule = (sorting: ISorting[]): ISqlOrder | undefined => {
  let order: ISqlOrder | undefined;

  sorting.forEach((sort) => {
    if (sort.active && sort.order) {
      order = {
        field: sort.id,
        asc: sort.order === 'asc',
      };
    }
  });

  return order;
};

/**
 *  Get calendar SQL rules
 */
export const getCalendarRules = (calendar: IInitialStateCalendar) => {
  if (
    !calendar.isActive ||
    !calendar.time.startTime ||
    !calendar.time.endTime
  ) {
    return [];
  }

  return [
    {
      field: calendar.field,
      operator: sqlWhereOperatorEnum.GE,
      value: calendar.time.startTime,
      valueType: calendar.valueType,
    },
    {
      field: calendar.field,
      operator: sqlWhereOperatorEnum.LE,
      value: calendar.time.endTime,
      valueType: calendar.valueType,
    },
  ];
};

/**
 * Get filter SQL rules
 */
export const getFilterRules = (filters: IFilter[]) => {
  return filters.reduce<sqlWhereRule[]>((acc, filter) => {
    if (filter.operator === sqlWhereOperatorEnum.IN) {
      const multiselectRule = getMultiselectRule(filter);

      return multiselectRule ? [...acc, multiselectRule] : acc;
    }

    const commonRules = getCommonRules(filter);

    return [...acc, ...commonRules];
  }, []);
};

/**
 * Get search rule for new format filter
 */
export const getSearchRule = (search: IInitialStateSearch) => {
  const searchRule: sqlWhereRule = {
    value: `%${search.value}%`,
    field: search.fields[0],
    operator: sqlWhereOperatorEnum.LIKE,
  };

  if (search.valueType) {
    searchRule.valueType = search.valueType;
  }

  return searchRule;
};

/**
 * Get search rules for all search fields
 */
export const getSearchRules = (search: IInitialStateSearch) => {
  const searchRules = search.fields.map((searchField) => {
    const rule: sqlWhereRule = {
      value: `%${search.value}%`,
      field: searchField,
      operator: sqlWhereOperatorEnum.LIKE,
    };

    if (search.valueType) {
      rule.valueType = search.valueType;
    }

    return rule;
  });

  return searchRules;
};

/**
 * Check page is have DynamoDB type
 */
export const isDDB = (page?: string) => {
  if (!page) return false;

  const pagesDDB = [
    'commissions',
  ];
  return pagesDDB.includes(page);
};

const getCommonRules = (filter: IFilter): sqlWhereRule[] => {
  const selectedValues = filter.values.filter((item) => item.isSelected);

  return selectedValues.map((filterValue) => {
    return {
      field: filter.field,
      operator: filterValue.operator || filter.operator,
      value: filterValue.value,
      valueType: filterValue.valueType || filter.valueType,
    };
  });
};

const getMultiselectRule = (filter: IFilter): sqlWhereRule | null => {
  const selectedValues = filter.values
    .filter((item) => item.isSelected)
    .map((item) => item.value);

  if (!selectedValues.length) return null;

  return {
    field: filter.field,
    operator: filter.operator,
    value: JSON.stringify(selectedValues),
    valueType: 'JSON',
  };
};
