import React, { useState } from 'react';
import {
  Box,
  Button,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControlLabel,
  IconButton,
  Typography,
} from '@material-ui/core';

import CloseIcon from 'src/assets/icons/light/close.svg';

import { useStyles } from './styles';

type Props = {
  open: boolean;
  setOpen: (isOpen: boolean) => void;
  menu: () => JSX.Element;
};

export const MobileMenuModal = ({ open, setOpen, menu: Menu }: Props) => {
  const classes = useStyles();

  return (
    <Dialog
      fullWidth
      maxWidth="sm"
      open={open}
      className={classes.mobileMenuWrapper}
      onClose={() => setOpen(false)}>
      <DialogTitle>
        <Box className={classes.mobileMenuWrapperHeader}>
          <Box className={classes.mobileMenuTitle}>Settings</Box>
          <IconButton
            onClick={() => setOpen(false)}
            className={classes.closeIcon}>
            <CloseIcon />
          </IconButton>
        </Box>
      </DialogTitle>
      <DialogContent>
        <Box
          width={1}
          display="flex"
          flexWrap="wrap"
          justifyContent="space-between">
            <Menu />
        </Box>
      </DialogContent>
    </Dialog>
  );
};
