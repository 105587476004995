import { makeStyles } from "@material-ui/core/styles";
import { Theme } from "src/web/themes/theme";

export const useStyles = makeStyles((theme: Theme) => ({
  center_side_header: {
    position: "relative",
    zIndex: 100,
    display: "flex",
    alignItems: "center",
    backgroundColor: theme.trip_shock_theme.environment.trip_shock_navy,
    padding: "16px 0",
    height: "80px",
    boxSizing: "border-box",
    width: "100%",
    "& .DateRangePicker": {
      marginLeft: "10px",
    },
    "& .DateRangePickerInput_arrow": {
      height: "2px",
      width: "4px",
      margin: "0 5px 0 4px",
      background: "#fff",
    },
    "& .DateRangePickerInput_arrow_svg": {
      display: "none",
    },
    "& .DateRangePickerInput": {
      background: "none",
    },
    "& #your_unique_start_date_id": {
      textAlign: "end",
    },
    "& .DateInput_input": {
      color: "#fff",
      backgroundColor: theme.trip_shock_theme.environment.trip_shock_navy,
      fontSize: "14px",
      padding: "10px 0",
      cursor: "pointer",
    },
    "& .DateRangePickerInput__withBorder": {
      border: "none",
    },
    "& .DateInput": {
      width: "77px",
    },
    "& .DateRangePickerInput_calendarIcon": {
      color: "#fff",
      padding: 0,
      margin: "0 5px 0 20px",
      outline: "none",
    },
  },
  input_container: {
    border: "0.6px solid #C4C4C4",
    flexGrow: 4,
    borderRadius: "50px",
  },
  menu_icon: {
    cursor: "pointer",
    marginRight: "7px",
    color: "white",
    [theme.breakpoints.up("lg")]: {
      display: "none",
    },
  },
  searchBox: {
    [theme.breakpoints.down("sm")]: {
      display: "none",
    },
  },
  searchBoxMobile: {
    display: "none",
    [theme.breakpoints.down("sm")]: {
      display: "flex",
      justifyContent: 'flex-end'
    },
  },
  search_field: {
    color: "#C4C4C4",
    width: "100%",
    outline: "none",
    borderRadius: "50px",
    border: "0.6px solid #C4C4C4",
    "& .MuiOutlinedInput-adornedStart": {
      paddingLeft: "30px",
    },
    "& .MuiOutlinedInput-notchedOutline": {
      border: "none",
    },
    "&:focus, &:hover": {
      outline: "none",
      "& .MuiOutlinedInput-notchedOutline": {
        border: "none",
      },
    },
    "& .MuiInputBase-root": {
      height: "35px",
      "& input": {
        paddingLeft: "7px",
        color: "white",
        "&::placeholder": {
          color: "#C4C4C4",
        },
      },
    },
    [theme.breakpoints.down("sm")]: {
      width: "90%",
      "& .MuiOutlinedInput-adornedStart": {
        paddingLeft: "12px",
      },
      "& .MuiInputBase-root": {
        height: "26px",
      },
    },
  },
  flex: {
    display: "flex",
    marginRight: "8px",
    zIndex: 2,
    position: "relative",
    "&:after": {
      content: '""',
      display: "block",
      position: "absolute",
      backgroundColor: "#fff",
      zIndex: -1,
      height: "2px",
      bottom: "0",
      left: "25px",
      right: "0",
    },
    "& .DateRangePickerInput_calendarIcon": {
      margin: "0 15px 8px",
    },
    "& .DateRangePickerInput_calendarIcon_svg": {
      fill: "#fff",
      height: "24px",
    },
    "& .DateInput_input": {
      borderColor: "#fff",
    },
  },
  clearIcon: {
    cursor: "pointer",
  },
}));
