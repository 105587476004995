import React from "react";
import { Box, Button, Paper, Typography } from "@material-ui/core";

import WarningIcon from "src/assets/icons/web/somethingWentWrong.svg";

import { useStyles } from "./styles";

type Props = {
  refetch: () => Promise<any>;
};

export const QueryError = ({ refetch }: Props) => {
  const classes = useStyles();

  return (
    <Box className={classes.root}>
      <Paper className={classes.wrapper}>
        <WarningIcon />
        <Typography variant="h4" className={classes.errorText}>
          Something went wrong...
        </Typography>
        <Button className={classes.tryAgainButton} onClick={refetch}>
          Try again
        </Button>
      </Paper>
    </Box>
  );
};
