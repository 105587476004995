import { useDispatch, useSelector } from 'react-redux';
import { useQuery } from '@apollo/client';
import { GET_DESTINATIONS_AUTOCOMPLETE } from 'src/api/destinations';
import { setNotification } from 'src/redux/Notifications/sliceNotifications';
import { auth } from 'src/redux/Authentication/sliceAuthentication';
import { config, Navigation } from 'src/web/routes/config/navigation';
import { checkIsHasAccessToRoute } from 'src/helpers/limitationRoutes';
import { GET_PARTNER_SHORT } from 'src/api/partners';

interface IShortInfo {
  v1_partners_get: {
    slug: string;
    id: number;
    is_rs_active: number;
  };
}

export const useShortInfo = () => {
  const dispatch = useDispatch();

  const { user } = useSelector(auth);

  const navigation = config.reduce<Navigation[]>((acc, curr) => {
    const { links } = curr;

    const newLinkArray = links.filter((link) => {
      return checkIsHasAccessToRoute(user, link.title, 'routes');
    });

    if (!newLinkArray.length) {
      return [...acc];
    }

    return [
      ...acc,
      {
        ...curr,
        links: newLinkArray,
      },
    ];
  }, []);

  const { data, loading } = useQuery<IShortInfo>(GET_PARTNER_SHORT, {
    onError: (error) => {
      dispatch(
        setNotification({
          type: 'error',
          message: error.message,
          duaration: 10000,
        })
      );
    },
    fetchPolicy: 'cache-first',
    pollInterval: 1800000,
    variables: {
      prm: {
        id: user?.['custom:partner_id'],
        user_id: user?.['custom:partner_id'],
      },
    },
    skip: !user?.['custom:partner_id'],
  });

  return {
    data: data?.v1_partners_get,
    loading,
    navigation,
  };
};
