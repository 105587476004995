import React from 'react';
import { useSelector } from 'react-redux';
import { Route, Redirect, RouteProps } from 'react-router-dom';
import { checkIsHasAccessToRoute } from 'src/helpers/limitationRoutes';
import { auth } from 'src/redux/Authentication/sliceAuthentication';

interface customRouteProps extends RouteProps {
  name?: string;
  firstAvailablePrivateRoute?: string;
}

export const PrivateRoute = ({
  component: Component,
  firstAvailablePrivateRoute,
  ...rest
}: customRouteProps) => {
  const { user } = useSelector(auth);

  if (!user) {
    return <Redirect to="/login" />;
  }

  if (!Component) {
    return null;
  }

  return (
    <Route
      path={rest.path}
      exact
      render={(props) => {
        if (rest.name && !checkIsHasAccessToRoute(user, rest.name, 'routes')) {
          if (firstAvailablePrivateRoute) {
            return <Redirect to={firstAvailablePrivateRoute} />;
          }

          return <Redirect to="/403" />;
        }

        return <Component {...props} />;
      }}
    />
  );
};

export const AuthRoute = ({ component: Component, ...rest }: RouteProps) => {
  const { user } = useSelector(auth);

  if (!Component) {
    return null;
  }

  return (
    <Route
      {...rest}
      render={(props) => {
        return !user ? <Component {...props} /> : <Redirect to="/" />;
      }}
    />
  );
};
