import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles(() => ({
  invoiceWrapper: {
    padding: '70px 100px 140px 100px',
    background: '#FFF',
    '@media (max-width: 1020px)': {
      padding: '30px 20px 50px 20px',
    },

    '& .MuiTypography-h1': {
      color: '#333333',
      fontSize: '34px',
      fontWeight: 600,
    },
    '& .MuiTypography-caption': {
      color: '#6C6B6B',
      fontSize: '18px',
      fontWeight: 500,
    },
    '& .MuiTypography-h2': {
      color: '#333333',
      fontSize: '18px',
      fontWeight: 600,
    },
    '& .MuiTypography-h3': {
      color: '#333333',
      fontSize: '16px',
      fontWeight: 600,
    },
    '& .MuiTableCell-head': {
      color: '#333333',
      fontSize: '14px',
      fontWeight: 500,
      whiteSpace: 'nowrap'
    },
    '& .MuiTypography-h4': {
      color: '#333333',
      fontSize: '16px',
      fontWeight: 500,
    },
    '& .MuiTypography-body1': {
      color: '#6C6B6B',
      fontSize: '16px',
      fontWeight: 400,
    },
    '& .MuiTypography-body2': {
      color: '#6C6B6B',
      fontSize: '12px',
      fontWeight: 500,
    },
  },
  invoiceHeader: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',

    '@media (max-width: 1020px)': {
      flexDirection: 'column',
      alignItems: 'flex-start',
    }
  },
  downloadCSV: {
    height: '35px',
    padding: '0 30px',
    border: '1px solid #2C6DCF',
    color: '#2C6DCF',
    fontSize: '16px',
    background: 'transparent',
    borderRadius: '50px',
    textTransform: 'none',

    '@media (max-width: 1020px)': {
      marginTop: '20px',
    }
  },
  invoiceSubheader: {
    display: 'flex',
    flexDirection: 'row',
    marginBottom: '110px',
    marginTop: '50px',

    '& h3': {
      marginBottom: '10px',
    },

    '@media (max-width: 1020px)': {
      flexDirection: 'column',
      marginBottom: '60px',
    }
  },
  invoiceSubheaderDateWrapper: {
    marginRight: '105px',
  },
  invoiceSubheaderBillWrapper: {
    '@media (max-width: 1020px)': {
      marginTop: '20px',
    }
  },

  table: {
    '& .MuiTableCell-head': {
      backgroundColor: '#F9FCFD',
      borderBottom: 'none',

      '&:first-child': {
        borderRadius: '15px 0 0 15px',
      },
      '&:last-child': {
        borderRadius: '0 15px 15px 0',
      },
    },

    '& .MuiTableCell-body': {
      padding: '24px 16px',
    },

    marginTop: '15px',
  },
  feesBlock: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-end',

    '@media (max-width: 1020px)': {
      flexDirection: 'column-reverse',
      marginBottom: '20px',
    }
  },
  feesBlockFirst: {
    marginTop: '20px',
    padding: '25px',
    borderRadius: 15,
    border: '1px solid #dbf8f6',
    backgroundColor: '#F9FCFD',
    marginRight: '75px',
    height: 'fit-content',

    '& .MuiTypography-body2': {
      margin: '8px 0',
    },

    '@media (max-width: 1020px)': {
      marginRight: '0px',
    }
  },
  feesBlockSecond: {
    marginTop: '16px',
    '& .MuiTypography-body2': {
      margin: '8px 0',
    },

    '@media (max-width: 1020px)': {
      marginLeft: '20px',
    }
  },
  feesBlockDivider: {
    margin: '15px 0',
    backgroundColor: '#dbf8f6',
  },
  netTotalItem: {
    '@media (max-width: 1020px)': {
      marginLeft: '20px',
    }
  },

  mobileTable: {
    display: 'flex',
    flexDirection: 'column',
  },
  mobileTableRow: {
    marginBottom: '20px',
    background: '#F9FCFD',
    borderRadius: '15px',
    padding: '20px',
  },
  mobileTableCell: {
    padding: '15px 0',
    borderBottom: '1px solid #dbf8f6',
    display: 'flex',
    flexDirection: 'column',
  },

  mobileTableHeader: {
    color: '#333333',
    fontSize: '12px',
    fontWeight: 500,
  },
  mobileTableBody: {
    color: '#6C6B6B',
    fontSize: '12px',
    fontWeight: 400,
  }
}));
