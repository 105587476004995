import { makeStyles } from "@material-ui/core";

export const useStyles = makeStyles((theme: any) => ({
  "@keyframes spin": {
    "0%": {
      transform: "rotate(0deg)",
    },
    "100%": {
      transform: "rotate(360deg)",
    },
  },
  datePicker: {
    height: "50px",
    borderRadius: "50px",
    padding: "15px 30px",
    color: "#333333",
    border: "1px solid rgba(0, 0, 0, 0.23)",
    fontSize: "14px",
    width: "100%",
    "&:hover": {
      outline: "none",
      border: "1px solid #333333",
    },
    "&:focus-visible": {
      outline: "none",
      border: "1px solid #333333",
    },
    fontFamily: `"Montserrat", "Helvetica", "Arial", sans-serif`,
    "&::placeholder": {
      color: "#C4C4C4",
      fontFamily: `"Montserrat", "Helvetica", "Arial", sans-serif`,
    },
  },
  datePickerWrapper: {
    "& .react-datepicker-popper": {
      zIndex: "10",
      "@media (max-width: 750px)": {
        position: "fixed !important",
        transform: "none !important",
        bottom: "0px !important",
        left: "25px !important",
        inset: "auto !important",
      },
    },
    "& .react-datepicker__month-container": {
      padding: "19px 23px",
    },
    "& .react-datepicker__input-container": {
      verticalAlign: "text-bottom",
      marginTop: "2px",
    },
    "& .react-datepicker": {
      boxShadow: "0px 3.89312px 23.3587px rgba(96, 96, 97, 0.18)",
      borderRadius: "15px",
      border: "none",
      color: "#6C6B6B",
      fontSize: "12px",
      fontFamily: `"Montserrat", "Helvetica", "Arial", sans-serif`,
    },
    "& .react-datepicker__day--outside-month": {
      visibility: "hidden",
    },
    "& .react-datepicker-popper[data-placement^=bottom] .react-datepicker__triangle::after":
      {
        borderBottomColor: "white",
      },
    "& .react-datepicker-popper[data-placement^=bottom] .react-datepicker__triangle::before":
      {
        borderBottomColor: "white",
      },
    "& .react-datepicker__navigation": {
      margin: "20px",
      padding: "10px",
      boxShadow: "0px 3.89312px 23.3587px rgba(189, 189, 189, 0.23)",
      borderRadius: "16px",
      color: "#333333",
      height: "30px",
      width: "30px",
      "& span": {
        "&.react-datepicker__navigation-icon--next": {
          "&::before": {
            left: "-2px",
            borderWidth: "1px 1px 0 0",
            color: "#333333",
            height: "6px",
            width: "6px",
            top: "9px",
          },
        },
        "&.react-datepicker__navigation-icon--previous": {
          "&::before": {
            right: "-2px",
            borderWidth: "1px 1px 0 0",
            color: "#333333",
            height: "6px",
            width: "6px",
            top: "9px",
          },
        },
      },
    },
    "& .react-datepicker__header": {
      border: "none",
      background: "white",
      paddingBottom: "5px",
      "& .react-datepicker__current-month": {
        color: "#6C6B6B",
        fontSize: "16px",
        paddingBottom: "22px",
      },
    },
    "& .react-datepicker__week": {
      display: "flex",
      margin: "3px 0",
    },
    "& .react-datepicker__day--keyboard-selected": {
      background: "#C4C4C4",
      borderRadius: "50px",
      color: "white",
    },
    "& .react-datepicker__day-name": {
      color: "#6C6B6B",
      fontSize: "12px",
      width: "36px",
      margin: "0 2px",
      padding: "4.5px 0",
    },
    "& .react-datepicker__month": {
      margin: 0,
    },
    "& .react-datepicker__day": {
      color: "#6C6B6B",
      fontSize: "14px",
      width: "36px",
      padding: "4.5px 0",
      maxHeight: "36px",
      margin: "2px",
      "&:hover": {
        borderRadius: "50px",
      },
    },
    "& .react-datepicker__day--today": {
      color: "white",
      background: "#C4C4C4",
      borderRadius: "50px",
      fontWeight: 400,
    },
    "& .react-datepicker__day--range-end": {
      borderRadius: "50px",
      color: "white",
      background: "#0DD1C5 !important",
      boxShadow:
        "0px 13px 28px rgba(29, 203, 192, 0.2), 0px 8px 16px rgba(29, 203, 192, 0.06)",
    },
    "& .react-datepicker__close-icon": {
      marginRight: "18px",
      "&::after": {
        background: "none",
        fontSize: "16px",
        color: "#C4C4C4",
      },
    },
    "& .react-datepicker__day--in-range, & .react-datepicker__day--in-selecting-range":
      {
        color: "#6C6B6B",
        background: "#F9FCFD",
        borderRadius: "50px",
        // borderRaduis: "0px",
        // "&:first-child": {
        //   borderTopRightRadius: "100px",
        //   borderBottomRightRadius: "100px",
        // },
        // "&:last-child": {
        //   borderTopLeftRadius: "100px",
        //   borderBottomLeftRadius: "100px",
        // },
      },
    "& .react-datepicker__day--selecting-range-start, react-datepicker__day--selecting-range-end":
      {
        color: "white",
        background: "#0DD1C5",
        borderRadius: "50px",
      },
    "& .react-datepicker__day--range-end, & .react-datepicker__day--range-start":
      {
        color: "white",
        background: "#0DD1C5",
        borderRadius: "50px",
      },

    "& .react-datepicker__day--selected, .react-datepicker__day--in-selecting-range, .react-datepicker__day--in-range, .react-datepicker__month-text--selected, .react-datepicker__month-text--in-selecting-range, .react-datepicker__month-text--in-range, .react-datepicker__quarter-text--selected, .react-datepicker__quarter-text--in-selecting-range, .react-datepicker__quarter-text--in-range, .react-datepicker__year-text--selected, .react-datepicker__year-text--in-selecting-range, .react-datepicker__year-text--in-range":
      {
        color: "white",
        background: "#0DD1C5",
        borderRadius: "50px",
      },
  },
  datePickerLabel: {
    color: "#333",
    paddingLeft: "6px",
    paddingRight: "6px",
    background: "white",
    marginLeft: "12px",
  },
}));
