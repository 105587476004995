import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles(() => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    background: '#f5f5f5',
  },
  page_container: {
    display: 'flex',
    flex: 1,
  },
  content_container: {
    height: '100vh',
    display: 'flex',
    flexDirection: 'column',
    overflow: 'hidden',
    flex: 1,
  },
  page_content_container: {
    height: 'calc(100vh - 66px)',
    overflow: 'hidden',
    position: 'relative',
  },
  backdrop: {
    position: 'fixed',
    top: 0,
    right: 0,
    bottom: 0,
    left: 0,
    zIndex: 9,
    background: 'rgba(0, 0, 0, 0.5)',
  },
}));
