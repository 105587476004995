import { makeStyles } from "@material-ui/core/styles";

export const useStyles = makeStyles(() => ({
  interior: {
    position: "fixed",
    zIndex: 1400,
    width: "100%",
    maxWidth: "700px",
    bottom: 0,
    left: "50%",
    transform: "translateX(-50%)",
    display: "flex",
    flexDirection: "column",
    padding: "20px",
  },
  external: {
    position: "fixed",
    zIndex: 1400,
    width: "100%",
    maxWidth: "348px",
    top: 0,
    right: 0,
    display: "flex",
    flexDirection: "column",
    padding: "20px",
  },
  alert: {
    width: "100%",
    marginBottom: "10px",
    padding: "8px 28px 8px 36px",
    borderRadius: "100px",
    alignItems: "center",
    '& .MuiAlert-message': {
      fontSize: "14px",
      fontWeight: 600
    },

    "& .MuiIconButton-root": {
      borderRadius: "43px",
      padding: "12px",
      "& svg": {
        width: "12px",
        height: "12px",
      },
    },

    "&.MuiAlert-filledError": {
      background: "#FFE5E6",
      border: "0.6px solid #ff9da1",
      boxShadow: "0px 3.89312px 23.3587px rgba(189, 189, 189, 0.23)",
      '& .MuiAlert-message': {
        color: "#EC4652"
      },
      "& .MuiIconButton-root": {
        background: "#F8B8BC",
      },
    },

    '&.MuiAlert-filledInfo': {
      background: "#E3EFFF",
      border: "0.6px solid #71A5E9",
      boxShadow: "box-shadow: 0px 3.89312px 23.3587px rgba(189, 189, 189, 0.23);",
      '& .MuiAlert-message': {
        color: "#5C8FDC"
      },
      "& .MuiIconButton-root": {
        background: "#C0D4F2",
      },
    },
    '&.MuiAlert-filledWarning': {
      background: "#FFEFD6",
      border: "0.6px solid #EBB35A",
      boxShadow: "0px 3.89312px 23.3587px rgba(189, 189, 189, 0.23)",
      '& .MuiAlert-message': {
        color: "#FF9800"
      },
      "& .MuiIconButton-root": {
        background: "#FFD89D",
      },
    },
    '&.MuiAlert-filledSuccess': {
      background: "#DFF8E3",
      border: "0.6px solid #65C576",
      boxShadow: "0px 3.89312px 23.3587px rgba(189, 189, 189, 0.23)",
      '& .MuiAlert-message': {
        color: "#50B061"
      },
      "& .MuiIconButton-root": {
        background: "#BCE1C2",
      },
    }
  },
}));
