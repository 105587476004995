import {
  Box,
  Button,
  Checkbox,
  Divider,
  FormControl,
  FormControlLabel,
  Radio,
  RadioGroup,
  Typography,
} from '@material-ui/core';
import React, { useState } from 'react';
import { useStyles } from './styles';
import { TripBlock } from './components/TripBlock';
import moment from 'moment';
import { calendarWeek } from 'src/helpers/calendar/calendarWeek';
import { WeekBlock } from './components/WeekBlock';
import { Spiner } from '../../Loaders';
import { SchedulesBlock } from './components/SchedulesBlock';
import { useMutation } from '@apollo/client';
import { TOGGLE_CALENDAR } from 'src/api/calendar';
import { useDispatch, useSelector } from 'react-redux';
import { setNotification } from 'src/redux/Notifications/sliceNotifications';
import {
  checkIsDisabledToSearch,
  convertWeekToObject,
} from 'src/helpers/calendar/calendarRequests';
import { auth } from 'src/redux/Authentication/sliceAuthentication';
import CheckboxIcon from 'src/assets/icons/web/checkbox.svg';
import CheckboxCheckedIcon from 'src/assets/icons/web/checkboxChecked.svg';
import { DatesBlock } from './components/DatesBlock';

export const OpenCloseFilter = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { user } = useSelector(auth);
  const [loading, setLoading] = useState(false);
  const [trip, setTrip] = useState<ITripCalendarData>({} as ITripCalendarData);
  const [type, setType] = useState<'ticket' | 'schedule' | 'option'>('ticket');
  const [dates, setDates] = useState<
    {
      date_from: string | null;
      date_to: string | null;
    }[]
  >([
    {
      date_from: moment()
        .startOf('day')
        .tz('America/Chicago', true)
        .utc()
        .format('YYYY-MM-DD'),
      date_to: moment()
        .startOf('day')
        .add(1, 'day')
        .tz('America/Chicago', true)
        .utc()
        .format('YYYY-MM-DD'),
    },
  ]);
  const [week, setWeek] = useState(calendarWeek);
  const [isOpen, setIsOpen] = useState(false);

  const isDisabled = checkIsDisabledToSearch({ trip, type, week, dates });

  const [toggleCalendar] = useMutation(TOGGLE_CALENDAR, {
    onCompleted: (data) => {
      if (JSON.parse(data.v1_activities_toggleCalendar)?.success) {
        dispatch(
          setNotification({
            message: 'The calendar has been updated successfully',
            type: 'success',
          })
        );
      }
    },
    onError: (error) => {
      dispatch(
        setNotification({
          message: error.message,
          type: 'error',
        })
      );
    },
  });

  const onToggleCalendar = async () => {
    await toggleCalendar({
      variables: {
        prm: {
          trip_id: +trip.id,
          user_id: 2,
          type: type,
          date_ranges: JSON.stringify(
            dates.reduce(
              (acc, { date_from, date_to }, index) => ({
                ...acc,
                [index + 1]: {
                  date_from,
                  date_to,
                },
              }),
              {}
            )
          ),
          days: JSON.stringify(convertWeekToObject(week)),
          schedules_id: JSON.stringify(
            trip.schedules
              .filter((schedule) => schedule.checked)
              .map((schedule) => schedule.id)
          ),
          action: isOpen ? 'open' : 'close',
        },
      },
    });
  };

  return (
    <Box className={classes.cardBox} marginTop="16px" width={1}>
      {loading && <Spiner fixed />}
      <Typography className={classes.cardTitle}>Open / Close Filter</Typography>
      <Divider className={classes.cardDivider} />
      <Box>
        <TripBlock
          setTrip={setTrip}
          type={type}
          setType={setType}
          setLoading={setLoading}
          withoutOptions
        />
        {trip.id && <SchedulesBlock trip={trip} setTrip={setTrip} />}
        <DatesBlock dates={dates} setDates={setDates} />
        <WeekBlock week={week} onChange={setWeek} />

        <Box p={1} width={1} mt={4}>
          <FormControl fullWidth component="fieldset">
            <RadioGroup
              style={{
                flexDirection: 'row',
                justifyContent: 'space-between',
              }}
              aria-label="action"
              value={Number(isOpen)}
              onChange={(e) => setIsOpen(Boolean(Number(e.target.value)))}>
              <FormControlLabel
                style={{ width: '49%' }}
                className={classes.customCheckbox}
                value={1}
                control={
                  <Radio
                    icon={<CheckboxIcon />}
                    checkedIcon={<CheckboxCheckedIcon />}
                  />
                }
                label="Open All"
              />
              <FormControlLabel
                style={{ width: '49%' }}
                className={classes.customCheckbox}
                value={0}
                control={
                  <Radio
                    icon={<CheckboxIcon />}
                    checkedIcon={<CheckboxCheckedIcon />}
                  />
                }
                label="Close All"
              />
            </RadioGroup>
          </FormControl>
        </Box>

        <Box display="flex" justifyContent="flex-end" mt={4}>
          <Button
            //disabled={isDisabled}
            onClick={onToggleCalendar}
            className={classes.button}
            disabled>
            Save
          </Button>
        </Box>
      </Box>
    </Box>
  );
};
