import React from 'react';
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  Typography,
} from '@material-ui/core';

import CloseIcon from 'src/assets/icons/light/close.svg';

import { useStyles } from './styles';

type Props = {
  open: boolean;
  setOpen: (isOpen: boolean) => void;
};

export const DeleteGroupAlertModal = ({ open, setOpen }: Props) => {
  const classes = useStyles();

  return (
    <Dialog
      fullWidth
      maxWidth="xs"
      open={open}
      className={classes.detailModalRoot}
      onClose={() => setOpen(false)}>
      <DialogTitle style={{ paddingBottom: 0, marginTop: 38 }}>
        <Box display="flex" justifyContent="flex-end" marginRight="-30px">
          <IconButton
            onClick={() => setOpen(false)}
            className={classes.closeIcon}>
            <CloseIcon />
          </IconButton>
        </Box>
      </DialogTitle>
      <DialogContent style={{ paddingTop: 0, paddingBottom: 25 }}>
        <Box>
          <Typography className={classes.deleteAlertText}>
            Are you sure you really want to delete this group?
          </Typography>
        </Box>
      </DialogContent>
      <DialogActions style={{ marginBottom: '-20px' }}>
        <Box display="flex" justifyContent="center" width={1}>
          <Button
            style={{ marginRight: 40 }}
            onClick={() => setOpen(false)}
            className={classes.saveButton}
            variant="text">
            No
          </Button>
          <Button
            style={{
              background: 'transparent',
              boxShadow: 'none',
              color: '#6C6B6B',
            }}
            onClick={() => setOpen(false)}
            className={classes.cancelButton}
            variant="contained">
            Yes
          </Button>
        </Box>
      </DialogActions>
    </Dialog>
  );
};
