import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles(() => ({
  headerTitle: {
    display: 'flex',
    alignItems: 'center',

    '& svg': {
      fontSize: '1.8rem',
      marginBottom: '4px',
      marginRight: '8px',
    },
  },
}));