import React from 'react';
import { Link } from 'react-router-dom';
import { Box, Button, ButtonProps, useMediaQuery } from '@material-ui/core';

import ArrowBack from 'src/assets/icons/ArrowBack.svg';
import SeeMoreIcon from 'src/assets/icons/web/seeMore.svg';

import { useStyles } from './styles';
import clsx from 'clsx';

export const InnerPageHeader = ({
  iconTitle,
  backLink,
  components,
  buttons,
  isExistMobileMenu,
  toggleMobileMenu,
}: IInnerPageHeaderProps) => {
  const classes = useStyles();
  const mdMatches = useMediaQuery('(max-width: 1020px)');

  return (
    <Box
      className={clsx(classes.root, {
        withMobileMenu: isExistMobileMenu,
      })}>
      {backLink && (
        <Link
          to={backLink.path}
          className={classes.linkBack}
          onClick={() => {
            if (backLink.onClick) {
              backLink.onClick();
            }
          }}>
          <ArrowBack />
          {backLink.label}
        </Link>
      )}
      {iconTitle && (
        <Box className={classes.titleRoot}>
          {iconTitle.icon}
          <Box className={classes.title}>{iconTitle.title}</Box>
        </Box>
      )}
      {!!components && components}
      {buttons && buttons.length && (
        <Box style={{ marginLeft: mdMatches ? 'auto' : '' }}>
          {buttons.map((button, idx) => {
            return (
              <Button
                {...button.props}
                key={idx}
                variant="outlined"
                className={classes.btn}>
                {mdMatches ? button.mobileIcon : button.title}
              </Button>
            );
          })}
        </Box>
      )}
      {isExistMobileMenu && (
        <Button className={classes.mobileMenuButton} onClick={toggleMobileMenu}>
          <SeeMoreIcon />
        </Button>
      )}
    </Box>
  );
};

export interface IInnerPageHeaderProps {
  iconTitle?: {
    icon: JSX.Element;
    title: string;
  };
  backLink?: {
    path: string;
    label: string;
    onClick?: () => void;
  };
  components?: JSX.Element;
  buttons?: {
    title: string;
    mobileIcon?: JSX.Element;
    props: ButtonProps;
  }[];
  isExistMobileMenu?: boolean;
  toggleMobileMenu?: () => void;
}
