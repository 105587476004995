import moment from 'moment';
import React, { useState } from 'react';
import {
  Box,
  Button,
  Checkbox,
  Divider,
  FormControlLabel,
  MenuItem,
  Radio,
  TextField,
  Typography,
  useMediaQuery,
} from '@material-ui/core';

import { TripBlock } from './components/TripBlock';
import { WeekBlock } from './components/WeekBlock';
import { calendarWeek } from 'src/helpers/calendar/calendarWeek';
import { useStyles } from './styles';
import { SchedulesBlock } from './components/SchedulesBlock';
import { TicketsModal } from '../CalendarModals/TicketsModal';
import { ScheduleModal } from '../CalendarModals/ScheduleModal';
import { OptionModal } from '../CalendarModals/OptionModal';
import { Spiner } from '../../Loaders';
import { checkIsDisabledToSearch } from 'src/helpers/calendar/calendarRequests';
import { DatesBlock } from './components/DatesBlock';
import CheckboxIcon from 'src/assets/icons/web/checkbox.svg';
import CheckboxCheckedIcon from 'src/assets/icons/web/checkboxChecked.svg';

export const DetailedSettingsFilter = () => {
  const classes = useStyles();
  const isMobile = useMediaQuery('(max-width: 960px)');
  const [loading, setLoading] = useState(false);
  const [trip, setTrip] = useState<ITripCalendarData>({} as ITripCalendarData);
  const [type, setType] = useState<'ticket' | 'schedule' | 'option'>('ticket');
  const [week, setWeek] = useState(calendarWeek);
  const [dates, setDates] = useState<
    {
      date_from: string | null;
      date_to: string | null;
    }[]
  >([
    {
      date_from: moment()
        .startOf('day')
        .tz('America/Chicago', true)
        .utc()
        .format('YYYY-MM-DD'),
      date_to: moment()
        .startOf('day')
        .add(1, 'day')
        .tz('America/Chicago', true)
        .utc()
        .format('YYYY-MM-DD'),
    },
  ]);
  const [openCalendarModal, setOpenCalendarModal] = useState(false);
  const [isBatchEdit, setIsBatchEdit] = useState(false);

  const [ticketId, setTicketId] = useState(0);
  const [optionId, setOptionId] = useState(0);
  const [rateId, setRateId] = useState(0);

  const isDisabled = checkIsDisabledToSearch({
    trip,
    type,
    week,
    dates,
    rateId,
  });

  return (
    <Box className={classes.cardBox} marginTop="16px" width={1}>
      {loading && <Spiner fixed />}
      {trip && (
        <>
          <TicketsModal
            trip={trip}
            type={type}
            rateId={rateId}
            week={week}
            dates={dates}
            ticketId={ticketId}
            open={openCalendarModal && type === 'ticket'}
            onClose={() => setOpenCalendarModal(false)}
            isBatchEdit={isBatchEdit}
          />
          <ScheduleModal
            dates={dates}
            trip={trip}
            type={type}
            week={week}
            ticketId={ticketId}
            open={openCalendarModal && type === 'schedule'}
            onClose={() => setOpenCalendarModal(false)}
            isBatchEdit={isBatchEdit}
          />
          <OptionModal
            dates={dates}
            trip={trip}
            type={type}
            week={week}
            optionId={optionId}
            open={openCalendarModal && type === 'option'}
            onClose={() => setOpenCalendarModal(false)}
            isBatchEdit={isBatchEdit}
          />
        </>
      )}

      <Typography className={classes.cardTitle}>
        Detailed settings of availability
      </Typography>
      <Divider className={classes.cardDivider} />
      <Box>
        <TripBlock
          trip={trip}
          setTrip={setTrip}
          type={type}
          setType={setType}
          setLoading={setLoading}
          rateId={rateId}
          setRateId={setRateId}
        />
        {trip.id && type !== 'option' && (
          <SchedulesBlock trip={trip} setTrip={setTrip} />
        )}
        {type === 'ticket' && trip && !!trip.tickets?.length && (
          <Box p={1} pt={3} width={1} mt={1} className={classes.inputWrapper}>
            <TextField
              select
              className={classes.label}
              fullWidth
              value={ticketId}
              label="Ticket Type"
              variant="outlined"
              onChange={(e) => setTicketId(+e.target.value)}>
              <MenuItem key="0" value=""></MenuItem>
              {trip.tickets.map((option, idx: number) => (
                <MenuItem key={idx} value={option.id}>
                  {option.name}
                </MenuItem>
              ))}
            </TextField>
          </Box>
        )}
        {type === 'option' && trip && !!trip.additional_options?.length && (
          <Box p={1} pt={3} width={1} mt={1} className={classes.inputWrapper}>
            <TextField
              select
              fullWidth
              className={classes.label}
              label="Option Type"
              variant="outlined"
              value={optionId}
              onChange={(e) => setOptionId(+e.target.value)}>
              <MenuItem key="0" value=""></MenuItem>
              {trip.additional_options.map((option, idx: number) => (
                <MenuItem key={idx} value={option.id}>
                  {option.name}
                </MenuItem>
              ))}
            </TextField>
          </Box>
        )}
        <DatesBlock dates={dates} setDates={setDates} />
        <WeekBlock week={week} onChange={setWeek} />

        <Box
          marginTop={4}
          className={classes.checkboxItem}
          width={isMobile ? 1 : 1 / 4}>
          <FormControlLabel
            label="Batch edit"
            control={
              <Checkbox
                icon={<CheckboxIcon />}
                checkedIcon={<CheckboxCheckedIcon />}
                checked={isBatchEdit}
                onChange={(_, checked) => {
                  setIsBatchEdit(checked);
                }}
              />
            }
          />
        </Box>
      </Box>

      <Box display="flex" justifyContent="flex-end" mt={4}>
        <Button
          className={classes.button}
          disabled={isDisabled}
          onClick={() => setOpenCalendarModal(true)}>
          Choose
        </Button>
      </Box>
    </Box>
  );
};
