import React, { useRef, useEffect } from "react";
import PhoneInputComponent from "react-phone-input-2";
import { Box } from "@material-ui/core/";
import { IPhoneInput } from "./interfaces";
import "react-phone-input-2/lib/material.css";
import { useStyles } from "./styles";
import { InputError } from "../InputError";

const PhoneInput: React.FC<IPhoneInput> = ({
  placeholder = "enter your number",
  onChange,
  value,
  error,
  helperText = "phone is not correct",
  label,
  onlyCountries,
  preferredCountries,
  required = false,
  autoFocus,
  isForm,
  disabled
}) => {
  const classes = useStyles();

  const phoneInput = useRef<any>(null);
  useEffect(() => {
    if (autoFocus) {
      phoneInput?.current && phoneInput.current.focus();
    }
  }, []);

  return (
    <Box width={1} className={error ? classes.rootError : classes.root}>
      <PhoneInputComponent
        placeholder={placeholder}
        value={value}
        specialLabel={required ? `${label} *` : label}
        onChange={(value, data: any) => {
          onChange(`+${value}`);
          if (isForm) {
            if (value === data.dialCode) {
              onChange("");
            }
          }
        }}
        isValid={!error}
        country={"us"}
        onlyCountries={onlyCountries}
        preferredCountries={preferredCountries}
        countryCodeEditable={false}
        inputProps={{
          ref: phoneInput,
        }}
        disabled={disabled}
      />
      {error && (
        <InputError message={helperText} />
      )}
    </Box>
  );
};

export default PhoneInput;
