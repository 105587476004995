import { makeStyles } from "@material-ui/core/styles";

export const useStyles = makeStyles(() => ({
  root: {
    "& .react-tel-input": {
      "& .form-control": {
        width: "100%",
      },
      "& .special-label": {
        left: "10px",
      },
      "& .form-control::placeholder": {
        color: "#C4C4C4",
      },
    },
  },
  rootError: {
    "& .react-tel-input": {
      "& .form-control": {
        width: "100%",
      },
      "& .special-label": {
        left: "10px",
      },
    },
    "& .special-label": {
      color: "#f44336",
    },
    "& .form-control": {
      width: "100%",
    },
  },
  displayNone: {
    display: "none",
  },
}));
