import { makeStyles } from "@material-ui/core/styles";
import { Theme } from "src/web/themes/theme";

export const useStyles = makeStyles((theme: Theme) => ({
  contentWrapper: {
    paddingTop: "17px",
  },
  table: {
    "& .MuiTableCell-head": {
      color: "#333333",
      height: "40px",
      padding: "0 30px",
      fontSize: "14px",
      fontWeight: 500,
      borderBottom: "1px solid #c6c6c6",
      backgroundColor: "#F9FCFD",
    },
    "& .MuiTableRow-root:hover": {
      ".MuiTableCell-body": {
        background: "#F9FCFD",

        "&:first-child": {
          borderRadius: "15px 0 0 15px",
        },
        "&:last-child": {
          borderRadius: "0 15px 15px 0",
        },
      },
    },
    "& .MuiTableCell-body": {
      height: "68px",
      color: "#6C6B6B",
      fontSize: "12px",
      fontWeight: 500,
      padding: "0 30px",
      borderBottom: "1px solid #dbf8f6",

      "&:first-child": {
        paddingLeft: "30px",
      },

      "&:last-child": {
        paddingRight: "30px",
      },
    },

    "@media (max-width: 1020px)": {
      "& .MuiTableCell-head, & .MuiTableCell-body": {
        padding: "0 15px 0 10px",
      },
      "& .MuiTableCell-body:first-child": {
        paddingLeft: "15px !important",
      },
      "& .MuiTableCell-body:last-child": {
        paddingRight: "15px !important",
      },
    },
  },
  statusWrapper: {
    background: "#4C93FF",
    borderRadius: 50,
    padding: "4px 4px 5px",
    width: "110px",
    height: "20px",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    color: "#FFFFFF",
  },
  actions: {
    display: "flex",
    flexDirection: "row",
  },

  detailModalRoot: {
    "& .MuiPaper-root": {
      padding: "50px",
      paddingTop: "0px",
      borderRadius: "15px",
    },

    "& .MuiDialogTitle-root": {
      padding: "15px 0 23px 0",
      border: "1px solid #FAFAFA",
    },

    "& .MuiDialogContent-root": {
      padding: "37px 0",
    },
    "@media (max-width: 750px)": {
      "& .MuiPaper-root": {
        padding: "27px",
        "& .MuiDialogTitle-root": {
          padding: "15px 0 18px 0",
          border: "none",
          borderBottom: "1px solid #FAFAFA",
        },
      },
    },
  },
  dialogHeader: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "flex-start",
  },
  dialogHeaderTitle: {
    fontSize: "18px",
    "@media (max-width: 850px)": {
      fontSize: "14px",
      paddingTop: "0px",
    },
    fontWeight: 600,
    paddingTop: "34px",
    color: "#202020",
  },
  dialogResponse: {
    fontSize: "12px",
    "@media (max-width: 850px)": {
      fontSize: "10px",
    },
    fontWeight: 400,
    color: "#6C6B6B",
  },
  closeIcon: {
    position: "absolute",
    top: "10px",
    right: "14px",

    "&:hover": {
      background: "none",
      border: "1px solid #FAFAFA",
      padding: "11px",
    },
  },
  deleteAlertText: {
    color: "#333333",
    fontSize: "16px",
    fontWeight: 600,
    textAlign: "center",
  },
  saveButton: {
    background: "linear-gradient(90deg, #03D9B1 0%, #1CBBD9 100%)",
    color: "white",
    textTransform: "none",
    borderRadius: "50px",
    boxShadow:
      "0px 13px 28px rgba(29, 203, 192, 0.2), 0px 8px 16px rgba(29, 203, 192, 0.06)",
    fontSize: "16px",
    "@media (max-width: 850px)": {
      fontSize: "14px",
    },
    padding: "6.5px 46px",
    fontWeight: 500,
    "&:hover": {
      background: "linear-gradient(90deg, #06C9A4 0%, #00A2C1 100%)",
      color: "#FFFFFF",
      boxShadow:
        "0px 13px 28px rgba(29, 203, 192, 0.2), 0px 8px 16px rgba(29, 203, 192, 0.06)",
    },
  },
  cancelButton: {
    textTransform: "none",
    color: "#c4c4c4",
    fontSize: "16px",
    "@media (max-width: 850px)": {
      fontSize: "14px",
    },
    [theme.breakpoints.down("sm")]: {
      fontSize: "14px",
      padding: "6.5px 20px",
    },
    padding: "6.5px 33px",
    borderRadius: "50px",
    "&:hover": {
      background: "white",
      color: "#6C6B6B",
    },
  },

  groupRightsTitle: {
    marginBottom: "30px",
    background: "#F9FCFD",
    borderRadius: "15px",
    height: "24px",
    width: "100%",
    textAlign: "center",
    color: "#6C6B6B",
    fontSize: "12px",
    fontWeight: 500,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  permissionRow: {
    width: "100%",
    display: "flex",
    flexDirection: "row",
    justifyContent: "flex-end",
    alignItems: "center",
  },
  permissionEntityTitle: {
    color: "#6C6B6B",
    fontSize: "12px",
    fontWeight: 500,
    marginRight: "auto",
  },
  permissionWrapper: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",

    "& .MuiFormControlLabel-label": {
      color: "#6C6B6B",
      fontSize: "12px",
    },
  },
  permissionName: {
    color: "#0DD1C5",
    fontSize: "12px",
    fontWeight: 500,
  },

  actionsPaper: {
    backgroundColor: "#ffffff",
    boxShadow: "0px 3.89312px 23.3587px rgba(96, 96, 97, 0.18)",
    borderRadius: "15px",
    padding: "20px",
  },
  actionButton: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    backgroundColor: "transparent",
    fontSize: "10px",
    color: "#6C6B6B",
    textTransform: "none",

    "& svg": {
      marginRight: "9px",
    },

    "&:first-child": {
      marginBottom: "20px",
    },
  },
}));
