/* eslint-disable no-useless-escape */
import parsePhoneNumber from 'libphonenumber-js';

const validation = {
  email: {
    required: {
      value: true,
      message: 'Write your email',
    },
    pattern: {
      value:
        /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
      message: 'Email is invalid',
    },
  },
  password: {
    required: {
      value: true,
      message: 'Write your password',
    },
    minLength: {
      value: 6,
      message: 'Must include min 6 Chars',
    },
    // pattern: {
    //   value:
    //     /(?=^.{8,}$)((?=.*\d)((?=.*\W+)|(?=.*\_)))(?![.\n])(?=.*[A-Z])(?=.*[a-z]).*$/,
    //   message: 'Must include UpperCase, LowerCase, Number/SpecialChar',
    // },
  },
  first_name: {
    required: {
      value: true,
      message: 'Write your first name',
    },
    pattern: {
      value: /[A-Za-z]/,
      message: 'can only contain letters',
    },
  },
  last_name: {
    required: {
      value: true,
      message: 'Write your last name',
    },
    pattern: {
      value: /[A-Za-z]/,
      message: 'can only contain letters',
    },
  },
  phone: {
    required: {
      value: true,
      message: 'Write your phone number',
    },
    validate: {
      value: (v: string | undefined) => {
        const phoneNumber = parsePhoneNumber(v || '', 'US');
        return phoneNumber?.isValid() || false;
      },
    },
  },
};

export default validation;
