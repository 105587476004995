import React, { SyntheticEvent } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  TableSortLabel,
  TableRow,
  TableCell,
  Typography,
} from '@material-ui/core';
import {
  sorting,
  setSorting,
  setLoading,
} from 'src/web/features/table/sliceTables';
import SortingIcon from 'src/assets/icons/sorting.svg';
import { useStyles } from './styles';
import clsx from 'clsx';

export const TableSorting = () => {
  const classes = useStyles();
  const currentSorting = useSelector(sorting);
  const dispatch = useDispatch();

  const onClickHandler = (e: SyntheticEvent) => {
    const sortingKey = Number(
      (e.target as HTMLElement).closest('[data-key]')?.getAttribute('data-key')
    );
    if (!sortingKey && sortingKey !== 0) return;
    dispatch(setLoading({ type: 'change', value: true }));
    dispatch(setSorting(sortingKey));
  };

  return (
    <TableRow onClick={onClickHandler}>
      {currentSorting.map((item: any, idx: any) => (
        <TableCell
          width={item.width ? item.width : 'auto'}
          key={item.id}
          align={item.align ? item.align : item.sortable ? 'left' : 'center'}
          sortDirection={item.active ? item.order : false}
          style={{ padding: '7px 16px' }}
          data-key={item.sortable ? idx : null}>
          {item.sortable ? (
            <TableSortLabel
              className={clsx(classes.tableSort, {
                active: item.active,
                asc: item.order === 'asc',
                desc: item.order === 'desc',
              })}
              active={item.active}
              IconComponent={() => <SortingIcon style={{ opacity: 0.5 }} />}
              direction={item.active ? item.order : undefined}>
              <Typography variant="button" className={classes.sortingItem}>
                {item.label}
              </Typography>
            </TableSortLabel>
          ) : (
            <Typography
              style={{ textTransform: 'none' }}
              className={classes.sortingItem}
              variant="button">
              {item.label}
            </Typography>
          )}
        </TableCell>
      ))}
    </TableRow>
  );
};
