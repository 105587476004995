import { gql } from "@apollo/client";

export const GET_CALENDAR = gql`
  query v1_activities_getCalendar($prm: v1_activities_getCalendar_prm!) {
    v1_activities_getCalendar(prm: $prm) {
      success
      msg
      data
    }
  }
`;

export const UPDATE_CALENDAR = gql`
  mutation v1_activities_updateCalendar(
    $prm: v1_activities_updateCalendar_prm!
  ) {
    v1_activities_updateCalendar(prm: $prm)
  }
`;

export const TOGGLE_CALENDAR = gql`
  mutation v1_activities_toggleCalendar(
    $prm: v1_activities_toggleCalendar_prm!
  ) {
    v1_activities_toggleCalendar(prm: $prm)
  }
`;

export const GET_TRIP = gql`
  query v1_activities_get($prm: getActivityPrm) {
    v1_activities_get(prm: $prm) {
      id
      name
      additional_options {
        id
        is_disabled
        name
        required
        type
        values {
          apply_tax
          is_disabled
          id
          name
          price
        }
      }
      schedules {
        cut_off_hour
        hour
        id
        is_disabled
        max_tickets
        min_tickets
        minute
      }
      tickets {
        ages {
          age_from
          age_to
          visible
        }
        barcodes_remaining
        barcodes_total
        duration
        id
        is_child
        is_default
        is_disabled_ts
        min
        name
        price
        strike_out
        total_ts
        wholesale
        view_order
      }
      rates {
        id
        trip_id
        name
        disabled
      }
      partner_id
      type_id
      weekly
    }
  }
`;
