import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme: any) => ({
  root: {
    padding: '10px 17px',
    position: 'relative',
    display: 'flex',
    flexDirection: 'row',
    height: '66px',
    zIndex: 1000,
    alignItems: 'center',
    backgroundColor: theme.trip_shock_theme.environment.trip_shock_white,
    boxShadow: '0px 3.89312px 23.3587px rgba(17, 15, 15, 0.53)',
    justifyContent: 'space-between',
    [theme.breakpoints.down('xs')]: {
      padding: '0 16px',
    },
  },
  title: {
    color: theme.trip_shock_theme.environment.trip_shock_charcoal,
    fontWeight: 500,
    fontSize: '16px',
    [theme.breakpoints.down('xs')]: {
      lineHeight: '1.2',
      fontSize: '14px',
    },
  },
  dropdown: {
    margin: '10px 0 0 5px',
    cursor: 'pointer',
  },
  avatar: {
    width: '40px',
    height: '40px',
    backgroundColor: '#F9FCFD',
    borderRadius: '50%',
    marginRight: '10px',
    [theme.breakpoints.down('xs')]: {
      display: 'none !important',
    },
  },
  sub_title: {
    display: 'block',
    fontWeight: 400,
    fontSize: '14px',
    color: theme.trip_shock_theme.environment.trip_shock_gray,
  },
  menu_container: {
    width: '40px',
    height: '40px',
    display: 'flex',
    marginRight: '15px',
    alignItems: 'center',
    justifyContent: 'center',
    cursor: 'pointer',
    boxShadow: '0px 3.89312px 23.3587px rgba(189, 189, 189, 0.23)',
    borderRadius: '15px',
  },
  left_container: {
    height: '50px',
    display: 'flex',
    alignItems: 'center',
  },
  headerIcons: {
    display: 'flex',
    alignItems: 'center',
  },
  notificationsBadge: {
    '& span': {
      color: 'white',
      width: '6px',
      height: '6px',
      padding: '0 3px',
      fontSize: '6px',
      minWidth: '4px',
      backgroundColor: '#0DD1C5',
    },
  },
  exitApp: {
    display: 'flex',
    alignItems: 'center',
    paddingLeft: '17px',
    marginLeft: '24px',
    height: '100%',
    borderLeft: '1px solid rgba(224, 224, 224, 1)',
    cursor: 'pointer',
    [theme.breakpoints.down('xs')]: {
      paddingLeft: '16px',
      marginLeft: '16px',
    },
  },
  popupItem: {
    color: '#FFFFFF',
    fontWeight: 300,
    fontSize: '16px',
    lineHeight: '32px',
    cursor: 'pointer',
  },
  popover: {
    '& .MuiPopover-paper': {
      borderRadius: '15px',
      minWidth: '240px',
      top: '80px !important',
    },
  },

  popupDialog: {
    backgroundColor: '#FFFFFF',
    borderRadius: '15px',
    padding: '10px 30px',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    boxShadow: '0px 3.89312px 23.3587px rgba(17, 15, 15, 0.53)',
  },
  navItem: {
    borderBottom: '0.6px solid #dbf8f6',
    padding: '20px 0 18px 0',
    cursor: 'pointer',
    '&:last-child': {
      borderBottom: 'none',
    },
  },
  popoverName: {
    fontSize: '14px',
    color: '#333333',
    marginBottom: '10px',
    fontWeight: 500,
    '@media (max-width: 850px)': {
      fontSize: '12px',
      whiteSpace: 'nowrap',
    },
  },
  popoverInfo: {
    fontSize: '12px',
    color: '#6C6B6B',
    display: 'flex',
    justifyContent: 'space-between',
    '@media (max-width: 850px)': {
      fontSize: '10px',
    },
  },
  popoverBox: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'flex-start',
    width: '100%',
  },
  popoverId: {
    color: '#0DD1C5',
    background: '#e7faf9',
    borderRadius: '50px',
    fontSize: '14px',
    fontWeight: 600,
    textAlign: 'center',
    padding: '2px 13px',
    '@media (max-width: 850px)': {
      fontSize: '12px',
    },
  },
  chatIconWrapper: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    paddingTop: '3px',
    height: '32px',
    width: '32px',
    marginRight: '8px',
  },
}));
