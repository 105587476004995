import {
  Box,
  createStyles,
  LinearProgress,
  Typography,
  withStyles,
} from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import Rating from 'react-rating';
import { useStyles } from './styles';
import RatingFullIcon from 'src/assets/icons/ratingL.svg';
import RatingEmptyIcon from 'src/assets/icons/ratingLEmpty.svg';
import RatingProgressIcon from 'src/assets/icons/ratingM.svg';
import { IReviewsPrm } from 'src/web/pages/Reviews/normalizePrm';
import clsx from 'clsx';
import { RateData } from 'src/redux/Reviews/interfaces';

const BorderLinearProgress = withStyles(() =>
  createStyles({
    root: {
      height: 6,
      borderRadius: 5,
      margin: '0 20px',
      width: '60%',
      '@media (max-width: 1440px)': {
        width: '50%',
      },
      '@media (max-width: 1300px)': {
        width: '40%',
      },
      '@media (max-width: 1175px)': {
        margin: '0 10px',
      },
      '@media (max-width: 720px)': {
        width: '200px',
        margin: '0 20px 0 3px',
      },
    },
    colorPrimary: {
      backgroundColor: '#DDDDDD',
    },
    bar: {
      borderRadius: 5,
      background: 'linear-gradient(90deg, #03D9B1 0%, #1CBBD9 100%)',
      boxShadow: '0px 1.7px 4.8px rgba(13, 209, 197, 0.7)',
    },
  })
)(LinearProgress);

export const Header = ({
  rates,
  onChangeFilter,
  product_id,
}: {
  rates: RateData;
  onChangeFilter: (filter: Partial<IReviewsPrm>) => void;
  product_id?: number;
}) => {
  const classes = useStyles();
  const [choosenRates, setChoosenRates] = useState<number[]>([]);
  const filterRates = product_id ? rates[product_id] : rates['all'];
  const reviewsCount = filterRates?.count;
  const fullRating = filterRates?.avg_rate;

  const progressPercent = (value: number) =>
    Math.ceil((value / reviewsCount) * 100);

  const SVGIcon = (icon: any) => <div style={{ margin: '0 4px' }}>{icon}</div>;

  const totals = filterRates?.by_rate ? JSON.parse(filterRates?.by_rate) : {};

  useEffect(() => {
    if (choosenRates.length) {
      return onChangeFilter({ rates: choosenRates.join(',') });
    }
    return onChangeFilter({ rates: '' });
  }, [choosenRates]);

  const filterByRate = (value: number) => {
    if (choosenRates.includes(value)) {
      const index = choosenRates.indexOf(value);
      if (index > -1) {
        setChoosenRates(choosenRates.filter((item) => item !== value));
      }
    } else {
      setChoosenRates((prev) =>
        [...prev, value].sort((a, b) => Number(a) - Number(b))
      );
    }
  };

  return (
    <>
      <Box className={classes.cardBox} width={1}>
        <Box className={classes.leftContainer}>
          <Box className={classes.leftWebContainer}>
            <Box className={classes.reviewsCount}>
              {reviewsCount || 0} reviews
            </Box>
            <Box className={classes.ratingTitle}>
              {fullRating ? `${fullRating?.toFixed(1)} / 5.0` : '0.0'}
            </Box>
            <Box>
              <Rating
                emptySymbol={SVGIcon(<RatingEmptyIcon />)}
                fullSymbol={SVGIcon(<RatingFullIcon />)}
                readonly
                initialRating={fullRating}
                fractions={2}
              />
            </Box>
          </Box>
          <Box className={classes.leftMobileContainer}>
            <RatingFullIcon />
            <Box className={classes.ratingTitle}>
              {fullRating ? `${fullRating?.toFixed(1)} / 5.0` : '0.00'}
            </Box>
            <Box className={classes.reviewsCount}>({reviewsCount})</Box>
          </Box>
        </Box>

        <Box className={classes.rateContent}>
          <Box
            className={classes.ratingBox}
            style={{ gridArea: 1 / 1 / 2 / 2 }}>
            <Box className={classes.ratingBox}>
              <Typography className={classes.rateNumber}>
                5<span className={classes.adaptive_1}>&thinsp;stars</span>
              </Typography>
              <span className={classes.adaptive}>
                <RatingProgressIcon />
              </span>
            </Box>
            <BorderLinearProgress
              variant="determinate"
              value={progressPercent(totals[5])}
            />
            <Typography
              className={clsx(classes.rateCount, {
                choosedRate: choosenRates.includes(5),
              })}
              onClick={() => {
                if (totals[5]) {
                  filterByRate(5);
                }
              }}
              style={totals[5] ? { cursor: 'pointer' } : { cursor: 'default' }}>
              {totals[5]}
              {filterRates && (
                <span className={classes.adaptive}>&thinsp;reviews</span>
              )}
            </Typography>
          </Box>
          <Box
            className={classes.ratingBox}
            style={{ gridArea: 2 / 1 / 3 / 2 }}>
            <Box className={classes.ratingBox}>
              <Typography className={classes.rateNumber}>
                4<span className={classes.adaptive_1}>&thinsp;stars</span>
              </Typography>
              <span className={classes.adaptive}>
                <RatingProgressIcon />
              </span>
            </Box>
            <BorderLinearProgress
              variant="determinate"
              value={progressPercent(totals[4])}
            />
            <Typography
              className={clsx(classes.rateCount, {
                choosedRate: choosenRates.includes(4),
              })}
              onClick={() => {
                if (totals[4]) {
                  filterByRate(4);
                }
              }}
              style={totals[4] ? { cursor: 'pointer' } : { cursor: 'default' }}>
              {totals[4]}
              {filterRates && (
                <span className={classes.adaptive}>&thinsp;reviews</span>
              )}
            </Typography>
          </Box>
          <Box
            className={classes.ratingBox}
            style={{ gridArea: 3 / 1 / 4 / 2 }}>
            <Box className={classes.ratingBox}>
              <Typography className={classes.rateNumber}>
                3<span className={classes.adaptive_1}>&thinsp;stars</span>
              </Typography>
              <span className={classes.adaptive}>
                <RatingProgressIcon />
              </span>
            </Box>
            <BorderLinearProgress
              variant="determinate"
              value={progressPercent(totals[3])}
            />
            <Typography
              className={clsx(classes.rateCount, {
                choosedRate: choosenRates.includes(3),
              })}
              onClick={() => {
                if (totals[3]) {
                  filterByRate(3);
                }
              }}
              style={totals[3] ? { cursor: 'pointer' } : { cursor: 'default' }}>
              {totals[3]}
              {filterRates && (
                <span className={classes.adaptive}>&thinsp;reviews</span>
              )}
            </Typography>
          </Box>
          <Box
            className={classes.ratingRightBox}
            style={{ gridArea: 1 / 2 / 2 / 3 }}>
            <Box className={classes.ratingRightBox}>
              <Typography className={classes.rateNumber}>
                2<span className={classes.adaptive_1}>&thinsp;stars</span>
              </Typography>

              <span className={classes.adaptive}>
                <RatingProgressIcon />
              </span>
            </Box>
            <BorderLinearProgress
              variant="determinate"
              value={progressPercent(totals[2])}
            />
            <Typography
              className={clsx(classes.rateCount, {
                choosedRate: choosenRates.includes(2),
              })}
              onClick={() => {
                if (totals[2]) {
                  filterByRate(2);
                }
              }}
              style={totals[2] ? { cursor: 'pointer' } : { cursor: 'default' }}>
              {totals[2]}
              {filterRates && (
                <span className={classes.adaptive}>&thinsp;reviews</span>
              )}
            </Typography>
          </Box>

          <Box
            className={classes.ratingRightBox}
            style={{ gridArea: 2 / 2 / 3 / 3 }}>
            <Box className={classes.ratingRightBox}>
              <Typography className={classes.rateNumber}>
                1<span className={classes.adaptive_1}>&thinsp;stars</span>
              </Typography>
              <span className={classes.adaptive}>
                <RatingProgressIcon />
              </span>
            </Box>
            <BorderLinearProgress
              variant="determinate"
              value={progressPercent(totals[1])}
            />
            <Typography
              className={clsx(classes.rateCount, {
                choosedRate: choosenRates.includes(1),
              })}
              onClick={() => {
                if (totals[1]) {
                  filterByRate(1);
                }
              }}
              style={totals[1] ? { cursor: 'pointer' } : { cursor: 'default' }}>
              {totals[1]}
              {filterRates && (
                <span className={classes.adaptive}>&thinsp;reviews</span>
              )}
            </Typography>
          </Box>
        </Box>
      </Box>
    </>
  );
};
