// // interface for filter

export enum sqlWhereOperatorEnum {
  EQ = 'eq',
  GE = 'ge',
  GT = 'gt',
  IN = 'in',
  LE = 'le',
  LT = 'lt',
  NE = 'ne',
  NIN = 'nin',
  NNU = 'nnu',
  NU = 'nu',
  LIKE = 'like'
}

export type sqlWhereOperator =
  | 'eq'
  | 'ge'
  | 'gt'
  | 'in'
  | 'le'
  | 'lt'
  | 'ne'
  | 'nin'
  | 'nnu'
  | 'nu'
  | 'like';

export type sqlValueType = 'JSON' | 'Number' | 'String';

interface sqlWhereRuleWithoutValue {
  field: string;
  operator: sqlWhereOperator;
  valueType?: sqlValueType;
}

export interface sqlWhereRule extends sqlWhereRuleWithoutValue {
  value: string;
}

export interface sqlWhereRuleDetailed extends sqlWhereRuleWithoutValue {
  value: string | null;
}

export interface IDetailedFilters {
  [key: string]: sqlWhereRuleDetailed;
}

export interface IFilterValue {
  label: string;
  value: string;
  isSelected: boolean;
  operator?: sqlWhereOperator;
  valueType?: sqlValueType;
}

export interface IFilter extends sqlWhereRuleWithoutValue {
  label: string;
  multiselect: boolean;
  isHidden?: boolean;
  values: IFilterValue[];
  internal?: boolean;
}

// interface for order

export interface ISqlOrder {
  field: string;
  asc: boolean;
}

export interface ISorting {
  id: string;
  label: string;
  sortable: boolean;
  active: boolean;
  order: 'desc' | 'asc' | undefined;
  width?: string;
  align?: 'left' | 'center' | 'right';
}

// interface for prm

export interface IPrm {
  limit: number;
  offset?: string;
  order?: ISqlOrder;
  where: sqlWhereRule[][];
  where_scheme?: 'and_for_or' | 'or_for_and';
  //pager?: any;
  // type?: string;
  // filter?: any;
  // isApproved?: string;
  // fromDB?: boolean;
  // isArchived?: boolean;
}

export interface ICommissionsFilter {
  search?: string;
  status?: string;
  fromDB?: boolean;
}

// other

export interface IInitialStateCalendar {
  isActive: boolean;
  field: string;
  valueType?: sqlValueType;
  time: {
    startTime: string | null;
    endTime: string | null;
  };
}

export interface IInitialStateSearch {
  isActive: boolean;
  value: string | null;
  fields: string[];
  valueType?: sqlValueType;
}

export interface IHistoryItem {
  prm: IPrm | null;
  filter: IFilter[];
  detailedFilters?: IDetailedFilters;
  sorting: ISorting[];
  search: null | IInitialStateSearch;
  calendar: null | IInitialStateCalendar;
}

export interface IHistory {
  [item: string]: IHistoryItem;
}

export interface IInitialState {
  page: string;
  prm: IPrm | null;
  filter: IFilter[];
  detailedFilters?: IDetailedFilters;
  detailedFiltersApplied?: boolean;
  sorting: ISorting[];
  search: null | IInitialStateSearch;
  calendar: null | IInitialStateCalendar;
  scrollLoading: boolean;
  changeLoading: boolean;
  offset?: string;
  pager?: string;
  history: IHistory;
  availableStates: string[];
  usersFilter?: string | null;
}

export interface ISetInitialPrmProps {
  page: string;
  filter: IFilter[];
  detailedFilters?: IDetailedFilters;
  sorting: ISorting[];
  prm: IPrm | null;
  search: IInitialStateSearch | null;
  calendar: IInitialStateCalendar | null;
}

export interface IChangePrmProps {
  page?: string;
  prm: IPrm | null;
  filter?: IFilter[];
  sorting?: ISorting[];
  search?: IInitialStateSearch | null;
  calendar?: IInitialStateCalendar | null;
  detailedFilters?: IDetailedFilters;
  detailedFiltersApplied?: boolean;
}

export interface IAffiliateFilter {
  status?: string;
  financial?: string;
  search?: string;
  dateFrom?: number;
  tags?: string;
}

// const initialTime = {
//   startTime: moment().endOf('day').format('YYYY-MM-DD HH-mm-ss'),
//   endTime: moment().day(-365).startOf('day').format('YYYY-MM-DD HH-mm-ss'),
// };

export interface ITableSearchProps {
  setOpenSidebar: (value: boolean) => void;
}
