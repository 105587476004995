import React, { useEffect, useState } from "react";
import { Box, Button } from "@material-ui/core";
import { FormElement } from "src/web/components/OnboardingForm/FormElement";
import {
  IOnboardingForm,
  IOnboardingScreen,
  IInterviewBlock,
  IFieldGroupArray,
} from "src/web/components/OnboardingForm/interface";
import { validateField } from "src/web/components/OnboardingForm/validate";
import { testForm } from "src/web/components/OnboardingForm/fieldGroupInputs";
import { ArrowBack } from "@material-ui/icons";
import { IOnboardingFields } from "src/redux/Onboarding/interfaces";
import { formArray } from "./forn";
import { Spiner } from "src/web/components/Loaders";

const FieldsArray = () => {
  const [form, setForm] = useState<any>(null);
  const [activeStep, setActiveStep] = useState(2);
  const [completed, setCompleted] = useState<{ [k: number]: boolean }>({});
  const [step, setStep] = useState(0);
  const [errorMessage, setErrorMessage] = useState<
    Partial<Record<keyof IOnboardingForm, string>>
  >({});
  const [other, setOther] = useState(false);
  const [isNextWasClicked, setIsNextClicked] = useState(false);

  useEffect(() => {
    if (!form) {
      setForm(formArray);
    }
  }, []);

  const currentScreen = testForm?.screens[step] as IOnboardingScreen;

  const currentFields = currentScreen?.content as IInterviewBlock;

  const onCompleteStep = (type: boolean) => {
    const newCompleted = completed;
    newCompleted[activeStep] = type;
    setCompleted(newCompleted);
  };

  const handleComplete = () => {
    onCompleteStep(true);
    console.log("complete");
  };

  useEffect(() => {
    if (activeStep === 0) {
      const keyDownHandler = (event: any) => {
        if (event.key === "Enter") {
          handleComplete();
        }
      };

      document.addEventListener("keydown", keyDownHandler);

      return () => {
        document.removeEventListener("keydown", keyDownHandler);
      };
    }
  }, [activeStep, form]);

  const handleChange = (
    field: string,
    value: string,
    parentName: string,
    index?: number
  ) => {
    if (isNextWasClicked) {
      validateField(setErrorMessage, currentFields, field, value);
    }

    if (typeof index === "number") {
      const arrayClone = JSON.parse(JSON.stringify(form[parentName]));
      arrayClone[index][field] = value;

      setForm((prev: any) => {
        return {
          ...prev,
          [parentName]: arrayClone,
        };
      });
    } else {
      setForm((prev: any) => {
        return {
          ...prev,
          [field]: value,
        };
      });
    }
  };

  const incrementStep = () => {
    setIsNextClicked(true);
    let isValid = true;

    form[currentScreen.name as string].forEach((formElement: any) => {
      // console.log(formElement);
      currentFields?.fields?.forEach((item: any) => {
        item.fields.forEach((field: any) => {
          console.log(field, item.name, "===");
          if (!isValid) {
            return;
          }

          isValid = validateField(
            setErrorMessage,
            item,
            field.name as string,
            formElement[field.name as keyof IOnboardingFields]
          );

          console.log(isValid, currentFields);
        });
      });
    });

    if (!isValid || !testForm) {
      return;
    }

    if (step === testForm.screens.length - 1) {
      handleComplete();
      return;
    }

    setOther(false);
    setStep(step + 1);
  };

  const decrementStep = () => {
    setStep(step - 1);
  };

  if (!form) {
    return <Spiner fixed />;
  }

  console.log(form);
  return (
    <>
      <Box>
        <FormElement
          form={testForm}
          formInput={currentScreen}
          errorMessage={errorMessage}
          handleChange={handleChange}
          data={form}
          receivedData={form}
          setOther={setOther}
          other={other}
          handleAccept={handleChange}
        />
      </Box>
      <Button
        onClick={decrementStep}
        variant="text"
        color="primary"
        type="submit"
        size="large"
      >
        <ArrowBack />
        Back
      </Button>
      <Button
        onClick={incrementStep}
        variant="text"
        color="primary"
        type="submit"
        size="large"
      >
        Next
      </Button>
    </>
  );
};

export default FieldsArray;
