import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles(() => ({
  headerTitle: {
    display: 'flex',
    alignItems: 'center',

    '& svg': {
      height: '24px',
      marginRight: '8px',
      fill: '#fff',
    },
  },
}));
