import React, { ReactNode } from 'react';
import {
  ARRIVALS_PATH,
  CALENDAR_MANAGEMENT_PATH,
  DASHBOARD_PATH,
  FIELDS_GROUP_PATH,
  ONBOARDING_PATH,
  PAYOUTS_PATH,
  REVIEWS_PATH,
} from 'src/web/routes/config/paths';
import DashboardIcon from 'src/assets/icons/web/sidebar/dashboard.svg';
import ArrivalsIcon from 'src/assets/icons/web/sidebar/arrivals.svg';
import ReviewsIcon from 'src/assets/icons/web/sidebar/reviews.svg';
import CalendarIcon from 'src/assets/icons/web/sidebar/calendar.svg';
import PaymentsIcon from 'src/assets/icons/web/sidebar/payments.svg';

export const config: Navigation[] = [
  {
    title: 'Sales',
    links: [
      {
        title: 'Dashboard',
        path: DASHBOARD_PATH,
        icon: <DashboardIcon />,
      },
      {
        title: 'Arrivals',
        path: ARRIVALS_PATH,
        icon: <ArrivalsIcon />,
      },
    ],
  },
  {
    title: 'Controls',
    links: [
      {
        title: 'Reviews',
        path: REVIEWS_PATH,
        icon: <ReviewsIcon />,
      },
      {
        title: 'Calendar Management',
        path: CALENDAR_MANAGEMENT_PATH,
        icon: <CalendarIcon />,
      },
      {
        title: 'Payments',
        path: PAYOUTS_PATH,
        icon: <PaymentsIcon />,
      },
    ],
  },
];

export type NavigationLink = {
  title: string;
  path?: string;
  icon?: ReactNode;
  dropdownId?: string;
  links?: NavigationLink[];
};

export type Navigation = {
  title: string;
  links: NavigationLink[];
};
