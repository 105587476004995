import { hexToRgb } from '@material-ui/core';
import React from 'react';
import { Sector } from 'recharts';
import { dollarAmountsFloat } from 'src/helpers/DollarAmounts';

type Props = {
  cx: number;
  cy: number;
  midAngle: number;
  innerRadius: number;
  outerRadius: number;
  startAngle: number;
  endAngle: number;
  fill: string;
  payload: any;
  percent: number;
  value: any;
  title: any;
  isActive: boolean;
};

const ActiveShape = (props: Props) => {
  const RADIAN = Math.PI / 180;
  const {
    cx,
    cy,
    midAngle,
    innerRadius,
    outerRadius,
    startAngle,
    endAngle,
    fill,
    payload,
    percent,
    value,
    title,
    isActive,
  } = props;

  const sin = Math.sin(-RADIAN * midAngle);
  const cos = Math.cos(-RADIAN * midAngle);
  const sx = cx + (outerRadius + 10) * cos;
  const sy = cy + (outerRadius + 5) * sin;
  const mx = cx + (outerRadius + 60) * cos;
  const my = cy + (outerRadius + 50) * sin;
  const ex = mx + (cos >= 0 ? 1 : -1) * 55;
  const ey = my;
  const textAnchor = cos >= 0 ? 'start' : 'end';

  const amount = dollarAmountsFloat(payload.total);
  const rgbFill = hexToRgb(fill);

  if (isActive) {
    return (
      <g>
        <g>
          <text
            x={cx}
            y={cy - 20}
            dy={8}
            textAnchor="middle"
            fill="#6C6B6B"
            fontSize={11}>
            Total Earnings
          </text>
          <text
            x={cx}
            y={cy}
            dy={8}
            textAnchor="middle"
            fill="#333333"
            fontSize={16}
            fontWeight={600}>
            {amount}
          </text>
          <text
            x={cx}
            y={cy + 15}
            dy={11}
            textAnchor="middle"
            fill="#6C6B6B"
            fontSize={10}>
            {payload.count} orders
          </text>
        </g>

        <Sector
          style={{
            cursor: 'pointer',
          }}
          cx={cx}
          cy={cy}
          innerRadius={innerRadius - 4}
          outerRadius={outerRadius + 4}
          startAngle={startAngle}
          endAngle={endAngle}
          fill={fill}
        />
        {isActive && (
          <Sector
            style={{ cursor: 'pointer' }}
            cx={cx}
            cy={cy}
            startAngle={startAngle}
            endAngle={endAngle}
            innerRadius={outerRadius + 10}
            outerRadius={outerRadius + 14}
            fill={fill}
          />
        )}
      </g>
    );
  }

  return (
    <g>
      <g>
        <text
          x={cx}
          y={cy - 20}
          dy={8}
          textAnchor="middle"
          fill="#6C6B6B"
          fontSize={11}>
          Total Earnings
        </text>
        <text
          x={cx}
          y={cy}
          dy={8}
          textAnchor="middle"
          fill="#333333"
          fontSize={16}
          fontWeight={600}>
          {amount}
        </text>
        <text
          x={cx}
          y={cy + 15}
          dy={8}
          textAnchor="middle"
          fill="#6C6B6B"
          fontSize={11}>
          {payload.count} orders
        </text>
      </g>

      <Sector
        style={{
          cursor: 'pointer',
        }}
        cx={cx}
        cy={cy}
        innerRadius={innerRadius}
        outerRadius={outerRadius}
        startAngle={startAngle}
        endAngle={endAngle}
        fill={fill}
      />
    </g>
  );
};

export default ActiveShape;
