import { makeStyles } from '@material-ui/core/styles';
import { Theme } from 'src/web/themes/theme';

export const useStyles = makeStyles((theme: Theme) => ({
  root: {
    background: '#FFFFFF',
    padding: '40px',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    '@media (max-width: 620px)': {
      padding: '24px 18px',
    },
  },
  logoWrapper: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'column',
    '@media (max-width: 620px)': {
      display: 'none',
    },
  },
  logoWrapperAdaptive: {
    display: 'none',
    '@media (max-width: 620px)': {
      display: 'flex',
      justifyContent: 'center',
      paddingBottom: '5px',
    },
  },
  stepper: {
    width: '70%',
    marginTop: '24px',
    marginBottom: '20px',
    '& .MuiStepConnector-lineHorizontal': {
      borderTopWidth: '4px',
      borderColor: '#F9FCFD',
    },
    '@media (max-width: 620px)': {
      width: '100%',
      marginTop: '0px',
      marginBottom: '10px',
    },
  },
  contentWrapper: {
    width: '50%',
    '@media (max-width: 620px)': {
      width: '100%',
    },
  },
  step: {
    background: '#F9FCFD',
    width: '200px',
    borderRadius: '100px',
    padding: '14px 29px',
    '& .MuiStepLabel-label': {
      color: '#C4C4C4',
      fontSize: '16px',
      whiteSpace: 'nowrap',
      fontWeight: 500,
      display: 'flex',
    },
    '& .MuiStepLabel-label.MuiStepLabel-active': {
      color: '#0DD1C5',
    },
    '&.completed': {
      background: '#F0FCFC',
      '& .MuiStepLabel-label': {
        color: '#0DD1C5',
      },
    },
    '@media (max-width: 620px)': {
      width: 'auto',
      minWidth: '15px',
      padding: '9.5px 10.5px',
      '& .MuiStepLabel-label': {
        fontSize: '12px',
      },
      '& .MuiStepLabel-active .step_title': {
        display: 'block !important',
      },
      '& .MuiStepLabel-labelContainer .step_title': {
        display: 'none',
      },
      '& .MuiStepLabel-iconContainer': {
        padding: '0',
      },
      '& .MuiStepLabel-label.MuiStepLabel-active': {
        width: '185px',
        justifyContent: 'center',
      },
      '&.completed': {
        '& .MuiStepLabel-labelContainer': {
          display: 'none',
        },
      },
    },
  },
  completeIconAdaptive: {
    display: 'none',
    '@media (max-width: 620px)': {
      display: 'block',
    },
  },
  completeIcon: {
    display: 'block',
    '@media (max-width: 620px)': {
      display: 'none',
    },
  },
  termsWrapper: {
    width: '50%',
    marginTop: '48px',
    '@media (max-width: 1024px)': {
      width: '70%',
      marginTop: '20px',
    },
    '@media (max-width: 620px)': {
      width: '100%',
    },
  },
}));
