import React, { useState } from 'react';
import { Route, Switch, useParams } from 'react-router-dom';

import SettingsIcon from 'src/assets/icons/web/settings.svg';
import ProfileIcon from 'src/assets/icons/web/settings/profile.svg';
import CompanyIcon from 'src/assets/icons/web/settings/company.svg';
import NotificationsIcon from 'src/assets/icons/web/settings/notifications.svg';
import UsersIcon from 'src/assets/icons/web/settings/users.svg';
import GroupsIcon from 'src/assets/icons/web/settings/groups.svg';
import AddHeaderButtonIcon from 'src/assets/icons/web/addHeaderButton.svg';
import { PageWithMenuLayout } from 'src/web/layouts/PageWithMenuLayout';
import ProfilePage from './Profile';
import CompanyPage from './Company';
import NotificationsPage from './Notifications';
import UsersPage from './Users';
import GroupsPage from './Groups';
import { checkIsHasAccessToRoute } from 'src/helpers/limitationRoutes';
import { useSelector } from 'react-redux';
import { auth } from 'src/redux/Authentication/sliceAuthentication';
import { isUserHasPermissions } from 'src/helpers/permissions';
import { GET_PARTNER } from 'src/api/partners';
import { useQuery } from '@apollo/client';
import { Spiner } from 'src/web/components/Loaders';

type IRouteParams = {
  page: 'profile' | 'company' | 'notifications' | 'users' | 'groups';
};

export interface IPartner {
  about_us: string;
  address: string;
  admin_only_email: string | null;
  email: string;
  first_name: string;
  last_name: string;
  id: number;
  name: string;
  phone: string;
  phone_client: string;
  title: string;
  comission: string | number;
}

export const SettingsPage = () => {
  const { page } = useParams<IRouteParams>();
  const { user } = useSelector(auth);
  const [partnerData, setPartnerData] = useState<IPartner | null>(null);

  const [openDetailUsers, setOpenDetailUsers] = useState(false);
  const [openDetailGroups, setOpenDetailGroups] = useState(false);

  const { loading } = useQuery(GET_PARTNER, {
    variables: {
      prm: {
        id: +user['custom:partner_id'],
        user_id: +user['custom:partner_id'],
      },
    },
    onCompleted: (data) => {
      console.log('test');
      setPartnerData(data.v1_partners_get);
    },
    onError: (error) => {
      console.log(error);
    },
  });

  const isCanEditUsers = isUserHasPermissions(user, ['users_manage']);

  if (!partnerData || loading) {
    return <Spiner />;
  }

  const allPagesMap = {
    profile: {
      name: 'Profile',
      component: <ProfilePage />,
      headerButtons: undefined,
    },
    company: {
      name: 'Company',
      component: <CompanyPage data={partnerData} setData={setPartnerData} />,
      headerButtons: undefined,
    },
    notifications: {
      name: 'Notifications',
      component: <NotificationsPage data={partnerData} />,
      headerButtons: undefined,
    },
    users: {
      name: 'Users',
      component: (
        <UsersPage
          openDetail={openDetailUsers}
          setOpenDetail={setOpenDetailUsers}
        />
      ),
      headerButtons: isCanEditUsers
        ? [
            {
              title: 'Add a new user',
              mobileIcon: <AddHeaderButtonIcon />,
              props: {
                onClick: () => setOpenDetailUsers(true),
              },
            },
          ]
        : undefined,
    },
    groups: {
      name: 'Groups',
      component: (
        <GroupsPage
          openDetail={openDetailGroups}
          setOpenDetail={setOpenDetailGroups}
        />
      ),
      headerButtons: [
        {
          title: 'Add a new group',
          mobileIcon: <AddHeaderButtonIcon />,
          props: {
            onClick: () => setOpenDetailGroups(true),
          },
        },
      ],
    },
  };

  const allMenuItems = [
    {
      link: '/settings/profile',
      title: 'Profile',
      icon: <ProfileIcon />,
    },
    {
      link: '/settings/company',
      title: 'Company',
      icon: <CompanyIcon />,
    },
    {
      link: '/settings/notifications',
      title: 'Notifications',
      icon: <NotificationsIcon />,
    },
    {
      link: '/settings/users',
      title: 'Users',
      icon: <UsersIcon />,
    },
    // {
    //   link: '/settings/groups',
    //   title: 'Groups',
    //   icon: <GroupsIcon />,
    // },
  ];

  const menu = allMenuItems.filter((item) =>
    checkIsHasAccessToRoute(user, item.title, 'settings_tabs')
  );

  const pages = Object.keys(allPagesMap).reduce<any>((acc, key) => {
    if (
      !checkIsHasAccessToRoute(
        user,
        allPagesMap[key as keyof typeof allPagesMap].name,
        'settings_tabs'
      )
    ) {
      return {
        ...acc,
      };
    }

    return {
      ...acc,
      [key]: allPagesMap[key as keyof typeof allPagesMap],
    };
  }, {});

  return (
    <PageWithMenuLayout
      headerProps={{
        iconTitle: {
          icon: <SettingsIcon />,
          title: 'Settings',
        },
        buttons: pages[page]?.headerButtons,
      }}
      menu={menu}
      content={
        <Switch>
          {Object.keys(pages).map((pageName, index) => (
            <Route
              key={index}
              exact
              path={`/settings/${pageName}`}
              render={() => pages[pageName].component}
            />
          ))}
        </Switch>
      }
    />
  );
};
