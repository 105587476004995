import { gql } from '@apollo/client';

export const GET_EMPLOYEE_PERMISSIONS = gql`
  query v1_partners_getEmployee($prm: getPartnersEmployeePrm!) {
    v1_partners_getEmployee(prm: $prm) {
      permissions
    }
  }
`;

export const GET_EMPLOYEES_LIST = gql`
  query v1_partners_listEmployees($prm: listPartnersEmployeesPrm!) {
    v1_partners_listEmployees(prm: $prm) {
      items {
        email
        first_name
        id
        last_name
        partner_id
        permissions
        phone
      }
      pager
    }
  }
`;

export const CREATE_EMPLOYEE = gql`
  mutation v1_partners_createEmployee($prm: createPartnerEmployeePrm!) {
    v1_partners_createEmployee(prm: $prm) {
      id
      success
    }
  }
`;

export const UPDATE_EMPLOYEE = gql`
  mutation v1_partners_updateEmployee($prm: updatePartnerEmployeePrm!) {
    v1_partners_updateEmployee(prm: $prm) {
      success
    }
  }
`;

export const DELETE_EMPLOYEE = gql`
  mutation v1_partners_deleteEmployee($prm: deletePartnerEmployeePrm!) {
    v1_partners_deleteEmployee(prm: $prm) {
      success
    }
  }
`;
