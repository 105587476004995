import React from "react";
import { Box, Typography } from "@material-ui/core";
import { useStyles } from "./styles";
import NotFoundIcon from "src/assets/icons/web/general/notFound.svg";

export const NotFoundData = ({ title }: { title: string }) => {
  const classes = useStyles();

  return (
    <Box className={classes.mainBox}>
      <NotFoundIcon />
      <Typography style={{ marginTop: "30px" }} align="center">
        {title}
      </Typography>
    </Box>
  );
};
