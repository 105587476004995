import React from 'react';
import {
  Box,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from '@material-ui/core';
import PerfectScrollbar from 'react-perfect-scrollbar';

import { dollarAmountsFloat } from 'src/helpers/DollarAmounts';
import { useStyles } from './styles';

type Props = {
  trips: IDashboardTripExtended[];
};

export const DashboardTable = ({ trips }: Props) => {
  const classes = useStyles();

  return (
    <TableContainer className={classes.dashboardTable}>
      <PerfectScrollbar style={{ maxHeight: 420 }}>
        <Table stickyHeader>
          <TableHead>
            <TableRow className={classes.dashboardTableHeadRow}>
              <TableCell>Trip name</TableCell>
              <TableCell>Total Earnings</TableCell>
              <TableCell>Orders</TableCell>
              <TableCell>Percent</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {trips.map((trip) => (
              <TableRow key={trip.id} className={classes.dashboardTableBodyRow}>
                <TableCell style={{ display: 'flex', alignItems: 'center' }}>
                  <Box
                    className={classes.dashboardTableTripColor}
                    style={{ backgroundColor: trip.color }}
                  />
                  {trip.name}
                </TableCell>
                <TableCell>{dollarAmountsFloat(trip.total)}</TableCell>
                <TableCell>{trip.count}</TableCell>
                <TableCell>
                  {trip.percent ? `${trip.percent}%` : '0%'}
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </PerfectScrollbar>
    </TableContainer>
  );
};
