export const getTripColor = (
  tripId: string | number,
  existingColors: string[] = [],
  searchLimit?: number
): string => {
  const colors = [
    '#fc4747',
    '#e68c17',
    '#b8a202',
    '#73c902',
    '#08820e',
    //'#04b062',
    '#005de0',
    '#9008ff',
    '#eb05b1',
    //'#cc041f',
  ];

  const color = colors[+tripId % colors.length];
  if (existingColors.includes(color) && (!searchLimit || searchLimit < 5)) {
    return getTripColor(
      +tripId + Math.floor(Math.random() * 100),
      existingColors,
      (searchLimit || 0) + 1
    );
  }

  return color;
};
