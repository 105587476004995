import React from 'react';

import { NotificationsContent } from 'src/web/components/Settings/Notifications';
import { IPartner } from '..';

const NotificationsPage = ({ data }: { data: IPartner }) => {
  return <NotificationsContent data={data} />;
};

export default NotificationsPage;
