import React from 'react';
import { useStyles } from './styles';
import { Box, Typography } from '@material-ui/core';
import NoNotificaions from 'src/assets/icons/web/noNotifications.svg';
import { Link } from 'react-router-dom';
import NoNotificaionsAraptive from 'src/assets/icons/web/noNotificationsAdaptive.svg';

interface IProps {
  handleClose: () => void;
}

const NoNotificationsElement = ({ handleClose }: IProps) => {
  const classes = useStyles();

  return (
    <Box className={classes.popupDialog} style={{ boxShadow: 'none' }}>
      <Box className={classes.noNotificationWrapper}>
        <Box className={classes.noNotificationIcon}>
          <NoNotificaions />
        </Box>
        <Box className={classes.noNotificationIconAdaptive}>
          <NoNotificaionsAraptive />
        </Box>
        <Typography className={classes.noNotificationTitle}>
          No Notifications  Found
        </Typography>
        {/* <Box display={'flex'} justifyContent="center">
          <Link
            onClick={() => handleClose()}
            to={'/notifications'}
            className={classes.viewAllButton}>
            View all
          </Link>
        </Box> */}
      </Box>
    </Box>
  );
};

export default NoNotificationsElement;
