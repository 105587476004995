import { gql } from '@apollo/client';

export const GET_REVIEWS_LIST = gql`
  query v1_general_reviewsList($prm: prmReviewsList) {
    v1_general_reviewsList(prm: $prm) {
      data {
        reviews {
          customer
          id
          link
          order_id
          product_id
          product_link
          product_name
          rate
          reply_id
          reply_text
          reply_time
          reply_time_format
          text
          time
          time_format
          title
        }
        totals {
          avg_rate
          by_rate
          count
        }
        pagination {
          current_page
          from
          to
          total
          total_page
        }
      }
      msg
      success
    }
  }
`;

export const UPDATE_REVIEW = gql`
  mutation v1_general_updateReview($prm: updateReviewsPrm!) {
    v1_general_updateReview(prm: $prm) {
      data
      msg
      success
    }
  }
`;

export const DELETE_REVIEW = gql`
  mutation v1_general_deleteReview($prm: deleteReviewPrm!) {
    v1_general_deleteReview(prm: $prm) {
      data
      msg
      success
    }
  }
`;
