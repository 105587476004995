import { useQuery } from '@apollo/client';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import PayoutsIcon from 'src/assets/icons/web/payouts.svg';

import { PageTable } from 'src/web/features/table';
import {
  page,
  setInitialPrm,
  prm as initialPrm,
  setLoading,
  setOffset,
} from 'src/web/features/table/sliceTables';
import { auth } from 'src/redux/Authentication/sliceAuthentication';

import { GET_GENERAL_INVOICES_LIST } from 'src/api/payouts';

import { normalizePrm } from './data/normalizedPrm';
import { sorting } from './data';
import { PayoutsList } from 'src/web/components/Payouts/PayoutsList';
import { Box } from '@material-ui/core';

import { useStyles } from './styles';

const Payouts = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { user } = useSelector(auth);
  const prm = useSelector(initialPrm);
  const currentPage = useSelector(page);

  const [payouts, setPayouts] = useState<IPayoutInvoice[] | []>([]);
  const [payoutsPrm, setPayoutsPrm] = useState<any>(null);

  const partner_id = +user['custom:partner_id'];

  const { error } = useQuery(GET_GENERAL_INVOICES_LIST, {
    skip: payoutsPrm === null || currentPage !== 'payouts',
    variables: {
      prm: payoutsPrm,
    },
    onCompleted: (payoutsData) => {
      const { invoices, pagination } = payoutsData.v1_general_invoicesList.data;

      if (payoutsPrm && payoutsPrm.page_num > 1) {
        setPayouts((prev: any) => {
          return [...prev, ...(invoices as IPayoutInvoice[])];
        });
      } else {
        setPayouts(invoices as IPayoutInvoice[]);
      }

      dispatch(setLoading({ type: 'scroll', value: false }));
      dispatch(setLoading({ type: 'change', value: false }));

      const newOffset = pagination.current_page * payoutsPrm.per_page;

      if (newOffset < pagination.total) {
        dispatch(setOffset(`${newOffset}`));
      } else {
        dispatch(setOffset());
      }
    },
  });

  useEffect(() => {
    dispatch(
      setInitialPrm({
        page: 'payouts',
        filter: [],
        sorting: sorting,
        search: null,
        calendar: null,
        prm: {
          limit: 15,
          where: [[]],
        },
      })
    );
  }, [dispatch]);

  useEffect(() => {
    if (prm) {
      setPayoutsPrm(normalizePrm(prm, partner_id));
    }
  }, [prm]);

  return (
    <>
      <PageTable
        headerElement={
          <Box className={classes.headerTitle}>
            <PayoutsIcon />
            Payments
          </Box>
        }
        withoutSidebar
        content={<PayoutsList payouts={payouts} />}
        error={error}
      />
    </>
  );
};

export default Payouts;
