import React, { useState } from 'react';
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  TextField,
} from '@material-ui/core';

import CloseIcon from 'src/assets/icons/light/close.svg';

import { useStyles } from './styles';

type Props = {
  open: boolean;
  setOpen: (isOpen: boolean) => void;
};

export const EditNotificationsModal = ({ open, setOpen }: Props) => {
  const classes = useStyles();

  return (
    <Dialog
      fullWidth
      maxWidth="sm"
      open={open}
      className={classes.editModalRoot}
      onClose={() => setOpen(false)}>
      <DialogTitle>
        <Box className={classes.dialogHeader}>
          <Box className={classes.dialogHeaderTitle}>Edit</Box>
          <IconButton
            onClick={() => setOpen(false)}
            className={classes.closeIcon}>
            <CloseIcon />
          </IconButton>
        </Box>
      </DialogTitle>
      <DialogContent>
        <Box>
          <TextField
            className={classes.editInput}
            label="Main email"
            value="2by2host@gmail.com"
            variant="outlined"
          />

          <TextField
            className={classes.editInput}
            label="Admin only email"
            value="admin_only@gmail.com"
            variant="outlined"
          />

          <TextField
            className={classes.editInput}
            label="Accounting email"
            value="accounting@gmail.com"
            variant="outlined"
          />

          <TextField
            className={classes.editInput}
            label="Email for notifications"
            value="notifications@gmail.com"
            variant="outlined"
          />
        </Box>
      </DialogContent>
      <DialogActions>
        <Box>
          <Button
            onClick={() => setOpen(false)}
            className={classes.cancelButton}
            variant="text">
            Cancel
          </Button>
          <Button
            onClick={() => setOpen(false)}
            className={classes.saveButton}
            variant="contained">
            Save
          </Button>
        </Box>
      </DialogActions>
    </Dialog>
  );
};
