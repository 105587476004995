import { gql } from "@apollo/client";

export const GET_PARTNERS_DASHBOARD = gql`
  query v1_partners_dashboard($prm: indexInt!) {
    v1_partners_dashboard(prm: $prm) {
      items {
        count
        date
        name
        net
        product_id
      }
      month {
        count
        total
        trips
      }
      yesterday {
        count
        total
        trips
      }
      today {
        count
        total
        trips
      }
      previosMonth {
        count
        total
        trips
      }
      total {
        count
        total
      }
    }
  }
`;
