import React from 'react';
import { useSelector } from 'react-redux';
import { Box, Typography, Divider, IconButton } from '@material-ui/core';

import { GetPhoneFlagIcon } from 'src/web/helpers/GetPhoneFlagIcon';
import { arrivalData } from 'src/redux/Arrivals/sliceArrival';

import EditIcon from 'src/assets/icons/light/edit.svg';
import EditSmallIcon from 'src/assets/icons/editSmall.svg';

import { useStyles } from '../styles';
import { currentUser } from 'src/redux/Authentication/sliceAuthentication';
import { isUserHasPermissions } from 'src/helpers/permissions';

export const HeaderCard = ({
  handleOpenModal,
}: {
  handleOpenModal: () => void;
}) => {
  const classes = useStyles();
  const { arrivalData: arrival } = useSelector(arrivalData);
  const user = useSelector(currentUser);

  const isUserCanEdit = isUserHasPermissions(user, ['order_edit']);

  return (
    <>
      <Box className={classes.cardBox} width={3 / 5}>
        <Typography className={classes.cardTitle}>Customer</Typography>
        <Divider className={classes.cardDivider} />
        <Box className={classes.headerInfo}>
          <Box marginBottom={'10px'}>
            <Typography className={classes.itemTitle}>First Name</Typography>
            <Typography className={classes.itemContent}>
              {arrival.first_name}
            </Typography>
          </Box>
          <Box marginBottom={'10px'}>
            <Typography className={classes.itemTitle}>Last Name</Typography>
            <Typography className={classes.itemContent}>
              {arrival.last_name}
            </Typography>
          </Box>
          <Box className={classes.phoneBox}>
            <Typography className={classes.itemTitle}>Phone number</Typography>
            <Box display={'flex'}>
              <Box style={{ marginRight: '5px', marginTop: '2px' }}>
                {GetPhoneFlagIcon(arrival.phone)}
              </Box>
              <Typography className={classes.itemContent}>
                {arrival.phone}
              </Typography>
            </Box>
          </Box>
          {arrival.data?.comments?.guest && (
            <Box className={classes.phoneBox} marginTop={'25px'} width={1}>
              <Typography className={classes.itemTitle}>Comment</Typography>
              <Typography className={classes.itemContent}>
                {arrival.data.comments.guest}
              </Typography>
            </Box>
          )}
        </Box>
      </Box>
      {
        <Box marginLeft="20px" className={classes.cardBox} width={2 / 5}>
          <Box width={1} display="flex" justifyContent="space-between">
            <Typography className={classes.cardTitle}>
              Partner Comment
            </Typography>
            {isUserCanEdit && (
              <IconButton
                onClick={() => handleOpenModal()}
                style={{ padding: '0px' }}
                className={classes.editIcon}
              >
                <EditIcon />
              </IconButton>
            )}
            <IconButton
              className={classes.editIconSmall}
              onClick={() => handleOpenModal()}
              style={{ padding: '0px' }}
            >
              <EditSmallIcon />
            </IconButton>
          </Box>
          <Divider className={classes.cardDivider} />
          {arrival.data?.comments?.partner ? (
            <Typography className={classes.itemContent}>
              {arrival.data.comments.partner}
            </Typography>
          ) : (
            <Box
              className={classes.partnerComment}
              height={!arrival.data?.comments?.guest ? '50%' : '70%'}
            >
              <Typography className={classes.itemContent}>
                Leave a comment
              </Typography>
            </Box>
          )}
        </Box>
      }
    </>
  );
};
