import React, { useState } from "react";
import { Link } from "react-router-dom";
import { useForm } from "react-hook-form";
import valid from "./validation";
import {
  TextField,
  Box,
  Button,
  Typography,
  Link as MaterialLink,
  InputAdornment,
  IconButton,
} from "@material-ui/core/";
import VisibilityPassword from "src/assets/icons/web/visibilityPassword.svg";
import VisibilityOffPassword from "src/assets/icons/web/visibilityOffPassword.svg";
import { useStyles } from "./styles";

export const LoginForm = ({ onSubmit }: any) => {
  const classes = useStyles();
  const { register, handleSubmit, errors } = useForm();
  const [showPassword, setShowPassword] = useState(false);

  return (
    <>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Box mb={3} mt={2}>
          <TextField
            name="email"
            fullWidth
            className={classes.customInput}
            inputRef={register()}
            label="Username"
            placeholder="your username"
            InputProps={{
              startAdornment: <></>,
            }}
            variant="outlined"
            error={errors.email ? true : false}
            helperText={errors?.email?.message}
          />
        </Box>
        <Box mb={2}>
          <TextField
            name="password"
            type={showPassword ? 'text' : 'password'}
            className={classes.customInput}
            fullWidth
            inputRef={register({ required: { ...valid.password.required } })}
            label="Password"
            placeholder="your password"
            InputProps={{
              startAdornment: <></>,
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton
                    style={{ color: "#E0E2E4" }}
                    aria-label="toggle password visibility"
                    onClick={() => setShowPassword((prev: boolean) => !prev)}
                  >
                    {showPassword ? (
                      <VisibilityPassword />
                    ) : (
                      <VisibilityOffPassword />
                    )}
                  </IconButton>
                </InputAdornment>
              ),
            }}
            variant="outlined"
            error={errors.password ? true : false}
            helperText={errors?.password?.message}
          />
        </Box>
        <Button
          fullWidth={true}
          className={classes.authButton}
          variant="contained"
          color="primary"
          type="submit"
          size="large"
        >
          Sign In
        </Button>
      </form>
      <Box mt={2}>
        <Typography className={classes.customLinkWrapper}>
          <MaterialLink
            to="/registration"
            component={Link}
            className={classes.customLink}
          >
            Don't have an account?
          </MaterialLink>
        </Typography>
      </Box>
      <Box mt={2}>
        <Typography className={classes.customLinkWrapper}>
          <MaterialLink
            to="/forgot-password"
            component={Link}
            className={classes.customLink}
          >
            Forgot password?
          </MaterialLink>
        </Typography>
      </Box>
    </>
  );
};
