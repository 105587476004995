import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles(() => ({
  table_row: {
    cursor: 'pointer',
    height: '70px',
  },
  table_cell: {
    color: '#4F4F4F',
    fontWeight: 500,
    fontSize: '12px',
    whiteSpace: 'nowrap',
    padding: '16px',
    borderBottom: '1px solid #dbf8f6',
  },
  nameCell: {
    display: 'flex',
    alignItems: 'center',
    width: '300px',
    fontSize: '14px',
    color: '#6C6B6B',
    whiteSpace: 'nowrap',
    position: 'relative',
    '@media (max-width: 850px)': {
      fontSize: '12px',
    },
  },
  messageCell: {
    width: '450px',
    fontSize: '14px',
    color: '#6C6B6B',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    '@media (max-width: 850px)': {
      fontSize: '12px',
    },
  },
  dateCell: {
    width: '140px',
    whiteSpace: 'normal',
    fontSize: '12px',
    color: '#6C6B6B',
    '@media (max-width: 850px)': {
      fontSize: '10px',
    },
  },
  timeCell: {
    width: '140px',
    fontSize: '12px',
    whiteSpace: 'normal',
    color: '#6C6B6B',
    '@media (max-width: 850px)': {
      fontSize: '10px',
    },
  },
  idCell: {
    whiteSpace: 'nowrap',
    width: '150px',
  },
  id_badge: {
    color: '#0DD1C5',
    background: '#e7faf9',
    borderRadius: '50px',
    fontSize: '14px',
    fontWeight: 600,
    textAlign: 'center',
    '@media (max-width: 850px)': {
      fontSize: '12px',
    },
  },
  tableLoader: {
    position: 'absolute',
    height: 'calc(100vh - 270px)',
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  tableLoaderWrapper: {
    borderBottom: 'none',
    width: 0,
    height: 0,
    padding: 0,
  },
  popupDialog: {
    backgroundColor: '#FFFFFF',
    borderRadius: '15px',
    padding: '11px 13px',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    boxShadow: '0px 3.89312px 23.3587px rgba(17, 15, 15, 0.53)',
  },
  popupItem: {
    color: '#FFFFFF',
    fontWeight: 300,
    fontSize: '16px',
    lineHeight: '32px',
    cursor: 'pointer',
  },
  dialog: {
    '& .MuiDialog-paperScrollPaper': {
      maxHeight: '100%',
    },
    '& .MuiDialog-paper': {
      padding: '14px 13px 27px 13px',
      maxWidth: '550px',
      minHeight: '180px',
      boxShadow: '0px 10.89px 43.36px rgba(17, 15, 15, 0.53)',
      borderRadius: '15px',
      overflow: 'unset',
      '@media (max-width: 750px)': {
        padding: '5px 10px 0px 20px',
      },
      '& .MuiTypography-root': {
        width: '100%',
      },
      '& .MuiDialogContent-root': {
        paddingLeft: '25px',
        paddingRight: '37px',
        '@media (max-width: 750px)': {
          paddingRight: '12px',
          paddingBottom: '0px',
        },
      },
    },
  },
  dialogHeaderTitle: {
    fontSize: '14px',
    '@media (max-width: 850px)': {
      fontSize: '12px',
    },
    '@media (max-width: 750px)': {
      paddingTop: '14px',
    },
    fontWeight: 600,
    paddingBottom: '10px',
    color: '#333333',
  },
  dialogResponse: {
    fontSize: '15px',
    '@media (max-width: 850px)': {
      fontSize: '10px',
    },
    '@media (max-width: 650px)': {
      fontSize: '12px',
    },
    fontWeight: 400,
    color: '#6C6B6B',
    paddingBottom: '15px',
  },
  notificationItem: {
    padding: '20px 15px 18px 17px',
    borderRadius: '15px',
    '&:last-child': {
      borderBottom: 'none',
    },
    cursor: 'pointer',
    position: 'relative',
  },
  popover: {
    '& .MuiPopover-paper': {
      borderRadius: '15px',
      minWidth: '438px',
      top: '80px !important',
      '@media (max-width: 850px)': {
        minWidth: '340px',
      },
    },
  },
  popoverName: {
    fontSize: '14px',
    color: '#333333',
    marginBottom: '10px',
    fontWeight: 500,
    width: '170px',
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
    overflow: 'hidden',
    '@media (max-width: 850px)': {
      fontSize: '12px',
      whiteSpace: 'nowrap',
    },
  },
  popoverInfo: {
    fontSize: '12px',
    color: '#6C6B6B',
    display: 'flex',
    justifyContent: 'space-between',
    '@media (max-width: 850px)': {
      fontSize: '10px',
    },
  },
  popoverBox: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'flex-start',
    width: '100%',
  },
  popoverId: {
    color: '#0DD1C5',
    background: '#e7faf9',
    borderRadius: '50px',
    fontSize: '14px',
    fontWeight: 600,
    textAlign: 'center',
    padding: '2px 13px',
    '@media (max-width: 850px)': {
      fontSize: '12px',
    },
  },
  showMoreButton: {
    width: '90%',
    border: '1px solid #0DD1C5',
    borderRadius: '50px',
    color: '#0DD1C5',
    justifyContent: 'center',
    display: 'flex',
    textTransform: 'none',
    margin: '10px 0 17px 0',
    padding: '6.5px 38px',
    fontSize: '16px',
    textDecoration: 'none',
    '@media (max-width: 850px)': {
      fontSize: '14px',
      width: '100%',
      padding: '12px 40px',
    },
    '&:hover': {
      borderColor: '#C4C4C4',
      color: '#6C6B6B',
      background: 'white',
    },
  },
  viewAllButton: {
    cursor: 'pointer',
    background: 'linear-gradient(90deg, #03D9B1 0%, #1CBBD9 100%)',
    color: 'white',
    textTransform: 'none',
    borderRadius: '50px',
    margin: '10px 0 17px 0',
    textDecoration: 'none',
    boxShadow:
      '0px 13px 28px rgba(29, 203, 192, 0.2), 0px 8px 16px rgba(29, 203, 192, 0.06)',
    fontSize: '16px',
    padding: '6.5px 38px',
    '@media (max-width: 850px)': {
      fontSize: '14px',
      width: '100%',
      padding: '12px 40px',
    },
    '&:hover': {
      background: 'linear-gradient(90deg, #06C9A4 0%, #00A2C1 100%)',
      color: '#FFFFFF',
      boxShadow:
        '0px 13px 28px rgba(29, 203, 192, 0.2), 0px 8px 16px rgba(29, 203, 192, 0.06)',
    },
  },
  closeIcon: {
    '&:hover': {
      background: 'none',
      border: '1px solid #FAFAFA',
      padding: '11px',
    },
  },
  header: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'flex-start',
  },

  noNotificationWrapper: {
    padding: '20px 0',
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column',
  },
  noNotificationIcon: {
    display: 'block',
    '@media (max-width: 850px)': {
      display: 'none',
    },
  },
  noNotificationIconAdaptive: {
    display: 'none',
    '@media (max-width: 850px)': {
      display: 'block',
    },
  },
  noNotificationTitle: {
    color: '#C4C4C4',
    fontSize: '18px',
    fontWeight: 500,
    margin: '30px 0',
    '@media (max-width: 850px)': {
      fontSize: '16px',
    },
  },

  divider: {
    margin: '1px 17px 1px 17px',
    opacity: 0.15,
    '&:last-child': {
      display: 'none',
    },
  },
  popupList: {
    paddingTop: '25px',
  },

  popupHeader: {
    display: 'flex',
    flexDirection: 'column',
    padding: '25px 28px 6px 28px',
    borderBottom: '1px solid #FAFAFA',
  },
  popupHeaderTitle: {
    color: '#202020',
    fontSize: '18px',
    fontWeight: 600,
  },
  popupHeaderReadAll: {
    fontWeight: 500,
    fontSize: '16px',
    color: '#0DD1C5',
    cursor: 'pointer',
  },
  popupHeaderFilterButton: {
    color: '#6C6B6B',
    fontSize: '16px',
    fontWeight: 500,
    padding: '0 15px',
    cursor: 'pointer',
  },
  activePopupFilterButton: {
    color: '#0DD1C5',
    position: 'relative',

    '&::after': {
      content: "''",
      position: 'absolute',
      width: '100%',
      height: '4px',
      background: 'linear-gradient(90deg, #03D9B1 0%, #1CBBD9 100%)',
      bottom: '-10px',
      borderRadius: '6px',
      left: 0,
    },
  },
  newNotificationDot: {
    position: 'absolute',
    width: '8px',
    height: '8px',
    borderRadius: '50%',
    background: '#0DD1C5',
    left: '15px',
    top: '26px',
  },
  newNotificationListDot: {
    width: '8px',
    height: '8px',
    borderRadius: '50%',
    background: '#0DD1C5',
    marginRight: '8px',
  },
  popupOptionsButton: {
    position: 'absolute',
    right: '14px',
    top: '28px',
    padding: 0,
  },

  actionsPopup: {
    '& .MuiPaper-root': {
      borderRadius: '15px',
      padding: '10px',
      minWidth: '155px',
    },
  },
  actionsPopupButton: {
    fontWeight: 400,
    fontSize: '12px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-start',
    padding: '9px',
    borderRadius: '10px',
    width: '100%',
    textTransform: 'inherit',

    '&:hover': {
      background: '#F9FCFD',
    },
  },
  actionsPopupUnreadButton: {
    color: '#5C8FDC',
  },
  actionsPopupDeleteButton: {
    color: '#EC4652',
  },
  confirmModalHeader: {
    display: 'flex',
    alignItems: 'flex-start',
    justifyContent: 'flex-end',
  },
  confirmModalText: {
    fontSize: '16px',
    fontWeight: 600,
    color: '#333333',
    textAlign: 'center',
  },
  gradientButton: {
    cursor: 'pointer',
    background: 'linear-gradient(90deg, #03D9B1 0%, #1CBBD9 100%)',
    color: 'white',
    textTransform: 'none',
    borderRadius: '50px',
    textDecoration: 'none',
    boxShadow:
      '0px 13px 28px rgba(29, 203, 192, 0.2), 0px 8px 16px rgba(29, 203, 192, 0.06)',
    fontSize: '16px',
    padding: '6.5px 38px',
    '@media (max-width: 850px)': {
      fontSize: '14px',
      padding: '12px 40px',
    },
    '&:hover': {
      background: 'linear-gradient(90deg, #06C9A4 0%, #00A2C1 100%)',
      color: '#FFFFFF',
      boxShadow:
        '0px 13px 28px rgba(29, 203, 192, 0.2), 0px 8px 16px rgba(29, 203, 192, 0.06)',
    },
  },
  simpleButton: {
    color: '#6C6B6B',
    border: 'none',
    background: 'transparent',
    textTransform: 'none',
    borderRadius: '50px',
    padding: '6.5px 38px',
    '@media (max-width: 850px)': {
      fontSize: '14px',
      padding: '12px 40px',
    },
  },
  confirmModalActions: {
    justifyContent: 'space-between',
    margin: 'auto',
    width: '40%',
    marginTop: '10px',
  },
  mobileFilterTitle: {
    fontSize: '14px',
    color: '#333',
    fontWeight: 600,
  },
  mobileFilterOption: {
    background: 'transparent',
    padding: '21px 26px',
    color: '#6C6B6B',
    fontWeight: 500,
    fontSize: '12px',
    borderRadius: '15px',
  },
  mobileFilterActiveOption: {
    background: 'rgba(13, 209, 197, 0.05)',
    color: '#0DD1C5',
  },
  mobileFilterDialog: {
    '& .MuiDialog-paper': {
      width: '320px',
    },
  },
}));
