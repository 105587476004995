import { Platform } from 'react-native';

import { Action, configureStore, Reducer, ThunkAction } from '@reduxjs/toolkit';
import { connectRouter, routerMiddleware } from 'connected-react-router';
import { createBrowserHistory, createMemoryHistory } from 'history';
import tables from 'src/web/features/table/sliceTables';
import authentication from 'src/redux/Authentication/sliceAuthentication';
import notifications from 'src/redux/Notifications/sliceNotifications';
import arrival from 'src/redux/Arrivals/sliceArrival';
import reviews  from 'src/redux/Reviews/sliceReviews';
import registration  from 'src/redux/Registration/sliceRegistration';
import onboarding  from 'src/redux/Onboarding/sliceOnboarding';

export const history =
  Platform.OS === 'web' ? createBrowserHistory() : createMemoryHistory();

export const store = configureStore({
  reducer: {
    auth: authentication,
    notifications,
    tables,
    arrival,
    reviews,
    registration,
    onboarding,
    router: connectRouter(history) as Reducer,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware().concat(routerMiddleware(history)),
});

export type RootState = ReturnType<typeof store.getState>;
export type AppThunk<ReturnType = void> = ThunkAction<
  ReturnType,
  RootState,
  unknown,
  Action<string>
>;
