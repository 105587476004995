import { makeStyles } from "@material-ui/core/styles";
import { Theme } from "src/web/themes/theme";

export const useStyles = makeStyles((theme: Theme) => ({
  center_side_header: {
    position: "relative",
    zIndex: 100,
    display: "flex",
    justifyContent: "space-between",
    alignItems: "flex-end",
    height: "80px",
    backgroundColor: "#2E3035",
    padding: "16px 0",
    boxSizing: "border-box",
    width: "100%",
    "@media (max-width: 850px)": {
      height: '60px',
    },
    "& .DateRangePicker": {
      marginLeft: "10px",
    },
    "& .DateRangePickerInput_arrow": {
      height: "2px",
      width: "4px",
      margin: "0 5px 0 4px",
      background: "#fff",
    },
    "& .DateRangePickerInput_arrow_svg": {
      display: "none",
    },
    "& .DateRangePickerInput": {
      background: "none",
    },
    "& #your_unique_start_date_id": {
      textAlign: "center",
    },
    "& #your_unique_end_date_id": {
      textAlign: "center",
    },
    "& .DateInput_input": {
      borderColor: "#fff",
      color: "#fff",
      backgroundColor: "#2E3035",
      fontSize: "14px",
      padding: "10px 0",
      cursor: "pointer",
    },
    "& .DateRangePickerInput__withBorder": {
      border: "none",
    },
    "& .DateInput": {
      width: "86px",
    },
    "& .DateRangePickerInput_calendarIcon": {
      color: "#fff",
      padding: 0,
      margin: "0 10px 8px 0",
      outline: "none",
    },
    "& .DateRangePickerInput_calendarIcon_svg": {
      fill: "#fff",
      height: "20px",
      width: "18px",
    },
    "& .DateRangePickerInput_clearDates": {
      margin: "0",
      transform: "translateY(calc(-50% - 1px))",
    },
  },
  left_side_header: {
    position: "relative",
    zIndex: 100,
    backgroundColor: "#2E3035",
    padding: "16px 0",
    width: "100%",
  },
  input_container: {
    flexGrow: 4,
    borderRadius: "50px",
    marginRight: "10px",
  },
  button_container: {},
  button_content: {
    backgroundColor: "#109B2B",
    padding: "8px 16px",
    color: "white",
    borderRadius: "4px",
  },
  menu_icon: {
    cursor: "pointer",
    marginRight: "7px",
    [theme.breakpoints.up("lg")]: {
      display: "none",
    },
  },
  search_field: {
    color: "#000",
    width: "100%",
    outline: "none",
    border: "none",
    "& .MuiOutlinedInput-notchedOutline": {
      border: "none",
    },
    "&:focus, &:hover": {
      outline: "none",
      border: "none",
      "& .MuiOutlinedInput-notchedOutline": {
        border: "none",
      },
    },
    "& .MuiInputBase-root": {
      height: "48px",
    },
  },
  flex: {
    margin: "0 10px 0 10px",
    zIndex: 2,
    position: "relative",
    width: "20%",
  },
  aciveCalendar: {
    width: "20%",
  },
  inactiveCalendar: {
    "& .DateInput_input": {
      color: "#959595",
    },
    "& .DateRangePickerInput_clearDates": {
      //pointerEvents: 'none',
    },
  },
  clearIcon: {
    cursor: "pointer",
  },
  headerTitle: {
    display: "none",
    [theme.breakpoints.down("md")]: {
      display: "flex",
      alignItems: "center",
      width: '32%',
      color: "#fff",
      "& span": {
        fontSize: "16px",
        marginLeft: "8px",
      },
      "& svg": {
        fontSize: "30px",
        fill: "#fff",
      },
    },
  },
}));
