import { makeStyles } from '@material-ui/core/styles';
import { Theme } from 'src/web/themes/theme';

export const useStyles = makeStyles((theme: Theme) => ({
  contentWrapper: {
    paddingTop: '17px',
  },
  emailWrapper: {
    border: '1px solid #dbf8f6',
    borderRadius: '15px',
    padding: '22px 14px',
    marginBottom: '20px',

    '@media (max-width: 1020px)': {
      padding: '14px',
    },
  },
  emailContainer: {
    borderRadius: '15px',
    backgroundColor: '#F9FCFD',
    padding: '9px 15px',
  },
  emailLabel: {
    color: '#C4C4C4',
    fontSize: '12px',
    fontWeight: 500,
    marginBottom: '2px',
  },
  emailValue: {
    color: '#333333',
    fontSize: '14px',
    fontWeight: 400,
  },

  editModalRoot: {
    '& .MuiPaper-root': {
      padding: '50px',
      paddingTop: '0px',
      borderRadius: '15px',
    },

    '& .MuiDialogTitle-root': {
      padding: '15px 0 23px 0',
      borderBottom: '1px solid #FAFAFA',
    },

    '& .MuiDialogContent-root': {
      padding: '37px 0',
    },
  },
  dialogHeader: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'flex-start',
  },
  dialogHeaderTitle: {
    fontSize: '18px',
    '@media (max-width: 850px)': {
      fontSize: '14px',
      paddingTop: '0px',
    },
    fontWeight: 600,
    paddingTop: '34px',
    color: '#202020',
  },
  dialogResponse: {
    fontSize: '12px',
    '@media (max-width: 850px)': {
      fontSize: '10px',
    },
    fontWeight: 400,
    color: '#6C6B6B',
  },
  closeIcon: {
    position: 'absolute',
    top: '10px',
    right: '14px',

    '&:hover': {
      background: 'none',
      border: '1px solid #FAFAFA',
      padding: '11px',
    },
  },
  saveButton: {
    background: "linear-gradient(90deg, #03D9B1 0%, #1CBBD9 100%)",
    color: 'white',
    textTransform: 'none',
    borderRadius: '50px',
    boxShadow:
      '0px 13px 28px rgba(29, 203, 192, 0.2), 0px 8px 16px rgba(29, 203, 192, 0.06)',
    fontSize: '16px',
    '@media (max-width: 850px)': {
      fontSize: '14px',
    },
    padding: '6.5px 46px',
    fontWeight: 500,
    "&:hover": {
      background: "linear-gradient(90deg, #06C9A4 0%, #00A2C1 100%)",
      color: "#FFFFFF",
      boxShadow:
        "0px 13px 28px rgba(29, 203, 192, 0.2), 0px 8px 16px rgba(29, 203, 192, 0.06)",
    },
  },
  cancelButton: {
    textTransform: 'none',
    color: '#c4c4c4',
    fontSize: '16px',
    '@media (max-width: 850px)': {
      fontSize: '14px',
    },
    [theme.breakpoints.down('sm')]: {
      fontSize: '14px',
      padding: '6.5px 20px',
    },
    padding: '6.5px 33px',
    borderRadius: '50px',
    '&:hover': {
      background: 'white',
      color: '#6C6B6B',
    },
  },
  editInput: {
    width: '100%',
    marginBottom: '20px',

    '& label': {
      color: '#333333',
    },

    '& input': {
      padding: '0px 30px',
      height: '50px',
      color: '#6C6B6B',

      '&:focus': {
        color: '#333333',
      },
    },

    '& .MuiInputBase-root': {
      borderRadius: '50px',
    },

    '& .MuiOutlinedInput-notchedOutline': {
      border: '1px solid #C4C4C4',
    },

    '& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline': {
      border: '1px solid #333333',
    },

    '& .MuiFormLabel-root.Mui-focused': {
      color: '#333333',
    },
  },
}));
