import { Box, Typography, Divider } from "@material-ui/core";
import React from "react";
import { useSelector } from "react-redux";
import { arrivalData } from "src/redux/Arrivals/sliceArrival";

import { useStyles } from "../styles";

export const Tickets = () => {
  const classes = useStyles();
  const { arrivalData: arrival } = useSelector(arrivalData);

  return (
    <Box className={classes.addInfoBox}>
      {arrival.data?.tickets &&
        arrival.data?.tickets.map(
          (ticket: any, index: number) => {
            return (
              <Box key={index}>
                {index !== 0 && <Divider className={classes.cardDivider} />}
                <Box className={classes.ticketCard}>
                  <Box  className={classes.ticket} >
                    <Typography className={classes.itemTitle}>
                      Ticket
                    </Typography>
                    <Typography className={classes.itemContent}>
                      {ticket.name}
                    </Typography>
                  </Box>
                  <Box className={classes.ticketInfo}>
                    <Typography className={classes.itemTitle}>
                      Booked
                    </Typography>
                    <Typography className={classes.itemContent}>
                      {ticket.booked}
                    </Typography>
                  </Box>
                  <Box  className={classes.ticketInfo}>
                    <Typography className={classes.itemTitle}>Price</Typography>
                    <Typography className={classes.itemContent}>
                      ${ticket.price}
                    </Typography>
                  </Box>
                </Box>
                {ticket.guests && (
                  <Box key={index}>
                    {ticket.guests.map(
                      (guest: any, idx: number) => {
                        return (
                          <Box key={idx}>
                            <Divider className={classes.cardDivider} />
                            <Box
                              display={"flex"}
                              justifyContent="space-between"
                            >
                              <Box width={1 / 2}>
                                <Typography className={classes.itemTitle}>
                                  First name
                                </Typography>
                                <Typography className={classes.itemContent}>
                                  {guest.first_name}
                                </Typography>
                              </Box>
                              <Box width={1 / 2}>
                                <Typography className={classes.itemTitle}>
                                  Last name
                                </Typography>
                                <Typography className={classes.itemContent}>
                                  {guest.last_name}
                                </Typography>
                              </Box>
                            </Box>
                          </Box>
                        );
                      }
                    )}
                  </Box>
                )}
              </Box>
            );
          }
        )}
    </Box>
  );
};
