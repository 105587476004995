import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "src/redux/store";
import { IOnboarding, IOnboardingFields } from "./interfaces";

export const initialState: IOnboarding = {
  form: {
    first_name: "",
    last_name: "",
    name: "",
    email: "",
    phone: "",
    title: "",
    city: "",
    state: "",
    termsAndConditions: false,
    w9: "",
    fein_snn: "",
    admin_only_email: "",
    city_id: 0,
    country_id: 0,
    state_id: 0,
    address: "",
    phone2: "",
    phone_client: "",
    pli_exp: "",
    barcode_type: 0,
    commission: 0,
    zip: "",
    google_place_id: "",
    google_place_api_response: "",
    latitude: "",
    longitude: "",
    google_title: "",
    use_barcodes: "",
  },
  receivedData: null,
};

export const sliceOnboarding = createSlice({
  name: "onboarding",
  initialState,
  reducers: {
    setData: (state, action: PayloadAction<Partial<IOnboardingFields>>) => {
      const data = action.payload;
      state.form = {
        ...state.form,
        ...data,
      };
    },
    setInitialData: (state) => {
      state = initialState;
    },
    setReceivedData: (state, action: PayloadAction<IOnboardingFields>) => {
      state.receivedData = action.payload;
    },
  },
});

export const { setData, setInitialData, setReceivedData } =
  sliceOnboarding.actions;

export const onboardingData = (state: RootState) => state.onboarding;
export const onboardingDataForm = (state: RootState) => state.onboarding.form;

export const updateData = (state: RootState) => {
  const removedFields = [
    "termsAndConditions",
    "w9",
    "commission",
    "pli_exp",
    "use_barcodes",
  ];

  const currentData: any = state.onboarding.form;
  const keys = Object.keys(currentData);
  const data: any = currentData
    ? keys.reduce((acc: any, curr: string) => {
        if (removedFields.includes(curr)) return acc;
        acc[curr] = currentData[curr];
        return acc;
      }, {})
    : {};
  return data;
};

export default sliceOnboarding.reducer;
