import { makeStyles } from '@material-ui/core/styles';
import { Theme } from 'src/web/themes/theme';

export const useStyles = makeStyles((theme: Theme) => ({
  header: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  title: {
    color: '#fff',
    fontSize: '16px',
    fontWeight: 400,
    marginLeft: '4px',
    '@media (max-width: 750px)': {
      fontSize: '14px',
    },
  },
  filterButton: {
    color: '#C4C4C4',
    fontSize: '16px',
    fontWeight: 400,
    marginLeft: '4px',
    textTransform: 'none',
  },
  desktopFilters: {
    display: 'flex',
    flex: 1,
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'flex-end',
    height: '48px',

    '& .MuiFormControl-root': {
      width: '270px',
    },

    '& .MuiTypography-body1': {
      '@media (max-width: 921px)': {
        fontSize: '12px',
      },
    },

    '@media (max-width: 768px)': {
      display: 'none',
    },
  },
  adaptive_1: {
    display: 'none',
    '@media (max-width: 768px)': {
      display: 'flex',
    },
  },
  autocompleteItem: {
    display: 'flex',
    justifyContent: 'space-between',
    fontSize: '14px',
    padding: '16px 38px 16px 28px',
    marginRight: '30px',
    '&:hover': {
      background: '#f3fdfc',
      border: 'none',
      color: '#0DD1C5',
      borderRadius: '15px',
    },
  },
  filterArea: {
    '& .MuiInputLabel-outlined': {
      color: '#C4C4C4',
      paddingLeft: '22px',
      fontWeight: 500,
    },
    '& .MuiAutocomplete-inputRoot': {
      padding: '4px 24px !important',
    },
    '& .MuiFormControl-root': {
      width: '100%',
    },
    '& .MuiInputBase-root': {
      height: '37px',
      borderRadius: '50px',
      '& .MuiOutlinedInput-notchedOutline': {
        paddingLeft: '25px',
        border: '0.6px solid #C4C4C4',
      },
      '@media (max-width: 880px)': {
        height: '40px',
      },
    },
    '& .MuiInputBase-input': {
      padding: '7px 4px !important',
      color: '#C4C4C4',
      fontSize: '14px',
    },
    '& .Mui-focused .MuiOutlinedInput-notchedOutline': {
      border: '0.6px solid #C4C4C4',
    },
  },
  popper: {
    margin: 0,
    padding: 0,
    zIndex: 2000,
    '& .MuiAutocomplete-option': {
      borderRadius: '15px',
    },
    '& .Mui-focused': {
      background: '#FAFAFA',
      borderRadius: '15px',
    },
    '& .Mui-selected': {
      background: '#FAFAFA',
      color: '#0DD1C5',
      borderRadius: '15px',
    },
    '& .MuiAutocomplete-listbox': {
      maxHeight: '250px',
      overflow: 'hidden',
      '& :hover': {
        background: '#FAFAFA',
        color: '#0DD1C5',
        borderRadius: '15px',
      },
    },
  },
  popover: {
    '@media (max-width: 750px)': {
      background: 'rgba(0, 0, 0, 0.5)',
    },

    '& .MuiPopover-paper': {
      borderRadius: '15px',
      overflow: 'inherit',
    },
  },
  filterPopper: {
    borderRadius: '15px',
    background: 'white',
    padding: '22px 24px 30px 24px',
    boxShadow: '0px 3.89312px 23.3587px rgba(17, 15, 15, 0.53)',
    display: 'flex',
    flexDirection: 'column',
    [theme.breakpoints.down('sm')]: {
      width: '330px',
    },
  },
  filterPopperBody: {
    height: '150px',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'space-between',

    '& .MuiFormControlLabel-root': {
      marginBottom: '20px',
    },
  },
  filterTitle: {
    display: 'none',
    '@media (max-width: 750px)': {
      display: 'flex',
      justifyContent: 'space-between',
      borderBottom: '0.6px solid #FAFAFA',
      paddingBottom: '7px',
      marginBottom: '10px',
    },
  },
  filterItemTitle: {
    color: '#6C6B6B',
    fontSize: '16px',
    fontWeight: 500,
    marginBottom: '15px',
    '@media (max-width: 850px)': {
      fontSize: '14px',
    },
  },
  dialogHeaderTitle: {
    fontSize: '14px',
    '@media (max-width: 850px)': {
      fontSize: '12px',
    },
    '@media (max-width: 750px)': {
      paddingTop: '14px',
    },
    fontWeight: 600,
    paddingBottom: '10px',
    paddingTop: '34px',
    color: '#333333',
  },
  closeIcon: {
    '&:hover': {
      background: 'none',
      border: '1px solid #FAFAFA',
      padding: '11px',
    },
  },
  checkbox: {
    '& .MuiTypography-body1': {
      color: '#C4C4C4',
      fontSize: '16px',
      fontWeight: 400,
      display: 'flex',
      alignItems: 'center',
      cursor: 'pointer',
    },
    '& .PrivateSwitchBase-root-139': {
      padding: 0,
      marginRight: '4px',
    },
  },
}));
