/* eslint-disable sonarjs/cognitive-complexity */
import React from 'react';
import initChatWidget from './app/initChatWidget';
import { useSelector } from 'react-redux';
import { currentUser } from 'src/redux/Authentication/sliceAuthentication';
import { useStyles } from './styles';
import { Box } from '@material-ui/core';
import ChatIcon from 'src/assets/icons/chat.svg';

const AMAZON_CONNECT_WIDGET_BUTTON = '#amazon-connect-chat-widget button';

interface TChatHandlers {
  setOpen?: (value: boolean) => void;
  setIsLoad?: (value: boolean) => void;
}

interface TChatFields {
  open: boolean;
  showChatBtn: boolean;
  isLoad: boolean;
}

export type TChatProps = TChatFields & TChatHandlers;

export const ChatComponent = () => {
  const classes = useStyles();
  const userInfo = useSelector(currentUser);

  const initChat = () => {
    const attributes = {
      FirstName: userInfo?.given_name || '',
      LastName: userInfo?.family_name || '',
      Email: userInfo?.email || '',
      Phone: userInfo?.phone || '',
      Flow: 'PARTNERS',
      InitPage: 'New Partner app',
    };

    const clientChatToken = window.localStorage.getItem('clientChatToken');
    initChatWidget(attributes, clientChatToken, function () {
      setTimeout(() => {
        const widgetBtn = document.querySelector(AMAZON_CONNECT_WIDGET_BUTTON);
        if (widgetBtn) {
          (widgetBtn as HTMLElement).click();
        }
      }, 700);
    });
    window.localStorage.setItem('clientChatData', JSON.stringify(attributes));
  };

  return (
    <Box
      className={classes.chatIconWrapper}
      onClick={initChat}
      style={{ cursor: 'pointer' }}>
      <ChatIcon color="action" />
    </Box>
  );
};
