import moment from 'moment';

export const customFormat = (
  date: string | null | undefined,
  format: string
): string => {
  if (!date) {
    return '-';
  }
  return moment(date).format(format);
};

export const tableDates = (date: string | null | undefined): string => {
  if (!date) {
    return 'Any';
  }
  return moment(date).format('MMM Do YYYY');
};

export const tableDatesForContent = (
  date: string | number | null | undefined
): string => {
  if (!date) {
    return 'Any';
  }
  return moment(date).format('MM/DD/YYYY hh:mma');
};

export const tableDatesForCustomer = (
  date: string | null | undefined
): string => {
  if (!date) {
    return 'None';
  }
  return moment(date).format('MMM Do YYYY');
};

export const shortTableDates = (date: string | null | undefined): string => {
  if (!date) {
    return 'Any';
  }
  return moment(date).format('MMM DD YYYY');
};

export const tabDates = (date: string | null | undefined): string => {
  if (!date) {
    return 'Any';
  }
  return moment(date).format('ddd MM/DD');
};

export const calendarTime = (time: string | null | undefined): string => {
  if (!time) {
    return 'Any';
  }
  return moment(time, 'H:mma').format('hh:mma');
};

export const calendarCartTime = (time: string): string => {
  return moment(time, 'H:mm').format('hh:mma');
};

export const cut_off_time = (time: string): string => {
  if (!time) {
    return '';
  }
  return moment(time, 'hh:mma').format('hh:mma');
};

export const cart_format = (
  date: string | null,
  time: string | null
): string => {
  const formatTime = time ? `@ ${time.toUpperCase()}` : '';
  const formatDate = date ? moment(date).format('MMM DD, YYYY') : '';
  return `${formatDate} ${formatTime}`;
};

export const order_format = (date: string | null): string => {
  if (!date) return '';
  return moment(date).format('MMM DD, YYYY');
};
