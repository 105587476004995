import { makeStyles } from '@material-ui/core/styles';
import { Theme } from 'src/web/themes/theme';

export const useStyles = makeStyles((theme: Theme) => ({
  root: {
    width: '100%',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    minHeight: '68px'
  },
  linkBack: {
    display: 'flex',
    alignItems: 'center',
    color: '#fff',
    textDecoration: 'none',
    fontSize: '16px',
    fontWeight: 400,
    '& > svg': {
      marginRight: '12px',
      marginBottom: '2px',
    },
  },
  btn: {
    marginRight: '10px',
    borderRadius: '50px',
    borderColor: '#2C6DCF',
    color: '#2C6DCF',
    textTransform: 'none',
    height: '35px',
    padding: '0 22px',
    fontWeight: 500,
    fontSize: '16px',

    '&:last-child': {
      marginRight: '0',
    },

    '.withMobileMenu &': {
      '@media (max-width: 1020px)': {
        display: 'flex',
        maxWidth: '28px',
        minWidth: '28px',
        maxHeight: '24px',
        minHeight: '24px',
        padding: '0px',
        alignItems: 'center',
        justifyContent: 'center',
        background: 'rgba(255, 255, 255, 0.1)',
        borderRadius: '15px',
        border: 'none',
        marginLeft: 'auto',
      },
    },
  },
  titleRoot: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',

    '& svg': {
      marginRight: '5px',
    },
  },
  title: {
    fontSize: '16px',
    fontWeight: 500,
  },
  mobileMenuButton: {
    display: 'none',

    '@media (max-width: 1020px)': {
      display: 'flex',
      maxWidth: '28px',
      minWidth: '28px',
      maxHeight: '24px',
      minHeight: '24px',
      padding: '0px',
      alignItems: 'center',
      justifyContent: 'center',
      background: 'rgba(255, 255, 255, 0.1)',
      borderRadius: '15px',
      marginLeft: '10px',
    },
  },
}));
