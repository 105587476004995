import React from 'react';
import {
  Box,
  Dialog,
  DialogTitle,
  DialogContent,
  IconButton,
  Typography,
  Divider,
} from '@material-ui/core';
import CloseIcon from 'src/assets/icons/light/close.svg';
import { useStyles } from './styles';
import clsx from 'clsx';

interface IProps {
  open: boolean;
  showReaded?: boolean;
  onChangeFilter: (showReaded?: boolean) => void;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
}

const MobileNotificationsFilter = ({
  open,
  setOpen,
  showReaded,
  onChangeFilter,
}: IProps) => {
  const classes = useStyles();

  const activeFilter =
    typeof showReaded === 'undefined' ? 'all' : showReaded ? 'read' : 'new';

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <Dialog
      open={open}
      fullWidth={true}
      onClose={handleClose}
      className={clsx(classes.dialog, classes.mobileFilterDialog)}>
      <DialogTitle style={{ padding: '0px 14px' }}>
        <Box className={classes.confirmModalHeader}>
          <Typography className={classes.mobileFilterTitle}>Choose</Typography>
          <IconButton
            onClick={() => setOpen(false)}
            className={classes.closeIcon}>
            <CloseIcon />
          </IconButton>
        </Box>
      </DialogTitle>
      <DialogContent>
        <Box
          onClick={() => onChangeFilter()}
          className={clsx(
            classes.mobileFilterOption,
            activeFilter === 'all'
              ? classes.mobileFilterActiveOption
              : undefined
          )}>
          All
        </Box>
        <Box
          onClick={() => onChangeFilter(false)}
          className={clsx(
            classes.mobileFilterOption,
            activeFilter === 'new'
              ? classes.mobileFilterActiveOption
              : undefined
          )}>
          New
        </Box>
        <Divider style={{ background: 'rgba(13, 209, 197, 0.15)' }} />
        <Box
          onClick={() => onChangeFilter(true)}
          className={clsx(
            classes.mobileFilterOption,
            activeFilter === 'read'
              ? classes.mobileFilterActiveOption
              : undefined
          )}>
          Read
        </Box>
      </DialogContent>
    </Dialog>
  );
};

export default MobileNotificationsFilter;
