import React from 'react';
import { useSelector } from 'react-redux';
import { Box, TableCell, TableRow, useMediaQuery } from '@material-ui/core';
// import { IArrival } from 'src/pages/Arrivals/interfaces';
import { NotFoundData } from '../UI/NotFoundData';
import TripNameIcon from 'src/assets/icons/light/arrivalTripName.svg';
import { changeLoading } from 'src/web/features/table/sliceTables';
import { useHistory } from 'react-router-dom';
import { useStyles } from './styles';
import { dollarAmountsFloat } from 'src/helpers/DollarAmounts';
import { localizedTime } from 'src/helpers/DateLocal';
import { getColorStatus } from 'src/helpers/getColorStatus';
import moment from 'moment';
import { isUserHasPermissions } from 'src/helpers/permissions';
import { currentUser } from 'src/redux/Authentication/sliceAuthentication';

const paymentTypes = [
  'American Express',
  'AndroidPayCard',
  'ApplePayCard',
  'Discover',
  'MasterCard',
  'PayPalAccount',
  'VenmoAccount',
  'undefined',
  'Visa',
];

export const ArrivalsList = ({ arrivals }: any) => {
  const classes = useStyles();
  const history = useHistory();
  const loading = useSelector(changeLoading);
  const mdMatches = useMediaQuery('(max-width: 1020px)');
  const user = useSelector(currentUser);

  const row_click_handler = (id: string) => {
    isUserHasPermissions(user, ['order_view']) &&
      history.push(`arrivals/${id}`);
  };

  const getPaymentType = (type: string = '') => {
    const t = type.split('CreditCard|');
    if (!paymentTypes.includes(t[t.length - 1])) {
      return 'undefined';
    }
    return t[t.length - 1];
  };

  return (
    <>
      {!loading && !arrivals.length ? (
        <TableRow className={classes.table_row}>
          <TableCell className={classes.tableLoaderWrapper} width={100}>
            <Box className={classes.tableLoader}>
              <NotFoundData title={'No data'} />
            </Box>
          </TableCell>
        </TableRow>
      ) : (
        arrivals.map((arrival: any) => {
          const statusName = arrival.state_name.replace('_', ' ');
          const tripName = (value: string) =>
            value.length > 36 ? `${value.substring(0, 36)}...` : value;

          return mdMatches ? (
            <React.Fragment key={arrival.cart_item_id}>
              <TableRow
                hover={true}
                className={classes.table_row}
                onClick={() => row_click_handler(arrival.cart_item_id)}>
                <TableCell className={classes.table_cell}>
                  <Box className={classes.adaptiveTripCell}>
                    <TripNameIcon style={{ marginRight: '4px' }} />
                    {tripName(arrival.trip_name) || 'N/A'}
                  </Box>
                </TableCell>

                <TableCell className={classes.table_cell} align="left">
                  <Box className={classes.adaptiveCustomerCell}>
                    <Box className={classes.name_subtext}>
                      {(arrival.first_name + ' ' + arrival.last_name).trim() ||
                        'N/A'}
                    </Box>
                    <Box className={classes.phone_subtext}>
                      {arrival.phone || 'N/A'}
                    </Box>
                  </Box>
                </TableCell>
                <TableCell className={classes.table_cell}>
                  <Box
                    className={classes.custom_chip}
                    bgcolor={getColorStatus(arrival.state_name)}
                    color="white">
                    <div className={classes.chip_text}>
                      {statusName.charAt(0).toUpperCase() +
                        statusName.slice(1) || 'N/A'}
                    </div>
                  </Box>
                </TableCell>
              </TableRow>
            </React.Fragment>
          ) : (
            <React.Fragment key={arrival.cart_item_id}>
              <TableRow
                hover={true}
                className={classes.table_row}
                onClick={() => row_click_handler(arrival.cart_item_id)}>
                <TableCell className={classes.table_cell}>
                  <Box className={classes.orderCell}>
                    {arrival.id ? 'TRS-' + arrival.id : 'N/A'}
                  </Box>
                  <Box display="flex" alignItems="end" mb={0.6}>
                    <Box mb={-0.2}>
                      {localizedTime(arrival.made, 'MMM DD, YYYY hh:mma') ||
                        'N/A'}
                    </Box>
                  </Box>
                </TableCell>

                <TableCell className={classes.table_cell}>
                  <Box className={classes.madeCell}>
                    {arrival.date
                      ? moment(arrival.date).format('MMM DD, YYYY')
                      : 'N/A'}
                    <Box>{arrival.schedule_time}</Box>
                  </Box>
                </TableCell>

                <TableCell className={classes.table_cell}>
                  <Box className={classes.tripCell}>
                    <TripNameIcon style={{ marginRight: '4px' }} />
                    {arrival.trip_name}
                  </Box>
                </TableCell>

                <TableCell className={classes.table_cell} align="left">
                  <Box className={classes.customerCell}>
                    <Box className={classes.name_subtext}>
                      {(arrival.first_name + ' ' + arrival.last_name).trim() ||
                        'N/A'}
                    </Box>
                    <Box className={classes.phone_subtext}>
                      {arrival.phone || 'N/A'}
                    </Box>
                  </Box>
                </TableCell>

                <TableCell className={classes.table_cell}>
                  <Box className={classes.arrival_type_container}>
                    <Box className={classes.flex}>
                      {arrival.type && (
                        <img
                          src={`/img/payment-icons/${getPaymentType(
                            arrival.type
                          )}.png`}
                          alt={arrival.type}
                          className={classes.icon_arrival_type}
                        />
                      )}
                    </Box>
                    <Box textAlign={'left'}>
                      {dollarAmountsFloat(arrival.net_total) || 'N/A'}
                    </Box>
                  </Box>
                </TableCell>

                <TableCell className={classes.table_cell}>
                  <Box
                    className={classes.custom_chip}
                    bgcolor={getColorStatus(arrival.state_name)}
                    color="white">
                    <div className={classes.chip_text}>
                      {statusName.charAt(0).toUpperCase() +
                        statusName.slice(1) || 'N/A'}
                    </div>
                  </Box>
                </TableCell>
              </TableRow>
            </React.Fragment>
          );
        })
      )}
    </>
  );
};

// interface IArrivalsListProps {
//   arrivals: IArrival[];
// }
