import React from 'react';
import { ThemeProvider } from '@material-ui/core/styles';
import { Card, Box, CardContent, Typography } from '@material-ui/core/';
import Logo from 'src/images/login_bg.svg';
import { useStyles, theme } from './styles';

export const SystemLayout: React.FC<{}> = ({ children }: any) => {
  const classes = useStyles();

  return (
    <ThemeProvider theme={theme}>
      <Box className={classes.root}>
        <Box className={classes.logoWrapper}>
          <Logo />
          <Typography className={classes.subtitle}>Supplier Extranet</Typography>
        </Box>
        <Card className={classes.card}>
          <CardContent className={classes.cardContent}>{children}</CardContent>
        </Card>
      </Box>
    </ThemeProvider>
  );
};
