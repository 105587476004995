import React from 'react';
import {
  Box,
  Card,
  CardContent,
  Checkbox,
  FormControlLabel,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TextField,
  Typography,
  useMediaQuery,
} from '@material-ui/core';
import clsx from 'clsx';
import { useStyles } from './styles';
import CheckboxIcon from 'src/assets/icons/web/checkbox.svg';
import CheckboxCheckedIcon from 'src/assets/icons/web/checkboxChecked.svg';
import { parseNumberInputValue } from 'src/helpers/calendar/parseNumberInput';

interface Column {
  field: string;
  headerName: string;
  width?: string;
  align?: 'right' | 'left' | 'center';
  format?: (value: number) => string;
}

const columns: Column[] = [
  { field: 'value', headerName: 'Value Name', align: 'right', width: '30%' },
  {
    field: 'price',
    headerName: 'Price',
    align: 'center',
    width: '20%',
  },
  {
    field: 'disable',
    headerName: 'Disable',
    align: 'center',
    width: '25%',
  },
  {
    field: 'tax',
    headerName: 'Apply Tax',
    align: 'center',
    width: '25%',
  },
];

type Props = {
  trip: ITripCalendarData;
  optionId?: number;
  type: 'ticket' | 'option' | 'schedule';
  batchData: IBatchData;
  dates: {
    date_from: string | null;
    date_to: string | null;
  }[];
  withRates?: boolean;
  setBatchData: React.Dispatch<React.SetStateAction<IBatchData>>;
};

export const BatchEditFields = ({
  trip,
  optionId = 0,
  type,
  dates,
  batchData,
  withRates = false,
  setBatchData,
}: Props) => {
  const classes = useStyles();
  const isMobile = useMediaQuery('(max-width: 960px)');

  const onChangeField = (field: keyof IBatchData, value: number) => {
    setBatchData((prev) => ({ ...prev, [field]: value }));
  };

  const onChangeOptionValue = (
    valueId: number,
    field: string,
    value: number
  ) => {
    setBatchData((prev) => {
      const newValues = structuredClone(prev.values || {});
      newValues[valueId] = { ...newValues[valueId], [field]: value };

      return { ...prev, values: newValues };
    });
  };

  if (type === 'ticket') {
    return (
      <Card
        className={clsx(
          classes.fieldsCard,
          classes.masterCard,
          classes.inputWrapper
        )}>
        <CardContent style={{ padding: 0 }}>
          <Box>
            <div style={{ display: 'flex' }}>
              <Typography style={{ fontWeight: 600 }}>
                Apply these changes for date range: from {dates[0].date_from} to{' '}
                {dates[0].date_to}
              </Typography>
            </div>
            {!withRates && (
              <Box display="flex">
                <Box display="flex" alignItems="center" justifyContent="center">
                  <FormControlLabel
                    control={
                      <Checkbox
                        color="primary"
                        value={batchData.is_disabled}
                        onChange={(_, checked) =>
                          onChangeField('is_disabled', Number(checked))
                        }
                        icon={<CheckboxIcon />}
                        checkedIcon={<CheckboxCheckedIcon />}
                      />
                    }
                    label="Stop Sell"
                    labelPlacement="start"
                  />
                </Box>
              </Box>
            )}
          </Box>
          <Box width={1} display="flex" flexWrap="wrap">
            <Box
              width={isMobile ? 1 : 1 / 6}
              p={2}
              display="flex"
              alignItems="center"
              justifyContent="center">
              <TextField
                type="number"
                value={batchData.price}
                onChange={(e) => onChangeField('price', Number(e.target.value))}
                variant="outlined"
                size="small"
                InputLabelProps={{ shrink: true }}
                label="Rate"
              />
            </Box>
            <Box
              width={isMobile ? 1 : 1 / 6}
              p={2}
              display="flex"
              alignItems="center"
              justifyContent="center">
              <TextField
                type="number"
                value={batchData.strike_out}
                onChange={(e) =>
                  onChangeField('strike_out', Number(e.target.value))
                }
                variant="outlined"
                size="small"
                InputLabelProps={{ shrink: true }}
                label="Strikeout"
              />
            </Box>

            {!withRates && (
              <>
                <Box
                  width={isMobile ? 1 : 1 / 6}
                  p={2}
                  display="flex"
                  alignItems="center"
                  justifyContent="center">
                  <TextField
                    type="number"
                    value={batchData.min}
                    onChange={(e) =>
                      onChangeField('min', Number(e.target.value))
                    }
                    variant="outlined"
                    size="small"
                    InputLabelProps={{ shrink: true }}
                    label="Min"
                  />
                </Box>
                <Box
                  width={isMobile ? 1 : 1 / 6}
                  p={2}
                  display="flex"
                  alignItems="center"
                  justifyContent="center">
                  <TextField
                    type="number"
                    value={batchData.total_ts}
                    onChange={(e) =>
                      onChangeField('total_ts', Number(e.target.value))
                    }
                    variant="outlined"
                    size="small"
                    InputLabelProps={{ shrink: true }}
                    label="Total"
                  />
                </Box>
              </>
            )}
          </Box>
        </CardContent>
      </Card>
    );
  }

  if (type === 'schedule') {
    return (
      <Card
        className={clsx(
          classes.fieldsCard,
          classes.masterCard,
          classes.inputWrapper
        )}>
        <CardContent style={{ padding: 0 }}>
          <Box>
            <div style={{ display: 'flex' }}>
              <Typography style={{ fontWeight: 600 }}>
                Apply these changes for date range: {}
              </Typography>
            </div>
            <Box display="flex" alignItems="center" justifyContent="center">
              <FormControlLabel
                value="disable"
                control={
                  <Checkbox
                    checked={Boolean(batchData.is_disabled)}
                    onChange={(_, checked) =>
                      onChangeField('is_disabled', Number(checked))
                    }
                    color="primary"
                    icon={<CheckboxIcon />}
                    checkedIcon={<CheckboxCheckedIcon />}
                  />
                }
                label="Disable"
                labelPlacement="start"
              />
            </Box>
          </Box>
          <Box width={1} display="flex" flexWrap="wrap">
            <Box
              width={isMobile ? 1 : 1 / 2}
              p={2}
              display="flex"
              alignItems="center"
              justifyContent="center">
              <TextField
                type="number"
                variant="outlined"
                label="Min tickets"
                value={batchData.min_tickets}
                InputLabelProps={{
                  shrink: true,
                }}
                onChange={(e) =>
                  onChangeField('min_tickets', Number(e.target.value))
                }
              />
            </Box>
            <Box
              width={isMobile ? 1 : 1 / 2}
              p={2}
              display="flex"
              alignItems="center"
              justifyContent="center">
              <TextField
                type="number"
                variant="outlined"
                label="Max tickets"
                value={batchData.max_tickets}
                InputLabelProps={{
                  shrink: true,
                }}
                onChange={(e) =>
                  onChangeField('max_tickets', Number(e.target.value))
                }
              />
            </Box>
          </Box>
        </CardContent>
      </Card>
    );
  }

  if (type === 'option') {
    const option = trip.additional_options?.find(
      (option) => +option.id === +optionId
    );

    if (!option) {
      return <></>;
    }

    return (
      <Card
        className={clsx(
          classes.fieldsCard,
          classes.masterCard,
          classes.inputWrapper
        )}>
        <CardContent style={{ padding: 0 }}>
          <Box>
            <div style={{ display: 'flex' }}>
              <Typography style={{ fontWeight: 600 }}>
                Apply these changes for date range: {}
              </Typography>
            </div>
            <Box display="flex" alignItems="center" justifyContent="center">
              <FormControlLabel
                control={
                  <Checkbox
                    checked={Boolean(batchData.is_disabled)}
                    onChange={(_, checked) =>
                      onChangeField('is_disabled', Number(checked))
                    }
                    icon={<CheckboxIcon />}
                    checkedIcon={<CheckboxCheckedIcon />}
                  />
                }
                label="Disable Option"
                labelPlacement="start"
              />
            </Box>
          </Box>
          <Table>
            <TableHead>
              <TableRow>
                {columns.map((column) => (
                  <TableCell
                    align={column.align}
                    key={column.field}
                    style={{ width: column.width, fontSize: '16px' }}>
                    {column.headerName}
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {option?.values?.map((row: any, index: number) => {
                const valueId = +row.id;
                return (
                  <TableRow
                    hover
                    role="checkbox"
                    tabIndex={-1}
                    key={row.id + index}>
                    <TableCell style={{ padding: '6px 16px' }} align="right">
                      {row.name}
                    </TableCell>
                    <TableCell style={{ padding: '6px 16px' }} align="center">
                      <Box
                        p={3}
                        display="flex"
                        alignItems="center"
                        justifyContent="center">
                        <TextField
                          value={parseNumberInputValue(
                            batchData.values?.[+valueId]?.price
                          )}
                          onChange={(e) =>
                            onChangeOptionValue(
                              +valueId,
                              'price',
                              +e.target.value
                            )
                          }
                          type="number"
                          size="small"
                          variant="outlined"
                          style={{ padding: '0 20px', minWidth: '150px' }}
                        />
                      </Box>
                    </TableCell>
                    <TableCell style={{ padding: '6px 16px' }} align="center">
                      <Box
                        display="flex"
                        alignItems="center"
                        justifyContent="center">
                        <Checkbox
                          checked={Boolean(
                            batchData?.values?.[+valueId]?.is_disabled
                          )}
                          onChange={(_, checked) =>
                            onChangeOptionValue(
                              +valueId,
                              'is_disabled',
                              Number(checked)
                            )
                          }
                          color="primary"
                          icon={<CheckboxIcon />}
                          checkedIcon={<CheckboxCheckedIcon />}
                        />
                      </Box>
                    </TableCell>
                    <TableCell style={{ padding: '6px 16px' }} align="center">
                      <Box
                        display="flex"
                        alignItems="center"
                        justifyContent="center">
                        <Checkbox
                          checked={Boolean(
                            batchData?.values?.[+valueId]?.apply_tax
                          )}
                          onChange={(_, checked) =>
                            onChangeOptionValue(
                              +valueId,
                              'apply_tax',
                              Number(checked)
                            )
                          }
                          color="primary"
                          icon={<CheckboxIcon />}
                          checkedIcon={<CheckboxCheckedIcon />}
                        />
                      </Box>
                    </TableCell>
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
        </CardContent>
      </Card>
    );
  }

  return <Typography>Please choose type</Typography>;
};
