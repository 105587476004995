import clsx from "clsx";
import React, { useState } from "react";
import {
  Box,
  Dialog,
  DialogContent,
  DialogTitle,
  MenuItem,
  Select,
  Typography,
} from "@material-ui/core";
import { Cell, Pie, PieChart, ResponsiveContainer } from "recharts";
import PerfectScrollbar from "react-perfect-scrollbar";
import CloseIcon from "src/assets/icons/light/close.svg";
import DropdownArrowIcon from "src/assets/icons/light/dropdownArrow.svg";

import { DateSelector } from "src/web/pages/Dashboard";
import DashboardPieShape from "./DashboardPieShape";

import { useStyles } from "./styles";

type Props = {
  selectedDate: DateSelector;
  selectDate: React.Dispatch<React.SetStateAction<DateSelector>>;
  trips: IDashboardTripExtended[];
  totalAmount: number;
  totalOrders: number;
};

const valueLabelSelect = {
  today: "Today",
  yesterday: "Yesterday",
  month: "This month",
  previosMonth: "Last month",
};

export const DashboardPieChart = ({
  trips,
  totalAmount,
  totalOrders,
  selectedDate,
  selectDate,
}: Props) => {
  const classes = useStyles();

  const [isOpenMobileSelector, setIsOpenMobileSelector] = useState(false);
  const [activeTripId, setActiveTripId] = useState<string>();

  const activeIndex = trips.findIndex((trip) => trip.id === activeTripId);

  const activeShape = (props: any) => {
    return (
      <DashboardPieShape
        {...props}
        isActive={!!activeTripId}
        payload={
          !activeTripId
            ? {
                total: totalAmount,
                count: totalOrders,
              }
            : { ...props.payload }
        }
      />
    );
  };

  const onSetActiveTrip = (newTripId: string) => {
    if (newTripId === activeTripId) {
      return setActiveTripId(undefined);
    }

    setActiveTripId(newTripId);
  };

  const tripsForPie =
    trips.length && !trips.every((item) => item.percent === 0)
      ? trips
      : [
          {
            id: "0",
            color: "#eef9f2",
            count: 0,
            total: 1,
            percent: 100,
          },
        ];

  return (
    <Box className={classes.dashboardPieChart}>
      <Dialog
        className={classes.dashboardPieChartMobileDialog}
        open={isOpenMobileSelector}
        onClose={() => setIsOpenMobileSelector(false)}
      >
        <DialogContent>
          <Box
            className={classes.dashboardPieChartMobileDialogSelectorClose}
            onClick={() => setIsOpenMobileSelector(false)}
          >
            <CloseIcon width={15} height={15} />
          </Box>
          <DialogTitle>Options</DialogTitle>
          <Box className={classes.dashboardPieChartMobileDialogSelector}>
            {Object.keys(valueLabelSelect).map((key) => (
              <Box
                key={key}
                onClick={() => selectDate(key as DateSelector)}
                className={clsx(
                  classes.dashboardPieChartMobileDialogSelectorItem,
                  { active: key === selectedDate }
                )}
              >
                {valueLabelSelect[key as DateSelector]}
              </Box>
            ))}
          </Box>
        </DialogContent>
      </Dialog>

      <Box className={classes.dashboardPieChartHeader}>
        <Typography className={classes.dashboardPieChartTitle}>
          Trip sales
        </Typography>
        <Box
          className={classes.dashboardPieChartSelectMobile}
          onClick={() => setIsOpenMobileSelector(true)}
        >
          <Typography className={classes.dashboardPieChartSelectMobileText}>
            {valueLabelSelect[selectedDate]}
          </Typography>
          <DropdownArrowIcon />
        </Box>
        <Select
          variant="outlined"
          className={classes.dashboardPieChartSelect}
          value={selectedDate}
          MenuProps={{
            PaperProps: {
              className: classes.dashboardPieChartSelectPaper,
            },
          }}
          onChange={(e) => selectDate(e.target.value as DateSelector)}
        >
          {Object.keys(valueLabelSelect).map((key) => (
            <MenuItem key={key} value={key}>
              {valueLabelSelect[key as DateSelector]}
            </MenuItem>
          ))}
        </Select>
      </Box>
      <Box className={classes.dashboardPieChartWrapper}>
        <ResponsiveContainer>
          <PieChart>
            <Pie
              activeIndex={activeIndex === -1 ? 0 : activeIndex}
              activeShape={activeShape}
              data={tripsForPie}
              cx="50%"
              cy="47%"
              outerRadius={81}
              innerRadius={57}
              dataKey="total"
              strokeWidth={0}
              onClick={(e: any, index: number) =>
                trips.length && onSetActiveTrip(tripsForPie[index].id)
              }
            >
              {tripsForPie?.map((trip, index) => (
                <Cell
                  className={classes.dashboardPieChartCell}
                  key={index}
                  fill={trip.color}
                />
              ))}
            </Pie>
          </PieChart>
        </ResponsiveContainer>
      </Box>
      <Box height={"125px"}>
        <PerfectScrollbar>
          {trips.map((trip, index) => (
            <Box
              className={clsx(classes.dashboardPieChartTripsListItem, {
                active: trip.id === activeTripId,
                disabled: trip.count === 0
              })}
              
              key={trip.id}
              onClick={() => {
                if (trip.count) {
                  onSetActiveTrip(trips[index].id);
                }
              }}
            >
              <Box
                className={classes.dashboardPieChartTripsListItemColor}
                style={{ backgroundColor: trip.color }}
              />
              <Typography
                className={classes.dashboardPieChartTripsListItemTitle}
              >
                {trip.name}
              </Typography>
              <Typography
                className={classes.dashboardPieChartTripsListItemOrders}
              >
                {trip.count}
              </Typography>
            </Box>
          ))}
        </PerfectScrollbar>
      </Box>
    </Box>
  );
};
