import React from 'react';
import { useSelector } from 'react-redux';
import {
  calendar as sliceCalendar,
  search,
  page,
  filter,
} from 'src/web/features/table/sliceTables';
import { Box } from '@material-ui/core';
import Animation from 'src/web/features/animation';
import { TableSearch, TableCalendar } from '../index';
import { ITableSearchProps } from '../interfaces';
import { useStyles } from './styles';

export const TableHeader = ({
  setOpenSidebar,
  where_scheme,
  headerElement,
  headerWidth,
  customSearchElement: CustomSearch,
  headerTitle,
}: ITableHeaderProps) => {
  const classes = useStyles();
  const currentFilter = useSelector(filter);
  const currentCalendar = useSelector(sliceCalendar);
  const currentSearch = useSelector(search);
  const currentPage = useSelector(page);
  const isActive = currentCalendar?.isActive;

  return (
    <>
      <Box
        className={
          currentFilter.length
            ? classes.center_side_header
            : classes.left_side_header
        }
      >
        <Box className={classes.headerTitle}>{headerTitle}</Box>
        <Animation animation="transition.slideDownIn" delay={300}>
          <Box
            className={
              currentPage === 'groups'
                ? classes.button_container
                : classes.input_container
            }>
            {CustomSearch ? (
              <CustomSearch setOpenSidebar={setOpenSidebar} />
            ) : (
              !!currentSearch && <TableSearch setOpenSidebar={setOpenSidebar} />
            )}
          </Box>
        </Animation>
        {!!currentCalendar && (
          <Animation animation="transition.slideRightIn" delay={300}>
            <Box
              className={`${classes.flex} ${
                isActive ? classes.aciveCalendar : classes.inactiveCalendar
              }`}>
              <TableCalendar />
            </Box>
          </Animation>
        )}
        {headerElement && (
          <div style={{ width: headerWidth }}>
            <Animation animation="transition.slideLeftIn" delay={300}>
              {headerElement}
            </Animation>
          </div>
        )}
      </Box>
    </>
  );
};

interface ITableHeaderProps {
  setOpenSidebar: (value: boolean) => void;
  where_scheme?: string;
  headerElement?: JSX.Element | null;
  customSearchElement?: React.FC<ITableSearchProps>;
  headerWidth?: string;
  headerTitle?: any;
}
