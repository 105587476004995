import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  IconButton,
  Typography,
} from '@material-ui/core';
import PerfectScrollbar from 'react-perfect-scrollbar';
import React, { useState } from 'react';
import { useMutation, useQuery } from '@apollo/client';
import { GET_CALENDAR, UPDATE_CALENDAR } from 'src/api/calendar';
import { useDispatch, useSelector } from 'react-redux';
import { auth } from 'src/redux/Authentication/sliceAuthentication';
import {
  convertDateRangesForQuery,
  convertWeekForRequest,
  normalizeCalendarDataForRates,
} from 'src/helpers/calendar/calendarRequests';
import { dateRanges, normalizeTime } from 'src/helpers/calendar/normalizeTime';
import { MasterTicketFields } from '../CalendarFields/MasterTicketFields';
import { TicketFields } from '../CalendarFields/TicketFields';

import { useStyles } from './styles';
import { Close } from '@material-ui/icons';
import { setNotification } from 'src/redux/Notifications/sliceNotifications';
import { Spiner } from '../../Loaders';
import { BatchEditFields } from '../CalendarFields/BatchEditFields';

type Props = {
  open: boolean;
  onClose: () => void;
  trip: ITripCalendarData;
  type: 'ticket' | 'schedule' | 'option';
  dates: {
    date_from: string | null;
    date_to: string | null;
  }[];
  rateId: number;
  week: {
    id: number;
    label: string;
    mLabel: string;
    checked: boolean;
  }[];
  ticketId: number;
  isBatchEdit: boolean;
};

export const TicketsModal = ({
  open,
  onClose,
  trip,
  rateId,
  type,
  dates,
  week,
  ticketId,
  isBatchEdit,
}: Props) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { user } = useSelector(auth);
  const [calendarTicket, setCalendarTicket] = useState<ICalendarTicket>({});
  const [batchData, setBatchData] = useState<IBatchData>({} as IBatchData);

  const withRates = Boolean(rateId);

  useQuery(GET_CALENDAR, {
    variables: {
      prm: {
        user_id: +user['custom:partner_id'],
        trip_id: trip.id,
        rate_id: rateId,
        partner_id: +user['custom:partner_id'],
        date_ranges: convertDateRangesForQuery(dates),
        days: convertWeekForRequest(week),
        type: type,
        schedules_id: trip.schedules
          ?.filter((schedule) => schedule.checked)
          .map((schedule) => schedule.id)
          .toString(),
        ticket_id: ticketId,
      },
    },
    skip: !open || !user['custom:partner_id'],
    onCompleted: (data) => {
      const calendarData = JSON.parse(
        data.v1_activities_getCalendar.data || '{}'
      );

      setCalendarTicket(calendarData);
    },
  });

  const [updateCalendar, { loading: updateCalendarLoading }] = useMutation(
    UPDATE_CALENDAR,
    {
      onCompleted: (data) => {
        if (JSON.parse(data.v1_activities_updateCalendar)?.success) {
          setCalendarTicket({});
          onClose();
          dispatch(
            setNotification({
              message: 'The calendar has been updated successfully',
              type: 'success',
            })
          );
        }
      },
      onError: (error) => {
        onClose();
        dispatch(
          setNotification({
            message: error.message,
            type: 'error',
          })
        );
      },
    }
  );

  const onSaveData = () => {
    const prm: Record<string, string | number | number[]> = {
      user_id: +user['custom:partner_id'],
      trip_id: +trip.id,
      rate_id: rateId,
      partner_id: +user['custom:partner_id'] || 0,
      type: type,
      ticket_id: ticketId,
    };

    if (isBatchEdit) {
      prm.batch_data = JSON.stringify(batchData);
      prm.date_from = dates[0].date_from || '';
      prm.date_to = dates[0].date_to || '';
      prm.schedules_ids = trip.schedules
        .filter((schedule) => schedule.checked)
        .map((schedule) => schedule.id);
    } else {
      prm.calendar_days = JSON.stringify(calendarTicket);
    }

    if (withRates) {
      prm.calendar_days = JSON.stringify(
        normalizeCalendarDataForRates(calendarTicket)
      );
    }

    updateCalendar({
      variables: {
        prm,
      },
    });
  };

  const ticketName = trip.tickets?.find((i) => +i.id === +ticketId)?.name;
  const checkedDays = week
    ?.filter((item) => item.checked)
    .map((item) => item.id)
    .join(',');

  return (
    <Dialog open={open} onClose={onClose} className={classes.dialog}>
      {updateCalendarLoading && <Spiner />}
      <DialogTitle>
        <Box>
          <Box
            display="flex"
            flexDirection="row-reverse"
            justifyContent="space-between"
            alignItems="flex-start">
            <IconButton
              onClick={onClose}
              style={{
                padding: '13px',
                background: 'white',
                boxShadow: '0px 3.89312px 23.3587px rgba(189, 189, 189, 0.23)',
              }}>
              <Close />
            </IconButton>
          </Box>
          <Divider style={{ backgroundColor: '#FAFAFA' }} />
        </Box>
      </DialogTitle>
      <DialogContent>
        <Box>
          <PerfectScrollbar>
            {isBatchEdit ? (
              <BatchEditFields
                batchData={batchData}
                setBatchData={setBatchData}
                trip={trip}
                type="ticket"
                dates={dates}
                withRates={withRates}
              />
            ) : (
              <>
                <MasterTicketFields
                  subTitle="Bulk Update (All Schedules)"
                  ticket={ticketName}
                  setCalendarTicket={setCalendarTicket}
                  withRates={withRates}
                />
                {trip.schedules
                  ?.filter((item) => item.checked)
                  .map((schedule) => {
                    return (
                      <div style={{ marginTop: '35px' }}>
                        <Box pl={2} pb={1}>
                          <Typography style={{ fontWeight: 600 }}>
                            {normalizeTime(schedule.hour, schedule.minute)}
                          </Typography>
                        </Box>
                        <Box>
                          <MasterTicketFields
                            scheduleId={schedule.id}
                            subTitle="Bulk Update (Single Schedule)"
                            ticket={ticketName}
                            setCalendarTicket={setCalendarTicket}
                            withRates={withRates}
                          />
                          {dates.map((choosenPeriod) => {
                            const _dates = dateRanges(
                              choosenPeriod.date_from,
                              choosenPeriod.date_to
                            );

                            return _dates.map((date) => {
                              if (
                                checkedDays.includes(
                                  new Date(date).getDay().toString()
                                )
                              ) {
                                return (
                                  <TicketFields
                                    date={date}
                                    subTitle=""
                                    ticket={ticketName}
                                    scheduleId={schedule.id}
                                    calendarTicket={calendarTicket}
                                    setCalendarTicket={setCalendarTicket}
                                    withRates={withRates}
                                  />
                                );
                              }
                              return <></>;
                            });
                          })}
                        </Box>
                      </div>
                    );
                  })}
              </>
            )}
          </PerfectScrollbar>
        </Box>
      </DialogContent>
      <DialogActions>
        <Box display="flex" justifyContent="flex-end">
          <Button className={classes.button} onClick={onSaveData} disabled>
            Save
          </Button>
        </Box>
      </DialogActions>
    </Dialog>
  );
};
