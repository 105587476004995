import React from "react";

import { Box } from "@material-ui/core/";
import { useStyles } from "./styles";
import ErrorIcon from "src/assets/icons/web/errorFieldIcon.svg";

export const InputError = ({ message }: { message: string }) => {
  const classes = useStyles();

  return (
    <Box className={classes.helperText}>
      <div className={classes.triangle}></div>
      <ErrorIcon style={{ marginRight: "8px" }} /> {message}
    </Box>
  );
};
