import { makeStyles } from '@material-ui/core/styles';
import { Theme } from 'src/web/themes/theme';

export const useStyles = makeStyles((theme: Theme) => ({
  cardBox: {
    display: 'flex',
    padding: '27px 15px',
    backgroundColor: 'white',
    borderRadius: '15px',
    justifyContent: 'space-between',
    height: '160px',
    marginBottom: '20px',
    boxShadow: '0px 3.89312px 23.3587px rgba(96, 96, 97, 0.18)',
    '@media (max-width: 1175px)': {
      width: '100%',
      marginLeft: '0',
      marginBottom: '10px',
      marginTop: '0',
      padding: '23px 35px 25px 20px',
    },
    '@media (max-width: 1000px)': {
      marginLeft: '0',
      marginBottom: '10px',
      marginTop: '0',
      height: '225px',
      padding: '23px 12px 25px 20px',
      flexDirection: 'column',
    },
    '@media (max-width: 850px)': {
      height: 'auto',
      padding: '19px 12px 19px 20px',
      alignItems: 'center',
      marginBottom: '20px',
    },
  },
  mainBox: {
    position: 'relative',
    backgroundColor: 'white',
    boxShadow: '0px 3.89312px 23.3587px rgba(96, 96, 97, 0.18)',
    borderRadius: '15px',
    minHeight: 'calc(100vh - 350px)',
    '@media (max-width: 750px)': {
      boxShadow: 'none',
      background: 'none',
    },
  },
  noDataBox: {
    height: 'calc(100vh - 350px)',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },

  ratingRightBox: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    '@media (max-width: 1280px)': {
      justifyContent: 'center',
    },
    '@media (max-width: 1000px)': {
      justifyContent: 'center',
    },
    '@media (max-width: 750px)': {
      marginBottom: '5px',
    },
  },
  ratingBox: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    '@media (max-width: 1000px)': {
      justifyContent: 'center',
      marginBottom: '5px',
    },
    '@media (max-width: 750px)': {
      marginBottom: '5px',
    },
    '&.choosedRate': {
      borderRadius: '50%',
      backgroundColor: '#0DD1C5',
      color: 'white',
    },
  },
  adaptive: {
    '@media (max-width: 850px)': {
      display: 'none',
    },
  },
  adaptive_1: {
    display: 'none',
    '@media (max-width: 850px)': {
      display: 'flex',
    },
  },
  adaptive_2: {
    '@media (max-width: 750px)': {
      display: 'none',
    },
  },
  adaptive_3: {
    display: 'none',
    '@media (max-width: 750px)': {
      display: 'flex',
    },
  },
  reviewsBox: {
    display: 'flex',
    padding: '37px 15px 0 15px',
    backgroundColor: 'white',
    borderRadius: '15px',
    justifyContent: 'space-between',
    flexDirection: 'column',
    '@media (max-width: 850px)': {
      width: '100%',
      marginLeft: '0',
      marginTop: '0',
      padding: '23px 12px 12px 20px',
    },
    '@media (max-width: 750px)': {
      marginTop: '10px',
      boxShadow: '0px 3.89312px 23.3587px rgba(96, 96, 97, 0.18)',
    },
  },
  reviewInfoLeft: {
    display: 'flex',
    justifyContent: 'space-between',
    height: '100%',
    flexDirection: 'column',
    '@media (max-width: 750px)': {
      flexDirection: 'row',
      width: '100%',
    },
  },
  reviewBox: {
    display: 'flex',
    justifyContent: 'space-between',
    paddingBottom: '37px',
    '@media (max-width: 750px)': {
      flexDirection: 'column',
      borderBottom: 'none',
      paddingBottom: '15px',
    },
  },
  nameOfReview: {
    color: '#333333',
    fontSize: '14px',
    fontWeight: 500,
    marginRight: '10px',
    '@media (max-width: 850px)': {
      fontSize: '12px',
    },
    '@media (max-width: 750px)': {
      fontSize: '14px',
    },
  },
  reviewRaiting: {
    color: '#6C6B6B',
    fontSize: '14px',
    fontWeight: 400,
    marginLeft: '7px',
    '@media (max-width: 850px)': {
      fontSize: '12px',
    },
  },
  reviewContent: {
    flex: 1,
    color: '#6C6B6B',
    fontSize: '12px',
    fontWeight: 400,
    marginBottom: '12px',
    '@media (max-width: 850px)': {
      fontSize: '10px',
    },
    '@media (max-width: 750px)': {
      fontSize: '12px',
    },
  },
  reviewEditBlock: {
    display: 'flex',
    alignItems: 'center',
    flex: 'none',
    color: '#6C6B6B',
    fontSize: '12px',
    marginLeft: '8px',
  },
  responseButton: {
    color: '#0DD1C5',
    fontSize: '14px',
    fontWeight: 500,
    textTransform: 'none',
    border: '1px solid #0DD1C5',
    padding: '1px 34px',
    borderRadius: '50px',
    '&:hover': {
      color: '#6C6B6B',
      border: '1px solid #C4C4C4',
      background: 'white',
    },
    '@media (max-width: 850px)': {
      fontSize: '12px',
    },
    '@media (max-width: 750px)': {
      padding: '3px 29px',
      '&:hover': {
        color: '#0DD1C5',
        border: '1px solid #0DD1C5',
        background: 'white',
      },
    },
  },
  tripName: {
    background: '#F9FCFD',
    borderRadius: '30px',
    textAlign: 'center',
    padding: '3px 0',
    marginBottom: '19px',
    color: '#6C6B6B',
    fontSize: '12px',
    fontWeight: 500,
    '@media (max-width: 850px)': {
      fontSize: '10px',
    },
    '@media (max-width: 750px)': {
      background: 'white',
      textAlign: 'left',
      fontSize: '14px',
      borderBottom: '0.6px solid #dbf8f6',
      borderRadius: 0,
      padding: '13px 0',
    },
  },
  reviewName: {
    color: '#333333',
    fontSize: '16px',
    fontWeight: 500,
    marginBottom: '9px',
    '@media (max-width: 850px)': {
      fontSize: '14px',
      whiteSpace: 'pre-wrap',
    },
  },
  reviewBottomInfo: {
    display: 'flex',
    '@media (max-width: 850px)': {
      flexDirection: 'column',
    },
    '@media (max-width: 750px)': {
      flexDirection: 'row',
    },
  },
  reviewContentItem: {
    color: '#333333',
    fontSize: '12px',
    fontWeight: 400,
    marginBottom: '2px',
    '@media (max-width: 850px)': {
      fontSize: '10px',
    },
    '@media (max-width: 650px)': {
      fontSize: '12px',
    },
  },
  reviewContentName: {
    color: '#6C6B6B',
    fontSize: '12px',
    fontWeight: 400,
    marginBottom: '2px',
    marginRight: '10px',
    '@media (max-width: 850px)': {
      fontSize: '10px',
    },
    '@media (max-width: 650px)': {
      fontSize: '12px',
    },
  },
  reviewReplyTitle: {
    color: '#333333',
    fontSize: '14px',
    fontWeight: 500,
    '@media (max-width: 850px)': {
      fontSize: '12px',
    },
    '@media (max-width: 750px)': {
      marginTop: '3px',
    },
    '@media (max-width: 355px)': {
      textAlign: 'end',
    },
  },
  reviewTime: {
    color: '#C4C4C4',
    fontSize: '12px',
    fontWeight: 400,
    '@media (max-width: 850px)': {
      fontSize: '10px',
    },
    '@media (max-width: 750px)': {
      marginTop: '3px',
    },
    '@media (max-width: 355px)': {
      textAlign: 'end',
    },
  },
  reviewTripBox: {
    width: '90%',
    '@media (max-width: 750px)': {
      width: '100%',
    },
  },
  leftContainer: {
    width: '25%',
    '@media (max-width: 850px)': {
      width: '50%',
    },
    '@media (max-width: 400px)': {
      width: '100%',
    },
  },
  leftWebContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    borderRight: '1px solid #DDDDDD',
    '@media (max-width: 850px)': {
      display: 'none',
    },
  },
  leftMobileContainer: {
    display: 'none',
    '@media (max-width: 850px)': {
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
      justifyContent: 'center',
      width: '100%',
    },
  },
  reviewsCount: {
    color: '#C4C4C4',
    fontSize: '14px',
    fontWeight: 500,
    marginBottom: '5px',
    '@media (max-width: 850px)': {
      fontSize: '12px',
      color: '#6C6B6B',
      marginBottom: '0',
    },
  },
  reviewInfo: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    width: '25%',
    paddingLeft: '3%',
    borderRight: '1px solid #DDDDDD',
    alignItems: 'flex-start',
    '@media (max-width: 1280px)': {
      paddingLeft: '2%',
    },
    '@media (max-width: 750px)': {
      borderBottom: '0.6px solid #dbf8f6',
      borderRight: 'none',
      width: '100%',
      paddingBottom: '15px',
    },
  },
  ratingTitle: {
    color: '#333333',
    fontSize: '30px',
    fontWeight: 600,
    marginBottom: '9px',
    '@media (max-width: 1175px)': {
      fontSize: '25px',
    },
    '@media (max-width: 850px)': {
      fontSize: '20px',
      margin: '0 4px 0 7px',
    },
  },
  reviewTrip: {
    justifyContent: 'center',
    display: 'flex',
    width: '75%',
    '@media (max-width: 750px)': {
      width: '100%',
    },
  },
  cardDivider: {
    backgroundColor: '#DDDDDD',
  },
  rateContent: {
    display: 'grid',
    width: '75%',
    margin: '10px 0',
    gridTemplateColumns: '1fr 1fr',
    gridTemplateRows: '1fr 1fr 1fr',
    gridAutoFlow: 'column',
    '@media (max-width: 1000px)': {
      width: '100%',
    },
    '@media (max-width: 720px)': {
      display: 'flex',
      flexDirection: 'column',
    },
  },
  rateNumber: {
    fontSize: '14px',
    fontWeight: 500,
    color: '#6C6B6B',
    marginRight: '7px',
    display: 'flex',
    width: '9px',
    '@media (max-width: 850px)': {
      fontSize: '12px',
      width: '42px',
      marginRight: '2px',
    },
  },
  rateCount: {
    fontSize: '12px',
    display: 'flex',
    width: '79px',
    fontWeight: 500,
    color: '#C4C4C4',
    '@media (max-width: 850px)': {
      fontSize: '10px',
      width: '32px',
    },
    '&.choosedRate': {
      color: '#0DD1C5',
      fontWeight: 600,
      textDecoration: 'underline',
    },
  },

  filterButton: {
    textTransform: 'none',
    color: '#6C6B6B',
    fontSize: '14px',
    '@media (max-width: 850px)': {
      fontSize: '12px',
    },
    '@media (max-width: 650px)': {
      fontSize: '14px',
      width: 'auto',
      padding: '1px 23px',
    },
    fontWeight: 400,
    border: '1px solid #C4C4C4',
    borderRadius: '50px',
    width: '128px',
    padding: '4px 0',
    '&:hover': {
      background: '#ecfbfa',
      color: '#0DD1C5',
      border: 'none',
    },
    '&:active': {
      background: '#0DD1C5',
      color: '#FFF',
      border: 'none',
    },
  },
  saveButton: {
    background: 'linear-gradient(90deg, #03D9B1 0%, #1CBBD9 100%)',
    color: 'white',
    textTransform: 'none',
    borderRadius: '50px',
    boxShadow:
      '0px 13px 28px rgba(29, 203, 192, 0.2), 0px 8px 16px rgba(29, 203, 192, 0.06)',
    fontSize: '16px',
    '@media (max-width: 850px)': {
      fontSize: '14px',
    },
    '@media (max-width: 750px)': {
      fontSize: '14px',
      padding: '4px 25px',
    },
    padding: '6.5px 46px',
    fontWeight: 500,
    '&:hover': {
      background: 'linear-gradient(90deg, #06C9A4 0%, #00A2C1 100%)',
      color: '#FFFFFF',
      boxShadow:
        '0px 13px 28px rgba(29, 203, 192, 0.2), 0px 8px 16px rgba(29, 203, 192, 0.06)',
    },
  },
  cancelButton: {
    textTransform: 'none',
    color: '#c4c4c4',
    fontSize: '16px',
    '@media (max-width: 750px)': {
      fontSize: '14px',
      padding: '4px 15px',
    },
    [theme.breakpoints.down('sm')]: {
      fontSize: '14px',
      padding: '6.5px 20px',
    },
    padding: '6.5px 33px',
    borderRadius: '50px',
    '&:hover': {
      background: 'white',
      color: '#6C6B6B',
    },
  },
  dialog: {
    '& .MuiDialog-paperScrollPaper': {
      maxHeight: '100%',
    },
    '& .MuiPaper-elevation24': {
      padding: '14px 13px 27px 50px',
      maxWidth: '550px',
      boxShadow: '0px 10.89px 43.36px rgba(17, 15, 15, 0.53)',
      borderRadius: '15px',
      overflow: 'unset',
      '@media (max-width: 750px)': {
        padding: '5px 10px 0px 20px',
      },
      '& .MuiTypography-root': {
        width: '100%',
      },
      '& .MuiDialogContent-root': {
        paddingLeft: 0,
        paddingRight: '37px',
        '@media (max-width: 750px)': {
          paddingRight: '12px',
          paddingBottom: '0px',
        },
      },
    },
  },
  dialogTitle: {
    display: 'flex',
    padding: 0,
  },
  dialogHeader: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'flex-start',
  },

  dialogResponse: {
    fontSize: '12px',
    '@media (max-width: 850px)': {
      fontSize: '10px',
    },
    '@media (max-width: 650px)': {
      fontSize: '12px',
    },
    fontWeight: 400,
    color: '#6C6B6B',
    paddingBottom: '15px',
  },
  textareaBox: {
    position: 'relative',
  },
  textareaLenght: {
    position: 'absolute',
    top: '12px',
    right: '18px',
    fontSize: '12px',
    color: '#6C6B6B',
    '@media (max-width: 850px)': {
      fontSize: '10px',
    },
  },
  textarea: {
    borderRadius: '50px',
    width: '100%',
    fontSize: '12px',
    '@media (max-width: 850px)': {
      fontSize: '10px',
    },
    [theme.breakpoints.down('sm')]: {
      fontSize: '10px',
    },
    color: '#6C6B6B',

    '& .MuiOutlinedInput-multiline': {
      padding: '0',
    },

    '& .MuiInputBase-root': {
      // height: "155px",
      borderRadius: '25px !important',
      '& .MuiOutlinedInput-notchedOutline': {
        paddingLeft: '25px',
      },
    },

    '& .MuiInputBase-input': {
      padding: '18px',
      paddingRight: '43px',
      [theme.breakpoints.down('sm')]: {
        fontSize: '12px',
      },
    },

    '& .MuiOutlinedInput-root': {
      borderRadius: '50px',
    },

    '& .MuiSelect-select:focus': {
      background: 'white',
    },
    '& fieldset': {
      border: '1px solid #C4C4C4 !important',
      paddingLeft: '8px',
    },

    '&:hover': {
      color: '#0DD1C5',

      '& fieldset': {
        border: '1px solid #0DD1C5 !important',
      },
    },

    '& .Mui-focused': {
      color: '#6C6B6B !importnant',
    },

    '& .MuiSelect-root': {
      height: '50px',
      paddingTop: 0,
      paddingBottom: 0,
      display: 'flex',
      alignItems: 'center',
      padding: '0 30px',
      '@media (max-width: 850px)': {
        height: '45px',
      },
    },
  },

  hiddenScrollActivator: {
    position: 'absolute',
    bottom: '150px',
  },
  dialogHeaderTitle: {
    fontSize: '14px',
    '@media (max-width: 850px)': {
      fontSize: '12px',
    },
    '@media (max-width: 750px)': {
      paddingTop: '14px',
    },
    fontWeight: 600,
    paddingBottom: '10px',
    paddingTop: '34px',
    color: '#333333',
  },
  closeIcon: {
    '&:hover': {
      background: 'none',
      border: '1px solid #FAFAFA',
      padding: '11px',
    },
  },
}));
