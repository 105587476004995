import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles(() => ({
  helperText: {
    color: "#EC4652",
    background: "#FEF6F6",
    border: "1px solid #DDDDDD",
    boxShadow: "0px 3.89312px 23.3587px rgba(189, 189, 189, 0.23)",
    borderRadius: "15px",
    padding: "12px 29px",
    fontWeight: 600,
    fontSize: "12px",
    fontFamily: `"Montserrat", "Helvetica", "Arial", sans-serif`,
    margin: "13px 0 0 0",
    display: "flex",
    alignItems: "center",
    position: "absolute",
    width: "100%",
    zIndex: 10,
    "@media (max-width: 620px)": {
      padding: "8px 29px",
      fontSize: "10px",
    },
  },
  triangle: {
    top: "-8px",
    position: "absolute",
    width: "15px",
    height: "15px",
    border: "1px #ddd solid",
    borderRadius: 4,
    backgroundColor: "#FEF6F6",
    transform: "rotate(45deg)",
    borderBottomColor: "transparent",
    borderRightColor: "transparent",
    borderBottomLeftRadius: 0,
    borderTopRightRadius: 0,
  },
}));
