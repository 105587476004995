import { createSlice, current, PayloadAction } from '@reduxjs/toolkit';
import {
  ISetInitialPrmProps,
  sqlWhereRule,
  IInitialState,
  IChangePrmProps,
  IPrm,
  ISorting,
} from './interfaces';
import { RootState } from 'src/redux/store';
import {
  getCalendarRules,
  getFilterRules,
  getSearchRule,
  getOrderRule,
  getSearchRules,
} from './helpers';

const initialState: IInitialState = {
  page: '',
  prm: null,
  filter: [],
  sorting: [],
  search: null,
  calendar: null,
  scrollLoading: false,
  changeLoading: true,
  history: {},
  availableStates: [],
  usersFilter: null,
};

const changePrm = (data: IChangePrmProps): null | any | IPrm => {
  const {
    prm,
    filter,
    sorting,
    search,
    calendar,
    page,
    detailedFilters,
    detailedFiltersApplied,
  } = data;
  let newFilter: sqlWhereRule[] = [];
  let newFormatFilter: sqlWhereRule[][] = [];

  const isNewFormat: boolean = prm
    ? !!prm.where_scheme && prm.where_scheme === 'and_for_or'
    : false;

  if (prm) {
    prm.offset = undefined;
  }

  if (sorting && prm) {
    prm.order = getOrderRule(sorting);
  }

  if (calendar) {
    newFilter = [...newFilter, ...getCalendarRules(calendar)];
  }

  if (filter) {
    newFilter = [...newFilter, ...getFilterRules(filter)];

    if (isNewFormat) {
      newFilter.forEach((item: sqlWhereRule) => {
        newFormatFilter.push([item]);
      });
    }
  }

  if (search?.isActive && prm) {
    if (isNewFormat) {
      const searchRule = getSearchRule(search);
      prm.where = [...newFormatFilter, [searchRule]];
    } else {
      const searchRules = getSearchRules(search);
      const filtersWithSearchRule = searchRules.map((rule) => [
        ...newFilter,
        rule,
      ]);

      prm.where = filtersWithSearchRule;
    }
  } else if (prm) {
    prm.where = isNewFormat ? newFormatFilter : [newFilter];
  }

  if (detailedFiltersApplied && detailedFilters) {
    const detailedFilterArray = Object.values(detailedFilters).reduce(
      (acc: sqlWhereRule[][], curr) => {
        if (curr.value) {
          if (curr.field === 'name') {
            acc.push([
              {
                ...curr,
                field: 'first_name',
                value: `${curr.value}%`,
              },
              {
                ...curr,
                field: 'last_name',
                value: `${curr.value}%`,
              },
            ]);

            return acc;
          }
          if (
            curr.field === 'email' ||
            curr.field === 'orders_users_data.phone'
          ) {
            acc.push([
              {
                ...curr,
                value: `%${curr.value}%`,
              },
            ]);

            return acc;
          }
          if (curr.field === 'cart_items.state_time') {
            acc.push([
              {
                ...curr,
                value: curr.value,
              },
            ]);
            if (
              !acc.find(
                (i) =>
                  i[0].field === 'cart_items.state_name' &&
                  i[0].value === 'cancelled'
              )
            ) {
              acc.push([
                {
                  field: 'cart_items.state_name',
                  operator: 'eq',
                  value: 'cancelled',
                  valueType: 'String',
                },
              ]);
            }

            return acc;
          }

          if (curr.field === 'orders.is_office') {
            acc.push(
              [
                {
                  ...curr,
                  value: curr.value,
                },
              ],
              [
                {
                  field: 'payments.number',
                  operator: 'eq',
                  value: 'cash',
                  valueType: 'String',
                },
              ]
            );

            return acc;
          }
          acc.push([
            {
              ...curr,
              value: curr.value,
            },
          ]);
        }
        return acc;
      },
      []
    );
    newFormatFilter.push(...detailedFilterArray);
  }

  return prm;
};

export const tableSlice = createSlice({
  name: 'tables',
  initialState,
  reducers: {
    setInitialState: (state, action: PayloadAction<ISetInitialPrmProps>) => {
      const { payload } = action;
      const { page, filter, sorting, prm, search, calendar, detailedFilters } =
        payload;
      state.page = page;
      state.filter = filter;
      state.sorting = sorting;
      state.prm = prm ? prm : null;
      state.search = search ? search : null;
      state.calendar = calendar ? calendar : state.calendar;
      state.detailedFilters = detailedFilters ? detailedFilters : undefined;
      state.history[page] = {
        filter,
        sorting,
        prm: prm ? prm : null,
        search: search ? search : null,
        calendar: calendar ? calendar : state.calendar,
      };
    },
    setLoading: (
      state,
      action: PayloadAction<{ type: 'scroll' | 'change'; value: boolean }>
    ) => {
      const { payload } = action;
      const { type, value } = payload;
      if (type === 'scroll') {
        state.scrollLoading = value;
      }
      if (type === 'change') {
        state.changeLoading = value;
      }
    },
    setInitialPrm: (state, action: PayloadAction<ISetInitialPrmProps>) => {
      const { payload } = action;
      const { page, filter, sorting, search, prm, calendar, detailedFilters } =
        payload;

      state.changeLoading = true;

      const historyPage = state.history[page];

      if (historyPage) {
        state.page = page;
        state.filter = historyPage.filter;
        state.detailedFilters = historyPage.detailedFilters;
        state.sorting = historyPage.sorting;
        state.search = historyPage.search;
        state.calendar = historyPage.calendar;
        state.prm = { ...historyPage.prm!, offset: '0' };
        return;
      }

      state.page = page;
      state.filter = filter;
      state.detailedFilters = detailedFilters;
      state.sorting = sorting;
      state.search = search;
      state.calendar = calendar;
      state.prm = prm;
      state.history[page] = {
        filter,
        sorting,
        search,
        prm,
        calendar,
        detailedFilters,
      };
    },
    changeInitialPrmFilter: (state, action: PayloadAction<IPrm>) => {
      const { payload } = action;
      const { where } = payload;

      if (state.prm) {
        state.prm = {
          ...state.prm!,
          where,
          offset: '0',
        };

        state.history[state.page] = {
          ...state.history[state.page],
          prm: state.prm,
        };
      }
    },
    setPrmFromDashboard: (
      state,
      action: PayloadAction<ISetInitialPrmProps>
    ) => {
      const { payload } = action;
      const { page, filter, sorting, search, prm, calendar, detailedFilters } =
        payload;

      state.changeLoading = true;

      if (state.prm) {
        state.prm.offset = '0';
      }

      state.page = page;
      state.filter = filter;
      state.detailedFilters = detailedFilters;
      state.detailedFiltersApplied = true;
      state.sorting = sorting;
      state.search = search;
      state.calendar = calendar;
      state.prm = prm;

      state.history[page] = {
        filter,
        sorting,
        search,
        prm,
        calendar,
        detailedFilters,
      };
    },
    changeInitialFilter: (state, action: PayloadAction<any>) => {
      const { payload } = action;
      const { page, filter } = payload;

      if (page === state.page) {
        state.filter = filter;
      }

      changePrm({
        prm: state.prm,
        filter: state.filter,
        search: state.search,
        calendar: state.calendar,
        page: state.page,
        detailedFilters: state.detailedFilters,
        detailedFiltersApplied: state.detailedFiltersApplied,
      });

      state.history[state.page] = {
        ...state.history[state.page],
        filter: state.filter,
        calendar: state.calendar,
        prm: state.prm,
      };
    },
    changeInitialSorting: (state, action: PayloadAction<ISorting[]>) => {
      const { payload } = action;

      state.sorting = payload;
    },
    setOffset: (state, action: PayloadAction<string | undefined>) => {
      const { payload } = action;
      state.offset = payload;
    },
    setOffsetLoadData: (state, action: PayloadAction<{ page: string }>) => {
      const { payload } = action;
      const { page } = payload;

      if (state.prm) {
        state.prm.offset = state.offset;
      }
      state.history[state.page] = {
        ...state.history[state.page],
        prm: state.prm,
      };
    },
    setTime: (
      state,
      action: PayloadAction<{ start?: string | null; end?: string | null }>
    ) => {
      const { payload } = action;
      if (state.calendar) {
        state.calendar.isActive = !payload.start || !payload.end ? false : true;
        state.calendar.time.startTime = payload.start || null;
        state.calendar.time.endTime = payload.end || null;
      }
      if (state.calendar) {
        if (
          state.calendar.isActive &&
          state.calendar.time.startTime &&
          state.calendar.time.endTime &&
          state.calendar.field
        ) {
          state.filter.forEach((i) => {
            if (i.field === state.calendar?.field) {
              i.values.forEach((v) => {
                v.isSelected = false;
              });
            }
          });
        }
        if (
          (state.calendar.time.startTime && state.calendar.time.endTime) ||
          (!state.calendar.time.startTime && !state.calendar.time.endTime)
        ) {
          state.prm = changePrm({
            prm: state.prm,
            filter: state.filter,
            search: state.search,
            calendar: state.calendar,
            page: state.page,
            detailedFilters: state.detailedFilters,
            detailedFiltersApplied: state.detailedFiltersApplied,
          });
        }
      }
      state.history[state.page] = {
        ...state.history[state.page],
        calendar: state.calendar,
        filter: state.filter,
        prm: state.prm,
      };
    },
    setSearch: (state, action: PayloadAction<string | null>) => {
      const { payload } = action;
      if (state.search) {
        state.search.isActive = payload ? true : false;
        state.search.value = payload;
      }
      state.prm = changePrm({
        prm: state.prm,
        filter: state.filter,
        search: state.search,
        calendar: state.calendar,
        page: state.page,
      });
      state.history[state.page] = {
        ...state.history[state.page],
        search: state.search,
        prm: state.prm,
      };
    },
    setSorting: (state, action: PayloadAction<number>) => {
      const { payload } = action;
      const newSorting = state.sorting;
      newSorting.forEach((item, idx) => {
        if (idx === payload) {
          item.active = true;
          item.order = item.order === 'asc' ? 'desc' : 'asc';
        } else {
          item.order = undefined;
          item.active = false;
        }
      });

      state.prm = changePrm({
        prm: state.prm,
        sorting: newSorting,
        filter: state.filter,
        page: state.page,
        search: state.search,
        detailedFilters: state.detailedFilters,
        detailedFiltersApplied: state.detailedFiltersApplied,
      });
      state.history[state.page] = {
        ...state.history[state.page],
        sorting: state.sorting,
        filter: state.filter,
        prm: state.prm,
      };
    },
    setSelector: (state, action) => {
      const { payload } = action;
      const { field, value } = payload;
      const fieldIdx = state.filter.findIndex((f) => f.field === field);

      let newFilter = state.filter;
      const newSqlFilterPrm = state.prm;

      newFilter[fieldIdx].values[0].isSelected = !!value;
      newFilter[fieldIdx].values[0].value = value;

      state.prm = changePrm({
        prm: newSqlFilterPrm,
        filter: newFilter,
        search: state.search,
        calendar: state.calendar,
        page: state.page,
      });
      state.history[state.page] = {
        ...state.history[state.page],
        filter: state.filter,
        calendar: state.calendar,
        prm: state.prm,
      };
    },
    setFilter: (
      state,
      action: PayloadAction<{ field: string; valueIdx: number }>
    ) => {
      const { payload } = action;
      const { field, valueIdx } = payload;

      const fieldIdx = state.filter.findIndex((f) => f.field === field);
      const newFilter = state.filter;
      const newSqlFilterPrm = state.prm;
      const isMultiselect = newFilter[fieldIdx].multiselect;
      const isSelected = newFilter[fieldIdx].values[valueIdx].isSelected;

      if (isSelected || isMultiselect) {
        newFilter[fieldIdx].values[valueIdx].isSelected = !isSelected;
      } else {
        newFilter[fieldIdx].values.forEach((value, idx) => {
          if (
            newFilter[fieldIdx].field === state.calendar?.field &&
            idx === valueIdx
          ) {
            if (state.calendar?.isActive) {
              state.calendar.isActive = false;
              state.calendar.time.startTime = null;
              state.calendar.time.endTime = null;
            }
          }
          value.isSelected = idx === valueIdx ? true : false;
        });
      }

      state.prm = changePrm({
        prm: newSqlFilterPrm,
        filter: newFilter,
        search: state.search,
        calendar: state.calendar,
        page: state.page,
        detailedFilters: state.detailedFilters,
        detailedFiltersApplied: state.detailedFiltersApplied,
      });
      state.history[state.page] = {
        ...state.history[state.page],
        filter: state.filter,
        calendar: state.calendar,
        prm: state.prm,
      };
    },
    setAllDetailedFilters: (state, action) => {
      console.log(action)
      state.detailedFilters = action.payload;

      state.prm = changePrm({
        prm: state.prm,
        filter: state.filter,
        search: state.search,
        calendar: state.calendar,
        detailedFilters: state.detailedFilters,
        detailedFiltersApplied: state.detailedFiltersApplied,
      });
      state.history[state.page] = {
        ...state.history[state.page],
        filter: state.filter,
        calendar: state.calendar,
        search: state.search,
        detailedFilters: state.detailedFilters,
        prm: state.prm,
      };
    },
    setDetailedFilters: (
      state,
      action: PayloadAction<{
        key: string;
        value: string | null;
        clear?: boolean;
      }>
    ) => {
      if (state.detailedFilters) {
        const { key, value, clear } = action.payload;

        if (state.detailedFilters[key]) {
          state.detailedFilters[key] = {
            ...state.detailedFilters[key],
            value,
          };

          if (clear) {
            state.detailedFiltersApplied = false;
            for (let key in state.detailedFilters) {
              state.detailedFilters[key].value = null;
            }
            if (state.prm) {
              state.prm = {
                ...state.prm,
                where_scheme: 'and_for_or',
              };
            }

            state.prm = changePrm({
              prm: state.prm,
              filter: state.filter,
              search: state.search,
              calendar: state.calendar,
              detailedFilters: state.detailedFilters,
              detailedFiltersApplied: state.detailedFiltersApplied,
            });
            state.history[state.page] = {
              ...state.history[state.page],
              filter: state.filter,
              calendar: state.calendar,
              search: state.search,
              detailedFilters: state.detailedFilters,
              prm: state.prm,
            };
            return;
          }

          state.history[state.page] = {
            ...state.history[state.page],
            detailedFilters: state.detailedFilters,
          };
        }
      }
    },
    applyDetailedFilters: (state) => {
      const isDetailedFilters =
        state.detailedFilters &&
        Object.values(state.detailedFilters).some((item) => !!item.value);

      if (state.detailedFilters && state.prm) {
        if (isDetailedFilters) {
          state.detailedFiltersApplied = true;
        } else {
          state.detailedFiltersApplied = false;
        }

        // if(Object.values(state.detailedFilters).every((item) => item.value === null)) {
        //   state.detailedFiltersApplied = false;
        // } else {

        //   state.detailedFiltersApplied = true;
        // }

        if (state.search) {
          state.search = {
            ...state.search,
            isActive: false,
            value: null,
          };
        }
        state.prm = {
          ...state.prm,
          where_scheme: 'and_for_or',
        };
        state.prm = changePrm({
          prm: state.prm,
          filter: state.filter,
          search: state.search,
          calendar: state.calendar,
          detailedFilters: state.detailedFilters,
          detailedFiltersApplied: state.detailedFiltersApplied,
        });
        state.history[state.page] = {
          ...state.history[state.page],
          filter: state.filter,
          calendar: state.calendar,
          search: state.search,
          detailedFilters: state.detailedFilters,
          prm: state.prm,
        };
      }
      //change prm
    },
    setAvailableStates: (state, action: PayloadAction<string[]>) => {
      const { payload } = action;
      state.availableStates = payload;
    },
    clearFilterStates: (state) => {
      const fieldIdx = state.filter.findIndex((f) => f.field === 'state_id');
      const newFilter = state.filter;
      const newSqlFilterPrm = state.prm;

      newFilter[fieldIdx]?.values?.forEach((f) => (f.isSelected = false));
      state.prm = changePrm({
        prm: newSqlFilterPrm,
        filter: newFilter,
        search: state.search,
        calendar: state.calendar,
        page: state.page,
      });
      state.history[state.page] = {
        ...state.history[state.page],
        filter: state.filter,
        calendar: state.calendar,
        prm: state.prm,
      };
    },
    clearFilterDestination: (state) => {
      const fieldIdx = state.filter.findIndex((f) => f.field === 'city_id');
      const newFilter = state.filter;
      const newSqlFilterPrm = state.prm;

      newFilter[fieldIdx].values[0].value = '';
      newFilter[fieldIdx].values[0].isSelected = false;

      state.prm = changePrm({
        prm: newSqlFilterPrm,
        filter: newFilter,
        search: state.search,
        calendar: state.calendar,
        page: state.page,
      });
    },
    clearFilterCategory: (state) => {
      const fieldIdx = state.filter.findIndex((f) => f.field === 'category_id');
      const newFilter = state.filter;
      const newSqlFilterPrm = state.prm;

      newFilter[fieldIdx].values[0].value = '';
      newFilter[fieldIdx].values[0].isSelected = false;

      state.prm = changePrm({
        prm: newSqlFilterPrm,
        filter: newFilter,
        search: state.search,
        calendar: state.calendar,
        page: state.page,
      });
    },
    setFiltersUser: (state, action: PayloadAction<string | null>) => {
      const { payload } = action;
      state.usersFilter = payload;
    },
  },
});

export const {
  setInitialState,
  setFilter,
  setSorting,
  setLoading,
  setSearch,
  setOffset,
  setOffsetLoadData,
  setInitialPrm,
  setTime,
  setSelector,
  setAvailableStates,
  clearFilterStates,
  clearFilterDestination,
  clearFilterCategory,
  changeInitialFilter,
  changeInitialSorting,
  setFiltersUser,
  setDetailedFilters,
  applyDetailedFilters,
  setPrmFromDashboard,
  setAllDetailedFilters,
  changeInitialPrmFilter,
} = tableSlice.actions;

export const tableState = (state: RootState) => state.tables;
export const filter = (state: RootState) => state.tables.filter;
export const sorting = (state: RootState) => state.tables.sorting;
export const prm = (state: RootState) => state.tables.prm;
export const scrollLoading = (state: RootState) => state.tables.scrollLoading;
export const changeLoading = (state: RootState) => state.tables.changeLoading;
export const search = (state: RootState) => state.tables.search;
export const calendar = (state: RootState) => state.tables.calendar;
export const offset = (state: RootState) => state.tables.offset;
export const pager = (state: RootState) => state.tables.pager;
export const page = (state: RootState) => state.tables.page;
export const userFilter = (state: RootState) => state.tables.usersFilter;
export const history = (state: RootState) => state.tables.history;
export const availableStates = (state: RootState) =>
  state.tables.availableStates;
export const activeFilterStates = (state: RootState) => {
  const currentFilter = filter(state);
  const fieldIdx = currentFilter.findIndex((f) => f.field === 'state_id');
  const activeStates =
    currentFilter[fieldIdx]?.values.filter((s: any) => s.isSelected) || [];
  return activeStates.map((s) => s.label);
};
export const activeFilterDestinations = (state: RootState) => {
  const currentFilter = filter(state);
  const fieldIdx = currentFilter.findIndex((f) => f.field === 'city_id');
  const activeStates =
    currentFilter[fieldIdx]?.values.filter((s: any) => s.isSelected) || [];
  return activeStates.map((s) => s.value);
};

export default tableSlice.reducer;
