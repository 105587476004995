import React from 'react';
import { useHistory } from 'react-router-dom';
import { ArrowBack, HomeOutlined, Replay } from '@material-ui/icons/';
import SystemButtom from '../UI/SystemButton';
import LogoutIcon from 'src/assets/icons/logOut.svg';
import { useStyles } from './styles';
import { useDispatch } from 'react-redux';
import { signOut } from 'src/redux/Authentication/sliceAuthentication';

type Props = {
  onlyLogout?: boolean;
};

const SystemNavigation = ({ onlyLogout }: Props) => {
  const classes = useStyles();
  const history = useHistory();
  const dispatch = useDispatch();

  const back_click = () => {
    history.goBack();
  };

  const home_click = () => {
    history.push('/');
  };

  const refresh_click = () => {
    window.location.reload();
  };

  const logout_click = () => dispatch(signOut());

  if (onlyLogout) {
    return (
      <div className={classes.buttons_container}>
        <SystemButtom onHandlerClick={home_click}>
          <HomeOutlined />
        </SystemButtom>
        <SystemButtom onHandlerClick={logout_click}>
          <LogoutIcon />
        </SystemButtom>
      </div>
    );
  }

  return (
    <div className={classes.buttons_container}>
      <SystemButtom onHandlerClick={back_click}>
        <ArrowBack />
      </SystemButtom>

      <SystemButtom onHandlerClick={home_click}>
        <HomeOutlined />
      </SystemButtom>

      <SystemButtom onHandlerClick={refresh_click}>
        <Replay />
      </SystemButtom>
    </div>
  );
};

export default SystemNavigation;
