import React, { Dispatch, SetStateAction } from "react";
import { Drawer } from "@material-ui/core";
import PerfectScrollbar from "react-perfect-scrollbar";
import Animation from "src/web/features/animation";
import clsx from "clsx";
import { useStyles } from "./styles";

export const PageSidebar = ({
  title,
  icon,
  header,
  content,
  isOpenSidebar,
  withoutSidebar,
  setIsOpenSidebar,
}: IPageWithTable) => {
  const classes = useStyles();
  return (
    <>
      {isOpenSidebar && (
        <div
          className={classes.backdrop}
          onClick={() => setIsOpenSidebar(false)}
        ></div>
      )}
      <Drawer
        variant="permanent"
        open={true}
        className={clsx(classes.sidebar, {
          [classes.sidebarOpen]: isOpenSidebar,
        })}
      >
        <div className={classes.sidebarHeader}>
          {icon && title && (
            <div className={classes.sidebarIcon}>
              <Animation animation="transition.expandIn" delay={300}>
                {icon}
              </Animation>
              <Animation animation="transition.slideLeftIn" delay={300}>
                <span>{title}</span>
              </Animation>
            </div>
          )}
          {header && (
            <div>
              <Animation animation="transition.slideLeftIn" delay={300}>
                {header}
              </Animation>
            </div>
          )}
        </div>
        {!withoutSidebar && (
          <div className={classes.sidebarContent}>
            <PerfectScrollbar>
              <div className={classes.innerSidebarContent}>{content}</div>
            </PerfectScrollbar>
          </div>
        )}
      </Drawer>
    </>
  );
};

interface IPageWithTable {
  title: string;
  icon: JSX.Element;
  header?: JSX.Element;
  content: JSX.Element;
  withoutSidebar?: boolean;
  isOpenSidebar: boolean;
  setIsOpenSidebar: Dispatch<SetStateAction<boolean>>;
}
