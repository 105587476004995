import React, { useState } from 'react';
import { Button, Popover } from '@material-ui/core';

import DeleteIcon from 'src/assets/icons/web/notifications/deleteAction.svg';
import UnreadActionIcon from 'src/assets/icons/web/notifications/unreadAction.svg';

import { useStyles } from './styles';
import clsx from 'clsx';
import { useNotificationsContext } from 'src/contexts/NotificationsContext';

type Props = {
  activeItem: INotification | null;
  anchor: HTMLButtonElement | null;
  setActive: (
    anchor: React.MouseEvent<HTMLButtonElement, MouseEvent> | null,
    item: INotification | null
  ) => void;
};

const NotificationActionsPopup = ({ activeItem, anchor, setActive }: Props) => {
  const classes = useStyles();
  const { unreadLastNotificationHandle, deleteLastNotificationsHandle } =
    useNotificationsContext();

  const open = Boolean(anchor);

  const handleClose = () => {
    setActive(null, null);
  };

  if (!activeItem) {
    return <></>;
  }

  return (
    <Popover
      open={open}
      anchorEl={anchor}
      onClose={handleClose}
      className={classes.actionsPopup}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'center',
      }}
      transformOrigin={{
        vertical: 'top',
        horizontal: 'center',
      }}>
      {activeItem.isReaded && (
        <Button
          onClick={() => {
            unreadLastNotificationHandle(activeItem.id);
            handleClose();
          }}
          className={clsx(
            classes.actionsPopupButton,
            classes.actionsPopupUnreadButton
          )}>
          <UnreadActionIcon style={{ marginRight: '5px' }} /> Mark as unread
        </Button>
      )}
      <Button
        onClick={() => {
          deleteLastNotificationsHandle(activeItem.id);
          handleClose();
        }}
        className={clsx(
          classes.actionsPopupButton,
          classes.actionsPopupDeleteButton
        )}>
        <DeleteIcon style={{ marginRight: '5px' }} />
        Delete
      </Button>
    </Popover>
  );
};

export default NotificationActionsPopup;
