import React, { useEffect, useMemo, useRef, useState } from 'react';
import {
  Box,
  Button,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from '@material-ui/core';
import clsx from 'clsx';
import { Area, AreaChart, ResponsiveContainer, Tooltip, XAxis } from 'recharts';
import moment from 'moment';
import PerfectScrollbar from 'react-perfect-scrollbar';
import DotsMenuIcon from 'src/assets/icons/light/dotsBurgerVMenu.svg';
import CheckboxCheckedIcon from 'src/assets/icons/web/statisticCheckboxChecked.svg';
import CheckboxIcon from 'src/assets/icons/web/statisticCheckbox.svg';
import { dollarAmountsFloat } from 'src/helpers/DollarAmounts';

import { useStyles } from './styles';
import useOnClickOutside from 'src/web/hooks/useOnClickOutside';
import { getTripColor } from 'src/helpers/getTripColor';

type Props = {
  data: IDashboardStatisticsItem[];
};

type StatisticItem = {
  product_id: number;
  name: string;
  net: string;
  count: number;
  color: string;
};

export const DashboardStatistics = ({ data }: Props) => {
  const classes = useStyles();
  const dropdownRef = useRef(null);
  const dropdownButtonRef = useRef(null);

  const [isOpenDropdown, setIsOpenDropdown] = useState(false);
  const [choosenTrips, setChoosenTrips] = useState<number[]>([]);

  useOnClickOutside(
    dropdownRef,
    () => setIsOpenDropdown(false),
    dropdownButtonRef
  );

  const trips = data.reduce<StatisticItem[]>((acc, curr) => {
    if (acc.some((i) => i.product_id === curr.product_id)) {
      return acc;
    }

    return [
      ...acc,
      {
        product_id: curr.product_id,
        name: curr.name,
        net: curr.net,
        count: curr.count,
        color: getTripColor(curr.product_id),
      },
    ];
  }, []);

  const initialTripData = choosenTrips.reduce((acc, curr) => {
    const trip: StatisticItem | undefined = trips.find(
      (i) => i.product_id === curr
    );

    return {
      ...acc,
      [curr]: {
        product_id: trip?.product_id,
        name: trip?.name,
        count: 0,
        net: 0,
      },
    };
  }, {});

  const tripsData = data.reduce<{
    [key: string]: { [i: string]: IDashboardStatisticsItem };
  }>((acc, curr) => {
    const date = moment(curr.date, 'DD.MM.YYYY');

    return {
      ...acc,
      [date.format('MM/DD')]: {
        ...acc[date.format('MM/DD')],
        [curr.product_id]: {
          ...curr,
        },
      },
    };
  }, {});

  const dataForChart = [...new Array(30)]
    .map((_, index) => {
      const date: moment.Moment = moment().subtract(index, 'days');

      return {
        date: date.format('MM/DD'),
        trips: {
          ...initialTripData,
          ...tripsData[date.format('MM/DD')],
        },
      };
    })
    .reverse();

  const onHandleChooseTrip = (id: number) => {
    setChoosenTrips((prev) => {
      if (prev.includes(id)) {
        return prev.filter((i) => i !== id);
      }

      return [...prev, id];
    });
  };

  useEffect(() => {
    setChoosenTrips(trips.map((i) => i.product_id));
  }, []);

  const rendererAreas = useMemo(() => {
    return trips.map(({ product_id: tripId, color }) => (
      <React.Fragment key={tripId}>
        <Area
          opacity={choosenTrips.includes(tripId) ? 1 : 0}
          key={tripId}
          type="monotone"
          dataKey={`trips.${tripId}.net`}
          label="orders"
          stroke={color}
          strokeWidth={1.4}
          fill={`url(#colorGradient-${tripId})`}
          activeDot={(props) => {
            return (
              <circle
                cx={props.cx}
                cy={props.cy}
                r={3}
                style={{
                  display: choosenTrips.includes(tripId) ? 'block' : 'none',
                  boxShadow: '0px 1.7px 4.8px #5B40C373',
                }}
                stroke={color}
                strokeWidth={2}
                fill="#ffffff"
              />
            );
          }}
        />
        <defs>
          <linearGradient
            id={`colorGradient-${tripId}`}
            x1="0"
            y1="0"
            x2="0"
            y2="1">
            <stop offset="0%" stopColor={color} stopOpacity={0.46} />
            <stop offset="79%" stopColor={color} stopOpacity={0} />
          </linearGradient>
        </defs>
      </React.Fragment>
    ));
  }, [choosenTrips]);

  const CustomTooltip = ({
    active,
    payload,
    label,
  }: {
    active?: boolean;
    label?: string;
    payload?: any[];
  }) => {
    if (active && payload && payload.length) {
      const payloadTrips: Partial<IDashboardStatisticsItem>[] =
        Object.values(payload[0]?.payload.trips || {}) || [];

      const trips = payloadTrips
        .filter(
          (trip) => !!trip.product_id && choosenTrips.includes(trip.product_id)
        )
        .sort((a, b) => parseFloat(b.net || '0') - parseFloat(a.net || '0'));

      return (
        <Box className={classes.dashboardStatisticsTooltip}>
          <Box className={classes.dashboardStatisticsTooltipLabel}>
            {moment(label, 'MM/DD').format('DD MMMM')}
          </Box>
          <TableContainer
            className={classes.dashboardStatisticsTooltipTableContainer}>
            <Table stickyHeader>
              <TableHead
                className={classes.dashboardStatisticsTooltipTableHead}>
                <TableRow>
                  <TableCell style={{ width: 210 }}>Trip</TableCell>
                  <TableCell>Amount</TableCell>
                  <TableCell>Orders</TableCell>
                </TableRow>
              </TableHead>
              <TableBody
                className={classes.dashboardStatisticsTooltipTableBody}>
                {trips.map((trip) => (
                  <TableRow key={trip.product_id}>
                    <TableCell
                      style={{ display: 'flex', alignItems: 'center' }}>
                      {trip.product_id && (
                        <Box
                          className={
                            classes.dashboardStatisticsTooltipTableTripColor
                          }
                          style={{
                            backgroundColor: getTripColor(trip.product_id || 0),
                          }}></Box>
                      )}
                      {trip.name}
                    </TableCell>
                    <TableCell>{dollarAmountsFloat(trip.net || 0)}</TableCell>
                    <TableCell>{trip.count || 0}</TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </Box>
      );
    }

    return null;
  };

  return (
    <Box className={classes.dashboardStatistics}>
      <div
        ref={dropdownRef}
        className={classes.dashboardStatisticsDropdown}
        style={{ display: isOpenDropdown ? 'flex' : 'none' }}>
        <PerfectScrollbar>
          <Box className={classes.dashboardStatisticsDropdownListWrapper}>
            <Box className={classes.dashboardStatisticsDropdownList}>
              {trips.map((trip, index) => (
                <Box
                  className={clsx(classes.dashboardStatisticsDropdownListItem, {
                    active: choosenTrips.includes(trip.product_id),
                  })}
                  key={index}
                  onClick={() => onHandleChooseTrip(trip.product_id)}>
                  <Box className={classes.dashboardStatisticsDropdownTripIcon}>
                    {choosenTrips.includes(trip.product_id) ? (
                      <CheckboxCheckedIcon />
                    ) : (
                      <CheckboxIcon />
                    )}
                  </Box>
                  <Box
                    style={{ backgroundColor: trip.color }}
                    className={
                      classes.dashboardStatisticsDropdownTripColor
                    }></Box>
                  <Typography
                    className={classes.dashboardStatisticsDropdownTripName}>
                    {trip.name}
                  </Typography>
                </Box>
              ))}
            </Box>
            <Box>
              <Button
                onClick={() => setChoosenTrips(trips.map((i) => i.product_id))}
                className={classes.dashboardStatisticsDropdownButton}>
                All trips
              </Button>
            </Box>
          </Box>
        </PerfectScrollbar>
      </div>
      <Box className={classes.dashboardStatisticsHeader}>
        <Typography className={classes.dashboardStatisticsHeaderTitle}>
          Statistics
        </Typography>
        <div
          ref={dropdownButtonRef}
          className={classes.dashboardStatisticsHeaderButton}
          onClick={() => setIsOpenDropdown(!isOpenDropdown)}>
          <DotsMenuIcon />
        </div>
      </Box>
      <Box className={classes.dashboardStatisticsChart}>
        <ResponsiveContainer width="100%" height={225}>
          <AreaChart
            data={dataForChart}
            margin={{
              top: 70,
              right: 0,
              left: 0,
              bottom: 5,
            }}>
            <XAxis
              dataKey="date"
              strokeWidth={0}
              fontSize={11}
              dy={13}
              height={50}
              minTickGap={15}
            />
            <Tooltip
              wrapperStyle={{
                zIndex: 100,
              }}
              cursorStyle={{
                strokeDasharray: '5,5',
              }}
              content={(props) => <CustomTooltip {...props} />}
            />
            {rendererAreas}
          </AreaChart>
        </ResponsiveContainer>
      </Box>
    </Box>
  );
};
