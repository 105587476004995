import { Box, Typography, Divider } from "@material-ui/core";
import React from "react";
import { useSelector } from "react-redux";
import { arrivalData } from "src/redux/Arrivals/sliceArrival";

import { useStyles } from "../styles";

export const AdditionalOptions = () => {
  const classes = useStyles();
  const { arrivalData: arrival } = useSelector(arrivalData);

  const options = arrival.data?.additional_options;
  const newOptions = options
    ? options.filter((item: any) => item.name.toLowerCase() !== "tripflex")
    : [];

  return arrival.data?.additional_options && newOptions?.length ? (
    <Box>
      <Box className={classes.addInfoBox}>
        {newOptions.map((option: any, index: number) => {
          return (
            <Box key={index}>
              {index !== 0 && <Divider className={classes.cardDivider} />}
              <Box display={"flex"} justifyContent="space-between">
                <Box className={classes.option}>
                  <Typography className={classes.itemTitle}>
                    {option.name}
                  </Typography>
                  <Typography className={classes.itemContent}>
                    {option.value.name}
                  </Typography>
                </Box>
              </Box>
            </Box>
          );
        })}
      </Box>
    </Box>
  ) : (
    <></>
  );
};
