import { makeStyles } from '@material-ui/core/styles';
import { Theme } from 'src/web/themes/theme';

export const useStyles = makeStyles((theme: Theme) => ({
  root: {
    position: 'relative',
    backgroundColor: theme.palette.background.default,
  },
  topBg: {
    position: 'absolute',
    left: 0,
    right: 0,
    top: 0,
    height: `153px`,
    backgroundColor: '#2E3035',
    pointerEvents: 'none',
  },
  pageWrapper: {
    width: '100%',
    display: 'flex',
    height: 'calc(100vh - 66px)',

    '@media (max-width: 1024px)': {
      height: `calc(100vh - ${theme.trip_shock_theme.environment.toolbarHeight}px)`,
    },
  },
  contentWrapper: {
    maxWidth: '100%',
    position: 'relative',
    display: 'flex',
    flex: '1',
    flexDirection: 'column',

    '@media (max-width: 1024px)': {
      padding: 0,
    },
  },
  header: {
    position: 'relative',
    height: theme.trip_shock_theme.environment.headerHeight,
    zIndex: 101,
    display: 'flex',
    alignItems: 'center',
    color: theme.palette.primary.contrastText,
  },
  headerSidebarToggleButton: {
    color: theme.palette.primary.contrastText,
  },
  content: {
    flex: '1 0 auto',
    //backgroundColor: '#fff',
    width: '100%',
    minHeight: 'calc(100vh - 165px)',
    boxShadow: '0 1px 3px 0 rgb(0 0 0 / 10%), 0 1px 2px 0 rgb(0 0 0 / 6%)',
    borderRadius: '8px 8px 0 0',
    overflow: 'hidden',
    padding: '0 20px 0 14px',

    '@media (max-width: 1024px)': {
      padding: 0,
    },
  },
  addBtn: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: '#50B061',
    color: '#fff',
    width: '56px',
    height: '56px',
    zIndex: 101,
    bottom: '24px',
    right: '24px',
    borderRadius: '50%',
    cursor: 'pointer',
    boxShadow: '3px 3px 15px 0 rgba(0, 0, 0, 0.2)',
  },
  addBtnWrapper: {
    width: '70px',
    height: '70px',
    position: 'absolute',
    bottom: '24px',
    right: '24px',
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'flex-end',
  },
}));
