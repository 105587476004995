import { Box, Typography } from "@material-ui/core";
import React from "react";
import { useStyles } from "./styles";
import {
  IInterviewBlock,
  IOnboardingScreen,
  IInformationalBlock,
  IAcceptanceBlock,
  IOnboardingForm,
  IDependenceField,
  IDate,
} from "./interface";
import { AcceptForm } from "./elements/AcceptForm";
import { Inputs } from "./Inputs";

type Props = {
  formInput: IOnboardingScreen;
  form: IOnboardingForm;
  errorMessage: Partial<Record<keyof any, string>> | undefined;
  data: any;
  receivedData: any;
  handleChange: any;
  files?: any;
  setFiles?: any;
  locations?: any;
  openDialog?: any;
  other?: boolean;
  setOther?: React.Dispatch<React.SetStateAction<boolean>>;
  handleAccept?: any;
  handleChangeGoogle?: (updateData: any) => void;
};

export const FormElement = ({
  form,
  formInput,
  errorMessage,
  locations,
  openDialog,
  data,
  receivedData,
  handleChange,
  files,
  setFiles,
  other,
  setOther,
  handleAccept,
  handleChangeGoogle,
}: Props) => {
  const classes = useStyles();

  const interviewContent = formInput.content as IInterviewBlock;
  const infoContent = formInput.content as IInformationalBlock;
  const acceptContent = formInput.content as IAcceptanceBlock;

  if (acceptContent.field) {
    return (
      <AcceptForm
        form={form}
        handleAccept={handleAccept || handleChange}
        receivedData={receivedData}
        data={data}
      />
    );
  }

  if (infoContent.markdown) {
    if (!formInput.description) {
      return (
        <Box pb={2} pt={"74px"}>
          <Typography
            align="center"
            variant="subtitle1"
            style={{ whiteSpace: "pre-wrap" }}
            className={classes.content}
          >
            {infoContent.markdown}
          </Typography>
        </Box>
      );
    } else {
      return (
        <>
          <Box mb={2}>
            <Typography align="center" variant="h5" className={classes.title}>
              {formInput.title}
            </Typography>
          </Box>
          <Box className={classes.box} mb={2}>
            <Typography
              align="center"
              variant="subtitle1"
              style={{ whiteSpace: "pre-wrap" }}
              className={classes.content}
            >
              {formInput.description}
            </Typography>
            <Typography
              align="center"
              variant="subtitle1"
              style={{ whiteSpace: "pre-wrap" }}
              className={classes.description}
            >
              {infoContent.markdown}
            </Typography>
          </Box>
        </>
      );
    }
  }

  return (
    <>
      {interviewContent.fields?.map((item, index) => {
        return (
          <Inputs
            item={item}
            errorMessage={errorMessage}
            locations={locations}
            openDialog={openDialog}
            data={data}
            receivedData={receivedData}
            handleChange={handleChange}
            files={files}
            setFiles={setFiles}
            other={other}
            setOther={setOther}
            handleAccept={handleAccept}
            handleChangeGoogle={handleChangeGoogle}
            formInput={formInput}
            index={index}
          />
        );
      })}
    </>
  );
};
