import { Box } from '@material-ui/core';
import React from 'react';
import { useStyles } from './styles';

export const AffiliateBlock = () => {
  const classes = useStyles();
  return (
    <Box className={classes.wrapper}>
      <Box className={classes.content}>
        Refer & Earn With Our Affiliate Program
      </Box>
      <a
        href="https://affiliates.tripshock.com/"
        rel="nofollow"
        target="__blank"
        className={classes.button}>
        Join Today
      </a>
    </Box>
  );
};
