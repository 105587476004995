import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles(() => ({
  buttons_container: {
    display: 'flex',
    width: '220px',
    margin: '50px auto 0',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
}));
