import { Button } from "@material-ui/core";
import React, { useEffect, useMemo } from "react";
import { useDropzone } from "react-dropzone";
import { useStyles } from "../styles";
import DropzoneIcon from "src/assets/icons/web/dropzoneIcon.svg";
import RemoveFileIcon from "src/assets/icons/web/removeFile.svg";

const baseStyle = {
  border: "1px dashed #C4C4C4",
  borderRadius: "15px",
  padding: "25px 83px",
  transition: "border .24s ease-in-out",
};

const acceptStyle = {
  background: "#F9FCFD",
  borderRadius: "15px",
  alignItems: "center",
  justifyContent: "center",
  border: "none",
  display: "flex",
  width: "340px",
  height: "190px",
  "& p": {
    color: "#C4C4C4",
    fontSize: "16px",
    fontWeight: 500,
  },
};

export const StyledDropzone = ({
  openDialog,
  removeItem,
  files,
  setFiles,
  isDisabled,
}: any) => {
  const classes = useStyles();
  const {
    getRootProps,
    getInputProps,
    isDragAccept,
    isDragReject,
    isDragActive,
    acceptedFiles,
  } = useDropzone({
    accept: {
      "application/vnd.openxmlformats-officedocument.wordprocessingml.document":
        [],
      "application/pdf": [],
      "application/msword": [],
    },
    noClick: isDisabled,
    noKeyboard: isDisabled,
    disabled: isDisabled,
  });

  const style = useMemo(
    () => ({
      ...baseStyle,
      ...(isDragAccept ? acceptStyle : {}),
      ...(isDragReject ? acceptStyle : {}),
    }),
    [isDragAccept, isDragReject]
  );

  useEffect(() => {
    if (acceptedFiles.length) {
      setFiles(acceptedFiles);
    }
  }, [acceptedFiles]);

  return (
    <div className="container">
      <div className={classes.dropzoneContainer} {...getRootProps({ style })}>
        <input
          {...getInputProps({
            multiple: false,
          })}
        />
        {isDragAccept && (
          <div className={classes.dropzoneDragAccept}>
            <p>Drag and drop files here</p>
          </div>
        )}
        {isDragReject && (
          <div className={classes.dropzoneDragAccept}>
            <p>This file will be rejected</p>
          </div>
        )}
        {!isDragActive && (
          <>
            <DropzoneIcon />
            <p className={classes.dropzoneTitle}>Drag and drop files here</p>
            <p className={classes.dropzoneSubTitle}>or</p>
            <Button
              onClick={openDialog}
              variant="contained"
              color="primary"
              type="submit"
              size="large"
              disabled={isDisabled}
              className={classes.saveButton}
              style={{
                width: "156px",
                fontSize: "14px",
                padding: "5px 34px",
              }}
            >
              Browse files
            </Button>
          </>
        )}
      </div>
      <aside>
        {files ? files.map((file: any, index: number) => (
          <div className={classes.acceptedFiles} key={index}>
            {file.name}
            <div style={{ cursor: "pointer" }} onClick={() => removeItem()}>
              <RemoveFileIcon />
            </div>
          </div>
        )) : <></>}
      </aside>
    </div>
  );
};
