import { makeStyles } from "@material-ui/core/styles";
import { Theme } from "src/web/themes/theme";

export const useStyles = makeStyles((theme: Theme) => ({
  header: {
    display: "flex",
    flexDirection: "row",
    "@media (max-width: 850px)": {
      flexDirection: "column",
    },
  },
  headerInfo: {
    width: "60%",
    display: "flex",
    flexWrap: "wrap",
    justifyContent: "space-between",
    "@media (max-width: 850px)": {
      width: "70%",
    },
  },
  phoneBox: {},
  cardBox: {
    padding: "32px 48px",
    backgroundColor: "white",
    borderRadius: "15px",
    boxShadow: "0px 3.89312px 23.3587px rgba(96, 96, 97, 0.18)",
    "@media (max-width: 850px)": {
      width: "100%",
      marginLeft: "0",
      marginBottom: "10px",
      marginTop: "0",
      padding: "14px 20px 19px 20px",
    },
  },
  cardTitle: {
    color: "#333",
    fontSize: "16px",
    fontWeight: 600,
    [theme.breakpoints.down("sm")]: {
      fontSize: "12px",
    },
  },
  cardDivider: {
    color: "#0DD1C5",
    opacity: 0.15,
    marginTop: "22px",
    marginBottom: "17px",
    "@media (max-width: 850px)": {
      marginTop: "14px",
      marginBottom: "11px",
    },
  },
  itemTitle: {
    fontSize: "12px",
    color: "#C4C4C4",
    fontWeight: 500,
    marginBottom: "2.5px",
    [theme.breakpoints.down("sm")]: {
      fontSize: "10px",
      marginBottom: "0",
    },
  },
  itemContent: {
    fontSize: "14px",
    color: "#333",
    fontWeight: 400,
    [theme.breakpoints.down("sm")]: {
      fontSize: "12px",
    },
  },
  cardInfo: {
    backgroundColor: "#F9FCFD",
    borderRadius: "15px",
    padding: "9px 16px",
    width: "180px",
    marginRight: "30px",
    height: "60px",
    "@media (max-width: 850px)": {
      width: "128px",
      height: "48px",
      padding: "7px 12px",
      marginRight: "12px",
      marginBottom: "19px",
      "&:last-child": {
        marginBottom: "5px",
      },
    },
  },
  ticketCard: {
    display: "flex",
    justifyContent: "space-between",
    "@media (max-width: 850px)": {
      flexWrap: "wrap",
    },
  },
  tripCards: {
    display: "flex",
    "@media (max-width: 1180px) and (min-width: 500px)": {
      flexWrap: "wrap",
    },
  },
  ticket: {
    width: "50%",
    "@media (max-width: 850px)": {
      width: "100%",
      marginBottom: "10px",
    },
  },
  option: {
    width: "50%",
    "@media (max-width: 850px)": {
      width: "100%",
    },
  },
  ticketInfo: {
    width: "25%",
    "@media (max-width: 850px)": {
      width: "50%",
    },
  },
  tripBox: {
    display: "flex",
    "@media (max-width: 850px)": {
      flexWrap: "wrap",
    },
  },
  box: {
    width: "49%",
    "@media (max-width: 850px)": {
      width: "100%",
    },
  },
  dialog: {
    "& .MuiDialog-paperScrollPaper": {
      maxHeight: "100%",
    },
    "& .MuiPaper-elevation24": {
      maxWidth: "550px",
      width: "550px",
      boxShadow: "0px 10.89px 43.36px rgba(17, 15, 15, 0.53)",
      borderRadius: "15px",
      overflow: "unset",
      "@media (max-width: 850px)": {
        padding: "0",
        fontSize: "14px",
        fontWeight: 500,
        margin: "18px",
      },
      "& .MuiTypography-root": {
        width: "100%",
      },
    },
    "& .MuiDialogTitle-root": {
      "@media (max-width: 850px)": {
        padding: "23px 9px 8px 9px",
      },
    },
    "& .MuiDialogContent-root": { padding: "5px 21px" },
    "& .MuiDialogActions-root": {
      "@media (max-width: 850px)": {
        padding: "0px 8px",
      },
    },
  },
  editIcon: {
    display: "flex",
    "@media (max-width: 850px)": {
      display: "none",
    },
  },
  editIconSmall: {
    display: "none",
    "@media (max-width: 850px)": {
      display: "flex",
    },
  },
  modalTitle: {
    fontSize: "18px",
    padding: "25px",
    color: "#333",
    fontWeight: 600,
    "@media (max-width: 850px)": {
      padding: "18px",
      fontSize: "14px",
      fontWeight: 500,
    },
  },
  infoBox: {
    width: "49%",
    border: "1px solid #dbf8f6",
    borderRadius: "15px",
    padding: "18px",
    display: "flex",
    marginRight: "18px",
    marginBottom: "10px",
    justifyContent: "space-between",
    "@media (max-width: 850px)": {
      width: "100%",
      marginRight: "0",
      padding: "9px 13px",
    },
  },
  addInfoBox: {
    border: "1px solid #dbf8f6",
    borderRadius: "15px",
    padding: "18px",
    "@media (max-width: 850px)": {
      padding: "9px 13px",
    },
  },
  partnerComment: {
    background: "#F9FCFD",
    borderRadius: "15px",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    "@media (max-width: 850px)": {
      height: "86px",
    },
  },
  selectorLabel: {
    color: "#333 !important",
    paddingLeft: "6px",
    paddingRight: "6px",
    background: "white",
    marginLeft: "12px",
  },
  statusSelect: {
    borderRadius: "50px",
    width: "100%",
    fontSize: "14px",
    [theme.breakpoints.down("sm")]: {
      fontSize: "12px",
    },
    color: "#6C6B6B",

    "& .MuiOutlinedInput-multiline": {
      padding: "0",
    },

    "& .MuiInputBase-root": {
      height: "100px",
      borderRadius: "25px !important",
      "& .MuiOutlinedInput-notchedOutline": {
        paddingLeft: "25px",
      },
    },

    "& .MuiInputBase-input": {
      padding: "15px 30px",
      [theme.breakpoints.down("sm")]: {
        fontSize: "12px",
      },
    },

    "& .MuiOutlinedInput-root": {
      borderRadius: "50px",
    },

    "& .MuiSelect-select:focus": {
      background: "white",
    },
    "& fieldset": {
      border: "1px solid #C4C4C4 !important",
      paddingLeft: "8px",
    },

    "&:hover": {
      color: "#0DD1C5",

      "& fieldset": {
        border: "1px solid #0DD1C5 !important",
      },
    },

    "& .Mui-focused": {
      color: "#6C6B6B !importnant",
    },

    "& .MuiSelect-root": {
      height: "50px",
      paddingTop: 0,
      paddingBottom: 0,
      display: "flex",
      alignItems: "center",
      padding: "0 30px",
      "@media (max-width: 850px)": {
        height: "45px",
      },
    },
  },
  statusSelectPaper: {
    boxShadow: "0px 3.89px 23.36px #110F0F26",
    backgroundColor: "#FFFFFF",
    borderRadius: "15px",
    padding: "25px 19px 17px 30px",
    overflow: "hidden",

    "& .MuiMenuItem-root": {
      color: "#6C6B6B",
      fontSize: "14px",
      marginRight: "30px",
      [theme.breakpoints.down("sm")]: {
        fontSize: "12px",
      },
      fontWeight: 400,
      borderRadius: "10px",
      padding: "22px 28px",
      "@media (max-width: 850px)": {
        padding: "17px 24px",
        marginRight: "15px",
      },
    },

    "& .MuiListItem-button:hover": {
      color: "#0DD1C5",
      backgroundColor: "#ecfbfa",
      borderRadius: "15px",
    },

    "& .Mui-selected": {
      color: "#C4C4C4",
      fontSize: "14px",
      [theme.breakpoints.down("sm")]: {
        fontSize: "12px",
      },
      backgroundColor: "transparent",

      "&:hover": {
        color: "#C4C4C4",
        backgroundColor: "transparent",
      },
    },
  },
  cancelButton: {
    textTransform: "none",
    color: "#c4c4c4",
    fontSize: "16px",
    [theme.breakpoints.down("sm")]: {
      fontSize: "14px",
      padding: "6.5px 20px",
    },
    padding: "6.5px 33px",
    borderRadius: "50px",
    "&:hover": {
      background: "white",
      color: "#6C6B6B",
    },
  },
  saveButton: {
    textTransform: "none",
    color: "white",
    fontSize: "16px",
    [theme.breakpoints.down("sm")]: {
      fontSize: "14px",
      padding: "6.5px 25px",
      width: "120px",
    },
    background: "linear-gradient(90deg, #03D9B1 0%, #1CBBD9 100%)",
    borderRadius: "50px",
    boxShadow:
      "0px 13px 28px rgba(29, 203, 192, 0.2), 0px 8px 16px rgba(29, 203, 192, 0.06)",
    width: "160px",
    padding: "6.5px 60px",
    "&:hover": {
      background: "linear-gradient(90deg, #06C9A4 0%, #00A2C1 100%)",
      color: "#FFFFFF",
      boxShadow:
        "0px 13px 28px rgba(29, 203, 192, 0.2), 0px 8px 16px rgba(29, 203, 192, 0.06)",
    },
  },
  datePicker: {
    height: "50px",
    "@media (max-width: 850px)": {
      height: "45px",
    },
    borderRadius: "50px",
    padding: "15px 30px",
    color: "#333333",
    border: "1px solid rgba(0, 0, 0, 0.23)",
    fontSize: "14px",
    [theme.breakpoints.down("sm")]: {
      fontSize: "12px",
    },
    width: "100%",
    "&:hover": {
      outline: "none",
      border: "1px solid #333333",
    },
    "&:focus-visible": {
      outline: "none",
      border: "1px solid #333333",
    },
    fontFamily: `"Montserrat", "Helvetica", "Arial", sans-serif`,
    "&::placeholder": {
      color: "#C4C4C4",
      fontFamily: `"Montserrat", "Helvetica", "Arial", sans-serif`,
    },
    "& .MuiInputBase-root": {
      fontSize: "14px",
      [theme.breakpoints.down("sm")]: {
        fontSize: "12px",
      },
      color: "#333333",
    },
    "& .MuiInputBase-input": {
      border: "none !important",
      padding: "0",
    },
    "& .MuiInput-underline:before": {
      border: "none !important",
    },
    "& .MuiInput-underline:after": {
      border: "none !important",
    },
  },
  datePickerWrapper: {
    "& .react-datepicker-popper": {
      zIndex: "10",
      transform: "translate(138px, 42px) !important",
      "@media (max-width: 850px)": {
        transform: "translate(-13px, 42px) !important",
      },
      "@media (max-width: 750px)": {
        position: "fixed !important",
        transform: "none !important",
        bottom: "0px !important",
        left: "25px !important",
        inset: "auto !important",
      },
    },
    "& .react-datepicker__month-container": {
      padding: "19px 23px",
    },
    "& .react-datepicker__input-container": {
      verticalAlign: "text-bottom",
      marginTop: "2px",
    },
    "& .react-datepicker": {
      boxShadow: "0px 3.89312px 23.3587px rgba(96, 96, 97, 0.18)",
      borderRadius: "15px",
      border: "none",
      color: "#6C6B6B",
      fontSize: "12px",
      fontFamily: `"Montserrat", "Helvetica", "Arial", sans-serif`,
    },
    "& .react-datepicker__day--outside-month": {
      visibility: "hidden",
    },

    "& .react-datepicker-popper[data-placement^=bottom] .react-datepicker__triangle":
      {
        transform: "translate(315px, 0px) !important",
      },
    "& .react-datepicker-popper[data-placement^=bottom] .react-datepicker__triangle::after":
      {
        borderBottomColor: "white",
      },
    "& .react-datepicker-popper[data-placement^=bottom] .react-datepicker__triangle::before":
      {
        borderBottomColor: "white",
      },
    "& .react-datepicker__navigation": {
      margin: "20px",
      padding: "10px",
      boxShadow: "0px 3.89312px 23.3587px rgba(189, 189, 189, 0.23)",
      borderRadius: "16px",
      color: "#333333",
      height: "30px",
      width: "30px",
      "& span": {
        "&.react-datepicker__navigation-icon--next": {
          "&::before": {
            left: "-2px",
            borderWidth: "1px 1px 0 0",
            color: "#333333",
            height: "6px",
            width: "6px",
            top: "9px",
          },
        },
        "&.react-datepicker__navigation-icon--previous": {
          "&::before": {
            right: "-2px",
            borderWidth: "1px 1px 0 0",
            color: "#333333",
            height: "6px",
            width: "6px",
            top: "9px",
          },
        },
      },
    },
    "& .react-datepicker__header": {
      border: "none",
      background: "white",
      paddingBottom: "5px",
      "& .react-datepicker__current-month": {
        color: "#6C6B6B",
        fontSize: "16px",
        paddingBottom: "22px",
      },
    },
    "& .react-datepicker__week": {
      display: "flex",
      margin: "3px 0",
    },
    "& .react-datepicker__day--keyboard-selected": {
      background: "#C4C4C4",
      borderRadius: "50px",
      color: "white",
    },
    "& .react-datepicker__day-name": {
      color: "#6C6B6B",
      fontSize: "12px",
      width: "36px",
      margin: "0px",
    },
    "& .react-datepicker__month": {
      margin: 0,
    },
    "& .react-datepicker__day": {
      color: "#6C6B6B",
      fontSize: "14px",
      width: "36px",
      padding: "4.5px 0",
      maxHeight: "36px",
      margin: "0px",
      "&:hover": {
        borderRadius: "50px",
      },
    },
    "& .react-datepicker__day--today": {
      color: "white",
      background: "#C4C4C4",
      borderRadius: "50px",
      fontWeight: 400,
    },
    "& .react-datepicker__day--selected, & .react-datepicker__day--range-end": {
      borderRadius: "50px",
      color: "white",
      background: "#0DD1C5 !important",
      boxShadow:
        "0px 13px 28px rgba(29, 203, 192, 0.2), 0px 8px 16px rgba(29, 203, 192, 0.06)",
    },
    "& .react-datepicker__close-icon": {
      marginRight: "18px",
      "&::after": {
        background: "none",
        fontSize: "16px",
        color: "#C4C4C4",
      },
    },
    "& .react-datepicker__day--in-range, & .react-datepicker__day--in-selecting-range":
      {
        color: "#6C6B6B",
        background: "#F9FCFD",
        borderRadius: "50px",
        // borderRaduis: "0px",
        // "&:first-child": {
        //   borderTopRightRadius: "100px",
        //   borderBottomRightRadius: "100px",
        // },
        // "&:last-child": {
        //   borderTopLeftRadius: "100px",
        //   borderBottomLeftRadius: "100px",
        // },
      },
    "& .react-datepicker__day--selecting-range-start, react-datepicker__day--selecting-range-end":
      {
        color: "white",
        background: "#0DD1C5",
        borderRadius: "50px",
      },
    "& .react-datepicker__day--range-end, & .react-datepicker__day--range-start":
      {
        color: "white",
        background: "#0DD1C5",
        borderRadius: "50px",
      },
  },
  datePickerLabel: {
    color: "#333",
    paddingLeft: "6px",
    paddingRight: "6px",
    background: "white",
    marginLeft: "12px",
  },
}));
