import React, { useEffect, useState } from "react";
import { Box, Button } from "@material-ui/core";
import ArrowBack from "src/assets/icons/web/arrowBack.svg";
import { useStyles } from "./styles";
import { useHistory } from "react-router-dom";
import { useLazyQuery, useMutation, useQuery } from "@apollo/client";
import { CREATE_PARTNER } from "src/api/tempPartners";
import { IRegistrationForm } from "src/redux/Registration/interfaces";
import { useDispatch, useSelector } from "react-redux";
import { partnerData, setData } from "src/redux/Registration/sliceRegistration";
import UpdateForm from "src/web/components/Registration/UpdateForm";
import { setNotification } from "src/redux/Notifications/sliceNotifications";
import { Spiner } from "src/web/components/Loaders";
import {
  IInterviewBlock,
  IOnboardingScreen,
} from "src/web/components/OnboardingForm/interface";
import { FormElement } from "src/web/components/OnboardingForm/FormElement";
import { IOnboardingForm } from "src/web/components/OnboardingForm/interface";
import { validateField } from "src/web/components/OnboardingForm/validate";
import { GET_ALL_FORMS, GET_FORM, SEND_FORM } from "src/api/onboardingForms";

const RegistrationForm = () => {
  const classes = useStyles();
  const [step, setStep] = useState(0);
  const history = useHistory();
  const [errorMessage, setErrorMessage] = useState<
    Partial<Record<keyof IOnboardingForm, string>>
  >({});
  const [other, setOther] = useState(false);
  const [isNextWasClicked, setIsNextClicked] = useState(false);
  const [isSpiner, setIsSpiner] = useState(false);
  const [registrationForm, setRegistrationForm] = useState<
    IOnboardingForm | undefined
  >(undefined);
  const [formId, setFormId] = useState<string>("");
  const { receivedData, form: data } = useSelector(partnerData);

  const dispatch = useDispatch();
  const [id, setId] = useState("");

  const { loading: formsLoading } = useQuery(GET_ALL_FORMS, {
    onCompleted: (data) => {
      const currentId = data.v1_general_listForms.items.find(
        (item: IOnboardingForm) => item.title === "Partner Registration"
      ).id;
      setFormId(currentId);
    },
    onError: (error) => {
      console.error(error);
    },
  });



  const [getForm, { loading }] = useLazyQuery(GET_FORM, {
    variables: {
      prm: {
        id: formId,
      },
    },
    onCompleted: (data) => {
      const dataScreens = data.v1_general_getForm.screens;

      const newForm = {
        ...data.v1_general_getForm,
        screens: JSON.parse(dataScreens),
      };
      setRegistrationForm(newForm);
    },
    onError: (error) => {
      console.error(error);
    },
  });

  const [createPartner] = useMutation(CREATE_PARTNER, {
    onCompleted: (data) => {
      if (data.v1_partners_createTempPartner.success) {
        setId(data.v1_partners_createTempPartner.id);
      }
      setIsSpiner(false);
    },
    onError: (error) => {
      setIsSpiner(false);
      dispatch(
        setNotification({
          type: "error",
          message: error.message,
          duaration: 10000,
        })
      );
    },
  });

  const [createTempPartner] = useMutation(SEND_FORM, {
    onCompleted: (data) => {
      console.log(data);
      setIsSpiner(false);
    },
    onError: (error) => {
      setIsSpiner(false);
      dispatch(
        setNotification({
          type: "error",
          message: error.message,
          duaration: 10000,
        })
      );
    },
  });

  const currentScreen = registrationForm?.screens[step] as IOnboardingScreen;

  const currentFields = currentScreen?.content as IInterviewBlock;

  const incrementStep = () => {
    setIsNextClicked(true);
    let isValid = true;
    currentFields?.fields?.forEach((item) => {
      if (!isValid) {
        return;
      }

      isValid = handleValidate(
        item.name as string,
        data[item.name as keyof IRegistrationForm]
      );
    });

    if (!isValid || !registrationForm) {
      return;
    }

    if (step === registrationForm.screens.length - 1) {
      handleSubmit();
      return;
    }

    setOther(false);
    setStep(step + 1);
  };

  const decrementStep = () => {
    if (step === 0) {
      history.push(`/login`);
    } else {
      setStep(step - 1);
    }
  };

  useEffect(() => {
    if (formId) {
      getForm();
    }
  }, [formId]);

  useEffect(() => {
    setIsNextClicked(false);
  }, [step]);

  useEffect(() => {
    const keyDownHandler = (event: any) => {
      if (event.key === "Enter") {
        incrementStep();
      }
    };

    document.addEventListener("keydown", keyDownHandler);

    return () => {
      document.removeEventListener("keydown", keyDownHandler);
    };
  }, [step, data, registrationForm]);

  if (!registrationForm || formsLoading) {
    return <Spiner fixed />;
  }

  const handleSubmit = () => {
    setIsSpiner(true);
    const createData = {
      ...data,
      reservationSystem: data.reservationSystem ? data.reservationSystem : "no",
      isInsured: data.isInsured === "Yes" ? true : false,
      yearsWork: +data.yearsWork,
    };
    // createPartner({
    //   variables: {
    //     prm: {
    //       ...data,
    //       reservationSystem: data.reservationSystem
    //         ? data.reservationSystem
    //         : "no",
    //       isInsured: data.isInsured === "Yes" ? true : false,
    //       yearsWork: +data.yearsWork,
    //     },
    //   },
    // });
    createTempPartner({
      variables: {
        prm: {
          id: formId,
          data: JSON.stringify(createData),
        },
      },
    });
  };

  const handleValidate = (name: string, value: string | number | boolean) => {
    if (currentScreen.order === 0 || currentScreen.order === 1) {
      return true;
    }
    return validateField(setErrorMessage, currentFields, name, value);
  };

  const handleChange = (field: string, value: string) => {
    if (isNextWasClicked) {
      handleValidate(field, value);
    }
    dispatch(setData({ [field]: value }));
  };

  const handleAccept = (name: keyof IRegistrationForm) => {
    dispatch(
      setData({
        [name]: !data[name],
      })
    );
  };

  return (
    <>
      {(loading || isSpiner) && <Spiner fixed />}
      {!id ? (
        <>
          <Box className={classes.contentWrapper}>
            <FormElement
              form={registrationForm}
              formInput={currentScreen}
              errorMessage={errorMessage}
              handleChange={handleChange}
              data={data}
              receivedData={receivedData}
              setOther={setOther}
              other={other}
              handleAccept={handleAccept}
            />
          </Box>

          <Box className={classes.buttonsContainer}>
            <Button
              onClick={decrementStep}
              variant="text"
              color="primary"
              type="submit"
              className={classes.cancelButton}
              size="large"
            >
              <ArrowBack />
              Back
            </Button>

            {step === registrationForm.screens.length - 1 ? (
              <Button
                onClick={incrementStep}
                variant="contained"
                color="primary"
                type="submit"
                size="large"
                className={classes.saveButton}
              >
                Submit
              </Button>
            ) : (
              <Button
                onClick={incrementStep}
                variant="contained"
                color="primary"
                type="submit"
                size="large"
                className={classes.saveButton}
              >
                Next
              </Button>
            )}
          </Box>
        </>
      ) : (
        <UpdateForm id={id} />
      )}
    </>
  );
};

export default RegistrationForm;
