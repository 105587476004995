import React, { useState } from "react";
import clsx from "clsx";
import { Box, Stepper, StepButton, Step, StepLabel } from "@material-ui/core";
import Logo from "src/images/login_bg.svg";
import { useStyles } from "./styles";
import { TermsAndConditionsForm } from "./Content/TermsAndConditions";
import Check from "src/assets/icons/web/completeForm.svg";
import CheckAdaptive from "src/assets/icons/web/completeFormAdaptive.svg";
import { AccountSetUp } from "./Content/AccountSetUp";
import { Insurance } from "./Content/Insurance";
import CompleteOnboarding from "./Content/CompleteOnboarding";
import LogoAdaptive from "src/images/LogoAdaptive.svg";
import { GET_ALL_FORMS } from "src/api/onboardingForms";
import { useQuery } from "@apollo/client";
import { IOnboardingForm } from "../OnboardingForm/interface";
import { Spiner } from "../Loaders";

interface Props {
  activeStep: number;
  handleStep: (step: number) => () => void;
  completed: {
    [k: number]: boolean;
  };
  handleComplete: () => void;
  handleBack: () => void;
  setIsSpiner: React.Dispatch<React.SetStateAction<boolean>>;
}

const OnboardingComponent = ({
  activeStep,
  handleStep,
  completed,
  handleComplete,
  handleBack,
  setIsSpiner,
}: Props) => {
  const classes = useStyles();
  const [termsFormId, setTermsFormId] = useState<string>("");
  const [w9FormId, setW9FormId] = useState<string>("");
  const [insuranceFormId, setInsuranceFormId] = useState<string>("");
  const { loading: formsLoading } = useQuery(GET_ALL_FORMS, {
    onCompleted: (data) => {
      const currentTermsId = data.v1_general_listForms.items.find(
        (item: IOnboardingForm) => item.title === "Terms and Conditions"
      ).id;
      setTermsFormId(currentTermsId);

      const currentW9Id = data.v1_general_listForms.items.find(
        (item: IOnboardingForm) => item.title === "Account Set Up"
      ).id;
      
      setW9FormId(currentW9Id);
      const currentInsuranceId = data.v1_general_listForms.items.find(
        (item: IOnboardingForm) => item.title === "Insurance"
      ).id;
      setInsuranceFormId(currentInsuranceId);
    },
    onError: (error) => {
      console.error(error);
    },
  });

  function QontoStepIcon(props: any) {
    const { completed } = props;

    const icon = (
      <div>
        <div className={classes.completeIcon}>
          <Check />
        </div>
        <div className={classes.completeIconAdaptive}>
          <CheckAdaptive />
        </div>
      </div>
    );

    return completed ? icon : <></>;
  };

  const steps = ["Terms and Conditions", "Account Set Up", "Insurance"];

  return (
    <>
      {formsLoading && <Spiner fixed />}
      <Box className={classes.root}>
        <Box mb={3} className={classes.logoWrapper}>
          <Logo />
        </Box>
        <Box className={classes.logoWrapperAdaptive}>
          <LogoAdaptive />
        </Box>
        <Box mt={3} mb={6} className={classes.stepper} width={7 / 10}>
          <Stepper nonLinear activeStep={activeStep}>
            {steps.map((label, index) => (
              <Step key={label}>
                <StepButton
                  onClick={handleStep(index)}
                  completed={completed[index]}
                  className={clsx(classes.step, {
                    completed: completed[index],
                  })}
                  disabled={!completed[index]}
                >
                  <StepLabel StepIconComponent={QontoStepIcon}>
                    {index + 1}
                    <div className="step_title">. {label}</div>
                  </StepLabel>
                </StepButton>
              </Step>
            ))}
          </Stepper>
        </Box>
        {activeStep === 0 && (
          <Box className={classes.termsWrapper}>
            <TermsAndConditionsForm handleSubmit={handleComplete} formId={termsFormId}/>
          </Box>
        )}
        {activeStep === 1 && (
          <Box className={classes.contentWrapper}>
            <AccountSetUp
              handleSubmit={handleComplete}
              handleBack={handleBack}
              formId={w9FormId}
            />
          </Box>
        )}
        {activeStep === 2 && (
          <Box className={classes.contentWrapper}>
            <Insurance
              setIsSpiner={setIsSpiner}
              handleSubmit={handleComplete}
              handleBack={handleBack}
              formId={insuranceFormId}
            />
          </Box>
        )}
        {activeStep === 3 && <CompleteOnboarding />}
      </Box>
    </>
  );
};

export default OnboardingComponent;
