import React, { useState } from "react";
import {
  Box,
  Button,
  Checkbox,
  FormControlLabel,
  Typography,
} from "@material-ui/core";
import { useStyles } from "./styles";
import CheckboxIcon from "src/assets/icons/web/policyCheckbox.svg";
import CheckboxCheckedIcon from "src/assets/icons/web/policyCheckboxChecked.svg";
import PerfectScrollbar from "react-perfect-scrollbar";
import { Spiner } from "../Loaders";

export const PrivacyPolicyForm = ({
  handleSubmit,
  isSpiner,
  privacyPolicy,
  setPrivacyPolicy
}: {
  handleSubmit: () => void;
  isSpiner: boolean;
  privacyPolicy: boolean;
  setPrivacyPolicy: React.Dispatch<React.SetStateAction<boolean>>
}) => {
  const classes = useStyles();

  return (
    <div>
      {isSpiner && <Spiner fixed />}
      <>
        <Box mb={2}>
          <Typography align="center" variant="h5" className={classes.title}>
            Privacy Policy
          </Typography>
        </Box>
        <Box mb={2} className={classes.policyBox}>
          <PerfectScrollbar>
            <Box pr={3}>
              <Typography
                align="center"
                variant="subtitle1"
                className={classes.content}
              >
                Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
                eiusmod tempor incididunt ut labore et dolore magna aliqua.
              </Typography>
              <Typography
                align="center"
                variant="subtitle1"
                style={{ whiteSpace: "pre-wrap" }}
                className={classes.description}
              >
                Ut enim ad minim veniam, quis nostrud exercitation ullamco
                laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure
                dolor in reprehenderit in voluptate velit esse cillum dolore eu
                fugiat nulla pariatur. Excepteur sint occaecat cupidatat non
                proident, sunt in culpa qui officia deserunt mollit anim id est
                laborum. Excepteur sint occaecat cupidatat non proident, sunt in
                culpa qui officia deserunt mollit anim id est laborum.
              </Typography>
            </Box>
          </PerfectScrollbar>
        </Box>
        <Box width={1} px={1} mt={1} mb={1} className={classes.acceptCheckbox}>
          <FormControlLabel
            value={privacyPolicy}
            className={classes.checkbox}
            control={
              <Checkbox
                color="default"
                icon={<CheckboxIcon />}
                checkedIcon={<CheckboxCheckedIcon />}
              />
            }
            label="I accept the privacy policy"
            labelPlacement="end"
            onChange={() => setPrivacyPolicy(!privacyPolicy)}
          />
        </Box>
      </>
      <>
        <Box mt={2} display="flex" justifyContent="center">
          <Button
            onClick={handleSubmit}
            variant="contained"
            color="primary"
            type="submit"
            size="large"
            fullWidth
            disabled={!privacyPolicy}
            className={classes.saveButton}
          >
            Submit
          </Button>
        </Box>
      </>
    </div>
  );
};

