import React from 'react';
import {
  Box,
  Dialog,
  DialogTitle,
  DialogContent,
  IconButton,
  Typography,
  DialogActions,
  Button,
} from '@material-ui/core';
import CloseIcon from 'src/assets/icons/light/close.svg';
import { useStyles } from './styles';
import { ConfirmType } from './NotificationsList';

interface IProps {
  confirmType?: ConfirmType;
  open: boolean;
  action?: Function;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
}

const ConfirmModal = ({ open, setOpen, confirmType, action }: IProps) => {
  const classes = useStyles();

  if (!action || !confirmType) {
    return <></>;
  }

  const handleClose = () => {
    setOpen(false);
  };

  const confirmText =
    confirmType === 'delete'
      ? 'Are you sure you really want to delete this notification?'
      : confirmType === 'readall'
      ? 'Are you sure you really want to mark all notifications as read?'
      : confirmType === 'unread'
      ? 'Are you sure you really want to mark as unread this notification?'
      : null;

  return (
    <Dialog
      open={open}
      fullWidth={true}
      onClose={handleClose}
      className={classes.dialog}>
      <DialogTitle style={{ padding: '0px 14px' }}>
        <Box className={classes.confirmModalHeader}>
          <IconButton
            onClick={() => setOpen(false)}
            className={classes.closeIcon}>
            <CloseIcon />
          </IconButton>
        </Box>
      </DialogTitle>
      <DialogContent>
        <Typography className={classes.confirmModalText}>
          {confirmText}
        </Typography>
      </DialogContent>
      <DialogActions className={classes.confirmModalActions}>
        <Button onClick={handleClose} className={classes.gradientButton}>
          No
        </Button>
        <Button
          onClick={() => {
            action();
            handleClose();
          }}
          className={classes.simpleButton}>
          Yes
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default ConfirmModal;
