import React, { useEffect } from 'react';
import { Redirect, useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

import { Box, Divider, Typography } from '@material-ui/core';
import { LockOpen } from '@material-ui/icons';

import {
  forgotPasswordSubmit,
  IConfirmPassword,
  resetPasswordEmail,
  setSuccessAuth,
  successAuth,
} from 'src/redux/Authentication/sliceAuthentication';
import { ConfirmPasswordForm } from 'src/web/features/authentication/';

import Logo from 'src/images/logo.svg';
import { useStyles } from './styles';

const ConfirmPassword: React.FC<{}> = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const history = useHistory();
  const email =
    useSelector(resetPasswordEmail) ||
    localStorage.getItem('resetPasswordEmail');
  const isConfirmPassword = useSelector(successAuth);

  useEffect(() => {
    if (isConfirmPassword) {
      setSuccessAuth(false);
      localStorage.removeItem('resetPasswordUser');
      history.push('/login');
    }
  }, [isConfirmPassword, history]);

  if (!email) {
    history.push('/login');
    return null;
  }

  const onSubmit = (data: IConfirmPassword) => {
    data.username = email;
    dispatch(forgotPasswordSubmit(data));
  };

  return (
    <>
      <Box>
        <Typography className={classes.title}>Confirm password</Typography>
      </Box>

      <ConfirmPasswordForm onSubmit={onSubmit} />
    </>
  );
};

export default ConfirmPassword;
