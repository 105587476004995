import React, { FC, useState, Suspense, useEffect, ReactElement } from 'react';
import { MainHeader } from 'src/web/components/MainHeader';
import { MainSidebar } from 'src/web/components/MainSidebar';
import { Spiner } from 'src/web/components/Loaders';
import { useStyles } from './styles';
import { NotificationsProvider } from 'src/contexts/NotificationsContext';

export const MainLayout: FC<{ children: ReactElement }> = ({ children }) => {
  const classes = useStyles();
  const { innerWidth: width } = window;
  let isOpen = width > 1200;
  if (localStorage.getItem('asidebar') === 'close') {
    isOpen = false;
  }
  const isBackdrop = width < 1020;
  const [open, setOpen] = useState<boolean>(isOpen);

  useEffect(() => {
    if (open) {
      localStorage.setItem('asidebar', 'open');
    } else {
      localStorage.setItem('asidebar', 'close');
    }
  }, [open]);

  return (
    <NotificationsProvider>
      <div className={classes.root}>
        {isBackdrop && open && (
          <div
            className={classes.backdrop}
            onClick={() => setOpen(false)}></div>
        )}
        <div className={classes.page_container}>
          <Suspense fallback={<Spiner fixed />}>
            <MainSidebar open={open} setOpen={setOpen} />
            <div className={classes.content_container}>
              <MainHeader open={open} setOpen={setOpen} />
              <div className={classes.page_content_container}>{children}</div>
            </div>
          </Suspense>
        </div>
      </div>
    </NotificationsProvider>
  );
};
