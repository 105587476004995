import React, { useState } from "react";
import clsx from "clsx";
import {
  Box,
  Card,
  Typography,
  FormControlLabel,
  Checkbox,
  CardContent,
  TextField,
  Table,
  TableCell,
  TableHead,
  TableRow,
  TableBody,
  useMediaQuery,
  TableContainer,
} from "@material-ui/core";
import { useStyles } from "./styles";
import { ExpandMore } from "@material-ui/icons";
import { parseNumberInputValue } from "src/helpers/calendar/parseNumberInput";
import CheckboxIcon from "src/assets/icons/web/checkbox.svg";
import CheckboxCheckedIcon from "src/assets/icons/web/checkboxChecked.svg";

interface Column {
  field: string;
  headerName: string;
  width?: string;
  align?: "right" | "left" | "center";
  format?: (value: number) => string;
}

const columns: Column[] = [
  { field: "value", headerName: "Value Name", align: "right", width: "30%" },
  {
    field: "price",
    headerName: "Price",
    align: "center",
    width: "20%",
  },
  {
    field: "disable",
    headerName: "Disable",
    align: "center",
    width: "25%",
  },
  {
    field: "tax",
    headerName: "Apply Tax",
    align: "center",
    width: "25%",
  },
];

const defaultOptionValue = {
  name: "",
  price: 0,
  is_disabled: 0,
  apply_tax: 0,
};

export const MasterOptionFields = ({
  title,
  option,
  setCalendarOption,
}: {
  title: string;
  option?: ITripAdditionalOptionReceived;
  setCalendarOption: (value: React.SetStateAction<ICalendarOption>) => void;
}) => {
  const classes = useStyles();
  const isMobile = useMediaQuery("(max-width: 960px)");

  const [isDisabled, setIsDisabled] = useState(Boolean(option?.is_disabled));
  const [state, setState] = useState(
    option?.values?.map((value) => ({
      ...defaultOptionValue,
      name: value.name,
    })) || [defaultOptionValue]
  );

  const onApplyOption = () => {
    setCalendarOption((prev) => {
      const result = Object.fromEntries(
        Object.entries(prev).map(([date, calendar]) => {
          return [
            date,
            {
              ...calendar,
              is_disabled: Number(isDisabled),
              option_is_disabled: Number(isDisabled),
            },
          ];
        })
      );

      return result;
    });
  };

  const onApplyValue = (
    valueIndex: number,
    field: keyof Omit<typeof defaultOptionValue, "name">
  ) => {
    setCalendarOption((prev) =>
      Object.fromEntries(
        Object.entries(prev).map(([date, calendar]) => {
          const newValues = structuredClone(calendar.values);
          newValues[valueIndex][field] = state[valueIndex][field];
          return [date, { ...calendar, values: newValues }];
        })
      )
    );
  };

  const onChangeField = (
    index: number,
    field: keyof typeof defaultOptionValue,
    value: number
  ) => {
    setState((prev) => {
      const values = structuredClone(prev);

      if (field !== "name") {
        values[index][field] = value;
      }

      return values;
    });
  };

  return (
    <Card
      className={clsx(
        classes.fieldsCard,
        classes.masterCard,
        classes.inputWrapper
      )}
    >
      <CardContent style={{ padding: 0 }}>
        <Box>
          <div style={{ display: "flex" }}>
            <Typography>{title}</Typography>
            <Typography>{option?.name}</Typography>
          </div>
          <Box display="flex" alignItems="center" justifyContent="flex-end">
            <FormControlLabel
              control={
                <Checkbox
                  checked={isDisabled}
                  onChange={(_, checked) => setIsDisabled(checked)}
                  icon={<CheckboxIcon />}
                  checkedIcon={<CheckboxCheckedIcon />}
                />
              }
              label="Disable Option"
              labelPlacement="start"
            />
            <ExpandMore
              fontSize="medium"
              style={{ marginLeft: "10px", cursor: "pointer" }}
              onClick={onApplyOption}
            />
          </Box>
        </Box>
        <TableContainer>
          <Table>
            <TableHead>
              <TableRow>
                {columns.map((column) => (
                  <TableCell
                    align={column.align}
                    key={column.field}
                    style={{ width: column.width, fontSize: "16px" }}
                  >
                    {column.headerName}
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {option?.values?.map((row, index) => {
                return (
                  <TableRow
                    hover
                    role="checkbox"
                    tabIndex={-1}
                    key={row.id + index}
                  >
                    <TableCell style={{ padding: "6px 16px" }} align="right">
                      {row.name}
                    </TableCell>
                    <TableCell style={{ padding: "6px 16px" }} align="center">
                      <Box
                        p={3}
                        display="flex"
                        alignItems="center"
                        justifyContent="center"
                      >
                        <TextField
                          value={parseNumberInputValue(state[index].price)}
                          onChange={(e) =>
                            onChangeField(index, "price", +e.target.value)
                          }
                          type="number"
                          size="small"
                          variant="outlined"
                          style={{ padding: "0 20px", minWidth: "150px" }}
                        />
                        <ExpandMore
                          fontSize="medium"
                          style={{ marginLeft: "10px", cursor: "pointer" }}
                          onClick={() => onApplyValue(index, "price")}
                        />
                      </Box>
                    </TableCell>
                    <TableCell style={{ padding: "6px 16px" }} align="center">
                      <Box
                        display="flex"
                        alignItems="center"
                        justifyContent="center"
                      >
                        <Checkbox
                          checked={Boolean(state[index].is_disabled)}
                          onChange={(_, checked) =>
                            onChangeField(index, "is_disabled", Number(checked))
                          }
                          color="primary"
                        />
                        <ExpandMore
                          fontSize="medium"
                          style={{ marginLeft: "10px", cursor: "pointer" }}
                          onClick={() => onApplyValue(index, "is_disabled")}
                        />
                      </Box>
                    </TableCell>
                    <TableCell style={{ padding: "6px 16px" }} align="center">
                      <Box
                        display="flex"
                        alignItems="center"
                        justifyContent="center"
                      >
                        <Checkbox
                          checked={Boolean(state[index].apply_tax)}
                          onChange={(_, checked) =>
                            onChangeField(index, "apply_tax", Number(checked))
                          }
                          color="primary"
                        />
                        <ExpandMore
                          fontSize="medium"
                          style={{ marginLeft: "10px", cursor: "pointer" }}
                          onClick={() => onApplyValue(index, "apply_tax")}
                        />
                      </Box>
                    </TableCell>
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
        </TableContainer>
      </CardContent>
    </Card>
  );
};
