import React, { Dispatch, SetStateAction } from 'react';
import { Box, List, Divider, ListItem, Button } from '@material-ui/core';
import NavigationItem from './NavigationItem';
import DividerCloseIcon from 'src/assets/sideBarIcons/dividerClose.svg';
import { useStyles } from './styles';
import { useShortInfo } from 'src/hooks/autocompletes/useShortInfo';
import LaunchIcon from '@material-ui/icons/Launch';

import WRLogo from 'src/images/wrLogo.svg';

export const MainSidebarNavigation = ({
  open,
  setOpen,
}: IMainSidebarNavigationProps) => {
  const classes = useStyles();

  const { navigation, data } = useShortInfo();

  return (
    <Box className={classes.root}>
      {navigation.map((nav, idx) => (
        <List style={{ padding: '8px 2px 0 2px' }} key={idx}>
          <Divider className={classes.divider} />
          <div className={classes.linkHeader}>
            {open ? (
              nav.title
            ) : (
              <Box className={classes.sidebarDevider}>
                <DividerCloseIcon />
              </Box>
            )}
          </div>
          {nav.links.map((link, idx) => (
            <NavigationItem
              isOpenDrawer={open}
              setOpenDrawer={setOpen}
              link={link}
              key={`${link.title}-${idx}`}
            />
          ))}
        </List>
      ))}
      <Box my={3}>
        <List style={{ padding: '0 2px' }}>
          <Divider className={classes.divider} />
          <div className={classes.linkHeader}>
            {open ? (
              'Listings'
            ) : (
              <Box className={classes.sidebarDevider}>
                <DividerCloseIcon />
              </Box>
            )}
          </div>
          <a
            href={`https://www.tripshock.com/partners/${data?.slug}/${data?.id}/`}
            target="_blanc"
            rel="nofollow"
            className={classes.linkWrapper}>
            <ListItem
              className={classes.externalLink}
              style={open ? { padding: '6px 0px' } : { padding: '6px 19px' }}
              button>
              {open && (
                <>
                  <Box mr={1.5} className={classes.linkTitle}>
                    Active Listings
                  </Box>
                  <LaunchIcon />
                </>
              )}
            </ListItem>
          </a>
          <a
            href="https://form.typeform.com/to/a31GuHQA"
            target="_blanc"
            rel="nofollow"
            className={classes.linkWrapper}>
            <ListItem
              className={classes.externalLink}
              style={open ? { padding: '6px 0px' } : { padding: '6px 19px' }}
              button>
              {open && (
                <>
                  <Box mr={1.5} className={classes.linkTitle}>
                    Listing Change Request
                  </Box>
                  <LaunchIcon />
                </>
              )}
            </ListItem>
          </a>
          <a
            href={`https://form.typeform.com/to/a4baV9Cl`}
            target="_blanc"
            rel="nofollow"
            className={classes.linkWrapper}>
            <ListItem
              className={classes.externalLink}
              style={open ? { padding: '6px 0px' } : { padding: '6px 19px' }}
              button>
              {open && (
                <>
                  <Box mr={1.5} className={classes.linkTitle}>
                    Add New Listings
                  </Box>
                  <LaunchIcon />
                </>
              )}
            </ListItem>
          </a>
          <a
            href="https://partners.tripshock.com/partner-solutions/"
            target="_blanc"
            rel="nofollow"
            className={classes.linkWrapper}>
            <ListItem
              className={classes.externalLink}
              style={open ? { padding: '6px 0px' } : { padding: '6px 19px' }}
              button>
              {open && (
                <>
                  <Box mr={1.5} className={classes.linkTitle}>
                    Featured Placement
                  </Box>
                  <LaunchIcon />
                </>
              )}
            </ListItem>
          </a>
        </List>
      </Box>
      {open && (
        <>
          <Divider className={classes.divider} />
          <Box mb={3}>
            <Box width="100px" margin="auto">
              <WRLogo />
            </Box>
            <Box fontSize="14px" color="#C4C4C4" mb={2} mt={1}>
              Partners who use WaveRez for their online booking software receive
              more bookings, increased exposure, and reduced commission!
            </Box>
            <Box>
              <a
                href="https://www.waverez.com"
                rel="nofollow"
                target="_blank"
                className={classes.wrButton}>
                Learn More
              </a>
            </Box>
          </Box>
        </>
      )}
    </Box>
  );
};

interface IMainSidebarNavigationProps {
  open: boolean;
  setOpen: Dispatch<SetStateAction<boolean>>;
}
