import React, { useState, useEffect } from "react";
import { Box, Button, Typography } from "@material-ui/core";
import { useStyles } from "./styles";
import { onboardingData, setData } from "src/redux/Onboarding/sliceOnboarding";
import { useSelector, useDispatch } from "react-redux";
import { IOnboardingFields } from "src/redux/Onboarding/interfaces";
import { isDisabled } from "./AccountInputsDisable";
import { useLocations } from "src/web/hooks/useLocations";
import { FormElement } from "../../OnboardingForm/FormElement";
import { validateField } from "../../OnboardingForm/validate";
import {
  IInterviewBlock,
  IOnboardingForm,
  IOnboardingScreen,
} from "../../OnboardingForm/interface";
import { useLazyQuery } from "@apollo/client";
import { GET_FORM } from "src/api/onboardingForms";
import { Spiner } from "../../Loaders";

export const AccountSetUp = ({
  handleSubmit,
  handleBack,
  formId,
}: {
  handleSubmit: () => void;
  handleBack: () => void;
  formId: string;
}) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const [step, setStep] = useState(0);
  const [isNextWasClicked, setIsNextClicked] = useState(false);
  const [errorMessage, setErrorMessage] = useState<
    Partial<Record<keyof IOnboardingFields, string>>
  >({});
  const [w9Form, setW9Form] = useState<IOnboardingForm | undefined>(undefined);
  const { receivedData, form: data } = useSelector(onboardingData);

  const [getForm, { loading }] = useLazyQuery(GET_FORM, {
    variables: {
      prm: {
        id: formId,
      },
    },
    onCompleted: (data) => {
      const dataScreens = data.v1_general_getForm.screens;
      const newForm = {
        ...data.v1_general_getForm,
        screens: JSON.parse(dataScreens),
      };
      setW9Form(newForm);
    },
    onError: (error) => {
      console.error(error);
    },
  });

  const locations = useLocations();

  const incrementStep = () => {
    setIsNextClicked(true);
    let isValid = true;

    currentFields.forEach((item) => {
      if (!isValid) {
        return;
      }

      isValid = validateField(
        setErrorMessage,
        currentContent,
        item.name as string,
        data[item.name as keyof IOnboardingFields]
      );

    });

    if (!isValid) {
      return;
    }

    if (step === currentScreens.length - 1) {
      handleSubmit();
      return;
    }

    setStep(step + 1);
  };

  const decrementStep = () => {
    setStep(step - 1);

    if (step === 0) {
      handleBack();
    } else {
      setStep(step - 1);
    }
  };

  useEffect(() => {
    if (formId) {
      getForm();
    }
  }, [formId]);

  useEffect(() => {
    if (data.barcode_type) {
      dispatch(
        setData({
          use_barcodes: "Yes",
        })
      );
    }
  }, []);

  useEffect(() => {
    if (data.use_barcodes === "No") {
      dispatch(
        setData({
          barcode_type: 0,
        })
      );
    }
  }, [data.use_barcodes]);

  useEffect(() => {
    setIsNextClicked(false);
  }, [step]);

  useEffect(() => {
    const keyDownHandler = (event: any) => {
      if (event.key === "Enter") {
        incrementStep();
      }
    };

    document.addEventListener("keydown", keyDownHandler);

    return () => {
      document.removeEventListener("keydown", keyDownHandler);
    };
  }, [step, data, w9Form]);

  if (loading || !w9Form) {
    return <Spiner fixed />;
  }

  const currentScreens = w9Form.screens as IOnboardingScreen[];
  const currentContent = currentScreens[step].content as IInterviewBlock;
  const currentFields = currentContent.fields;

  const renderSteps = () => {
    let steps = [];
    for (let i = 0; i <= step; i++) {
      steps.push(
        <div
          key={i}
          className={classes.step}
          style={{ background: "#0DD1C5" }}
        ></div>
      );
    }

    for (let k = 0; k <= 4 - step; k++) {
      steps.push(<div key={`${k}${step}`} className={classes.step}></div>);
    }

    return steps;
  };

  const handleChange = (field: string, value: string) => {
    if (isNextWasClicked) {
      validateField(setErrorMessage, currentContent, field, value);
    }
    dispatch(setData({ [field]: value }));
  };

  const handleAccept = (name: keyof IOnboardingFields) => {
    dispatch(
      setData({
        [name]: !data[name],
      })
    );
  };

  const handleChangeGoogle = (updateData: any) => {
    dispatch(setData({ ...updateData }));
  };

  return (
    <div>
      <Box className={classes.stepsWrapper}>
        {step + 1}/6
        <Box className={classes.stepsBox}>{renderSteps()}</Box>
      </Box>

      <Box className={classes.contentWrapper}>
        <Typography className={classes.groupTitle}>
          {currentScreens[step].title}
        </Typography>
        <div>
          <FormElement
            form={w9Form}
            formInput={currentScreens[step]}
            errorMessage={errorMessage}
            handleChange={handleChange}
            data={data}
            receivedData={receivedData}
            handleAccept={handleAccept}
            locations={locations}
            handleChangeGoogle={handleChangeGoogle}
          />
        </div>
      </Box>

      <Box className={classes.buttonsContainer}>
        <Button
          onClick={decrementStep}
          variant="text"
          color="primary"
          type="submit"
          className={classes.cancelButton}
          size="large"
        >
          Back
        </Button>

        <Button
          onClick={incrementStep}
          variant="contained"
          color="primary"
          type="submit"
          size="large"
          className={classes.saveButton}
          style={{ width: "140px" }}
          disabled={isDisabled(step, currentFields, data)}
        >
          Next
        </Button>
      </Box>
    </div>
  );
};
