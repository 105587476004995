import React, { useState } from "react";
import {
  Box,
  InputAdornment,
  TextField,
  Typography,
  useMediaQuery,
} from "@material-ui/core";
import {
  ActionButton,
  PageContentHeader,
} from "src/web/components/PageContentHeader";
import EditIcon from "src/assets/icons/web/edit.svg";
import { GetPhoneFlagIcon } from "src/web/helpers/GetPhoneFlagIcon";
import { useStyles } from "./styles";
import { EditCompanyModal } from "./EditCompanyModal";
import { isUserHasPermissions } from "src/helpers/permissions";
import { useSelector } from "react-redux";
import { currentUser } from "src/redux/Authentication/sliceAuthentication";
import { IPartner } from "src/web/pages/Settings";

export const CompanyContent = ({
  data,
  setData,
}: {
  data: IPartner;
  setData: React.Dispatch<React.SetStateAction<IPartner | null>>;
}) => {
  const classes = useStyles();
  const user = useSelector(currentUser);
  const [open, setOpen] = useState(false);

  const mdMatches = useMediaQuery("(max-width: 1020px)");

  const isUserCanEdit = isUserHasPermissions(user, ["company_edit"]);

  const buttons: ActionButton[] = isUserCanEdit
    ? [
        {
          icon: <EditIcon />,
          name: "Edit",
          onClick: () => setOpen(true),
        },
      ]
    : [];

  return (
    <Box pl="30px" pr="30px">
      <PageContentHeader title="General information" buttons={buttons} />

      <EditCompanyModal
        open={open}
        setOpen={setOpen}
        data={data}
        setData={setData}
      />

      <Box className={classes.contentWrapper}>
        <Box
          display="flex"
          flexDirection={mdMatches ? "column" : "row"}
          width={1}
          mb={mdMatches ? "0px" : "30px"}
        >
          <Box width={mdMatches ? 1 : 3 / 10} mb={mdMatches ? "20px" : "0px"}>
            <TextField
              className={classes.input}
              disabled
              label="Phone number"
              value={data.phone}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    {GetPhoneFlagIcon(data.phone)}
                  </InputAdornment>
                ),
              }}
            />
          </Box>
          <Box width={mdMatches ? 1 : 2 / 5} mb={mdMatches ? "20px" : "0px"}>
            <TextField
              className={classes.input}
              disabled
              label="Address"
              value={data.address}
            />
          </Box>
          <Box width={mdMatches ? 1 : 3 / 10} mb={mdMatches ? "20px" : "0px"}>
            <TextField
              className={classes.input}
              disabled
              label="Email"
              value={data.email}
            />
          </Box>
        </Box>

        <Box
          display="flex"
          flexDirection={mdMatches ? "column" : "row"}
          width={1}
          mb={mdMatches ? "0px" : "30px"}
        >
          <Box width={mdMatches ? 1 : 3 / 10} mb={mdMatches ? "20px" : "0px"}>
            <TextField
              className={classes.input}
              disabled
              label="Comission"
              value={data.comission}
            />
          </Box>
          <Box width={mdMatches ? 1 : 7 / 10} mb={mdMatches ? "20px" : "0px"}>
            <TextField
              className={classes.input}
              disabled
              label="Title"
              value={data.title}
            />
          </Box>
        </Box>

        <Box
          display="flex"
          flexDirection="row"
          width={1}
          mb={mdMatches ? "0px" : "30px"}
        >
          <Box className={classes.about} width={1}>
            <Typography className={classes.aboutLabel}>About us</Typography>
            <Typography className={classes.aboutValue}>
              {data.about_us}
            </Typography>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};
