import React, { useState, useRef, RefObject } from 'react';
import { Box, CircularProgress, TextField } from '@material-ui/core';
import FilterAutocomplete from 'src/assets/icons/light/filterAutocomplete.svg';
import PerfectScrollbar from 'react-perfect-scrollbar';
import CloseIcon from 'src/assets/icons/light/close.svg';
import { useStyles } from './styles';
import useOnClickOutside from 'src/web/hooks/useOnClickOutside';

type Props = {
  label: string;
  list: { id: string | number; name: string; state_code?: string }[];
  onChange: (event: any) => void;
  loading?: boolean;
  placeholder: string;
  value?: { name: string };
  deletable?: boolean;
  onFocus?: React.FocusEventHandler<HTMLInputElement | HTMLTextAreaElement>;
  position: string;
};

export const CustomSelect = (props: Props) => {
  const classes = useStyles();
  const {
    label,
    list,
    onChange,
    loading,
    placeholder,
    value,
    deletable,
    onFocus,
    position,
  } = props;

  const [open, setOpen] = useState<boolean>(false);
  const ref: RefObject<HTMLDivElement> = useRef(null);

  useOnClickOutside(ref, () => setOpen(false));

  return (
    <Box position="relative">
      {open && !loading && (
        <div
          ref={ref}
          className={classes.listWrapper}
          style={
            position === 'top'
              ? { top: '55px', paddingBottom: '25px' }
              : { bottom: '55px', paddingTop: '25px' }
          }>
          <PerfectScrollbar>
            <Box className={classes.listContent}>
              {list.map((item: any) => {
                return (
                  <Box
                    onClick={(e) => {
                      onChange(e);
                      setOpen(false);
                    }}
                    key={item.id}
                    className={classes.listItem}>
                    <Box>{item.name}</Box>
                    <span className={classes.listSubitem}>
                      {item.state_code || ''}
                    </span>
                  </Box>
                );
              })}
            </Box>
          </PerfectScrollbar>
        </div>
      )}
      <Box className={classes.inputWrapper} onClick={() => setOpen(true)}>
        <TextField
          fullWidth
          InputProps={{
            startAdornment: <></>,
            endAdornment: (
              <>
                {!list.length ? (
                  <CircularProgress
                    color="inherit"
                    size={15}
                    style={{ marginRight: '8px' }}
                  />
                ) : null}
                {deletable && value ? (
                  <Box
                    style={{ cursor: 'pointer' }}
                    onClick={(e) => {
                      e.preventDefault();
                      onChange(undefined);
                    }}
                    mr={1}>
                    <CloseIcon color={'#C4C4C4'} />
                  </Box>
                ) : null}
                <Box className={classes.icon}>
                  <FilterAutocomplete style={{ marginRight: '10px' }} />
                </Box>
              </>
            ),
          }}
          onFocus={onFocus}
          placeholder={placeholder}
          variant="outlined"
          className={classes.label}
          label={label}
          value={value?.name || ''}
        />
      </Box>
    </Box>
  );
};
