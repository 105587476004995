import { Box, Typography, Divider, IconButton } from '@material-ui/core';
import React from 'react';
import EditIcon from 'src/assets/icons/light/edit.svg';
import { useSelector } from 'react-redux';
import { arrivalData } from 'src/redux/Arrivals/sliceArrival';
import EditSmallIcon from 'src/assets/icons/editSmall.svg';
import CalendarIcon from 'src/assets/icons/calendar.svg';
import CalendarSmallIcon from 'src/assets/icons/calendarSmall.svg';
import { useStyles } from '../styles';
import { AdditionalOptions } from './AdditionalOptions';
import { Tickets } from './Tickets';
import moment from 'moment';
import { isUserHasPermissions } from 'src/helpers/permissions';
import { currentUser } from 'src/redux/Authentication/sliceAuthentication';

export const TripDetailCard = ({
  handleOpenModal,
}: {
  handleOpenModal: () => void;
}) => {
  const classes = useStyles();
  const user = useSelector(currentUser);
  const { arrivalData: arrival } = useSelector(arrivalData);

  const stateName = arrival.state_name?.replace('_', ' ');

  const isUserCanEdit = isUserHasPermissions(user, ['order_edit']);

  return (
    <>
      <Box className={classes.cardBox} marginTop="16px" width={1}>
        <Typography className={classes.cardTitle}>Trip details</Typography>
        <Divider className={classes.cardDivider} />
        <Box className={classes.tripBox}>
          <Box className={classes.infoBox}>
            <Box width={1}>
              <Box display={'flex'} justifyContent="space-between">
                <Box marginBottom={'16px'}>
                  <Typography className={classes.itemTitle}>
                    Confirmation
                  </Typography>
                  <Typography className={classes.itemContent}>
                    TRS-{arrival.id}
                  </Typography>
                </Box>
                <Box marginRight={'5px'}>
                  {isUserCanEdit && (
                    <>
                      <IconButton
                        onClick={() => handleOpenModal()}
                        style={{ padding: '0px' }}
                        className={classes.editIcon}>
                        <EditIcon />
                      </IconButton>
                      <IconButton
                        className={classes.editIconSmall}
                        onClick={() => handleOpenModal()}
                        style={{ padding: '3px' }}>
                        <EditSmallIcon />
                      </IconButton>
                    </>
                  )}
                </Box>
              </Box>
              <Box marginBottom={'16px'}>
                <Typography className={classes.itemTitle}>Trip</Typography>
                <Typography className={classes.itemContent}>
                  {arrival.data.product.name}
                </Typography>
              </Box>
              <Box display={'flex'} flexWrap="wrap">
                <Box width={1} className={classes.tripCards}>
                  <Box className={classes.cardInfo} marginBottom="24px">
                    <Typography className={classes.itemTitle}>
                      Arrival Date
                    </Typography>
                    <Typography
                      className={classes.itemContent}
                      style={{
                        display: 'flex',
                        justifyContent: 'space-between',
                        alignItems: 'center',
                      }}>
                      {arrival.date
                        ? moment(arrival.date).format('MMM DD, YYYY')
                        : 'N/A'}
                      <span className={classes.editIcon}>
                        <CalendarIcon />
                      </span>
                      <span className={classes.editIconSmall}>
                        <CalendarSmallIcon />
                      </span>
                    </Typography>
                  </Box>
                  <Box className={classes.cardInfo} marginBottom="24px">
                    <Typography className={classes.itemTitle}>
                      Schedule time
                    </Typography>

                    <Typography className={classes.itemContent}>
                      {arrival.data?.schedule?.time
                        ? moment
                            .utc(
                              arrival.data?.schedule?.time?.split('.')[0],
                              'HH:mm:ss'
                            )
                            .format('h:mma')
                        : 'N/A'}
                    </Typography>
                  </Box>
                </Box>
                <Box className={classes.cardInfo}>
                  <Typography className={classes.itemTitle}>Status</Typography>
                  <Typography className={classes.itemContent}>
                    {stateName?.charAt(0).toUpperCase() + stateName?.slice(1)}
                  </Typography>
                </Box>
              </Box>
            </Box>
          </Box>
          <Box className={classes.box} width={'49%'}>
            <Box marginBottom={'10px'}>
              <Tickets />
            </Box>
            <AdditionalOptions />
          </Box>
        </Box>
      </Box>
    </>
  );
};
