import { makeStyles } from "@material-ui/core/styles";

export const useStyles = makeStyles(() => ({
  root: {
    width: "100%",
    height: "100%",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  wrapper: {
    boxShadow: "0px 3.89312px 23.3587px rgb(96 96 97 / 18%)",
    borderRadius: "15px",
    backgroundColor: "white",
    margin: "auto",
    height: "35%",
    width: "40%",
    padding: "40px",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "space-between",
    "@media (max-width: 800px)": {
      width: "60%",
    },
    "@media (max-width: 475px)": {
      width: "80%",
    },
  },
  errorText: {
    color: "#333333",
    fontSize: "16px",
    fontWeight: 500,
  },
  tryAgainButton: {
    color: "#FFFFFF",
    height: "35px",
    padding: "0 42.5px",
    fontSize: "16px",
    fontWeigth: 500,
    background: "linear-gradient(90deg, #03D9B1 0%, #1CBBD9 100%)",
    borderRadius: "50px",
    textTransform: "none",
    boxShadow:
      "0px 13px 28px rgba(29, 203, 192, 0.2), 0px 8px 16px rgba(29, 203, 192, 0.06)",
  },
}));
