import { makeStyles } from "@material-ui/core/styles";

export const useStyles = makeStyles(() => ({
  title: {
    fontWeight: 500,
    fontSize: "20px",
    color: "#333333",
    fontFamily: "Montserrat",
    "@media (max-width: 620px)": {
      fontWeight: 500,
      fontSize: "14px",
    },
  },
  description: {
    fontWeight: 400,
    fontSize: "12px",
    color: "#6C6B6B",
    fontFamily: "Montserrat",
    "@media (max-width: 620px)": {
      fontSize: "10px",
    },
  },
  saveButton: {
    textTransform: "none",
    color: "white",
    fontSize: "16px",
    background: "linear-gradient(90deg, #03D9B1 0%, #1CBBD9 100%)",
    borderRadius: "50px",
    boxShadow:
      "0px 13px 28px rgba(29, 203, 192, 0.2), 0px 8px 16px rgba(29, 203, 192, 0.06)",
    width: "310px",
    padding: "6.5px 50px",
    fontFamily: `"Montserrat", "Helvetica", "Arial", sans-serif`,
    fontWeight: 500,
    "&:hover": {
      background: "linear-gradient(90deg, #06C9A4 0%, #00A2C1 100%)",
      color: "#FFFFFF",
      boxShadow:
        "0px 13px 28px rgba(29, 203, 192, 0.2), 0px 8px 16px rgba(29, 203, 192, 0.06)",
    },
    "&.Mui-disabled": {
      background: "linear-gradient(270deg, #ACB9BA 0%, #D1DDDD 108.89%)",
      color: "#FFFFFF",
    },
    "@media (max-width: 620px)": {
      fontSize: "14px",
    },
  },
  dekstop: {
    display: "flex",
    justifyContent: "center",
    "@media (max-width: 620px)": {
      display: "none",
    },
  },
  adaptive: {
    display: "none",
    "@media (max-width: 620px)": {
      display: "flex",
      justifyContent: "center",
    },
  },

  buttonsContainer: {
    display: "flex",
    justifyContent: "center",
    marginTop: "70px",
    "@media (max-width: 620px)": {
      marginTop: "10px",
      marginX: "0px 8px",
    },
  },
  cancelButton: {
    textTransform: "none",
    marginRight: "27px",
    color: "#0DD1C5",
    fontFamily: `"Montserrat", "Helvetica", "Arial", sans-serif`,
    fontSize: "16px",
    padding: "6.5px 33px",
    borderRadius: "50px",
    border: "1px solid #0DD1C5",
    width: "140px",
    fontWeight: 500,
    "&:hover": {
      background: "white",
    },
    "@media (max-width: 620px)": {
      fontSize: "14px",
      padding: "7.5px 43px",
      marginRight: "10px",
    },
  },
  groupTitle: {
    color: "#333333",
    fontSize: "20px",
    textAlign: "center",
    fontWeight: 500,
    paddingBottom: "24px",
    "@media (max-width: 620px)": {
      fontSize: "16px",
      paddingBottom: "12px",
    },
  },
  stepsBox: {
    display: "flex",
    justifyContent: "center",
    marginTop: "16px",
    marginBottom: "40px",
    "@media (max-width: 620px)": {
      marginTop: "8px",
      marginBottom: "23px",
    },
  },
  step: {
    width: "26px",
    height: "2px",
    borderRadius: "10px",
    background: "#DDDDDD",
    opacity: 0.5,
    margin: "4px",
    "@media (max-width: 620px)": {
      width: "16px",
      height: "1.5px",
    },
  },
  stepsWrapper: {
    display: "flex",
    justifyContent: "center",
    flexDirection: "column",
    alignItems: "center",
    color: "#6C6B6B",
    fontSize: "12px",
    "@media (max-width: 620px)": {
      fontSize: "10px",
    },
  },
  contentWrapper: {
    height: "285px",
    "@media (max-width: 620px)": {
      padding: "0 12px",
      height: "285px",
    },
  },
  addEmailButton: {
    background: "#F9FCFD",
    borderRadius: "50px",
    color: "#C4C4C4",
    marginTop: "20px",
    fontWeight: 400,
    fontSize: "14px",
    padding: "9px 25px",
    textTransform: "none",
    "@media (max-width: 620px)": {
      fontSize: "12px",
      marginTop: "0px",
    },
  },
  completeWrapper: {
    marginTop: "50px",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
  },
}));
