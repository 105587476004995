import React from 'react';
import { Box } from '@material-ui/core';
import { useStyles } from './styles';
import CalendarIcon from 'src/assets/icons/web/sidebar/calendar.svg';
import { CalendarFilters } from 'src/web/components/CalendarManagement/CalendarFilters';
import { PageCardsLayout } from 'src/web/layouts/PageCardsLayout';
import { InnerPageHeader } from 'src/web/components/InnerPagesHeader';

const CalendarManagement = () => {
  const classes = useStyles();

  return (
    <PageCardsLayout
      header={
        <InnerPageHeader
          components={
            <Box className={classes.headerTitle}>
              <CalendarIcon />
              Calendar Management
            </Box>
          }
        />
        // <TableHeader
        //   setOpenSidebar={() => undefined}
        //   headerElement={
        //     <Box className={classes.headerTitle}>
        //       <CalendarTodayRounded />
        //       Calendar Management
        //     </Box>
        //   }
        // />
      }
      content={
        <Box>
          <CalendarFilters />
        </Box>
      }
    />
  );
};

export default CalendarManagement;
