import {
  Box,
  Button,
  Divider,
  Typography,
  useMediaQuery,
} from '@material-ui/core';
import { CSVLink } from 'react-csv';
import React from 'react';
import PerfectScrollbar from 'react-perfect-scrollbar';
import { useStyles } from './styles';
import InvoiceTable from './InvoiceTable';
import DeductTable from './DeductTable';
import { customFormat } from 'src/helpers/DateFormat';

interface Props {
  invoice: IPayoutInvoice;
  csvInvoiceData: string;
}

const PayoutDetailItem = ({ invoice, csvInvoiceData }: Props) => {
  const classes = useStyles();

  const mdMatches = useMediaQuery('(max-width: 1020px)');

  return (
    <PerfectScrollbar>
      <Box className={classes.invoiceWrapper}>
        <Box className={classes.invoiceHeader}>
          <Box>
            <Typography variant="h1">Invoice</Typography>
            <Typography variant="caption">{invoice.title}</Typography>
          </Box>
          <Box>
            <CSVLink
              data={csvInvoiceData}
              separator={','}
              style={{ textDecoration: 'none' }}>
              <Button className={classes.downloadCSV}>Download as CSV</Button>
            </CSVLink>
          </Box>
        </Box>

        <Box className={classes.invoiceSubheader}>
          <Box className={classes.invoiceSubheaderDateWrapper}>
            <Typography variant="h3">Date:</Typography>
            <Typography variant="body1">
              {customFormat(invoice.date_after, 'MM/DD/YYYY')} -{' '}
              {customFormat(invoice.date_before, 'MM/DD/YYYY')}
            </Typography>
          </Box>
          <Box className={classes.invoiceSubheaderBillWrapper}>
            <Typography variant="h3">Bill to:</Typography>
            <Typography variant="body1">
              {invoice.data?.bill_to.join(' ')}
            </Typography>
          </Box>
        </Box>

        {invoice.data?.invoice_item && (
          <InvoiceTable invoice_item={invoice.data?.invoice_item} />
        )}

        <Box className={classes.feesBlock}>
          <Box className={classes.feesBlockFirst}>
            <Typography variant="body2">
              Less Deductions: {invoice.data?.deductions_total}
            </Typography>
            <Typography variant="body2">
              Cancellation Fees: {invoice.data?.partner_fee_total}
            </Typography>
            <Typography variant="body2">
              Total Amount Due: {invoice.data?.amount_due_total}
            </Typography>
          </Box>
          <Box className={classes.feesBlockSecond}>
            {invoice.data?.invoice_item_subtotals.map((itemSubtotal) => (
              <Typography variant="body2">
                {itemSubtotal.booked}×{itemSubtotal.ticket_name}:{' '}
                {itemSubtotal.net}
              </Typography>
            ))}

            {!mdMatches && (
              <>
                <Divider className={classes.feesBlockDivider} />
                <Typography variant="h4">
                  Net total: ${invoice.net_total}
                </Typography>
              </>
            )}
          </Box>
        </Box>
        {mdMatches && (
          <Typography variant="h4" className={classes.netTotalItem}>
            Net total: ${invoice.net_total}
          </Typography>
        )}

        {invoice.data?.deduct_item?.length && (
          <DeductTable
            deduct_item={invoice.data?.deduct_item}
            deductions_total={invoice.data?.deductions_total}
          />
        )}
      </Box>
    </PerfectScrollbar>
  );
};

export default PayoutDetailItem;
