import React, { useEffect, useState } from 'react';
import {
  Box,
  Button,
  IconButton,
  Paper,
  Popper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
  useMediaQuery,
} from '@material-ui/core';
import PerfectScrollbar from 'react-perfect-scrollbar';

import EditIcon from 'src/assets/icons/web/editGray.svg';
import DeleteIcon from 'src/assets/icons/web/delete.svg';
import PersonSettingsIcon from 'src/assets/icons/web/personSettings.svg';
import DotsBurgerVMenuIcon from 'src/assets/icons/light/dotsBurgerVMenu.svg';

import { DeleteUserAlertModal } from './DeleteUserAlertModal';
import { useStyles } from './styles';
import { ApolloQueryResult } from '@apollo/client';
import { Loader, Spiner } from '../../Loaders';
import { useInView } from 'react-intersection-observer';
import { useDispatch, useSelector } from 'react-redux';
import { isUserHasPermissions } from 'src/helpers/permissions';
import { currentUser } from 'src/redux/Authentication/sliceAuthentication';

type Props = {
  pager: string | null;
  isScrollLoading: boolean;
  isSpinner: boolean;
  loadMore: (pager: string | null) => void;
  setIsSpinner: React.Dispatch<React.SetStateAction<boolean>>;
  refetch: (
    variables?:
      | Partial<{
          prm: {
            partner_id: string | number;
          };
        }>
      | undefined
  ) => Promise<ApolloQueryResult<any>>;
  setOpenDetail: (isOpen: boolean) => void;
  employees: IEmployee[];
  choosenUser: string | null;
  setChoosenUser: React.Dispatch<React.SetStateAction<string | null>>;
};

export const UsersContent = ({
  isScrollLoading,
  pager,
  isSpinner,
  loadMore,
  setIsSpinner,
  refetch,
  setOpenDetail,
  employees,
  choosenUser,
  setChoosenUser,
}: Props) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const user = useSelector(currentUser);

  const [ref, inView] = useInView();
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);
  const [openDeleteAlert, setOpenDeleteAlert] = useState(false);
  const [openDropdownActions, setOpenDropdownActions] = useState(false);

  const mdMatches = useMediaQuery('(max-width: 1020px)');

  useEffect(() => {
    if (inView && !isScrollLoading && !isSpinner) {
      loadMore(pager);
    }
  }, [dispatch, inView, isScrollLoading, isSpinner, pager]);

  const handleClickActions = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
    setOpenDropdownActions(!openDropdownActions);
  };

  const handleEditClick = (employeeId: string) => {
    setChoosenUser(employeeId);
    setOpenDetail(true);
  };

  const handleDeleteClick = (employeeId: string) => {
    setChoosenUser(employeeId);
    setOpenDeleteAlert(true);
  };

  const isUserCanEdit = isUserHasPermissions(user, ['users_manage']);

  return (
    <>
      <DeleteUserAlertModal
        refetch={refetch}
        setIsSpinner={setIsSpinner}
        choosenUser={choosenUser}
        setChoosenUser={setChoosenUser}
        open={openDeleteAlert}
        setOpen={setOpenDeleteAlert}
      />

      <TableContainer style={{ height: 'calc(100vh - 134px)' }}>
        {isSpinner && <Spiner />}
        <PerfectScrollbar>
          <Table stickyHeader className={classes.table}>
            <TableHead>
              <TableRow>
                <TableCell>Person</TableCell>
                {mdMatches ? (
                  <TableCell>Contacts</TableCell>
                ) : (
                  <>
                    <TableCell>Email</TableCell>
                    <TableCell>Phone</TableCell>
                  </>
                )}
                {isUserCanEdit && <TableCell></TableCell>}
              </TableRow>
            </TableHead>
            <TableBody>
              {employees.map((employee) => (
                <TableRow key={employee.id}>
                  <TableCell>
                    <Box className={classes.userCellWrapper}>
                      <Box className={classes.userAvatar}>
                        <PersonSettingsIcon />
                      </Box>
                      <Typography className={classes.userName}>
                        {employee.first_name} {employee.last_name}
                      </Typography>
                    </Box>
                  </TableCell>
                  {mdMatches ? (
                    <TableCell>
                      {employee.email}
                      <br />
                      +${employee.phone}
                    </TableCell>
                  ) : (
                    <>
                      <TableCell>{employee.email}</TableCell>
                      <TableCell>
                        <Box className={classes.phoneCellWrapper}>
                          +{employee.phone}
                        </Box>
                      </TableCell>
                    </>
                  )}
                  {isUserCanEdit && (
                    <TableCell
                      style={{
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'end',
                      }}>
                      {/* <Box className={classes.actions}>
                        {mdMatches ? (
                          <>
                            <IconButton onClick={handleClickActions}>
                              <DotsBurgerVMenuIcon />
                            </IconButton>
                            <Popper
                              open={openDropdownActions}
                              anchorEl={anchorEl}
                              placement="bottom-start">
                              <Paper className={classes.actionsPaper}>
                                <Button
                                  className={classes.actionButton}
                                  onClick={() => handleEditClick(employee.id)}>
                                  <EditIcon />
                                  Edit
                                </Button>
                                <Button
                                  onClick={() => setOpenDeleteAlert(true)}
                                  className={classes.actionButton}
                                  style={{ color: '#EC4652' }}>
                                  <DeleteIcon />
                                  Delete
                                </Button>
                              </Paper>
                            </Popper>
                          </>
                        ) : (
                          <>
                            <Box mr="30px">
                              <IconButton
                                onClick={() => handleEditClick(employee.id)}>
                                <EditIcon />
                              </IconButton>
                            </Box>
                            <Box>
                              <IconButton
                                onClick={() => handleDeleteClick(employee.id)}>
                                <DeleteIcon />
                              </IconButton>
                            </Box>
                          </>
                        )}
                      </Box> */}
                    </TableCell>
                  )}
                </TableRow>
              ))}
              {!isScrollLoading && !!pager && (
                <tr className={classes.customScroll} ref={ref}></tr>
              )}
            </TableBody>
          </Table>
          {isScrollLoading && <Loader />}
        </PerfectScrollbar>
      </TableContainer>
    </>
  );
};
