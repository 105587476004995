export const parseNumberInputValue = (
  value: string | number | undefined,
  float: boolean = false
) => {
  if (float) {
    return parseFloat(`${value}`);
  }

  return `${value}`.replace(/^0+/, ''); 
};
