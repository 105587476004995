import { gql } from "@apollo/client";

export const SUBSCIBE_TO_NOTIFICATIONS = gql`
  mutation v1_partners_createNotificationsEndpoint(
    $prm: createNotificationsEndpointPrm!
  ) {
    v1_partners_createNotificationsEndpoint(prm: $prm) {
      endpointArn
      subscriptionArn
    }
  }
`;

export const GET_NOTIFICATIONS = gql`
  query v1_partners_getNotifications($prm: v1_partners_getNotificationsPrm!) {
    v1_partners_getNotifications(prm: $prm) {
      notifications {
        id
        title
        message
        object_id
        object_type
        isReaded
        createdAt
      }
      pager
    }
  }
`;

export const UPDATE_NOTIFICATIONS = gql`
  mutation v1_partners_updateNotifications(
    $prm: updateNotificationsPrm!
  ) {
    v1_partners_updateNotifications(prm: $prm) {
      success
      requestId
    }
  }
`;

export const DELETE_NOTIFICATIONS = gql`
  mutation v1_partners_deleteNotifications(
    $prm: deleteNotificationsPrm!
  ) {
    v1_partners_deleteNotifications(prm: $prm) {
      success
    }
  }
`;

export const REQUEST_INFO = gql`
  mutation v1_mrs_request($prm: v1_affiliates_request_prm!) {
    v1_mrs_request(prm: $prm) {
      completed
      errors
    }
  }
`;
