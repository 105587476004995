import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "src/redux/store";

export interface INotification {
  type?: "error" | "info" | "success" | "warning";
  position?: "interior" | "external";
  duaration?: number;
  key: number;
  message: string;
}

export interface ICreateNotificationProps {
  type?: "error" | "info" | "success" | "warning";
  position?: "interior" | "external";
  duaration?: number;
  message: string;
}

interface notificationState {
  notifications: INotification[] | [];
  notificationCount: number;
}

const initialState: notificationState = {
  notifications: [],
  notificationCount: 0,
};

const createNotification = ({
  type = "error",
  message,
  position = "interior",
  duaration = 5000,
}: ICreateNotificationProps): INotification => {
  return {
    type,
    position,
    duaration,
    message,
    key: new Date().getTime() + Math.random(),
  };
};

export const notifierSlice = createSlice({
  name: "notifications",
  initialState,
  reducers: {
    setNotification: (
      state,
      action: PayloadAction<ICreateNotificationProps>
    ) => {
      state.notifications = [
        createNotification(action.payload),
        ...state.notifications,
      ];
    },
    removeNotification: (state, action: PayloadAction<number>) => {
      state.notifications = state.notifications.filter((notice) => {
        return notice.key !== action.payload;
      });
    },
    setNotificationCount: (state, action: PayloadAction<number>) => {
      state.notificationCount = action.payload;
    },
    setNotificationCountAfterChanges: (state) => {
      state.notificationCount = state.notificationCount - 1;
      if (state.notificationCount < 0) {
        state.notificationCount = 0;
      }
    },
  },
});

export const {
  setNotification,
  removeNotification,
  setNotificationCount,
  setNotificationCountAfterChanges,
} = notifierSlice.actions;

export const notifications = (state: RootState) =>
  state.notifications.notifications;

export const notificationsCount = (state: RootState) =>
  state.notifications.notificationCount;

export default notifierSlice.reducer;
