import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import {
  Typography,
  Box,
  Button,
  FormControlLabel,
  Checkbox,
  Popper,
} from '@material-ui/core';
import { auth } from 'src/redux/Authentication/sliceAuthentication';
import { ReviewsDateFilter } from 'src/web/components/Reviews/pageHeader/DateFilter';
import CheckboxIcon from 'src/assets/icons/web/checkbox.svg';
import CheckboxCheckedIcon from 'src/assets/icons/web/checkboxChecked.svg';
import FiltersMoblieIcon from 'src/assets/icons/moreFiltersMobile.svg';
import ReviewsIcon from 'src/assets/icons/light/components/reviews.svg';
import { useStyles } from './styles';
import { CustomAutocomplete } from 'src/web/components/UI/CustomAutocomplete';
import { IReviewsPrm } from 'src/web/pages/Reviews/normalizePrm';

interface ISkip {
  usePartnersTripsAutocomplete: boolean;
}

interface Props {
  onChangeFilter: (filter: Partial<IReviewsPrm>) => void;
  prm: IReviewsPrm;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
  productsData: {
    id: number;
    name: string;
    city_id: number;
  }[];
  partnerProductsLoading: boolean;
}

const PageHeader = ({
  onChangeFilter,
  prm,
  setOpen,
  productsData,
  partnerProductsLoading,
}: Props) => {
  const classes = useStyles();
  const { user } = useSelector(auth);

  const [skip, setSkip] = useState<ISkip>({
    usePartnersTripsAutocomplete: true,
  });

  const skipHandlerChange = (key: keyof ISkip) => {
    setSkip((prev) => ({ ...prev, [key]: false }));
  };

  const onChangeDates = ({
    start,
    end,
  }: {
    start: string | null;
    end: string | null;
  }) => {
    onChangeFilter({
      date_from: start || '',
      date_to: end || '',
    });
  };

  const CustomPopper = function (props: any) {
    const classes = useStyles();
    return <Popper {...props} className={classes.popper} placement="top" />;
  };

  return (
    <>
      <Box className={classes.header} width={1}>
        <Box display="flex" mr={6}>
          <ReviewsIcon />
          <Typography className={classes.title}>Reviews</Typography>
        </Box>

        <Box className={classes.desktopFilters}>
          <Box flex={1}>
            <CustomAutocomplete
              label={'Product'}
              placeholder={'Choose product'}
              options={productsData}
              className={classes.filterArea}
              value={
                productsData.find(
                  (i: any) => i?.id === +(prm.activity_id || 0)
                ) || null
              }
              onFocus={
                skip.usePartnersTripsAutocomplete
                  ? () => skipHandlerChange('usePartnersTripsAutocomplete')
                  : undefined
              }
              onChange={(data: any) =>
                onChangeFilter({
                  activity_id: data ? data.id : 0,
                })
              }
              loading={partnerProductsLoading}
              deletable={true}
              CustomPopper={CustomPopper}
              renderOption={(option: any) => {
                return (
                  <Box width={1} className={classes.autocompleteItem}>
                    <Box>{option.name}</Box>
                  </Box>
                );
              }}
            />
          </Box>

          <Box width="200px" mx={4}>
            <ReviewsDateFilter
              label="Dates"
              startDate={prm.date_from}
              endDate={prm.date_to}
              onChange={onChangeDates}
              width="100%"
            />
          </Box>
          <FormControlLabel
            className={classes.checkbox}
            control={
              <Checkbox
                checked={!prm.show_replied}
                onChange={() =>
                  onChangeFilter({ show_replied: !prm.show_replied })
                }
                icon={<CheckboxIcon />}
                color="default"
                checkedIcon={<CheckboxCheckedIcon />}
              />
            }
            label="Show reviews without reply"
          />
        </Box>
        <Box className={classes.adaptive_1}>
          <Button
            style={{ padding: '0', minWidth: '28px' }}
            variant="text"
            onClick={() => setOpen((previousOpen: boolean) => !previousOpen)}>
            <FiltersMoblieIcon />
          </Button>
        </Box>
      </Box>
    </>
  );
};

export default PageHeader;
