import { gql } from "@apollo/client";

export const CREATE_PARTNER = gql`
  mutation v1_partners_createTempPartner($prm: createTempPartnerPrm!) {
    v1_partners_createTempPartner(prm: $prm) {
      success
      id
    }
  }
`;

export const UPDATE_PARTNER = gql`
  mutation v1_partners_updateTempPartner($prm: updateTempPartnerPrm!) {
    v1_partners_updateTempPartner(prm: $prm) {
      success
    }
  }
`;

export const UPDATE_PARTNER_POLICY = gql`
  mutation v1_partners_acceptPolicy($prm: acceptTempPartnerPolicyPrm!) {
    v1_partners_acceptPolicy(prm: $prm) {
      success
    }
  }
`;

export const GET_TEMP_PARTNER = gql`
  query v1_partners_getTempPartner($prm: v1_partners_getTempPartnerPrm!) {
    v1_partners_getTempPartner(prm: $prm) {
      id
      termsAndConditions
      first_name
      last_name
      name
      email
      phone
      title
      city
      state
      commission
      w9
      fein_snn
      admin_only_email
      city_id
      country_id
      state_id
      address
      zip
      phone2
      phone_client
      pli_exp
      google_title
      barcode_type
    }
  }
`;
