import React, { useState } from 'react';
import { useQuery } from '@apollo/client';
import { useSelector } from 'react-redux';
import { Box } from '@material-ui/core';

import { GET_PARTNERS_DASHBOARD } from 'src/api/dashboard';

import { DashboardLayout } from 'src/web/layouts/Dashboard';
import { auth } from 'src/redux/Authentication/sliceAuthentication';
import { DashboardCard } from 'src/web/components/Dashboard/DashboardCard';
import { DashboardStatistics } from 'src/web/components/Dashboard/DashboardStatistics';
import { DashboardTotalCard } from 'src/web/components/Dashboard/DashboardTotalCard';

import TodayIcon from 'src/assets/icons/light/time.svg';
import YesterdayIcon from 'src/assets/icons/light/timeBack.svg';
import DashboardIcon from 'src/assets/sideBarIcons/dashboard.svg';
import LastMonthIcon from 'src/assets/icons/light/calendarBack.svg';
import ThisMonthIcon from 'src/assets/icons/light/calendarSuccess.svg';

import { useStyles } from './styles';
import { Spiner } from 'src/web/components/Loaders';
import { DashboardTable } from 'src/web/components/Dashboard/DashboardTable';
import { DashboardPieChart } from 'src/web/components/Dashboard/DashboardPieChart';
import { getTripColor } from 'src/helpers/getTripColor';
import { QueryError } from 'src/web/components/UI/QueryError/QueryError';

export type DateSelector = 'today' | 'yesterday' | 'month' | 'previosMonth';

const Dashboard = () => {
  const classes = useStyles();
  const { user } = useSelector(auth);

  const [selectedDate, selectDate] = useState<DateSelector>('today');
  const [dashboardData, setDashboardData] = useState<IDashboardData>();

  const { loading, error, refetch } = useQuery(GET_PARTNERS_DASHBOARD, {
    variables: {
      prm: {
        id: +user['custom:partner_id'],
      },
    },
    notifyOnNetworkStatusChange: true,
    onCompleted: (data) => {
      const parsedData = data.v1_partners_dashboard;
      setDashboardData(parsedData);
    },
    onError: (error) => {
      console.error(error);
    },
  });

  if (!dashboardData || loading) {
    if (error) {
      return <QueryError refetch={() => refetch()} />;
    }

    return <Spiner />;
  }

  const trips = dashboardData?.[selectedDate]
    ? JSON.parse(dashboardData[selectedDate].trips as string)
    : [];

  const currentTrips = Object.keys(trips).reduce<IDashboardTripExtended[]>(
    (acc, id) => {
      if (!dashboardData) {
        return [...acc];
      }

      const chartColor = getTripColor(id);

      return [
        ...acc,
        {
          id,
          ...trips[id],
          color: chartColor,
          percent:
            Math.round(
              (trips[id].total * 100) / dashboardData[selectedDate].total
            ) || 0,
        },
      ];
    },
    []
  );

  return (
    <DashboardLayout
      header={
        <Box display="flex" flexDirection="row" className={classes.headerTitle}>
          <DashboardIcon style={{ marginRight: '5px' }} fill="white" />
          Dashboard
        </Box>
      }
      content={
        <Box display="flex" flexDirection="row">
          <Box className={classes.leftColumn}>
            <DashboardStatistics data={dashboardData.items} />
            <DashboardTable trips={currentTrips} />
          </Box>

          <Box className={classes.rightColumn} marginLeft="10px">
            <Box
              height={250}
              display="flex"
              flexDirection="column"
              alignItems="space-between">
              <Box
                height={2 / 3}
                display="flex"
                flexWrap="wrap"
                flexDirection="row"
                justifyContent="flex-end">
                <DashboardCard
                  title="Today"
                  icon={() => <TodayIcon />}
                  amount={dashboardData.today?.total}
                />
                <DashboardCard
                  title="Yesterday"
                  icon={() => <YesterdayIcon />}
                  amount={dashboardData.yesterday?.total}
                />
                <DashboardCard
                  title="This month"
                  icon={() => <ThisMonthIcon />}
                  amount={dashboardData.month?.total}
                />
                <DashboardCard
                  title="Last month"
                  icon={() => <LastMonthIcon />}
                  amount={dashboardData.previosMonth?.total}
                />
              </Box>

              <Box
                height={1 / 3}
                display="flex"
                justifyContent="flex-end"
                alignItems="flex-end">
                <DashboardTotalCard amount={dashboardData.total?.total} />
              </Box>
            </Box>

            <Box display="flex" justifyContent="flex-end">
              <DashboardPieChart
                trips={currentTrips}
                totalAmount={dashboardData[selectedDate].total}
                totalOrders={dashboardData[selectedDate].count}
                selectedDate={selectedDate}
                selectDate={selectDate}
              />
            </Box>
          </Box>
        </Box>
      }></DashboardLayout>
  );
};

export default Dashboard;
