import React from 'react';
import { Box, Typography } from '@material-ui/core';
import { Error } from '@material-ui/icons/';
import SystemNavigation from 'src/web/components/SystemNavigation/index';
import { useStyles } from './styles';

export default function InternalServerPage() {
  const classes = useStyles();

  return (
    <>
      <Box bgcolor="error.main" className={classes.icon_box}>
        <Error className={classes.icon_box_icon} />
      </Box>
      <Box>
        <Typography variant="h3">500 Error</Typography>
      </Box>
      <Box>
        <Typography variant="subtitle1">
          We’re experiencing a problem. Don’t worry, the developers have already
          been notified. In the mean time please choose an option below.
        </Typography>
      </Box>
      <SystemNavigation />
    </>
  );
}
