import React from 'react';
import Loading from 'src/images/loading.svg';
import { useStyles } from '../styles';

export const Loader = () => {
  const classes = useStyles();
  return (
    <div className={classes.wrapperLoader}>
      <Loading style={{ marginLeft: '40px' }} />
    </div>
  );
};
