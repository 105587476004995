import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  selectLoadingUser,
  getCurrentUser,
} from 'src/redux/Authentication/sliceAuthentication';

import WebTemplate from 'src/web';

import 'react-datepicker/dist/react-datepicker.css';
import 'react-perfect-scrollbar/dist/css/styles.css';

const App = () => {
  const dispatch = useDispatch();
  const isLoadingUser = useSelector(selectLoadingUser);

  useEffect(() => {
    dispatch(getCurrentUser());
  }, [dispatch]);

  return <WebTemplate isLoadingUser={isLoadingUser} />;
};

export default App;
