import React, { useState, forwardRef } from "react";
import {
  TextField,
  TextFieldProps,
  CircularProgress,
  Paper,
  Box,
} from "@material-ui/core";
import { Autocomplete } from "@material-ui/lab";
import FilterAutocomplete from "src/assets/icons/light/filterAutocomplete.svg";
import CloseIcon from "src/assets/icons/light/close.svg";
import PerfectScrollbar from "react-perfect-scrollbar";

export interface ICustomAutocomplete {
  options: any;
  value: any;
  onChange: any;
  placeholder: string;
  label: string;
  disabled?: boolean;
  loading?: boolean;
  freeSolo?: boolean;
  error?: boolean;
  helperText?: string | boolean;
  onFocus?: React.FocusEventHandler<HTMLDivElement>;
  size?: any;
  styles?: any;
  className?: any;
  renderOption?: any;
  CustomPopper?: any;
  deletable?: boolean;
  getOptionLabel?: ((option: any) => string) | undefined;
}

export const CustomAutocomplete = ({
  options,
  value,
  label,
  onChange,
  placeholder,
  disabled,
  loading,
  freeSolo,
  error,
  onFocus,
  helperText,
  styles,
  size,
  renderOption,
  className,
  CustomPopper,
  deletable,
  getOptionLabel,
}: ICustomAutocomplete) => {
  const [open, setOpen] = useState<boolean>(false);

  const ListboxComponent = forwardRef<HTMLDivElement>(function ListboxComponent(
    props,
    ref
  ) {
    const { children, ...other } = props;
    const itemData = React.Children.toArray(children);

    return (
      <div ref={ref} style={{ height: "250px" }}>
        <PerfectScrollbar {...other}>{itemData}</PerfectScrollbar>
      </div>
    );
  });

  return (
    <Autocomplete
      open={open}
      onOpen={() => {
        setOpen(true);
      }}
      onClose={() => {
        setOpen(false);
      }}
      fullWidth
      PaperComponent={({ children }) => (
        <Paper
          style={
            loading
              ? { borderRadius: "15px", padding: "5px" }
              : {
                  borderRadius: "15px",
                  padding: "12.5px 19px 17px 15px",
                  height: "275px",
                }
          }
        >
          {children}
        </Paper>
      )}
      freeSolo={freeSolo}
      selectOnFocus
      className={className}
      autoSelect={freeSolo}
      disabled={disabled}
      handleHomeEndKeys
      options={options}
      getOptionSelected={(option, value) => {
        return option.id === value.id;
      }}
      getOptionLabel={
        getOptionLabel
          ? getOptionLabel
          : (option) => (option ? option.name || option.title || value : value)
      }
      PopperComponent={CustomPopper}
      value={value || null}
      onChange={(_, value) => {
        onChange(value);
      }}
      onFocus={onFocus}
      renderOption={renderOption}
      ListboxComponent={
        ListboxComponent as React.ComponentType<
          React.HTMLAttributes<HTMLElement>
        >
      }
      renderInput={(params: JSX.IntrinsicAttributes & TextFieldProps) => (
        <TextField
          {...params}
          label={label}
          error={error}
          helperText={helperText}
          variant="outlined"
          InputProps={{
            ...params.InputProps,
            startAdornment: <></>,
            endAdornment: (
              <>
                {loading ? (
                  <CircularProgress
                    color="inherit"
                    size={15}
                    style={{ marginRight: "8px" }}
                  />
                ) : null}
                {deletable && value ? (
                  <Box
                    style={{ cursor: "pointer" }}
                    onClick={() => onChange(undefined)}
                    mr={1}
                  >
                    <CloseIcon color={"#C4C4C4"} />
                  </Box>
                ) : null}

                <FilterAutocomplete />
              </>
            ),
            placeholder: placeholder,
            style: { ...styles },
          }}
          size={size ? size : "medium"}
        />
      )}
    />
  );
};
