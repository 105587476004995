import { gql } from '@apollo/client';

export const GET_PARTNER = gql`
  query v1_partners_get($prm: v1_partners_get_prm!) {
    v1_partners_get(prm: $prm) {
      id
      name
      email
      first_name
      last_name
      phone
      phone2
      phone_client
      title
      about_us
      admin_only_email
      address
      comission
      description
      pli_exp
    }
  }
`;

export const GET_PARTNER_SHORT = gql`
  query v1_partners_get($prm: v1_partners_get_prm!) {
    v1_partners_get(prm: $prm) {
      id
      slug
      is_rs_active
    }
  }
`;

export const UPDATE_PARTNER = gql`
  mutation v1_partners_update($prm: partnerUpdatePrm!) {
    v1_partners_update(prm: $prm) {
      id
      done
      message
    }
  }
`;

export const GET_PARTNER_PROFILE = gql`
  query v1_partners_profile($prm: indexInt!) {
    v1_partners_profile(prm: $prm) {
      about_us
      address
      admin_only_email
      comission
      description
      email
      fein_ssn
      first_name
      id
      last_name
      name
      phone
      phone_client
      pli_exp
      title
    }
  }
`;

export const GET_PARTNER_ORDERS = gql`
  query v1_partners_orders($prm: partner_orders_prm!) {
    v1_partners_orders(prm: $prm) {
      items {
        cart_item_id
        date
        first_name
        id
        last_name
        made
        net_total
        phone
        schedule_time
        state_name
        trip_name
        units
        product_id
      }
      offset
    }
  }
`;

export const GET_PARTNER_ORDER = gql`
  query v1_partners_order($prm: partner_order_prm!) {
    v1_partners_order(prm: $prm) {
      calendar_id
      cart_id
      cart_item_id
      data
      date
      first_name
      id
      last_name
      made
      phone
      product_id
      state_name
      tax
      total
    }
  }
`;

export const GET_PARTNERS_TRIPS_AUTOCOMPLETE = gql`
  query v1_general_trips($prm: prmForTripsAutocomplete) {
    v1_general_trips(prm: $prm) {
      id
      name
      city_id
      state_code
      city_name
    }
  }
`;

export const GET_PARTNERS_SCHEDULE = gql`
  query v1_partners_schedule($prm: partner_schedule_prm!) {
    v1_partners_schedule(prm: $prm) {
      id
      time
    }
  }
`;

export const GET_PARTNERS_AUTOCOMPLETE = gql`
  query v1_partners_autocomplete($prm: indexInt) {
    v1_partners_autocomplete(prm: $prm) {
      id
      title
    }
  }
`;
