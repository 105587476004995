import React from 'react';
import { useHistory } from 'react-router-dom';
import { useStyles } from './styles';
import { Popover, Box } from '@material-ui/core';

const Popup = ({ open, setOpen, anchorEl, setAnchorEl }: any) => {
  const classes = useStyles();

  const history = useHistory();

  const handleClose = () => {
    setOpen(false);
    setAnchorEl(null);
  };

  const handleRoute = (path: string) => {
    history.push(`/settings/${path}`);
    handleClose();
  };

  return (
    <Popover
      id="notifications"
      open={open}
      anchorEl={anchorEl}
      onClose={() => handleClose()}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'right',
      }}
      className={classes.popover}
      transformOrigin={{
        vertical: 'top',
        horizontal: 'right',
      }}
    >
      <Box className={classes.popupDialog}>
        <Box>
          <Box
            className={classes.navItem}
            onClick={() => handleRoute('profile')}
          >
            <Box
              display={'flex'}
              flexDirection="column"
              alignItems={'flex-start'}
            >
              <Box className={classes.popoverBox}>
                <Box className={classes.popoverName}>Profile</Box>
              </Box>
            </Box>
          </Box>
          <Box
            className={classes.navItem}
            onClick={() => handleRoute('company')}
          >
            <Box
              display={'flex'}
              flexDirection="column"
              alignItems={'flex-start'}
            >
              <Box className={classes.popoverBox}>
                <Box className={classes.popoverName}>Settings</Box>
              </Box>
            </Box>
          </Box>
        </Box>
      </Box>
    </Popover>
  );
};

export default Popup;
