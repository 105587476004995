import React, { Dispatch, SetStateAction } from 'react';
import { NavLink } from 'react-router-dom';
import { Box } from '@material-ui/core/';
import Logo from 'src/images/logo_web.svg';
import SidebarCloseIcon from 'src/assets/sideBarIcons/SideBarClose.svg';
import LogoAdaptive from 'src/images/logo_web_adaptive.svg';
import { useStyles } from './styles';

interface IMainSidebarHeaderProps {
  open: boolean;
  setOpen: Dispatch<SetStateAction<boolean>>;
}

export const MainSidebarHeader = ({
  open,
  setOpen,
}: IMainSidebarHeaderProps) => {
  const classes = useStyles();

  return (
    <>
      {open ? (
        <Box>
          <Box className={classes.MainSidebarHeaderOpen}>
            <NavLink to="/" className={classes.logo}>
              <Logo />
              <Box className={classes.beta}>beta</Box>
            </NavLink>
            <Box
              onClick={() => setOpen(false)}
              color="white"
              className={classes.MainSidebarHeaderToggler}>
              <SidebarCloseIcon />
            </Box>
          </Box>
        </Box>
      ) : (
        <Box>
          <Box className={classes.MainSidebarHeaderClose}>
            <NavLink
              to="/"
              className={classes.logo}
              style={{ marginBottom: '1px' }}>
              <LogoAdaptive />
            </NavLink>
          </Box>
        </Box>
      )}
    </>
  );
};
