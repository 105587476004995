import { gql } from '@apollo/client';

export const GET_ALL_LOCATIONS = gql`
  query v1_partners_places {
    v1_partners_places {
      countries {
        id
        name
      }
      states {
        id
        name
        country_id
      }
      cities {
        id
        name
        country_id
        state_id
      }
    }
  }
`;
