import React from "react";

import {
  Box,
  Card,
  Typography,
  FormControlLabel,
  Checkbox,
  CardContent,
  TextField,
  useMediaQuery,
} from "@material-ui/core";
import clsx from "clsx";
import { useStyles } from "./styles";
import { parseNumberInputValue } from "src/helpers/calendar/parseNumberInput";
import CheckboxIcon from "src/assets/icons/web/checkbox.svg";
import CheckboxCheckedIcon from "src/assets/icons/web/checkboxChecked.svg";

export const ScheduleFields = ({
  date,
  subTitle,
  time,
  scheduleId,
  calendarSchedule,
  setCalendarSchedule,
}: {
  date: string;
  subTitle?: string;
  time?: any;
  scheduleId: number;
  calendarSchedule: ICalendarSchedule;
  setCalendarSchedule: (value: React.SetStateAction<ICalendarSchedule>) => void;
}) => {
  const classes = useStyles();
  const isMobile = useMediaQuery("(max-width: 960px)");

  const calendarData = calendarSchedule?.[scheduleId]?.[date] || {};

  const onChangeField = (field: keyof typeof calendarData, value: number) => {
    setCalendarSchedule((prev) => ({
      ...prev,
      [scheduleId]: {
        ...prev[scheduleId],
        [date]: {
          ...prev[scheduleId][date],
          [field]: value,
        },
      },
    }));
  };

  return (
    <Card className={clsx(classes.fieldsCard, classes.inputWrapper)}>
      <CardContent style={{ padding: 0 }}>
        <Box>
          <div style={{ display: "flex" }}>
            <Typography>{date}</Typography>
            {subTitle && (
              <Typography style={{ marginRight: "20px" }}>
                {subTitle}
              </Typography>
            )}
            {time && <Typography>{time}</Typography>}
          </div>
          <Box display="flex" alignItems="center" justifyContent="flex-end">
            <FormControlLabel
              value="disable"
              control={
                <Checkbox
                  checked={Boolean(calendarData.is_disabled)}
                  onChange={(_, checked) =>
                    onChangeField("is_disabled", Number(checked))
                  }
                  color="primary"
                  icon={<CheckboxIcon />}
                  checkedIcon={<CheckboxCheckedIcon />}
                />
              }
              label="Disable"
              labelPlacement="start"
            />
          </Box>
        </Box>
        <Box width={1} display="flex" flexWrap="wrap">
          <Box width={isMobile ? 1 : 1 / 2} p={3} textAlign={"center"}>
            <TextField
              type="number"
              variant="outlined"
              label="Min tickets"
              value={parseNumberInputValue(calendarData.min_tickets)}
              onChange={(e) =>
                onChangeField("min_tickets", Number(e.target.value))
              }
              InputLabelProps={{
                shrink: true,
              }}
            />
          </Box>
          <Box width={isMobile ? 1 : 1 / 2} p={3} textAlign={"center"}>
            <TextField
              type="number"
              variant="outlined"
              label="Max tickets"
              value={parseNumberInputValue(calendarData.max_tickets)}
              onChange={(e) =>
                onChangeField("max_tickets", Number(e.target.value))
              }
              InputLabelProps={{
                shrink: true,
              }}
            />
          </Box>
        </Box>
      </CardContent>
    </Card>
  );
};
