import parsePhoneNumber from 'libphonenumber-js';

const phoneFields = ['phone', 'phone2', 'phone_client'];
const passwordFields = ['pass_clear', 'newPassword'];

export const basicValidation = (
  key: string,
  value: string | null | undefined | number
): boolean => {
  if (!value) return false;

  if (typeof value === 'number') return true;

  if (key === 'email') {
    return validateEmail(value);
  }

  if (phoneFields.includes(key)) {
    return validatePhone(value);
  }

  if (passwordFields.includes(key)) {
    return validatePassword(value);
  }

  return true;
};

export const validateEmail = (email: string) => {
  const checkEmail =
    /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return checkEmail.test(email);
};

export const validatePhone = (phone: string) => {
  const phoneNumber = parsePhoneNumber(phone, 'US');
  return phoneNumber?.isValid() || false;
};

export const validatePassword = (password: string) => {
  const checkPassword =
    /(?=^.{8,}$)((?=.*\d)((?=.*\W+)|(?=.*\_)))(?![.\n])(?=.*[A-Z])(?=.*[a-z]).*$/;
  return checkPassword.test(password);
};

export const validateUsername = (username: string) => {
  const re = /^[a-zA-Z0-9\.]+$/;
  return re.test(username);
};