import React, { useEffect, useState } from 'react';
import { Spiner } from 'src/web/components/Loaders';
import { NotificationsList } from 'src/web/components/Notifications/NotificationsList';
import { PageTable } from 'src/web/features/table/PageTable';
import { useStyles } from './styles';
import NotificationsIcon from 'src/assets/icons/notificationsPage.svg';
import FiltersMoblieIcon from 'src/assets/icons/filtersMobileIcon.svg';
import { useDispatch, useSelector } from 'react-redux';
import { sorting, filters } from './data';
import {
  setInitialPrm,
  setLoading,
  page,
  setOffset,
  prm as initialPrm,
  changeInitialSorting,
} from 'src/web/features/table/sliceTables';
import { useQuery } from '@apollo/client';
import { GET_NOTIFICATIONS } from 'src/api/notifications';
import { INotificationsPrm, normalizePrm } from './data/normalizedPrm';
import { auth } from 'src/redux/Authentication/sliceAuthentication';
import { Box, Button, useMediaQuery } from '@material-ui/core';
import { useNotificationsContext } from 'src/contexts/NotificationsContext';
import ConfirmModal from 'src/web/components/Notifications/ConfirmModal';
import MobileNotificationsFilter from 'src/web/components/Notifications/MobileNotificationsFilter';

const NotificationPage = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { user } = useSelector(auth);
  const prm = useSelector(initialPrm);
  const currentPage = useSelector(page);
  const { isReadAllProgress } = useNotificationsContext();
  const [isSpiner, setIsSpiner] = useState<boolean>(false);
  const [notifications, setNotifications] = useState<INotification[] | []>([]);
  const [currentPrm, setCurrentPrm] = useState<INotificationsPrm | null>(null);
  const [openConfirmModal, setOpenConfirmModal] = useState<boolean>(false);
  const { readAllNotifications } = useNotificationsContext();
  const mdMatches = useMediaQuery('(max-width: 1020px)');
  const [openMobileFilters, setOpenMobileFilters] = useState(false);

  const adaptiveSortingIds = ['createdAt', 'time', 'id', 'action'];
  const adaptiveSorting = sorting.filter((item) =>
    adaptiveSortingIds.includes(item.id)
  );

  const { error, refetch } = useQuery(GET_NOTIFICATIONS, {
    skip: currentPrm === null || currentPage !== 'notifications',
    variables: {
      prm: currentPrm,
    },
    onCompleted: (data) => {
      if (currentPrm && currentPrm.pager) {
        setNotifications((prev) => {
          return [...prev, ...data.v1_partners_getNotifications.notifications];
        });
      } else {
        setNotifications(data.v1_partners_getNotifications.notifications);
      }
      setIsSpiner(false);
      dispatch(setLoading({ type: 'scroll', value: false }));
      dispatch(setLoading({ type: 'change', value: false }));
      dispatch(setOffset(data.v1_partners_getNotifications.pager));
    },
  });

  useEffect(() => {
    dispatch(
      setInitialPrm({
        page: 'notifications',
        filter: filters,
        sorting: sorting,
        search: null,
        calendar: null,
        prm: {
          limit: 15,
          where: [[]],
        },
      })
    );
  }, [dispatch]);

  useEffect(() => {
    if (prm) {
      setCurrentPrm(normalizePrm(prm, user['cognito:username']));
    }
  }, [prm]);

  useEffect(() => {
    const filteredNotifications = notifications.filter(
      (notification) => notification.isReaded
    );
    setNotifications(filteredNotifications);
  }, [isReadAllProgress]);

  useEffect(() => {
    const currentSorting = mdMatches ? adaptiveSorting : sorting;
    dispatch(changeInitialSorting(currentSorting));
  }, [mdMatches]);

  const onChangeFilter = (showReaded?: boolean) => {
    setIsSpiner(true);
    setCurrentPrm((prev: any) => ({ ...prev, filter: { showReaded } }));
  };

  return (
    <>
      {isSpiner && <Spiner fixed />}
      <ConfirmModal
        open={openConfirmModal}
        confirmType="readall"
        setOpen={setOpenConfirmModal}
        action={() => readAllNotifications()}
      />
      <MobileNotificationsFilter
        open={openMobileFilters}
        onChangeFilter={onChangeFilter}
        setOpen={setOpenMobileFilters}
        showReaded={currentPrm?.filter?.showReaded}
      />
      <PageTable
        forbiddenReselect
        error={error}
        icon={<NotificationsIcon />}
        title="Notifications"
        content={
          <NotificationsList
            notifications={notifications}
            setNotifications={setNotifications}
            refetch={refetch}
            setIsSpiner={setIsSpiner}
            showReaded={currentPrm?.filter?.showReaded}
          />
        }
        headerElement={
          <Box className={classes.headerElementWrapper}>
            {/* <Button
              className={classes.readAllButton}
              onClick={() => setOpenConfirmModal(true)}>
              Mark all as read
            </Button> */}
            <Box className={classes.moreFiltersButtonMobile}>
              <Button
                style={{
                  padding: '0',
                  minWidth: '28px',
                  marginRight: '4px',
                  height: '30px',
                }}
                variant="text"
                onClick={() => setOpenMobileFilters(true)}>
                <FiltersMoblieIcon />
              </Button>
            </Box>
          </Box>
        }
      />
    </>
  );
};

export default NotificationPage;
