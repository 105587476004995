import React from "react";
import { useForm } from "react-hook-form";
import { Link } from "react-router-dom";
import valid from "./validation";
import { TextField, Box, Button } from "@material-ui/core/";
import BackIcon from "src/assets/icons/web/backLink.svg";
import { useStyles } from "./styles";

export const ForgotPasswordForm = ({ onSubmit }: any) => {
  const classes = useStyles();
  const { register, handleSubmit, errors } = useForm();

  return (
    <>
      <form onSubmit={handleSubmit(onSubmit)} className="w-full">
        <Box mb={3}>
          <TextField
            name="email"
            fullWidth
            className={classes.customInput}
            inputRef={register({ ...valid.email })}
            label="Email"
            placeholder="your email"
            InputProps={{
              startAdornment: <></>,
            }}
            variant="outlined"
            error={errors.email ? true : false}
            helperText={errors?.email?.message}
            size="small"
          />
        </Box>
        <Button
          fullWidth={true}
          className={classes.authButton}
          variant="contained"
          color="primary"
          type="submit"
        >
          Remind password
        </Button>
      </form>
      <Box mt={1}>
        <Button
          fullWidth
          className={classes.backButton}
          variant="text"
          component={Link}
          to="/login"
        >
          <BackIcon style={{ marginRight: "8px" }} />
          <div style={{ fontSize: "14px" }}>Back to login page</div>
        </Button>
      </Box>
    </>
  );
};
