import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles(() => ({
  listTitle: {
    color: '#333333',
    fontWeight: 500,
    letterSpacing: '0.04em',
    fontSize: '14px',
    lineHeight: '20px',
    paddingLeft: '20px',
    marginBottom: '5px',
    textTransform: 'none',
    display: 'flex',
    justifyContent: 'space-between',
  },
  listItem: {
    marginLeft: '20px',
    padding: '10px 0 10px 20px',
    borderRadius: '15px',
    textTransform: 'capitalize',
    color: '#C4C4C4',
    margin: "5px 0",
    fontSize: '12px',
    fontWeight: 400,
    '&.MuiButtonBase-root': {
      width: '60%',
      justifyContent: 'flex-start',
      paddingTop: '6px',
      paddingBottom: '6px',
    },
    '&.Mui-selected': {
      position: 'relative',
      background: '#f3fdfc',
      color: '#0DD1C5',
      '&::before': {
        content: "''",
        display: 'block',
        position: 'absolute',
        right: '15px',
        width: '10px',
        height: '10px',
        borderRadius: '50%',
        background: '#f3fdfc',
      },
    },
  },
  clearBtn: {
    color: '#2196f3',
    marginLeft: '15px',
    padding: '2px !important',
    fontSize: '12px',
    textTransform: 'none',
  },
}));
