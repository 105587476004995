import { registerRootComponent } from 'expo';
import React from 'react';
import Auth from '@aws-amplify/auth';
import Amplify from 'aws-amplify';

import { Provider } from 'react-redux';
import { store, history } from './redux/store';
import { ConnectedRouter } from 'connected-react-router/immutable';

import {
  ApolloProvider,
  ApolloClient,
  InMemoryCache,
  HttpLink,
} from '@apollo/client';
import { setContext } from '@apollo/client/link/context';

import App from './App';
import awsConfig from './awsConfig';
import config from './config';

const jwtToken = async () => {
  const session = await Auth.currentSession();
  const token = session.getAccessToken().getJwtToken();
  return token;
};

const httpLink = new HttpLink({
  uri: config.apiUrl,
});

const authLink = setContext(async (_, { headers }) => {
  try {
    const token = await jwtToken();
    return {
      headers: {
        ...headers,
        authorization: token || null,
      },
    };
  } catch (e) {
    return {
      headers: {
        ...headers,
        'X-Api-Key': config.apiKey,
      },
    };
  }
});

const client = new ApolloClient({
  link: authLink.concat(httpLink),
  cache: new InMemoryCache({
    addTypename: false,
    typePolicies: {},
  }),
  defaultOptions: {
    watchQuery: {
      fetchPolicy: 'cache-and-network',
    },
  },
});

Amplify.configure(awsConfig);

const RootComponet = () => {
  return (
    <ApolloProvider client={client}>
      <Provider store={store}>
        <ConnectedRouter history={history}>
          <App />
        </ConnectedRouter>
      </Provider>
    </ApolloProvider>
  );
};

registerRootComponent(RootComponet);
