import React, { Dispatch, SetStateAction, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Box, Typography } from '@material-ui/core';
import LogoutIcon from 'src/assets/icons/logOut.svg';
import { auth, signOut } from 'src/redux/Authentication/sliceAuthentication';
import { useStyles } from './styles';

export const Header = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { user } = useSelector(auth);

  const onSignOut = () => dispatch(signOut());

  return (
    <>
      <Box className={classes.headerRoot}>
        {/* <Box className={classes.left_container}>
          <Typography className={classes.title}>
            {user?.given_name + " " + user?.family_name}
            <span className={classes.sub_title}>
              {user?.["cognito:username"]}
            </span>
          </Typography>
        </Box> */}
        <Box className={classes.headerIcons}>
          <Box className={classes.exitApp}>
            <Box onClick={onSignOut}>
              <LogoutIcon />
            </Box>
          </Box>
        </Box>
      </Box>
    </>
  );
};
