import { Box, FormControl, InputLabel } from "@material-ui/core";
import React from "react";
import { useDispatch } from "react-redux";
import { useStyles } from "../styles";
import Geosuggest from "react-geosuggest";
import { InputError } from "src/web/components/UI/InputError";
import { ITextfield } from "../interface";

type Props = {
  formInput: ITextfield;
  errorMessage: string;
  locations: any;
  data: any;
  handleChange: any;
  disabled?: boolean;
};

(function () {
  if (!document.querySelector("#google-maps-api")) {
    const script = document.createElement("script");
    script.id = "google-maps-api";
    script.src =
      "https://maps.googleapis.com/maps/api/js?key=AIzaSyBwgqE3Lb3rjI9tsFxP8_ZSctA_BmT88uM&libraries=places&language=en";
    script.async = true;

    document.body.appendChild(script);
  }
})();

export const GoogleTitleElement = ({
  formInput,
  errorMessage,
  disabled,
  data,
  locations,
  handleChange,
}: Props) => {
  const classes = useStyles();

  const dispatch = useDispatch();

  const isGoogleError: boolean = !!errorMessage && !data.google_title;

  const onChangePlace = (value: any) => {
    let removeItems = ["utc_offset"];
    let gmapsKeys =
      value &&
      Object.keys(value.gmaps).filter((val) => {
        return !removeItems.includes(val);
      });

    if (value) {
      const locationValue = (type: string) => {
        const location = value.gmaps.address_components.find((item: any) =>
          item.types.includes(type)
        );
        return location && (location.long_name || location.short_name);
      };

      const cityValue = locations.cities.find(
        (item: any) => item.name === locationValue("locality")
      );

      const updateData = {
        latitude: value.location.lat,
        longitude: value.location.lng,
        google_place_id: value.placeId,
        address: value.gmaps.formatted_address,
        google_title: value.label,
        google_place_api_response: JSON.stringify(value.gmaps, gmapsKeys),
        city: locationValue("locality"),
        state: locationValue("administrative_area_level_1"),
        city_id: cityValue?.id || 0,
        state_id: cityValue?.state_id || 0,
        country_id: cityValue?.country_id || 0,
        zip: locationValue("postal_code") || "",
      };

      return handleChange(updateData);
    }
    const emptyData = {
      latitude: "",
      longitude: "",
      google_place_id: "",
      address: "",
      google_title: "",
      google_place_api_response: "",
      city: "",
      state: "",
      zip: "",
      city_id: 0,
      state_id: 0,
      country_id: 0,
    };
    handleChange(emptyData);
  };

  if (formInput.textType === "google") {
    return (
      <Box className={classes.googlePlacesContainer}>
        <FormControl
          variant="outlined"
          fullWidth
          className={
            classes.geosuggestContainer +
            `${isGoogleError ? " " + classes.geosuggestContainerError : ""}`
          }
          error={isGoogleError}
          disabled={disabled}
        >
          <InputLabel>{formInput.label}</InputLabel>
          <Geosuggest
            name={formInput.name}
            autoFocus
            onSuggestSelect={onChangePlace}
            placeholder={formInput.placeholder}
            radius={100}
            initialValue={data.google_title}
            location={
              google &&
              new google.maps.LatLng(
                Number(data?.latitude),
                Number(data?.longitude)
              )
            }
          />
          {isGoogleError && <InputError message={"Please fill this in"} />}
        </FormControl>
      </Box>
    );
  }

  return <></>;
};
