import React from 'react';
import DatePicker from 'react-datepicker';
import { Box, InputLabel } from '@material-ui/core';
import moment from 'moment';
import { IDatePickerProps } from '../DatePickerFilter/interfaces';
import { useStyles } from './styles';
import DateCalendar from 'src/assets/icons/DateCalendar.svg';
import 'react-datepicker/dist/react-datepicker.css';
import clsx from 'clsx';

export const DateRangeFilter: React.FC<IDatePickerProps> = ({
  startDate,
  endDate,
  onChange,
  placeholder,
  label,
  props,
  width,
}) => {
  const classes = useStyles();
  const dateFormat = 'YYYY-MM-DD';

  return (
    <Box>
      <InputLabel className={classes.label}>{label}</InputLabel>
      <Box className={clsx(classes.datePickerWrapper, classes.rangeWrapper)}>
        <DateCalendar />
        <DatePicker
          selected={
            startDate ? new Date(moment(startDate).format('MM/DD/yyyy')) : null
          }
          onChange={(dates) => {
            if (dates && !(dates instanceof Date)) {
              const start = dates[0]
                ? moment(dates[0]).format(dateFormat)
                : null;
              const end = dates[1] ? moment(dates[1]).format(dateFormat) : null;
              return onChange({ start, end });
            }
          }}
          startDate={
            startDate ? new Date(moment(startDate).format('MM/DD/yyyy')) : null
          }
          endDate={
            endDate ? new Date(moment(endDate).format('MM/DD/yyyy')) : null
          }
          placeholderText={placeholder}
          dateFormat={'MMM d, yyyy'}
          selectsRange
          isClearable
          className={classes.inputWrapper}
          {...props}
        />
      </Box>
    </Box>
  );
};
