import React, { useState, useEffect, useCallback } from "react";
import { useDispatch } from "react-redux";
import { IconButton, Slide } from "@material-ui/core/";
import { Alert } from "@material-ui/lab";
import CloseIcon from "@material-ui/icons/Close";
import ErrorIcon from "src/assets/icons/web/notifications/error.svg";
import InfoIcon from "src/assets/icons/web/notifications/info.svg";
import SuccessIcon from "src/assets/icons/web/notifications/success.svg";
import WarningIcon from "src/assets/icons/web/notifications/warning.svg";
import {
  removeNotification,
  INotification,
} from "src/redux/Notifications/sliceNotifications";
import { useStyles } from "./styles";

export const Notification: React.FC<{ notification: INotification }> = ({
  notification,
}) => {
  const classes = useStyles();
  const [open, setOpen] = useState(true);
  const dispatch = useDispatch();

  const handleClose = useCallback(() => {
    setOpen(false);
    setTimeout(() => dispatch(removeNotification(notification.key)), 400);
  }, [setOpen, dispatch, notification]);

  useEffect(() => {
    if (notification) {
      setTimeout(() => handleClose(), notification.duaration);
    }
  }, [notification, handleClose]);

  if (!notification) {
    return null;
  }

  const getAlertIcon = (
    type: "error" | "info" | "success" | "warning" | undefined
  ) => {
    switch (type) {
      case "error":
        return <ErrorIcon />;
      case "warning":
        return <WarningIcon />;
      case "info":
        return <InfoIcon />;
      case "success":
        return <SuccessIcon />;
    }
  };

  return (
    <Slide
      in={open}
      direction={notification.position === "interior" ? "up" : "down"}
    >
      <Alert
        className={classes.alert}
        variant="filled"
        severity={notification.type}
        icon={getAlertIcon(notification.type)}
        action={
          <IconButton color="inherit" onClick={handleClose}>
            <CloseIcon fontSize="inherit" />
          </IconButton>
        }
      >
        {notification.message}
      </Alert>
    </Slide>
  );
};
