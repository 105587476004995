import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme: any) => ({
  root: {
    width: '100%',
    height: '100vh',
    zIndex: 1003,
    transition: 'all ease 0.4s',
    overflowX: 'hidden',
    '& .MuiPaper-root': {
      display: 'block',
      width: '100%',
      maxWidth: '300px',
      height: '100vh',
      background: theme.trip_shock_theme.environment.trip_shock_black,
      transition: 'all ease 0.4s',
      borderRadius: '0px 6px 6px 0px',
    },
  },
  drawerOpen: {
    maxWidth: '300px',
    '& .MuiPaper-root': {
      transform: 'translateX(0)',
      overflow: 'hidden',
    },
    [theme.breakpoints.down('sm')]: {
      maxWidth: '280px',
      '& .MuiPaper-root': {
        maxWidth: '280px',
        transform: 'translateX(0)',
      },
    },
    [theme.breakpoints.down('xs')]: {
      maxWidth: '0',
    },
  },
  drawerClose: {
    maxWidth: '72px',
    '& .MuiPaper-root': {
      maxWidth: '72px',
      overflow: 'hidden',
    },
    [theme.breakpoints.down('xs')]: {
      maxWidth: '0',
      '& .MuiPaper-root': {
        maxWidth: '280px',
        transform: 'translateX(-100%)',
      },
    },
    '& $container': {
      padding: '12px 12px',
    },
  },
  scrollbarWrapper: {
    width: '100%',
    height: '100vh',
    position: 'relative',
  },
  container: {
    padding: '12px 20px',
  },
}));
