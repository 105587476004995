import React from "react";
import {
  Box,
  Checkbox,
  FormControlLabel,
  Typography,
  useMediaQuery,
} from "@material-ui/core";

import { useStyles } from "../styles";
import { normalizeTime } from "src/helpers/calendar/normalizeTime";
import CheckboxIcon from "src/assets/icons/web/checkbox.svg";
import CheckboxCheckedIcon from "src/assets/icons/web/checkboxChecked.svg";

type Props = {
  trip: ITripCalendarData;
  setTrip: React.Dispatch<React.SetStateAction<ITripCalendarData>>;
};

export const SchedulesBlock = ({ trip, setTrip }: Props) => {
  const classes = useStyles();
  const isMobile = useMediaQuery("(max-width: 960px)");

  const onDataChange = (index: number) => {
    const oldValues = structuredClone(trip.schedules);
    oldValues[index].checked = !oldValues[index].checked;

    setTrip((prev) => ({
      ...prev,
      schedules: oldValues,
    }));
  };

  const handleSelect = (isCheck: boolean) => {
    const oldValues = structuredClone(trip.schedules);
    const values = oldValues.map((item) => ({
      ...item,
      checked: isCheck,
    }));

    setTrip((prev) => ({
      ...prev,
      schedules: values,
    }));
  };

  return (
    <Box mt={4}>
      <Box width={1} p={1} mb={3} display="flex">
        <Typography
          style={{
            color: "#0073bb",
            cursor: "pointer",
            textDecoration: "underline dotted",
          }}
          onClick={() => handleSelect(true)}
        >
          Select All
        </Typography>
        &nbsp;|&nbsp;
        <Typography
          style={{
            color: "#0073bb",
            cursor: "pointer",
            textDecoration: "underline dotted",
          }}
          onClick={() => handleSelect(false)}
        >
          Unselect All
        </Typography>
      </Box>
      <Box width={1} p={1} display="flex" flexWrap="wrap">
        {trip && !!trip.schedules?.length ? (
          trip.schedules.map((item, index: number) => (
            <Box
              p={1}
              key={item.id}
              className={classes.checkboxItem}
              width={isMobile ? 2 / 5 : 1 / 5}
            >
              <FormControlLabel
                control={
                  <Checkbox
                    color="default"
                    icon={<CheckboxIcon />}
                    checkedIcon={<CheckboxCheckedIcon />}
                    checked={item.checked}
                    onChange={() => {
                      onDataChange(index);
                    }}
                  />
                }
                label={normalizeTime(item.hour, item.minute)}
                labelPlacement="end"
              />
            </Box>
          ))
        ) : (
          <></>
        )}
      </Box>
    </Box>
  );
};
