import React from "react";
import { DateRangeFilter } from "../../UI/DatePickers/DatePickerFilter/DatePickerRangeFilter";

type Props = {
  label: string;
  onChange: (dates: { start: string; end: string }) => void;
  startDate: string;
  endDate: string;
  width?: string;
};

export const ReviewsDateFilter: React.FC<Props> = ({
  label,
  onChange,
  startDate,
  endDate,
  width
}) => {
  return (
    <DateRangeFilter
      label={label}
      startDate={startDate}
      endDate={endDate}
      placeholder={"Choose date"}
      onChange={onChange}
      width={width}
    />
  );
};
