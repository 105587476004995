import { Box, Button, IconButton } from "@material-ui/core";
import moment from "moment";
import React from "react";
import { DateRangeFilter } from "src/web/components/UI/DatePickers/DateFilter/DateRangeFilter";
import DeleteIcon from "src/assets/icons/web/delete.svg";
import AddIcon from "src/assets/icons/web/addHeaderButton.svg";
import { useStyles } from "../styles";

type Props = {
  dates: {
    date_from: string | null;
    date_to: string | null;
  }[];
  setDates: React.Dispatch<
    React.SetStateAction<
      {
        date_from: string | null;
        date_to: string | null;
      }[]
    >
  >;
};

export const DatesBlock = ({ dates, setDates }: Props) => {
  const classes = useStyles();

  const onChange = (
    index: number,
    newDates: {
      date_from: string | null;
      date_to: string | null;
    }
  ) => {
    setDates((prev) => {
      const prevDates = structuredClone(prev);
      prevDates[index] = newDates;

      return prevDates;
    });
  };

  const addNewDatesRange = () => {
    setDates((prev) => [
      ...prev,
      {
        date_from: moment()
          .startOf("day")
          .tz("America/Chicago", true)
          .utc()
          .format("YYYY-MM-DD"),
        date_to: moment()
          .startOf("day")
          .add(1, "day")
          .tz("America/Chicago", true)
          .utc()
          .format("YYYY-MM-DD"),
      },
    ]);
  };

  const removeDatesRange = (index: number) => {
    setDates((prev) => prev.filter((_, idx) => idx !== index));
  };

  return (
    <Box
      marginTop={4}
      display="flex"
      alignItems="center"
      flexWrap="wrap"
      height="fit-content"
    >
      {dates.map(({ date_from, date_to }, index) => (
        <Box display="flex" alignItems="center" mb={2}>
          <DateRangeFilter
            key={`${date_to}` + `${date_from}` + index}
            label="Date ranges"
            placeholder="Date ranges"
            startDate={date_from?.toLocaleString()}
            endDate={date_to?.toLocaleString()}
            onChange={({ start, end }: any) => {
              onChange(index, {
                date_from: start
                  ? moment(start)
                      .startOf("day")
                      .tz("America/Chicago", true)
                      .utc()
                      .format("YYYY-MM-DD")
                  : null,
                date_to: end
                  ? moment(end)
                      .startOf("day")
                      .tz("America/Chicago", true)
                      .utc()
                      .format("YYYY-MM-DD")
                  : null,
              });
            }}
          />
          {dates.length > 1 && (
            <IconButton
              style={{ width: "40px", height: "40px" }}
              onClick={() => removeDatesRange(index)}
            >
              <DeleteIcon />
            </IconButton>
          )}
        </Box>
      ))}
      <IconButton className={classes.addIconButton} onClick={addNewDatesRange}>
        <AddIcon />
      </IconButton>
    </Box>
  );
};
